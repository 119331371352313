import React from "react";

import classes from "./styles.module.scss";
import bjp from "../../assets/img/bjp.png"
import inc from "../../assets/img/party/inc.png"
import btp from "../../assets/img/party/btp.jpg"
import ncp from "../../assets/img/party/ncp.png"
import download from "../../assets/img/download.png"


export const getParty = (party) => {
    if (party == "Indian National Congress" || party == "INC")
        return inc;
    else if (party == "Bharatiya Janata Party" || party == "BJP")
        return bjp
    else if (party == "Bharatiya Tribal Party" || party == "BTP")
        return btp
    else if (party == "Nationalist Congress Party" || party == "NCP")
        return ncp
    else
        return download
}

export const getPartyName = (party) => {
    if (party == "Indian National Congress")
        return "INC";
    else if (party == "Bharatiya Janata Party")
        return "BJP"
    else if (party == "Bharatiya Tribal Party")
        return "BPT"
    else if (party == "Nationalist Congress Party")
        return "NCP"
    else if (party == "Independent")
        return "Ind."
    else
        return "Others"
}

const InfoCard = (props) => {
    return (
        <div className={classes.infoCard}>
            <div className={classes.name}>Winning Candidate</div>
            <img className={classes.image} src={getParty(props.party)} alt="" />
            <div className={classes.name}>{props.value}</div>
        </div>
    )
}

export default InfoCard;