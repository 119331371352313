import React, { useState } from "react";
import Table from "../../components/tableFrontendPagination"
import Button from "../../components/button"


import classes from "./styles.module.scss";

const SingleSection = (props) => {
    return (
        <div className={classes.section}>
            {
                !props.hideButton
                    ?
                    <div className={classes.createBtn}>
                        <Button type="submit" className="auto" onClick={props.handleModal} title={props.title}></Button>
                    </div>
                    : null
            }
            {
                props.pagination
                    ?
                    <Table
                        columns={props.column}
                        rows={props.rows}
                        title={''}
                        hideSearch={true}
                        pageNumber={props.paginationData.pageNo}
                        fetchSelectedPage={(i) => props.updateTable("pageNo", i)}
                        totalSize={props.totalSize}
                    />
                    // null
                    : <Table
                        columns={props.column}
                        rows={props.rows}
                        title={''}
                        hideSearch={true}
                        hideFilter={true}
                    />
            }

        </div>
    );
};

export default SingleSection;
