// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/app";



const firebaseConfig = {
    apiKey: "AIzaSyDTv3XIEPXaoIry1rW099xOW7ldB89YR4U",
    authDomain: "gujarat-8304d.firebaseapp.com",
    databaseURL: "https://gujarat-8304d-default-rtdb.firebaseio.com",
    projectId: "gujarat-8304d",
    storageBucket: "gujarat-8304d.appspot.com",
    messagingSenderId: "287859169158",
    appId: "1:287859169158:web:aa100e152033cfd676fe07",
    measurementId: "G-6DN2L5S28W"
};

// Initialize Firebase
let fbase = firebase.initializeApp(firebaseConfig);
export default fbase;
