import React, { useEffect, useState } from 'react';

import WorkProgress from "./workProgress"
import KaryakartaPerformance from ".//karyakartaPerformance"
import Overall from "./overall"
import Influencer from "./influencer"
import Beneficiary from "./beneficiary"
import NoticeComplainEvent from "../../noticeComplainEvent"
import { connect } from "react-redux";


import { apiFunction } from "../../../apiCalls/functions/api"
import { GET_DROPDOWN, GET_SECTIONS, GET_BOOTH, GET_KARYAKARTA } from "../../../apiCalls/urls/dashboard/dashboard"
import { getAllBooth, getAllSections, getAllKaryakarta, getLastUpdatedTime } from "../../../apiCalls/apis/dropdown";
import LastUpdated from "../../../components/lastUpdated"


import classes from "./styles.module.scss";

const Karyakarta = (props) => {

    const [age, setAge] = useState([])


    useEffect(async () => {
        if (props.level != "constituency") {
            let date = await getLastUpdatedTime()
            setAge(date)
        }
        else {
            setAge("")
        }

    }, [props.level, props.currentDistrict])

    useEffect(() => {
        // console.log(props.constituencyData, "hello")
        getInitialData()
        if (props.constituencyData.constituency_name) {
            getBooth(props.constituencyData.constituency_name)
        }
    }, [])

    useEffect(() => {
        if (props.constituencyData.constituency_name) {
            getBooth(props.constituencyData.constituency_name)
        }
    }, [props.constituencyData.constituency_name])

    const [allCaste, setAllCaste] = useState([])
    const [allReligion, setAllReligion] = useState([])
    const [allOccupation, setAllOccupation] = useState([])
    const [allInfluencer, setAllInfluencer] = useState([])
    const [allBooth, setAllBooth] = useState([])
    const [allSection, setAllSection] = useState([])
    const [allKaryakarta, setAllKaryakarta] = useState([])


    const updateState = (section, data) => {
        switch (section) {
            case "religion":
                setAllReligion(data);
                break;
            case "caste":
                setAllCaste(data);
                break;
            case "occupation":
                setAllOccupation(data);
                break;
            case "influencer_type":
                setAllInfluencer(data);
                break;
        }
    }

    const getInitialData = async () => {
        let list = ["religion", "caste", "occupation", "influencer_type"]
        for (let i = 0; i < list.length; i++) {
            let result = await apiFunction(GET_DROPDOWN + list[i] + "/list", "get", {}, false)
            // console.log(result)
            if (result.status) {
                updateState(list[i], result.data)
            }
        }
    }

    const getBooth = async (name) => {
        let result = await getAllBooth(name)
        setAllBooth(result)
    }

    const getSections = async (booth) => {
        let result = await getAllSections(booth, props.constituencyData.id)
        setAllSection(result)
    }

    const getKaryakarta = async (booth) => {
        let result = await getAllKaryakarta(booth, props.constituencyData.constituency_name)
        setAllKaryakarta(result)
    }




    // console.log(allBooth)

    return (
        <>
            <WorkProgress
                constituencyData={props.constituencyData}
                level={props.level}
                currentDistrict={props.currentDistrict}
            />
            <KaryakartaPerformance
                allReligion={allReligion}
                allCaste={allCaste}
                allOccupation={allOccupation}
                allInfluencer={allInfluencer}
                allBooth={allBooth}
                getSections={getSections}
                getKaryakarta={getKaryakarta}
                allSection={allSection}
                allKaryakarta={allKaryakarta}
                constituencyData={props.constituencyData}
                level={props.level}
                currentDistrict={props.currentDistrict}
            />
            <Influencer
                allReligion={allReligion}
                allCaste={allCaste}
                allBooth={allBooth}
                allInfluencer={allInfluencer}
                constituencyData={props.constituencyData}
                level={props.level}
                currentDistrict={props.currentDistrict}
            />
            <Overall
                allReligion={allReligion}
                allCaste={allCaste}
                allBooth={allBooth}
                allInfluencer={allInfluencer}
                constituencyData={props.constituencyData}
                level={props.level}
                currentDistrict={props.currentDistrict}
            />
            {/* <Beneficiary /> */}
            {
                props.level === "constituency"
                    ?
                    <NoticeComplainEvent
                        constituencyData={props.constituencyData}
                        level={props.level}
                        currentDistrict={props.currentDistrict}
                    />
                    : null

            }
            <LastUpdated age={age} />

        </>
    )
}
const mapStateToProps = (state) => {
    return {
        firebase: state.Firebase
    }
}

export default connect(mapStateToProps, null)(Karyakarta);