import React, { useState, useEffect } from 'react';
import Table from "../../../components/tableFrontendPagination"
import Filter from "./filter";
import ModalLayout from "../../../components/modalLayout";
import QuestionModal from "./questionModal"
import HorizontalTabs from "../../../components/tabs"
import Analytics from "./analytics";
import { columns } from "./columns";
import { getAllBooth, getLastUpdatedTime } from "../../../apiCalls/apis/dropdown";
import { loaderStart, loaderEnd, notification } from "../../../components/notification"
import { apiFunction } from "../../../apiCalls/functions/api"
import { ADD_QUESTIONS, GET_QUESTIONS, GET_QUESTIONS_ANALYTICS } from "../../../apiCalls/urls/dashboard/dashboard"
import { getFetchAPIData } from "../../../shared/pureFunction"
import classes from "../constituency/styles.module.scss";
import LastUpdated from "../../../components/lastUpdated"
const AssignVoter = (props) => {
    console.log(props)
    const [section, setSection] = useState("graph")
    const [allBooth, setAllBooth] = useState([])
    const [selected, setSelected] = useState({
        booth: "",
    })
    const [questionModal, setQuestionModal] = useState(false)
    const [pagination, setPagination] = useState({
        search: "",
        sort: "",
        pageNo: 1,
        limit: 10,
        filter: ""
    })
    const [total, setTotal] = useState(0)
    const [allQuestions, setAllQuestions] = useState([])
    const [questionAnalytics, setQuestionAnalytics] = useState([])
    const [age, setAge] = useState([])


    useEffect(async () => {
        if (props.level != "constituency") {
            let date = await getLastUpdatedTime()
            setAge(date)
        }
        else {
            setAge("")
        }

    }, [props.level, props.currentDistrict])

    useEffect(() => {
        if (props.constituencyData.constituency_name) {
            getBooth(props.constituencyData.constituency_name)
        }
    }, [props.constituencyData.constituency_name])

    useEffect(() => {
        console.log(props.level)
        if (props.level) {
            getQuestionAnalytics()
        }
    }, [props.level, props.currentDistrict, props.constituencyData?.id])

    const getQuestionAnalytics = async () => {
        loaderStart()
        setQuestionAnalytics([])
        let data = getFetchAPIData(props.level, props.currentDistrict, props.constituencyData?.id)
        let result = null;
        if (data)
            result = await apiFunction(GET_QUESTIONS_ANALYTICS, "post", data, true)
        // console.log(result)
        if (result.status && result.data.length > 0) {
            let allQuestions = []
            result.data.forEach((q) => {
                let index = allQuestions.findIndex(a => a.question_id === q.question_id)
                if (index >= 0) {
                    let single = allQuestions[index]
                    single.value.push(q.count)
                    single.label.push(q.choice)
                }
                else {
                    let single = {
                        question_id: q.question_id,
                        question: q.question,
                        value: [q.count],
                        label: [q.choice]
                    }
                    allQuestions.push({ ...single })
                }
            })
            setQuestionAnalytics(allQuestions)
            // console.log(allQuestions)
        }
        loaderEnd()
    }

    useEffect(() => {
        if (selected.booth) {
            getQuestions(props.constituencyData.constituency_name)
        }
    }, [selected.booth])

    const getQuestions = async () => {
        loaderStart()
        let data = {
            "constituency": props.constituencyData.id,
            "boothNo": selected.booth != "all" ? selected.booth : null
        }
        let result = await apiFunction(GET_QUESTIONS, "post", data, true)
        if (result.status) {
            let _rows = []
            result.data.map((r, i) => {
                _rows.push({
                    sn: i + 1,
                    question: r.question,
                    type: r.question_type,
                    option: generateChoices(r.choices)
                })
            })
            setAllQuestions(_rows)
        }
        loaderEnd()
    }

    const generateChoices = (res) => {
        let choices = [];
        res.map((r) => {
            choices.push(r.choice)
        })
        return choices.join(" ,")
    }

    const getBooth = async (name) => {
        let result = await getAllBooth(name)
        setAllBooth([{ booth_number: "all", booth_name: "All Booth" }, ...result,])
    }

    const handleChange = (e, section) => {
        let _selected = { ...selected }
        _selected[section] = e;
        setSelected(_selected)
    }

    const handleSubmit = async (data) => {
        let _data = {
            ...data
        }
        _data.constituency = props.constituencyData.id;
        _data.boothNo = null;
        if (selected.booth !== "all")
            _data.boothNo = parseInt(selected.booth);
        // console.log(_data)
        loaderStart()
        let result = await apiFunction(ADD_QUESTIONS, "post", _data, true)
        if (result.status) {
            notification(true, "Question added successfully")
            setQuestionModal(false)
            getQuestions()
        }
        else {
            notification(false, result.message)
        }
    }

    const updateTable = () => { }

    const tabJson = [
        {
            title: "Analytics",
            name: "graph",
            component: <>
                {questionAnalytics?.length > 0
                    ?
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Analytics
                            data={questionAnalytics}
                            constituencyId={props.constituencyData?.id}
                        />
                        <LastUpdated age={age} />
                    </div>
                    : null
                }

            </>
        },
        {
            title: "Questions",
            name: "question",
            component: <div style={{ display: "flex", 'flexDirection': "column", marginTop: 15 }}>
                <Filter
                    allBooth={allBooth}
                    selected={selected}
                    handleChange={handleChange}
                    setQuestionModal={setQuestionModal}
                />
                <Table
                    columns={columns}
                    rows={allQuestions}
                    title={''}
                    fetchSelectedPage={(p) =>
                        updateTable('pageNo', p)
                    }
                    handleSearch={(s) =>
                        updateTable('search', s)
                    }
                    handleFilter={(s) => updateTable("filter", s)}
                    totalSize={total}
                    pageNumber={pagination.pageNo}
                    hideSearch={true}
                />
            </div>
        },
    ]


    const handleTabClick = (section) => {
        // console.log(section)
        setSection(section)
    }

    const getSelectionIndex = () => {
        let index = tabJson.findIndex((f) => f.name == section)
        return index >= 0 ? index : 0
    }

    const checkTabJson = (tabJson) => {
        if (props.level != "constituency") {
            // console.log([tabJson[0]])
            return [tabJson[0]]
        }
        return tabJson;
    }

    // console.log(questionAnalytics)

    return (
        <>
            <HorizontalTabs
                tabJson={checkTabJson(tabJson)}
                onClick={handleTabClick}
                selectedIndex={getSelectionIndex()}
            />

            {
                questionModal
                    ?
                    <ModalLayout
                        size="small"
                        isOpen={true}
                        toggleModal={() => setQuestionModal(false)}
                        title="Add Question"
                    >
                        <QuestionModal
                            constituencyData={props.constituencyData}
                            handleSubmit={(data) => handleSubmit(data, "event")}
                        />
                    </ModalLayout>
                    : null
            }
        </>
    )
}

export default AssignVoter;