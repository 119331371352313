import React, { useState, useEffect } from 'react';
import Table from "../../../components/tablePagination"
import { voterColumns } from "./columns/overall"
const Overall = (props) => {
    return (
        <>
            <Table
                columns={voterColumns}
                rows={props.rows}
                title={''}
                fetchSelectedPage={(p) =>
                    props.updateTable('pageNo', p)
                }
                handleSearch={(s) =>
                    props.updateTable('search', s)
                }
                handleFilter={(s) => props.updateTable("filter", s)}
                totalSize={props.total}
                pageNumber={props.pagination.pageNo}
                queryString={props.pagination.search}
                searchText="Search here."
                hideFilter={true}
                hideSearch={true}
                style={{ width: "95%" }}
                itemsCountPerPage={20}
            />
            <br />
        </>
    )
}

export default Overall