import React, { useState, useEffect } from 'react';

import classes from "./styles.module.scss";

const Election = (props) => {
    return (
        <>
            <table width="100%" border="0" cellSpacing="0" cellPadding="0" className={classes.grid}>
                <thead>
                    <tr>
                        <th rowSpan="2" className={classes.tal}>Gender</th>
                        <th colSpan="6">Age Range</th>
                        <th rowSpan="2">Total</th>
                    </tr>
                    <tr>
                        <th>18-24</th>
                        <th>25-34</th>
                        <th>35-44</th>
                        <th>45-54</th>
                        <th>55-64</th>
                        <th>65+</th>
                        {/* <th>Total</th> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        props.data.map((d, i) => (
                            <tr key={i}>
                                <td className={classes.tal}>{d.SEX}</td>
                                <td className={classes.tac}>{d["18-24"]}</td>
                                <td className={classes.tac}>{d["25-34"]}</td>
                                <td className={classes.tac}>{d["35-44"]}</td>
                                <td className={classes.tac}>{d["45-54"]}</td>
                                <td className={classes.tac}>{d["55-64"]}</td>
                                <td className={classes.tac}>{d["65>="]}</td>
                                <td className={classes.tac}>{d.total}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    )
}

export default Election;