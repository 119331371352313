export const pageType = [
    {
        label: "Election",
        value: "Constituency",
        route: "election"
    },
    {
        label: "Sangathan",
        value: "Mandal",
        route: "sangathan"
    },
    {
        label: "Voters",
        value: "Voters",
        route: "voters"
    },
    {
        label: "Assign Karyakarta",
        value: "Assign-Karyakarta",
        route: "assign-karyakarta"
    }
]

export const reportsPage = [
    {
        label: "Karyakarta",
        value: "Karyakarta",
        route: "karyakarta"
    },
    {
        label: "Graph",
        value: "Graph",
        route: "graph"
    },
    {
        label: "Question",
        value: "Question",
        route: "questions"
    }
]