import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Homepage from "./pages/homepage";
import Logout from "./pages/logout";

import Login from "./pages/login";

const MainRoutes = () => {

    // console.log(localStorage.getItem("gujarat-token"))

    return (
        <>
            {
                localStorage.getItem("gujarat-token")
                    ?
                    <Routes>
                        <Route path="/" element={<Logout />}  />
                        <Route path="/homepage/:route" element={<Homepage />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/*" element={<div></div>} />
                    </Routes>
                    : <Routes>
                        <Route path="/" element={<Navigate to="/login" />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/*" element={<div></div>} />
                    </Routes>
            }

        </>
    );
};



export default MainRoutes;
