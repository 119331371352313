import React, { useState, useEffect } from 'react';
import Table from "../../../components/tableFrontendPagination"
import Filter from "./filter";
// import { columns } from "./columns";
import { getAllBooth, getAllSections, getAllKaryakarta } from "../../../apiCalls/apis/dropdown";
import { apiFunction } from "../../../apiCalls/functions/api"
import { ASSIGN_VOTER, GET_VOTER_LIST, GET_VOTER_BY_KARYAKARTA, UNASIGN_VOTER } from "../../../apiCalls/urls/dashboard/dashboard"
import { loaderStart, loaderEnd, notification } from "../../../components/notification"



import classes from "../constituency/styles.module.scss";
const voterPerPage = 30;

const AssignVoter = (props) => {

    const [allBooth, setAllBooth] = useState([])
    const [allKaryakarta, setAllKaryakarta] = useState([])
    const [selected, setSelected] = useState({
        booth: "",
        karyakarta: ""
    })

    const [total, setTotal] = useState(0)
    const [voterList, setVoterList] = useState(0)
    const [rows, setRows] = useState([])
    const [selectedIndex, setSelectedIndex] = useState(new Set())

    //-------------Checkbox functions
    //Check All Functions
    const checkSelectAll = (total) => {
        // console.log(total, "sadsa")
        if (total) {
            let _total = parseInt(total / voterPerPage) + 1;
            return selectedIndex.size === _total ? true : false;
        }
    }
    const handleCheckboxAll = (e, total) => {
        // console.log(e, total)
        if (e.target.checked) {
            if (total) {
                let _total = parseInt(total / voterPerPage) + 1;
                let rows = [];
                for (let i = 0; i < _total; i++) {
                    rows.push(i)
                }
                setSelectedIndex(new Set([...rows]));
            }
            // console.log(total)
        }
        else {
            setSelectedIndex(new Set())
        }
    }
    const [columns, setColumns] = useState([
        {
            label: <><input type="checkbox" checked={checkSelectAll(0)} onChange={(e) => handleCheckboxAll(e, 0)} /></>,
            field: 'sn',
            sort: 'disabled',
            width: 150,
        },
        {
            label: 'ID set',
            field: 'set',
            sort: 'disabled',
        },
    ])
    const updateColumn = (total) => {
        let _columns = [...columns]
        _columns[0] = {
            label: <><input type="checkbox" checked={checkSelectAll(total)} onChange={(e) => handleCheckboxAll(e, total)} /></>,
            field: 'sn',
            sort: 'disabled',
            width: 150,
        }
        setColumns(_columns)
    }

    //Single Check box
    const checkSelected = (i, selectedIndex) => {
        let index = [...selectedIndex].findIndex(v => v == i);
        // console.log(index, selectedIndex)
        if (index >= 0) {
            return true;
        }
        else {
            return false;
        }
    }

    useEffect(() => {
        // console.log(total, selectedIndex)
        if (total > 0) {
            generatePage(total, selectedIndex)
            updateColumn(total)
        }
    }, [selectedIndex])

    const handleCheckbox = (e, id, total) => {
        if (e.target.checked) {
            setSelectedIndex(prv => new Set([...prv, id]));

        }
        else {
            setSelectedIndex(prv => new Set([...prv].filter(v2 => v2 != id)));
        }
        // console.log(e, id, total)
    }

    useEffect(() => {
        // console.log(props)
        getBooth(props.constituencyData.constituency_name)
    }, [])

    const getBooth = async (name) => {
        let result = await getAllBooth(name)
        setAllBooth(result)
    }

    const getVoterList = async (booth) => {
        loaderStart()
        let data = {
            "constituency": props.constituencyData.id,
            "boothNo": booth
        }
        let result = await apiFunction(GET_VOTER_LIST, "post", data, true)
        if (result.data) {
            setVoterList(result.data.rows)
            setTotal(result.data.count)
            generatePage(result.data.count, [])
            updateColumn(result.data.count)
        }
        loaderEnd()
    }



    const generatePage = (count, selectedIndex) => {
        let total = parseInt(count / voterPerPage) + 1;
        let _rows = []
        // console.log(count, voterPerPage)
        for (let i = 0; i < total; i++) {
            _rows.push({
                sn: <div style={{textAlign:"center"}}>
                    <input type="checkbox" checked={checkSelected(i, selectedIndex)} onChange={(e) => handleCheckbox(e, i, count)} />
                </div>,
                set: <>Page {i + 1}({i * voterPerPage + 1} - {i * voterPerPage + voterPerPage})</>
            })
        }
        setRows(_rows)
    }

    const getKaryakarta = async (booth) => {
        let result = await getAllKaryakarta(booth, props.constituencyData.constituency_name)
        setAllKaryakarta(result)
    }

    const getAssignedVoter = async (karyakarta) => {
        loaderStart()
        let data = {
            "constituency": props.constituencyData.id,
            "karyakarta_id": karyakarta
        }
        let result = await apiFunction(GET_VOTER_BY_KARYAKARTA, "post", data, true)
        // console.log(result)
        if (result.status && result.data.count > 0) {
            let indexSet = getSelectedRowsOfVoter(result.data.count, result.data.rows)
            setSelectedIndex(new Set([...indexSet]));
        }
        loaderEnd()
    }
    const getSelectedRowsOfVoter = (count, data) => {
        let set = count / voterPerPage;
        let indexSet = []
        // console.log(set)
        if (set > 0) {
            for (let index = 0; index < set; index++) {
                let item = data[index * voterPerPage];
                // console.log(item, voterList)
                let indexOf = voterList.findIndex(v => v.ID == item.e_detail_id);
                // console.log(indexOf)
                let pos = parseInt(indexOf / voterPerPage);
                indexSet.push(pos)
            }
        }
        // console.log(indexSet)
        return indexSet;
    }

    const handleChange = (e, section) => {
        let _selected = { ...selected }
        _selected[section] = e;
        if (section == "booth") {
            getKaryakarta(e)
            getVoterList(e)
            _selected["karyakarta"] = "";
            setSelectedIndex(new Set())
        }
        else if (section == "karyakarta") {
            getAssignedVoter(e)
            setSelectedIndex(new Set())
        }
        setSelected(_selected)
    }

    const getVoterFromKaryakarta = (data) => {
        let _voter = [];
        // console.log(data)
        for (let index = 0; index < data.length; index++) {
            let _total = [...voterList];
            // console.log(_total)
            let item = [..._total].slice(data[index] * voterPerPage, data[index] * voterPerPage + voterPerPage)
            // console.log(item, data[index]*voterPerPage, voterPerPage)
            item.map((m) => {
                _voter.push(m.ID)
            })
        }
        return _voter;
    }

    const assingKaryakarta = async () => {
        loaderStart()
        if (selectedIndex.size) {
            let data = {
                constituency: props.constituencyData.id,
                "karyakarta_id": selected.karyakarta,
                "id_set": getVoterFromKaryakarta([...selectedIndex])
            }
            // console.log(data)
            let result = await apiFunction(ASSIGN_VOTER, "post", data, true)
            if (result.status) {
                notification(true, "Assigned")
            }
            else {
                notification(false, result.message)
            }
        }
        else {
            notification(false, "Please select at least one set.")
        }
        loaderEnd()
    }

    // console.log(columns)
    return (
        <>
            <Filter
                allBooth={allBooth}
                allKaryakarta={allKaryakarta}
                selected={selected}
                handleChange={handleChange}
                assingKaryakarta={assingKaryakarta}
            />
            <Table
                columns={columns}
                rows={rows}
                title={''}
                hidePagination={true}
                hideSearch={true}
            />
        </>
    )
}

export default AssignVoter;