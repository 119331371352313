import React from "react";
import classes from "./middleNav.module.scss";
import DOI from "../../../assets/img/nav/gujarat-gov.png";
import SW from "../../../assets/img/nav/sw.png";
import EMBLEM from "../../../assets/img/bjp.png";
import { useNavigate } from "react-router-dom";

const MiddleNav = () => {
    const navigate = useNavigate();
    return (
        <div className={classes.container}>
            <div className={classes.navWrapper}>
                <div className={classes.leftImgWrapper}>
                    {/* <img src={DOI} alt="logos" className={classes.leftImg}></img> */}
                    <div className={classes.title}>
                        LAKSHYA GUJARAT
                    </div>

                </div>
                <div className={classes.rightImgWrapper}>
                    {/* <img src={SW} alt="logos" className={classes.rightImg}></img> */}
                    {/* <img src={EMBLEM} alt="logos" className={classes.rightImg}></img> */}
                    <button onClick={() => navigate("/logout")} className={classes.loginBtn}>
                        Logout
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MiddleNav;
