import React, { useState } from 'react';
import Input from "../../../components/input"
import classes from "../constituency/styles.module.scss";
import Button from '../../../components/button';
import GeoLocation from "../assignVoter/geoLocation";
import ModalLayout from "../../../components/modalLayout";

const AssignVoter = (props) => {
    const [locationModal, setLocationModal] = useState(false)

    return (
        <>
            <div className={classes.allInfo3} style={{ paddingBottom: 0, paddingLeft: 0 }}>
                <Input
                    title={""}
                    type="select"
                    options={props.allBooth}
                    optionLabel="booth_name"
                    optionValue="booth_number"

                    placeholder="Booth"
                    value={props.selected.booth}
                    onChange={(e) => props.handleChange(e, "booth")}
                />
                <Input
                    title={""}
                    type="select"
                    options={props.allKaryakarta}
                    optionLabel="Full Name"
                    optionValue="karya_id"

                    placeholder="Karyakarta"
                    value={props.selected.karyakarta}
                    onChange={(e) => props.handleChange(e, "karyakarta")}
                />
                {
                    props.selected.karyakarta
                        ?
                        <>
                            <div className={classes.createBtn}>
                                <Button type="submit" className="auto" onClick={() => props.assingKaryakarta()} title="Assign"></Button> &nbsp;&nbsp;&nbsp;
                                <Button type="submit" className="auto" onClick={() => setLocationModal(true)} title="Geo Location"></Button>
                            </div>

                        </>
                        : null
                }

            </div>
            {
                locationModal
                    ?
                    <ModalLayout
                        isOpen={true}
                        toggleModal={() => setLocationModal(false)}
                        title="Geo Location"
                    >
                        <GeoLocation
                            karyakartaId={props.selected.karyakarta}
                        />
                    </ModalLayout>
                    : null
            }
        </>
    )
}

export default AssignVoter;