import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";

import Input from "../../../components/input"
import PieChart from "../../charts/pieChart";
import { pieLabelVoter } from "../../../shared/constants"
import classes from "./styles.module.scss";
import { allInfluencer, allCaste, allReligion } from "../../../shared/dropdowns";
import { allBooth } from "../../../shared/booth"
import { fakeData } from "../../../shared/fakedata"
import { apiFunction } from "../../../apiCalls/functions/api"
import { GET_OVERALL_DATA_COUNT, GET_VOTER_RESPONSE_COUNT } from "../../../apiCalls/urls/dashboard/dashboard"
import ModalLayout from "../../../components/modalLayout"
import OverallModal from "./overallModal"
import { generateDataForPie, getResponse } from "../../../shared/functions"
import { getFetchAPIData } from "../../../shared/pureFunction"
import { loaderStart, loaderEnd, notification } from "../../../components/notification"
import { generateVoterData } from "../../../shared/functions"

const Overall = (props) => {

    const [overall, setOverall] = useState([])
    const [booth, setBooth] = useState([])
    const [influencer, setInfluencer] = useState([])
    const [caste, setCaste] = useState([])
    const [religion, setReligion] = useState([])
    const [modal, setModal] = useState(false)
    const [pagination, setPagination] = useState({
        "filterType": "CASTE",
        "filter": "Kshatriya"
    })
    const [rows, setRows] = useState([])
    const [total, setTotal] = useState(65533)

    const [paginationOverall, setPaginationOverall] = useState({
        "itemsPerPage": 20,
        "pageNo": 1,
    })

    const [selected, setSelected] = useState({
        booth: "%",
        influencer: "%",
        caste: "%",
        religion: "%"
    })

    useEffect(() => {
        generateData()
    }, [props.firebase.firebaseTime, props.level, props.currentDistrict, props.constituencyData?.id])

    const generateData = () => {
        for (let i = 0; i < 5; i++) {
            singleSection(i)
        }
    }

    const singleSection = async (i) => {
        let _data = [];
        if (i == 0) {
            _data = await getData()
            setOverall(_data)
        }
        else if (i == 1 && props.level === "constituency") {
            _data = await getData("PART_NO", selected.booth)
            setBooth(_data)
        }
        else if (i == 2) {
            // setInfluencer(_data)
            _data = await getData("INFLUENCER_TYPE", selected.influencer)
            // console.log(_data)
            setInfluencer(_data)
        }
        else if (i == 3) {
            _data = await getData("CASTE", selected.caste)
            // console.log(_data)
            setCaste(_data)
        }
        else if (i == 4) {
            _data = await getData("RELIGION", selected.religion)
            setReligion(_data)
        }
    }

    const handleChange = async (e, section) => {
        let _selected = { ...selected }
        _selected[section] = e;
        setSelected(_selected)
        // console.log(_data)
        let _data = [];
        // console.log(section)
        if (section == "booth" && props.level === "constituency") {
            _data = await getData("PART_NO", e ? e : "%")
            // console.log(_data, e)
            setBooth(_data)
        }
        else if (section == "influencer") {
            _data = await getData("INFLUENCER_TYPE", e ? e : "%")
            setInfluencer(_data)

        }
        else if (section == "caste") {
            _data = await getData("CASTE", e ? e : "%")
            // console.log(_data)
            setCaste(_data)
        }
        else if (section == "religion") {
            _data = await getData("RELIGION", e ? e : "%")
            setReligion(_data)
        }
    }

    const showSelected = async (label, data, section) => {
        console.log(label, data, section)
        loaderStart()
        let postData = {
            "level": "constituency",
            "constituency": props.constituencyData?.id,
            "response": getResponse(label),
            ...paginationOverall
        }
        let result = await apiFunction(GET_VOTER_RESPONSE_COUNT, "post", postData, true)
        if (result.status) {
            let rows = generateVoterData(result.data.rows)
            setRows(rows)
            setTotal(result.data.count)
            setModal(true)
        }
        loaderEnd()

        console.log(result)
    }

    const getData = async (section, value) => {
        let data = getFetchAPIData(props.level, props.currentDistrict, props.constituencyData?.id)
        let page = {
            "constituency": props.constituencyData.id,
            ...data
        }
        if (section) {
            page = {
                ...page,
                "filterType": section,
                "filter": value
            }
        }
        let result;
        if (data)
            result = await apiFunction(GET_OVERALL_DATA_COUNT, "post", page, true)
        // console.log(result)
        if (result.status && result.data.length > 1) {
            // console.log([
            //     result.data[0].count,
            //     result.data[1].count,
            //     result.data[2].count
            // ])
            return generateDataForPie(result.data)
        }
        else {
            return []
        }
    }

    const updateTable = (section, data) => {
        let _pagination = { ...pagination }
        _pagination[section] = data;
        setPagination(_pagination)
    }



    return (
        <>
            <div className={classes.title}>
                <span></span>Overall<span></span>
            </div>
            <div className={classes.col5}>
                <div>
                    <div className={classes.graphTitle}>
                        Overall
                    </div>
                    <div style={{ marginBottom: '3rem' }}></div>

                    <PieChart
                        data={overall}
                        labels={pieLabelVoter}
                        showSelected={(label, data) => showSelected(label, data, "overall")}
                    />
                </div>
                {
                    props.level === "constituency"
                        ?
                        <div>
                            <div className={classes.graphTitle}>
                                Booth wise
                            </div>
                            <Input
                                title={""}
                                type="select"
                                options={props.allBooth}
                                optionLabel="booth_name"
                                optionValue="booth_number"
                                style={{ width: "100%" }}
                                placeholder="Booth"
                                value={selected.booth}
                                onChange={(e) => handleChange(e, "booth")}
                            />
                            {

                                booth.length > 0
                                    ?
                                    <div className={classes.pie}>
                                        <PieChart
                                            data={booth}
                                            labels={pieLabelVoter}
                                            showSelected={(label, data) => showSelected(label, data, "booth")}
                                        />
                                    </div>
                                    : <div className={`${classes.pie} ${classes.noData}`}>No Data</div>

                            }

                        </div>
                        : null
                }
                <div>
                    <div className={classes.graphTitle}>
                        Influencer wise
                    </div>
                    <Input
                        title={""}
                        type="select"
                        options={props.allInfluencer}
                        optionLabel="INFLUENCER_TYPE"
                        optionValue="ID"
                        style={{ width: "100%" }}
                        placeholder="Influencer"
                        value={selected.influencer}
                        onChange={(e) => handleChange(e, "influencer")}
                    />
                    {
                        influencer.length > 0
                            ?
                            <div className={classes.pie}>
                                <PieChart
                                    data={influencer}
                                    labels={pieLabelVoter}
                                    showSelected={(label, data) => showSelected(label, data, "influencer")}
                                />
                            </div>
                            : <div className={`${classes.pie} ${classes.noData}`}>No Data</div>
                    }

                </div>
                <div>
                    <div className={classes.graphTitle}>
                        Caste
                    </div>

                    <Input
                        title={""}
                        type="select"
                        options={props.allCaste}
                        optionLabel="CASTE"
                        optionValue="ID"
                        style={{ width: "100%" }}
                        placeholder="Caste"
                        value={selected.caste}
                        onChange={(e) => handleChange(e, "caste")}
                    />

                    {
                        caste.length > 0
                            ?
                            <div className={classes.pie}>
                                <PieChart
                                    data={caste}
                                    labels={pieLabelVoter}
                                    showSelected={(label, data) => showSelected(label, data, "caste")}
                                />
                            </div>
                            : <div className={`${classes.pie} ${classes.noData}`}>No Data</div>
                    }

                </div>
                <div>
                    <div className={classes.graphTitle}>
                        Religion
                    </div>

                    <Input
                        title={""}
                        type="select"
                        options={props.allReligion}
                        optionLabel="RELIGION"
                        optionValue="ID"
                        style={{ width: "100%" }}
                        placeholder="Religion"
                        value={selected.religion}
                        onChange={(e) => handleChange(e, "religion")}
                    />
                    {
                        religion.length > 0
                            ?
                            <div className={classes.pie}>
                                <PieChart
                                    data={religion}
                                    labels={pieLabelVoter}
                                    showSelected={(label, data) => showSelected(label, data, "religion")}
                                />
                            </div>
                            : <div className={`${classes.pie} ${classes.noData}`}>No Data</div>
                    }

                </div>
            </div>
            {
                modal
                    ?
                    <ModalLayout
                        isOpen={modal}
                        toggleModal={() => setModal(false)}
                        title="Overall Data"
                    // styles={{ width: "30vw" }}
                    >

                        <OverallModal
                            updateTable={updateTable}
                            total={total}
                            pagination={pagination}
                            rows={rows}
                        />
                    </ModalLayout>
                    : null
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        firebase: state.Firebase
    }
}

export default connect(mapStateToProps, null)(Overall);