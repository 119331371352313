export const columns = [
    {
        label: 'Question',
        field: 'sn',
        sort: 'disabled',
    },
    {
        label: 'Answer',
        field: 'set',
        sort: 'disabled',
    },
];

