import React, { useState, useEffect } from 'react';

import classes from "../homepage/electionInfo/ageWise/styles.module.scss";
import { EachCard } from "./winLossCard";
import { commaSeparate } from "../../shared/functions"
const YearWise = (props) => {
    return (
        <div>
            <div>
                <div className={classes.title}>YEAR WISE REPORT</div>
                {
                    props.yearWise?.map((d, i) => (
                        <div className={classes.eachSection}>
                            <div style={{display:"flex", alignItems:"center"}} className={classes.numberSpan}>{d.YEAR}</div>
                            <EachCard
                                count={commaSeparate(d.party1)}
                                name="BJP"
                                hideButton={true}
                            />
                            <EachCard
                                count={commaSeparate(d.party2)}
                                name="INC"
                                hideButton={true}
                            />
                            <EachCard
                                count={commaSeparate(d.others)}
                                name="OTHERS"
                                hideButton={true}
                            />
                        </div>
                    ))
                }

            </div>
        </div>
    )
}

export default YearWise;