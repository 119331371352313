import React, { useState, useEffect } from "react";
import { select } from "d3";
import SvgMap from "./svgMap/svgMap";
import classes from "./gujaratMap.module.scss";
import axios from "axios";

let nagarColor = "#ffeee1"
let normalColor = "#FCFCFC"

const GujaratMap = ({
    currentDistrict,
    setCurrentDistrict,
    selectedDistrict,
    setSelectedDistrict,
    tableData,
    tableDataLoading,
    tableDataError,
    tableDataSuccess,
    setTableData,
    setTableDataLoading,
    setTableDataError,
    setTableDataSuccess,
    isSmall,
    districtName,
    mananagarpalika
}) => {

    const checkNagar = (d) => {
        // console.log(d)
        let filter = mananagarpalika.filter(f => f === d)
        if (filter.length > 0)
            return true;
        else
            return false;
    }

    useEffect(() => {
        if (selectedDistrict?.districtName) {
            const selectedDistrictElement = document.getElementById(selectedDistrict.districtName);
            onStateClick(selectedDistrictElement);
        }
        if (selectedDistrict && Object.keys(selectedDistrict) == 0) {
            districtName.forEach((d) => {
                select(`#${d}`).attr("fill", checkNagar(d) ? nagarColor : normalColor).transition().duration(500).attr("filter", "");
            })

        }
        // console.log(selectedDistrict)
    }, [selectedDistrict]);

    useEffect(() => {
        if (currentDistrict) {
            setSelectedDistrict({ districtName: currentDistrict });
        }
        // eslint-disable-next-line
    }, [currentDistrict]);

    useEffect(() => {
        // onStateClick("Ahemdabad");
        // const element = document.getElementById("Ahmedabad");
        // onStateClick(element);
    }, []);

    const onStateClick = (clickedDistrict) => {
        if (currentDistrict !== clickedDistrict.getAttribute("id")) {
            // setSelectedDistrict({});
            select(`#${currentDistrict}`).attr("fill", checkNagar(currentDistrict) ? nagarColor : normalColor).transition().duration(500).attr("filter", "");
        }
        select(clickedDistrict).attr("fill", "#ee8711").transition().duration(500);
        if (clickedDistrict.getAttribute("id") !== null) {
            setCurrentDistrict(clickedDistrict.getAttribute("id"));
        }
    };
    const onStateMouseEnter = (clickedDistrict) => {
        if (clickedDistrict.getAttribute("fill") !== "#ee8711") {
            select(clickedDistrict).attr("fill", "#bfc1c2").transition().duration(500);
        }
    };

    const onStateMouseLeave = (clickedDistrict) => {
        if (clickedDistrict.getAttribute("fill") === "#bfc1c2" && clickedDistrict.getAttribute("fill") !== "#ee8711") {
            let d = clickedDistrict.getAttribute("id")
            select(clickedDistrict).attr("fill", checkNagar(d) ? nagarColor : normalColor).transition().duration(500).attr("filter", "");
        }
    };

    return (
        <>
            <SvgMap
                clicked={onStateClick}
                entered={onStateMouseEnter}
                left={onStateMouseLeave}
                className={classes.biharSvg}
                style={isSmall ? { width: "60%" } : { width: "100%" }}
                filled={"red"}
                isSmall={isSmall}
                district={currentDistrict}
            />
        </>
    );
};

export default GujaratMap;
