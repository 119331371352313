import React from 'react';
import ReactWordcloud from 'react-wordcloud';



const WordCloud = (props) => {

    const callbacks = {
        // onWordClick: console.log,
        // onWordMouseOver: console.log,
        getWordTooltip: word => `${word.text}`,
    }
    // const options = {
    //     rotations: 2,
    //     rotationAngles: [-90, 0],
    // };
    const words = [
        {
            text: 'told',
            value: 64,
        },
        {
            text: 'mistake',
            value: 42,
        },
        {
            text: 'thought',
            value: 40,
        },
        {
            text: 'bad',
            value: 20,
        },
    ]

    return (
        <>
            {
                props.words && props.words.length > 0
                    ?
                    <ReactWordcloud
                        callbacks={callbacks}
                        // options={options}
                        size={props.size}
                        words={props.words}
                    />
                    : null
            }

        </>
    )
}

export default WordCloud;