import React, { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "./styles.module.scss";
import GujaratMap from "../../containers/gujaratMap/gujaratMap";
import DistrictMap from "../../containers/districtMap";

import Layout from "../../layout/layout";
import BasicInfo from "../../containers/homepage/basicInfo"
import DistrictInfo from "../../containers/homepage/districtInfo"

import InputField from "../../components/input";

import { pageType } from "../../shared/dropdowns";
import Mandal from "../../containers/homepage/mandal"
import Voters from "../../containers/homepage/voters"
import Constituency from "../../containers/homepage/constituency"
import ElectionInfo from "../../containers/homepage/electionInfo"

import Karyakarta from "../../containers/homepage/karyakarta"
import AssignVoter from "../../containers/homepage/assignVoter"
import Graph from "../../containers/homepage/graph"
import Question from "../../containers/homepage/questions"



import ConCard from "../../containers/contituencyCard"
import { loaderStart, loaderEnd, notification } from "../../components/notification"
import { apiFunction } from "../../apiCalls/functions/api"
import { GET_CONSTITUENCY, GET_MANDAL, GET_CONSTITUENCY_ELECTION } from "../../apiCalls/urls/dashboard/dashboard"
import { manipulateDataMandal, objectToKeys } from "../../shared/functions"

import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useEffect } from "react";
const defaultPagination = {
    "search": "",
    "sort": {
        "attribute": "id",
        "order": "asc"
    },
    "filters": {
        "zila": ""
    },
    "pageNo": 1,
    "itemsPerPage": 10
}
const Homepage = () => {
    const navigate = useNavigate();
    const { route } = useParams();
    const constRef = useRef(null)
    const mandalRef = useRef(null)
    const voterRef = useRef(null)
    const karyakartaRef = useRef(null)
    const assignRef = useRef(null)
    const graphRef = useRef(null)
    const questionRef = useRef(null)
    const [selectedDistrict, setSelectedDistrict] = useState({});
    const [currentDistrict, setCurrentDistrict] = useState();
    const [tableData, setTableData] = useState([]);
    const [tableDataLoading, setTableDataLoading] = useState();
    const [tableDataError, setTableDataError] = useState();
    const [tableDataSuccess, setTableDataSuccess] = useState();
    const districtName = [
        "Ahmedabad",
        "Amreli",
        "Anand",
        "Aravalli",
        "Banaskantha",
        "Bharuch",
        "Bhavnagar",
        "Botad",
        "Chhotaudepur",
        "Dahod",
        "Dangs",
        "Devbhumi-Dwarka",
        "Gandhinagar",
        "Gir-Somnath",
        "Jamnagar",
        "Junagadh",
        "Kachchh",
        "Kheda",
        "Mahisagar",
        "Mahesana",
        "Morbi",
        "Narmada",
        "Navsari",
        "Panchmahal",
        "Patan",
        "Porbandar",
        "Rajkot",
        "Sabarkantha",
        "Surat",
        "Surendranagar",
        "Tapi",
        "Vadodara",
        "Valsad",
    ];

    const mananagarpalika = [
        "Ahmedabad",
        "Bhavnagar",
        "Gandhinagar",
        "Jamnagar",
        "Junagadh",
        "Rajkot",
        "Surat",
        "Vadodara",
    ]

    const [isMapMode, setIsMapMode] = useState(true);

    const [constituencyData, setConstituencyData] = useState([])
    const [mandalData, setMandalData] = useState([])
    const [total, setTotal] = useState(0)
    const [mandalCount, setMandalCount] = useState({})
    const [selectedConstituencyData, setSelectedConstituencyData] = useState({})

    // const [section, setSection] = useState("Constituency")
    const [section, setSection] = useState("Constituency")
    const [constituency, setConstituency] = useState(null)
    const [level, setLevel] = useState("state")
    // const [pagination, setPagination] = useState({
    //     search: "",
    //     sort: "",
    //     pageNo: 1,
    //     limit: 10,
    //     filter: ""
    // })
    const [pagination, setPagination] = useState({
        ...defaultPagination
    })

    const [allMandal, setAllMandal] = useState([])
    const [allShaktiKendra, setAllShaktiKendra] = useState([])
    const [allBooth, setAllBooth] = useState([])
    const [mandalFilter, setMandalFilter] = useState("zila")
    const [allElection, setAllElection] = useState("zila")
    const [otherFilter, setOtherFilter] = useState({
        mandal: "",
        shakti_kendra: "",
        booth: ""
    })

    useEffect(() => {
        let filter = pageType.filter(f => f.route === route)
        if (filter.length > 0) {
            handleChange(filter[0].value)
        }

    }, [route])

    const updateOtherFilter = async (value, id) => {
        // console.log(id, value)
        let _otherFilter = { ...otherFilter }
        if (id == "mandal") {
            _otherFilter.mandal = value;
            _otherFilter.shakti_kendra = "";
            _otherFilter.booth = ""
            if (mandalFilter == "shakti_kendra" || mandalFilter == "booth") {
                await getFilterData(mandalFilter, "shakti_kendra", value)
            }
        }
        else if (id == "shakti_kendra") {
            _otherFilter.shakti_kendra = value;
            _otherFilter.booth = ""
            if (mandalFilter == "booth") {
                await getFilterData(mandalFilter, "booth", value)
            }
        }
        else if (id == "booth") {
            _otherFilter.booth = value
        }
        setOtherFilter(_otherFilter)
        await updateTable("no-change", null, _otherFilter)

    }

    //get filter data
    const getFilterData = async (section, filter, value) => {
        if ((section === "mandal" || section == "shakti_kendra" || section == "booth") && filter == "new") {
            let url = GET_MANDAL + section + "/column/" + selectedDistrict.districtName + "/mandal"
            let result = await apiFunction(url, "get", {}, true)
            // console.log(result.data)
            if (result.data) {
                setAllMandal(objectToKeys(result.data, "mandal"))
            }
        }
        else if ((section === "shakti_kendra" || section == "booth") && filter == "shakti_kendra") {
            let url = GET_MANDAL + section + "/column/" + selectedDistrict.districtName + "/shakti_kendra?mandal=" + value
            let result = await apiFunction(url, "get", {}, true)
            // console.log(result.data)
            if (result.data) {
                setAllShaktiKendra(objectToKeys(result.data, "shakti_kendra"))
            }
        }
        else if ((section === "booth") && filter == "booth") {
            let url = GET_MANDAL + section + "/column/" + selectedDistrict.districtName + "/booth_number?shakti_kendra=" + value
            let result = await apiFunction(url, "get", {}, true)
            // console.log(result.data)
            if (result.data) {
                setAllBooth(objectToKeys(result.data, "booth_number"))
            }
        }
    }




    const [currentConstituency, setCurrentConstituency] = useState()
    const handleChange = (e) => {
        setSection(e)
        setConstituencyData([])
        setMandalData([])
        setSelectedConstituencyData({})
        setConstituency(null)
        setSelectedDistrict({})
        setCurrentDistrict()
        setPagination({ ...defaultPagination })
        setLevel("state")
        // setAllShaktiKendra([])
        // setAllBooth([])
        // setMandalFilter([])
    }

    useEffect(() => {
        // console.log(selectedConstituencyData, section)
        if (selectedConstituencyData && Object.keys(selectedConstituencyData).length > 0 && section == "Constituency") {
            constRef.current.scrollIntoView()
        }
        else if (selectedConstituencyData && Object.keys(selectedConstituencyData).length > 0 && section == "Karyakarta") {
            karyakartaRef.current.scrollIntoView()
        }
        else if (selectedConstituencyData && Object.keys(selectedConstituencyData).length > 0 && section == "Graph") {
            graphRef.current.scrollIntoView()
        }
        else if (selectedConstituencyData && Object.keys(selectedConstituencyData).length > 0 && section == "Question") {
            questionRef.current.scrollIntoView()
        }
        else if (selectedConstituencyData && Object.keys(selectedConstituencyData).length > 0 && section == "Assign-Karyakarta") {
            assignRef.current.scrollIntoView()
        }
        else if (selectedConstituencyData && Object.keys(selectedConstituencyData).length > 0 && section == "Voters") {
            voterRef.current.scrollIntoView()
        }
        // console.log(section, selectedConstituencyData)
    }, [selectedConstituencyData])


    const handleConstituency = (data) => {
        setConstituency(data)
        let filter = constituencyData && constituencyData.filter((c => c.constituency_name === data))
        if (filter.length > 0) {
            setSelectedConstituencyData(filter[0])
        }
        else {
            setSelectedConstituencyData({})
        }
    }


    // console.log(selectedDistrict.districtName, constituency);

    const onClickMap = async (data) => {
        loaderStart()
        setLevel("district")
        if (section === "Constituency" || section === "Karyakarta" || section == "Assign-Karyakarta" || section == "Graph" || section === "Question" || section === "Voters") {
            setSelectedConstituencyData({})
            setConstituency(null)
            let result = await apiFunction(GET_CONSTITUENCY + "?district=" + data, "get", {}, true)
            // console.log(result.data)
            if (result.status) {
                let keys = Object.keys(result.data)
                let _data = [];
                keys.forEach((k) => {
                    _data.push({
                        ...result.data[k]
                    })
                })
                // console.log(_data)
                setConstituencyData(_data)
            }
            let result1 = await apiFunction(GET_CONSTITUENCY_ELECTION + "?district=" + data, "get", {}, true)
            if (result1.status) {
                setAllElection(result1.data)
            }
            // console.log(result1)
        }
        else {
            let _pagination = { ...pagination }
            _pagination = {
                ..._pagination,
                "filters": {
                    "zila": data
                },
            }
            await getMandalData(_pagination, mandalFilter)
        }

        if (section == "Mandal") {
            window.scrollTo(0, 200)
            // mandalRef.current.scrollIntoView()
        }
        // console.log(data)
        setCurrentDistrict(data)
        loaderEnd()
    }

    const onContMapClick = (data) => {
        setLevel("constituency")
        let _data = data.split("-").join(" ");
        _data = _data.split("_")

        // console.log(data, _data)
        setCurrentConstituency(data)
        handleConstituency(_data[0])
    }

    //Mandal
    const updateTable = async (section, data, extraFilter) => {
        loaderStart()
        let _pagination = { ...pagination }
        // console.log(section, data)

        let filter = mandalFilter;
        let _otherFilter = { ...otherFilter }
        if (section === "filter") {
            filter = data
            setOtherFilter({
                mandal: "",
                shakti_kendra: "",
                booth: ""
            })
            _otherFilter = {
                mandal: "",
                shakti_kendra: "",
                booth: ""
            }
            setAllShaktiKendra([])
            setAllBooth([])
            setMandalFilter([])
            setMandalFilter(data)
            _pagination.pageNo = 1;
        }
        else if (section == "no-change") {
            _pagination.pageNo = 1;
        }
        else {
            _pagination[section] = data;
        }
        setPagination(_pagination)

        let filters = {
            "zila": selectedDistrict.districtName
        };

        // console.log(mandalFilter, extraFilter)

        //For Mandal
        if (filter === "mandal" && section == "no-change") {
            if (extraFilter && extraFilter.mandal) {
                filters = {
                    ...filters,
                    mandal: extraFilter.mandal
                };
            }
        }
        else if (filter === "mandal" && _otherFilter.mandal) {
            filters = {
                ...filters,
                mandal: _otherFilter.mandal
            };
        }

        //Shakit kendra
        if (filter === "shakti_kendra" && section == "no-change") {
            if (extraFilter && extraFilter.mandal) {
                filters = {
                    ...filters,
                    mandal: extraFilter.mandal
                };
            }
        }
        else if (filter === "shakti_kendra" && _otherFilter.mandal) {
            filters = {
                ...filters,
                mandal: _otherFilter.mandal
            };
        }
        //
        if (filter === "shakti_kendra" && section == "no-change") {
            if (extraFilter && extraFilter.shakti_kendra) {
                filters = {
                    ...filters,
                    shakti_kendra: extraFilter.shakti_kendra
                };
            }
        }
        else if (filter === "shakti_kendra" && _otherFilter.shakti_kendra) {
            filters = {
                ...filters,
                shakti_kendra: _otherFilter.shakti_kendra
            };
        }

        //Booth
        if (filter === "booth" && section == "no-change") {
            if (extraFilter && extraFilter.mandal) {
                filters = {
                    ...filters,
                    mandal: extraFilter.mandal
                };
            }
        }
        else if (filter === "booth" && _otherFilter.mandal) {
            filters = {
                ...filters,
                mandal: _otherFilter.mandal
            };
        }
        //
        if (filter === "booth" && section == "no-change") {
            if (extraFilter && extraFilter.shakti_kendra) {
                filters = {
                    ...filters,
                    shakti_kendra: extraFilter.shakti_kendra
                };
            }
        }
        else if (filter === "booth" && _otherFilter.shakti_kendra) {
            filters = {
                ...filters,
                shakti_kendra: _otherFilter.shakti_kendra
            };
        }
        //
        if (filter === "booth" && section == "no-change") {
            if (extraFilter && extraFilter.booth) {
                filters = {
                    ...filters,
                    booth_number: extraFilter.booth
                };
            }
        }
        else if (filter === "booth" && _otherFilter.booth) {
            filters = {
                ...filters,
                booth_number: _otherFilter.booth
            };
        }

        _pagination = {
            ..._pagination,
            filters: filters
        }
        await getMandalData(_pagination, filter)
        loaderEnd()
    }

    const getMandalData = async (pagination, filter) => {
        let result = await apiFunction(GET_MANDAL + filter + "/list", "post", pagination, true)
        // console.log(result.data)
        if (result.status) {
            let data = manipulateDataMandal(result.data.rows, filter)
            // console.log(data)
            setTotal(result.data.count)
            setMandalData(data)
            setMandalCount(result.data.totalCount)
            await getFilterData(filter, "new")
        }
    }

    // console.log(mandalFilter)
    // console.log(constituency)

    const getAllDistrict = () => {
        let _data = []
        let number = 1;
        districtName.map((name, index) => {
            _data.push(
                <div
                    className={
                        selectedDistrict.districtName === name
                            ? `${classes.selectedDistrictList}`
                            : `${classes.districtList}`
                    }
                    onClick={() =>
                        setSelectedDistrict({
                            districtName: name,
                            districtIndex: index,
                        })
                    }
                >
                    <span >{number++}</span>
                    {name}
                </div>
            );
        })

        mananagarpalika.map((name) => {

            let index = districtName.indexOf(name)
            _data.push(
                <div
                    className={
                        selectedDistrict.districtName === name
                            ? `${classes.selectedDistrictList}`
                            : `${classes.districtList}`
                    }
                    onClick={() =>
                        setSelectedDistrict({
                            districtName: name,
                            districtIndex: index,
                        })
                    }
                >
                    <span >{number++}</span>
                    {name} (N)
                </div>
            );
        })



        return _data;
    }

    // console.log(constituency)

    const showConstituencySection = ["Constituency", "Voters", "Karyakarta", "Assign-Karyakarta", "Graph", "Question"]


    // console.log(selectedDistrict, currentConstituency)
    return (
        <Layout>
            <div className={classes.container}>
                {/* <HomeChart /> */}
                <div className={classes.mainWrapper}>
                    <div className={selectedDistrict.districtName && (showConstituencySection.includes(section)) ? classes.formGrid : classes.districtWrapper}>
                        <div className={classes.districtMap} >
                            <GujaratMap
                                currentDistrict={currentDistrict}
                                setCurrentDistrict={onClickMap}
                                setSelectedDistrict={setSelectedDistrict}
                                selectedDistrict={selectedDistrict}
                                tableData={tableData}
                                tableDataLoading={tableDataLoading}
                                tableDataError={tableDataError}
                                tableDataSuccess={tableDataSuccess}
                                setTableData={setTableData}
                                setTableDataLoading={setTableDataLoading}
                                setTableDataError={setTableDataError}
                                setTableDataSuccess={setTableDataSuccess}
                                isSmall={selectedDistrict.districtName && (showConstituencySection.includes(section)) ? true : false}
                                districtName={districtName}
                                mananagarpalika={mananagarpalika}
                            />
                        </div>
                        {
                            selectedDistrict.districtName && (showConstituencySection.includes(section))
                                ?
                                <>

                                    {
                                        isMapMode
                                            ?
                                            <>
                                                <div>
                                                    <div className={classes.districtName}>
                                                        <span
                                                            style={{ borderBottom: '5px solid #ee8710' }}
                                                        >
                                                            {selectedDistrict.districtName}
                                                        </span>
                                                        <span onClick={() => setIsMapMode(!isMapMode)}>
                                                            <i className="fa fa-toggle-on" aria-hidden="true"></i>

                                                        </span>
                                                    </div>
                                                    <DistrictMap
                                                        district={selectedDistrict.districtName}
                                                        currentConstituency={currentConstituency}
                                                        setCurrentConstituency={onContMapClick}
                                                    />
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div>
                                                    <div className={classes.districtName}>
                                                        <span
                                                            style={{ borderBottom: '5px solid #ee8710' }}
                                                        >
                                                            {selectedDistrict.districtName}
                                                        </span>
                                                        <span onClick={() => setIsMapMode(!isMapMode)}>
                                                            <i className="fa fa-toggle-off" aria-hidden="true"></i>

                                                        </span>
                                                    </div>
                                                    <div className={classes.constituency}>
                                                        {
                                                            constituencyData && constituencyData.map((k, i) => (
                                                                <ConCard
                                                                    title={k.constituency_name}
                                                                    active={k.constituency_name == constituency ? true : false}
                                                                    onClick={() => handleConstituency(k.constituency_name)}
                                                                    index={i}
                                                                    key={i}
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </>
                                : !selectedDistrict.districtName ? <BasicInfo section={section} /> : ""
                        }
                    </div>
                    {
                        selectedDistrict.districtName
                            ?
                            <DistrictInfo district={selectedDistrict} />
                            : null
                    }

                    {
                        section == "Constituency"
                            ?
                            <div ref={constRef} style={{ width: "100%" }}>
                                <ElectionInfo
                                    constituencyData={selectedConstituencyData}
                                    level={level}
                                    currentDistrict={currentDistrict}
                                />
                                {/* {
                                    constituency
                                        ?

                                        <Constituency
                                            allElection={allElection}
                                            data={selectedConstituencyData}
                                        />

                                        : null
                                } */}

                            </div>
                            : null
                    }
                    {
                        section == "Mandal"
                            ?
                            <div ref={mandalRef} style={{
                                width: "100%",
                                margin: 'auto',
                            }}>
                                {
                                    selectedDistrict.districtName
                                        ?
                                        <>
                                            <br />
                                            <br />
                                            <div className={classes.districtName}>
                                                <span
                                                    style={{ borderBottom: '5px solid #ee8710' }}
                                                >
                                                    {selectedDistrict.districtName}
                                                </span>
                                            </div>
                                            <br />
                                            <Mandal
                                                section={mandalFilter}
                                                data={mandalData}
                                                total={total}
                                                updateTable={updateTable}
                                                pagination={pagination}
                                                filter={mandalFilter}

                                                otherFilter={otherFilter}
                                                updateOtherFilter={updateOtherFilter}
                                                allMandal={allMandal}
                                                allShaktiKendra={allShaktiKendra}
                                                allBooth={allBooth}
                                                mandalCount={mandalCount}
                                                selectedDistrict={selectedDistrict}
                                            />
                                        </>
                                        : null
                                }

                            </div>
                            : null
                    }
                    {
                        section == "Karyakarta"
                            ?
                            <div ref={karyakartaRef} style={{
                                width: "100%",
                                margin: 'auto',
                            }}>
                                <Karyakarta
                                    constituencyData={selectedConstituencyData}
                                    level={level}
                                    currentDistrict={currentDistrict}
                                />
                            </div>

                            : null
                    }
                    {
                        section == "Voters"
                            ?
                            <div ref={voterRef} style={{
                                width: "100%",
                                margin: 'auto',
                            }}>
                                {
                                    constituency
                                        ?
                                        <>
                                            <div className={classes.districtName}>
                                                <span
                                                    style={{ borderBottom: '5px solid #ee8710' }}
                                                >
                                                    {/* {selectedDistrict.districtName +"-"+ currentConstituency}
                                                     */}
                                                    {currentConstituency}
                                                </span>
                                            </div>
                                            <br />
                                            <Voters
                                                constituencyData={selectedConstituencyData}
                                            />
                                        </>
                                        : null
                                }

                            </div>
                            : null
                    }
                    {
                        section == "Assign-Karyakarta"
                            ?
                            <div ref={assignRef} style={{
                                width: "100%",
                                margin: 'auto',
                            }}>
                                {
                                    constituency
                                        ?
                                        <AssignVoter
                                            constituencyData={selectedConstituencyData}
                                        />
                                        : null}

                            </div>

                            : null
                    }
                    {
                        section == "Question"
                            ?
                            <div ref={questionRef} style={{
                                width: "100%",
                                margin: 'auto',
                            }}>
                                <Question
                                    constituencyData={selectedConstituencyData}
                                    level={level}
                                    currentDistrict={currentDistrict}

                                />
                            </div>

                            : null
                    }
                    {
                        section == "Graph"
                            ?
                            <div ref={graphRef} style={{
                                width: "100%",
                                margin: 'auto',
                            }}>
                                <Graph
                                    constituencyData={selectedConstituencyData}
                                    level={level}
                                    currentDistrict={currentDistrict}
                                />
                            </div>
                            // <Graph />
                            : null
                    }


                    <br />
                    <br />
                    <br />
                    <div className={classes.districtListWrapper}>
                        {getAllDistrict()}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Homepage;
