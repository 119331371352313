import React, { useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Total Votes',
        },
    },
};

const labels = [''];

const BarChart = (props) => {

    const data = {
        labels,
        datasets: [
            {
                label: 'Male',
                data: [props.male],
                backgroundColor: 'rgb(240,138,30, 0.5)',
            },
            {
                label: 'Female',
                data: [props.female],
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Others',
                data: [props.other],
                backgroundColor: 'rgba(38,130,255, 0.5)',
            },
            {
                label: 'Total',
                data: [props.total],
                backgroundColor: 'rgba(238,82,52, 0.5)',
            },
        ],
    };

    // console.log(data)
    

    return <Bar options={options} data={data} />;
}

export default BarChart
