import React, { useState, useEffect } from 'react';

import classes from "../homepage/electionInfo/ageWise/styles.module.scss";
import ModalLayout from '../../components/modalLayout';
import Table from "../../components/tableFrontendPagination"
import { boothColumn } from "./columns";
const Election = (props) => {

    const [modal, setModal] = useState(false)
    const [data, setData] = useState([])

    // console.log(props);

    useEffect(() => {
        allRows()
    }, [])

    const checkWinLoss = (d) =>{
        if((d.party1 > d.party2) &&(d.party1 > d.others) ){
            return <span style={{fontWeight:700, color:"green"}}>WIN</span>
        }
        else{
            return <span style={{fontWeight:700, color:"red"}}>LOSS</span>
        }
    }

    const allRows = () => {
        let rows = []
        props.data.map?.((d) => {
            rows.push({
                booth_name: d.booth_name,
                party1: d.party1,
                party2: d.party2,
                others: d.others,
                total_voters: d.total_voters,
                result:checkWinLoss(d),
                view: <>
                    <i className="fa fa-user" style={{ color: "#f85055" }} aria-hidden="true"
                        onClick={() => {
                            setData(d.all_4_combine_result)
                            setModal(true)
                        }}
                    ></i>
                    &nbsp;
                    <i className="fa fa-eye" style={{ color: "#f85055" }} aria-hidden="true"
                        onClick={() => props.getBoothVoteAllYear(d.PART_NO)}
                    ></i>
                </>
            })
        })
        // console.log(rows);
        return rows;
    }

    const updateTable = (action, id) => {
        // console.log(action, id);
        props.setPagination(action, id)
    }

    return (
        <div>
            <div className={classes.title}>{props.title}</div>
            <Table
                columns={boothColumn}
                rows={allRows()}
                title={''}
                fetchSelectedPage={(p) =>
                    updateTable('pageNo', p)
                }
                handleSearch={(s) =>
                    updateTable('search', s)
                }
                handleFilter={(s) => updateTable("filter", s)}
                totalSize={props.total}
                pageNumber={props.pagination.pageNo}
                hideSearch={true}
            />
            {
                modal
                    ?
                    <ModalLayout
                        isOpen={modal}
                        toggleModal={() => setModal(false)}
                        title="Candidates"
                        size="small"
                    >
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0" className={classes.grid}>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Party</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((d, i) => (
                                        <tr key={i}>
                                            <td className={classes.tal}>{d.NAME_OF_CONSTITUENCY}</td>
                                            <td className={classes.tac}>{d.PARTY_NAME}</td>

                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </ModalLayout>
                    : null
            }
        </div>
    )
}

export default Election;