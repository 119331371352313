import React, { useState, useEffect } from 'react';

import SingleBox from "../singleBox";
import SingleParty from "./singleParty";

import classes from "./styles.module.scss";
import bjp from "../../../../assets/img/bjp.png";


const Election = (props) => {
    return (
        <>
            <SingleBox title="TOP 5 PARTIES">
                <div className={classes.box_matter_container}>
                    {
                        props.data.map((d, i) => (
                            <SingleParty
                                data={d}
                                img={bjp}
                                key={i}
                            />
                        ))
                    }
                </div>
            </SingleBox>
        </>
    )
}

export default Election;