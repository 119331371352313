import moment from 'moment';

export const monthAndDate = (date) => {
    return moment(date).format("Do MMM");
}

export const dateIndian = (date) => {
    return date ? moment(date).format("DD/MM/YYYY") : "";
}

export const dateIndianComplete = (date) => {
    return date ? moment(date).format("DD/MM/YYYY hh:mmA") : "";
}