export const columns = [
    {
        label: 'Booth ID',
        field: 'booth_name',
        sort: 'disabled',
    },
    {
        label: 'BJP',
        field: 'party1',
        sort: 'enable',
    },
    {
        label: 'INC',
        field: 'party2',
        sort: 'enable',
    },
    {
        label: 'Other',
        field: 'others',
        sort: 'disabled',
    },
    {
        label: 'Total',
        field: 'total_voters',
        sort: 'disabled',
    },
    {
        label: 'Action',
        field: 'view',
        sort: 'disabled',
    }

];

export const boothColumn = [
    {
        label: 'Booth ID',
        field: 'booth_name',
        sort: 'disabled',
    },
    {
        label: 'BJP',
        field: 'party1',
        sort: 'enable',
    },
    {
        label: 'INC',
        field: 'party2',
        sort: 'enable',
    },
    {
        label: 'Other',
        field: 'others',
        sort: 'disabled',
    },
    {
        label: 'Total',
        field: 'total_voters',
        sort: 'disabled',
    },
    {
        label: 'Result',
        field: 'result',
        sort: 'disabled',
    },
    {
        label: 'Action',
        field: 'view',
        sort: 'disabled',
    }
]

