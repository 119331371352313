import React, { useState, useEffect } from 'react';
import Pagination from "react-js-pagination";
import InputField from "../input";

import "../tablePagination/styles.scss"

const Table = (props) => {
    // console.log(props)

    const [allLabels, setAllLabels] = useState([])
    const [sortSelected, setSortSelected] = useState("")
    const [sortType, setSortType] = useState("desc")

    // console.log(props)
    useEffect(() => {
        if (props.rows && props.rows.length > 0) {
            let allLabels = Object.keys(props.rows[0]);
            // console.log(allLabels);
            setAllLabels(allLabels)
        }
    }, [props.rows])

    useEffect(() => {
        if (props.initialSort && !sortSelected) {
            setSortSelected(props.initialSort)
        }
    }, [props.initialSort])


    const handleSelected = (selectedPage) => {
        // console.log(selectedPage)
        props.fetchSelectedPage(selectedPage)
    }

    const handleSearch = (e) => {
        props.handleSearch(e)
    }

    const handleSort = (e) => {
        setSortSelected(e);
        let _sortType = "asc"
        if (e !== sortSelected) {
            _sortType = "asc"
        }
        else {
            _sortType = sortType === "asc" ? "desc" : "asc";
        }
        setSortType(_sortType);
        props.updateSort({
            "attribute": e,
            "sort": _sortType,
        })
    }


    return (
        <div className="" style={{ ...props.style }}>
            <div className="custom-table-header">
                {
                    props.hideSearch
                        ?
                        null
                        :
                        <div className="table-input text-right searchArea" >
                            <InputField
                                className={"inputSearch"}
                                title=""
                                type="text"
                                required={true}
                                value={props.queryString}
                                // className= 'small'
                                onChange={(e) => handleSearch(e)}
                                placeholder={props.searchText ? props.searchText : "Enter keyword"}
                            />
                        </div>
                }
            </div>
            <div className="row">
                <div className="">
                    <table className="custom-table">
                        <thead className="mdb-dataTable-head">
                            <tr>
                                {
                                    props.columns && props.columns.map((h, i) => (
                                        <th style={h.width ? { width: h.width } : {}}>
                                            <div
                                                onClick={h.sort != 'disabled' ? () => handleSort(h.field) : () => { }}
                                                className="custom-table-head"
                                            >
                                                {h.label}
                                                {
                                                    (sortSelected === h.field && (h.sort !== "disabled"))
                                                        ?
                                                        <i style={{ marginTop: 1 }} className={sortType === 'desc' ? "ri-arrow-up-line" : "ri-arrow-down-line"}></i>
                                                        :
                                                        h.sort !== "disabled"
                                                            ?
                                                            <i style={{ color: '#aeaeae', marginTop: 1.5 }} className={"ri-arrow-down-line"}></i>
                                                            : null
                                                }

                                            </div>

                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.rows && props.rows.length > 0 && props.rows.map((r, i) => (
                                    <tr>
                                        {
                                            allLabels && allLabels.length > 0 && allLabels.map((a, j) => (
                                                <td>
                                                    {r[a]}
                                                </td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                {
                    !props.hidePagination
                        ?
                        <div className="table-pagination mt-2">

                            {/* <span>1 - 1 of 1</span> &nbsp;&nbsp;&nbsp; */}
                            <Pagination
                                activePage={props.pageNumber}
                                itemsCountPerPage={10}
                                totalItemsCount={props.totalSize}
                                pageRangeDisplayed={3}
                                onChange={handleSelected}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div>
                        : null
                }

            </div>
        </div>
    )
}

export default Table;