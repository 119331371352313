import allDistrict from "./allDistrict.json"
export const getFetchAPIData = (level, district, constituency) => {
    // console.log(level, district, constituency)
    if (level === "state") {
        return {
            "level": "state"
        }
    }
    else if(level === "district") {
        let filter = allDistrict.filter((d) => d.ENG_DIST_NAME == district)
        // console.log(filter)
        if(filter.length > 0){
            return {
                "level": "district",
                dist_no:parseInt(filter[0].DIST_NO)
            }
        }
        else{
            return null
        }
        
    }
    else if(level === "constituency") {
        return {
            "level": "constituency",
            constituency:constituency
        }
    }
}