import classes from "./styles.module.scss";
import Modal from 'react-modal';
const ModalLayout = (props) => {
    const content = {
        padding: 0,
        minHeight: '40vh',
        top: '10%',
        left: '5%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        width: '90vw',
        maxHeight: '80vh',
        // transform: 'translate(-50%, -40%)'
    }

    const overlay = {
        position: 'fixed',
        zIndex: 9999,
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: '#393939bf',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }

    const customStyles = {
        content: { ...content },
        overlay: { ...overlay },
    };

    const customStylesSmall = {
        content: {
            ...content,
            width: '40vw',
            left: '30%',
            top: '20%'
        },
        overlay: { ...overlay },
    };

    const getStyle = () => {
        if (props.size == "small") {
            return { ...customStylesSmall, ...props.styles }
        }
        else {
            return { ...customStyles, ...props.styles }
        }
    }

    const getHeader = () => {
        if (props.size == "small") {
            return classes.modalHeader + " " + classes.modalSmall
        }
        else {
            return classes.modalHeader
        }

    }

    return (
        <Modal
            isOpen={props.isOpen}
            onAfterOpen={props.onAfterOpen}
            onRequestClose={props.toggleModal}
            style={getStyle()}
            contentLabel={props.title}
        >
            <div className={getHeader()}>
                <div className={classes.head}>
                    {props.title}
                </div>
                <div className={classes.cross}>
                    <i className="fa fa-times" aria-hidden="true"
                        onClick={props.toggleModal}
                    ></i>
                </div>
            </div>
            <div className={classes.modalBody}>
                {props.children}
            </div>
        </Modal>
    )
}

export default ModalLayout;