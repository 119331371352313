import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const Logout = () => {

    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem("gujarat-token")
        window.location.href = "/"
    }, [])

    return (
        <>
            <h1>&nbsp;</h1>
        </>
    );
}

export default Logout;

