import React from 'react';
import Input from "../../../components/input"
import classes from "../constituency/styles.module.scss";
import Button from '../../../components/button';

const AssignVoter = (props) => {

    const updateTable = () => {

    }

    return (
        <>
            <div className={classes.allInfo2} style={{ paddingBottom: 0, paddingLeft: 0 }}>
                <Input
                    title={""}
                    type="select"
                    options={props.allBooth}
                    optionLabel="booth_name"
                    optionValue="booth_number"

                    placeholder="Booth"
                    value={props.selected.booth}
                    onChange={(e) => props.handleChange(e, "booth")}
                />
                {
                    props.selected.booth
                        ?
                        <div className={classes.createBtn}>
                            <Button type="submit" className="auto" onClick={() => props.setQuestionModal(true)} title="Add Question"></Button>
                        </div>
                        : null
                }

            </div>
        </>
    )
}

export default AssignVoter;