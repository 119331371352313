import React, { useEffect, useState } from "react";

import InfoCard from "../../infoCard"
import PartyInfo from "../../partyInfo"
import { apiFunction } from "../../../apiCalls/functions/api"
import { COUNT_ALL } from "../../../apiCalls/urls/dashboard/dashboard"
import { commaSeparate } from "../../../shared/functions"

import classes from "./styles.module.scss";

const Constituency = (props) => {

    const [total, setTotal] = useState({})

    useEffect(async() => {
        let result = await apiFunction(COUNT_ALL, "get", {}, true)
        // console.log(result)
        if(result.status){
            setTotal(result.data)
        }
    }, [])
    // console.log(total)
    return (
        <div className={classes.constituency}>
            <div className={classes.allInfo2}>
                <InfoCard
                    title={"Male Population"}
                    value={commaSeparate(total?.male)}
                    background="#e3e1f4"
                    fontSize={'1.5rem'}
                    fontSize1={'1.7rem'}
                    fontWeight1={700}
                />
                <InfoCard
                    title={"Female Population"}
                    value={commaSeparate(total?.female)}
                    background="#fee4e4"
                    fontSize={'1.5rem'}
                    fontSize1={'1.7rem'}
                    fontWeight1={700}
                />
                <InfoCard
                    title={"Constituency won"}
                    value={commaSeparate(total?.winningCount?.bjp)}
                    background="#feefe4"
                    fontSize={'1.5rem'}
                    fontSize1={'1.7rem'}
                    fontWeight1={700}
                />
                <InfoCard
                    title={"Constituency loss"}
                    value={commaSeparate(total?.winningCount?.nbjp)}
                    background="#e4f8fe"
                    fontSize={'1.5rem'}
                    fontSize1={'1.7rem'}
                    fontWeight1={700}
                />
            </div>
        </div>
    )
}

export default Constituency;