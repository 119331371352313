import React, { useState, useEffect } from 'react';
import { apiFunction } from "../../../apiCalls/functions/api"
import { QUESTIONS_PER_VOTER } from "../../../apiCalls/urls/dashboard/dashboard"
import { columns } from "./questionColumn";
import Table from "../../../components/tableFrontendPagination"

const AssignVoter = (props) => {

    const [rows, setRows] = useState([])

    useEffect(async () => {
        let data = {
            "voter_id": props.voterId,
        }
        let result = await apiFunction(QUESTIONS_PER_VOTER, "post", data, true)
        if (result.status) {
            setRows(result.data)
        }
    }, [])

    return (
        <>
            <Table
                columns={columns}
                rows={rows}
                title={''}
                hidePagination={true}
                hideSearch={true}
            />
        </>
    )
}

export default AssignVoter;