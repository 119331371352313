import React, { useState, useEffect } from 'react';

import classes from "../homepage/electionInfo/ageWise/styles.module.scss";
import ModalLayout from '../../components/modalLayout';
import Table from "../../components/tableFrontendPagination"
import { columns } from "./columns";
const Election = (props) => {
    let _columns = [...columns]
    _columns.splice(5, 1)

    // console.log(props.data);
    
    useEffect(() =>{
        allRows()
    },[])

    const allRows = () => {
        let rows = []
        props.data.map?.((d) => {
            rows.push({
                booth_name:d.booth_name,
                party1:d.party1,
                party2:d.party2,
                others:d.others,
                total_voters:d.total_voters,
                // view: <i className="fa fa-eye" style={{ color: "#f85055" }} aria-hidden="true"
                //     onClick={() => {
                //         setData(d.party1AndParty2)
                //         setModal(true)
                //     }}
                // ></i>
            })
        })
        // console.log(rows);
        return rows;
    }

    const updateTable = (action, id) =>{
        // console.log(action, id);
        props.updateTable(action, id)
    }

    return (
        <div>
            <Table
                columns={_columns}
                rows={allRows()}
                title={''}
                fetchSelectedPage={(p) =>
                    updateTable('pageNo', p)
                }
                handleSearch={(s) =>
                    updateTable('search', s)
                }
                handleFilter={(s) => updateTable("filter", s)}
                totalSize={props.total}
                pageNumber={props.pagination.pageNo}
                hideSearch={true}
            />
        </div>
    )
}

export default Election;