import React from "react";

import classes from "./styles.module.scss";

const InfoCard = ({ title, value, background, fontSize, fontSize1, fontWeight1 }) => {
    return (
        <div className={classes.infoCard} style={{backgroundColor:background}}>
            <div className={classes.singleRow} >
                <div className={classes.title} style={{fontSize:fontSize}}>
                    {title}&nbsp;&nbsp;
                </div>
                <div className={classes.value} style={{fontSize:fontSize1, fontWeight:fontWeight1}}>
                    {value}
                </div>
            </div>
        </div>
    )
}

export default InfoCard;