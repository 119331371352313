let initials = "/";

export const GET_CONSTITUENCY = `${initials}constituency/list`;
export const GET_CONSTITUENCY_ELECTION = `${initials}constituency/list-all`;

export const GET_MANDAL = `${initials}data/`;
export const GET_FILTER_DATA = `${initials}data/`;

export const GET_KARYAKARTA_DATA = `${initials}voter/voter-response`;
export const GET_KARYAKARTA_DATA_COUNT = `${initials}voter/voter-response-count`;
export const GET_OVERALL_DATA_COUNT = `${initials}voter/voter-response-count-filter`;
export const GET_VOTER_RESPONSE_COUNT = `${initials}voter/voter-response-with-count`;


export const GET_INFLUENCER_DATA_COUNT = `${initials}voter/voter-response-influencer`;
export const GET_VOTERS_DATA = `${initials}/voter`;
export const GET_ANALYTICS = `${initials}voter/voter-dashboard-analytics`;


export const GET_DROPDOWN = `${initials}karyakarta/data/`;
export const GET_BOOTH = `${initials}karyakarta-rep/booths/`;
export const GET_SECTIONS = `${initials}karyakarta-rep/sections/`;
export const GET_KARYAKARTA = `${initials}karyakarta-rep/karyakarta/`;

export const GET_ANALYTICS_DATA = `${initials}data/analytics`;



export const ADD_NOTICE = `${initials}representative/notice/add`;
export const ADD_EVENTS = `${initials}representative/event/add`;
export const GET_EVENT = `${initials}representative/event/list`;
export const GET_NOTICE = `${initials}representative/notice/list`;
export const GET_COMPLAIN = `${initials}complaint/list`;


export const GET_QUESTIONS = "/questionnaire/list";
export const ADD_QUESTIONS = "/questionnaire/add";
export const GET_QUESTIONS_ANALYTICS = "/questionnaire/analytics";
export const GET_QUESTIONS_ANALYTICS_USER = "/questionnaire/analytics/user";



export const GET_CONTACTS = "/data/";

export const ASSIGN_VOTER = `/voter/assign`;
export const GET_VOTER_LIST = `/voter/list`;
export const GET_VOTER_BY_KARYAKARTA = `/voter/karyakarta/assigned`;
export const UNASIGN_VOTER = `/voter/assign`;
export const VOTER_BY_CASTE = "/constituency/list-all-caste?constituency="
export const COUNT_ALL = "/data/count/All"
export const COUNT_DISTRICT = "/data/count/"
export const QUESTIONS_PER_VOTER = "/data/count/"


export const IVR_CALL = "/representative/pinacle/call";
export const SMS_NUMBER = "/representative/pinacle/sms"
export const LAST_UPDATED = "/data/record-updated-ago"


export const GET_GEOLOCATION = "karyakarta-rep/get/geometry";
export const UPDATE_GEOLOCATION = "/karyakarta-rep/update/geometry"

//Election
export const GET_VOTER_COUNT_BY_AGE_GENDER = "/voter/voter-count-by-age-and-gender";
export const GET_AGG_VOTER_COUNT = "/constituency-detailed/voter-count";
export const GET_DETAILED_REPORT = "/constituency-detailed/detailed-report";

export const VOTES_PER_YEAR = "/report/votes-per-year";
export const VOTES_PER_YEAR_BOOTH = "/report/votes-per-year-booth-wise";
export const BOOTH_PERFORMANCE = "/report/booth-performance/";
export const MARGIN_BOOTH = "/report/margin-booth/";
export const WIN_LOOSE_BOOTH = "/report/win-and-loss-count/";
export const CONSTITUENCY_BOOTH = "/report/get-booths-in-constituency/";
export const WIN_LOSS_BOOTH = "/report/win-and-loss-booths/";
export const MARGIN_BOOTH_LIST = "/report/margin-booth-list/";

//Probable Candidate
export const ADD_CANDIDATE = "/report/margin-booth-list/";
export const EDIT_CANDIDATE = "/report/margin-booth-list/";
export const VIEW_CANDIDATE = "/report/margin-booth-list/";
export const DELETE_CANDIDATE = "/report/margin-booth-list/";
