export const columns = [
    {
        label: 'Sr.No.',
        field: 'sn',
        sort: 'enable',
    },
    {
        label: 'Question',
        field: 'question',
        sort: 'enable',
    },
    {
        label: 'Question Type',
        field: 'type',
        sort: 'enable',
    },
    {
        label: 'Options',
        field: 'option',
        sort: 'disabled',
    }
    // {
    //     label: 'Phone',
    //     field: 'phone',
    //     sort: 'disabled',
    //     width: 150,
    // }
];

