import React, { useState, useEffect } from 'react';
import Table from "../../../components/tablePagination"
import { zillaColumns, mandalColumns, shaktikendraColumns, boothColumns } from "./columns";
import classes from "../constituency/styles.module.scss";
import InfoCard from "../../infoCard"
import NumberCard from "../../numberCard"
import { commaSeparate } from "../../../shared/functions"
import { apiFunction } from "../../../apiCalls/functions/api"
import { GET_CONTACTS } from "../../../apiCalls/urls/dashboard/dashboard"

const getColumns = (column) => {
    // console.log(column)
    switch (column) {
        case "zila":
            return zillaColumns
            break;
        case "mandal":
            return mandalColumns
            break;
        case "shakti_kendra":
            return shaktikendraColumns
            break;
        case "booth":
            return boothColumns
            break;
        default:
    }
}


const Mandal = ({ mandalCount, ...props }) => {

    const [data, setData] = useState([])

    useEffect(async () => {
        console.log(props.selectedDistrict)
        let result = await apiFunction(GET_CONTACTS + props.selectedDistrict.districtName + "/contacts", "get", {}, true)
        console.log(result)
        if (result.status) {
            setData(result.data)
        }
    }, [props.selectedDistrict])



    return (
        <>
            <div className={classes.allInfo3} style={{ width: "60%", margin: "auto" }}>
                {/* <InfoCard
                    title="Total Zilla"
                    value={41}
                    background="#feefe4"
                    fontSize1={'1.8rem'}
                    fontWeight1={700}
                /> */}
                <InfoCard
                    title="Total Mandal"
                    value={mandalCount?.tb ? commaSeparate(mandalCount?.tm) : 0}
                    background="#e3e1f4"
                    fontSize1={'1.8rem'}
                    fontWeight1={700}
                />
                <InfoCard
                    title="Total Shaktikendra"
                    value={mandalCount?.tb ? commaSeparate(mandalCount?.tsk) : 0}
                    fontSize1={'1.8rem'}
                    fontWeight1={700}
                />
                <InfoCard
                    title="Total Booths"
                    value={mandalCount?.tb ? commaSeparate(mandalCount?.tb) : 0}
                    background="#8dc0ffa8"
                    fontSize1={'1.8rem'}
                    fontWeight1={700}
                />
            </div>
            <br />
            <div className={classes.allInfo3} style={{ display: "flex", flexWrap: 'wrap', justifyContent: 'center', width: "60%", margin: "auto" }}>
                {
                    data && data.map((d, i) => (
                        <NumberCard
                            title={d.designation}
                            value={d.name}
                            mobile={d.contact_1}
                            style={{ width: "32%" }}
                        />
                    ))
                }
            </div>
            <br />

            <br />
            <br />
            <Table
                columns={getColumns(props.section)}
                rows={props.data}
                title={''}
                fetchSelectedPage={(p) =>
                    props.updateTable('pageNo', p)
                }
                handleSearch={(s) =>
                    props.updateTable('search', s)
                }
                handleFilter={(s) => props.updateTable("filter", s)}
                totalSize={props.total}
                pageNumber={props.pagination.pageNo}
                queryString={props.pagination.search}
                searchText="Search here."
                filter={""}
                filter={props.filter}

                otherFilter={props.otherFilter}
                updateOtherFilter={props.updateOtherFilter}

                allMandal={props.allMandal}
                allShaktiKendra={props.allShaktiKendra}
                allBooth={props.allBooth}
            />
        </>
    )
}

export default Mandal;