export const zillaColumns = [
    {
        label: 'Sr.No.',
        field: 'sn',
        sort: 'enable',
    },
    {
        label: 'Name',
        field: 'name',
        sort: 'disabled',
    },
    {
        label: 'Designation',
        field: 'designation',
        sort: 'disabled',
    },
    {
        label: 'Phone Number',
        field: 'phone',
        sort: 'disabled',
    },
    {
        label: 'Zila',
        field: 'zilla',
        sort: 'enable',
    },
    {
        label: 'Unit',
        field: 'unit',
        sort: 'enable',
    },
    {
        label: 'Sub Unit',
        field: 'subUnit',
        sort: 'disabled',
    },
    // {
    //     label: 'Designation',
    //     field: 'designation',
    //     sort: 'disabled',
    //     width: 150,
    // },
    
    {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
    }
];

export const mandalColumns = [
    {
        label: 'Sr.No.',
        field: 'sn',
        sort: 'enable',
    },
    {
        label: 'Name',
        field: 'name',
        sort: 'disabled',
    },
    {
        label: 'Designation',
        field: 'designation',
        sort: 'disabled',
    },
    {
        label: 'Phone Number',
        field: 'phone',
        sort: 'disabled',
    },
    {
        label: 'Zila',
        field: 'zilla',
        sort: 'enable',
    },
    {
        label: 'Vidhan Shabha',
        field: 'vidhanSabha',
        sort: 'enable',
    },
    {
        label: 'Mandal',
        field: 'mandal',
        sort: 'enable',
    },
    {
        label: 'Unit',
        field: 'unit',
        sort: 'enable',
    },
    {
        label: 'Sub Unit',
        field: 'subUnit',
        sort: 'disabled',
    },
    // {
    //     label: 'Designation',
    //     field: 'designation',
    //     sort: 'disabled',
    //     width: 150,
    // },
    // {
    //     label: 'Name',
    //     field: 'name',
    //     sort: 'disabled',
    //     width: 150,
    // },
    {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
    }
];

export const shaktikendraColumns = [
    {
        label: 'Sr.No.',
        field: 'sn',
        sort: 'enable',
    },
    {
        label: 'Name',
        field: 'name',
        sort: 'disabled',
    },
    {
        label: 'Designation',
        field: 'designation',
        sort: 'disabled',
    },
    {
        label: 'Phone Number',
        field: 'phone',
        sort: 'disabled',
    },
    {
        label: 'Zila',
        field: 'zilla',
        sort: 'enable',
    },
    {
        label: 'Vidhan Shabha',
        field: 'vidhanSabha',
        sort: 'enable',
    },
    {
        label: 'Mandal',
        field: 'mandal',
        sort: 'enable',
    },
    {
        label: 'Shakti Kendra',
        field: 'shaktiKendra',
        sort: 'enable',
    },
    // {
    //     label: 'Designation',
    //     field: 'designation',
    //     sort: 'disabled',
    //     width: 150,
    // },
    // {
    //     label: 'Name',
    //     field: 'name',
    //     sort: 'disabled',
    //     width: 150,
    // },
    {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
    }
];

export const boothColumns = [
    {
        label: 'Sr.No.',
        field: 'sn',
        sort: 'enable',
    },
    {
        label: 'Name',
        field: 'name',
        sort: 'disabled',
    },
    {
        label: 'Designation',
        field: 'designation',
        sort: 'disabled',
    },
    {
        label: 'Phone Number',
        field: 'phone',
        sort: 'disabled',
    },

    {
        label: 'Gender',
        field: 'gender',
        sort: 'disabled',
    },{
        label: 'Religion',
        field: 'religion',
        sort: 'disabled',
    },

    {
        label: 'Zila',
        field: 'zilla',
        sort: 'enable',
    },
    {
        label: 'Vidhan Shabha',
        field: 'application',
        sort: 'enable',
    },
    {
        label: 'Mandal',
        field: 'application',
        sort: 'enable',
    },
    {
        label: 'Shakti Kendra',
        field: 'shaktiKendra',
        sort: 'enable',
    },
    {
        label: 'Booth Number',
        field: 'boothNo',
        sort: 'enable',
    },
    // {
    //     label: 'Designation',
    //     field: 'designation',
    //     sort: 'disabled',
    //     width: 150,
    // },
    // {
    //     label: 'Name',
    //     field: 'name',
    //     sort: 'disabled',
    //     width: 150,
    // },
    {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
    }
];