import React, { useEffect, useState } from 'react';
import Table from "../../../components/tablePagination"
import { voterColumns } from "./columns";
import classes from "../constituency/styles.module.scss";
import InfoCard from "../../infoCard"
import NumberCard from "../../numberCard"
import { commaSeparate } from "../../../shared/functions"
import { apiFunction } from "../../../apiCalls/functions/api"
import { GET_VOTERS_DATA } from "../../../apiCalls/urls/dashboard/dashboard"
import { loaderStart, loaderEnd } from "../../../components/notification"
import Pagination from 'react-js-pagination';
import { getAllBooth, callToMobile, smsToMobile } from "../../../apiCalls/apis/dropdown";
import Questions from "./questions";
import ModalLayout from "../../../components/modalLayout";

const Voters = (props) => {

    const [pagination, setPagination] = useState({
        "pageNo": 1,
        itemsPerPage: 20,
        search: "",
        filters: {}
    })
    const [rows, setRows] = useState([])
    const [total, setTotal] = useState(0)
    const [allBooth, setAllBooth] = useState([])
    const [modal, setModal] = useState(null)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (pagination.search || pagination.search == "") {
                updateTable("pageNo", 1)
                getData(pagination.search, 1)
            }
        }, 400)
        return () => clearTimeout(delayDebounceFn)
    }, [pagination.search])

    const getBooth = async (name) => {
        let result = await getAllBooth(name)
        setAllBooth(result)
    }

    useEffect(() => {
        getBooth(props.constituencyData.constituency_name)
    }, [props.constituencyData.constituency_name])


    useEffect(() => {
        updateTable("pageNo", 1)
        getData(pagination.search, 1)
    }, [props.constituencyData.constituency_name, pagination.filters.is_met, pagination.filters.PART_NO])

    useEffect(() => {
        getData(pagination.search, pagination.pageNo)
    }, [pagination.pageNo])


    const makeCall = async (phoneNumber) => {
        // await callToMobile(8075326695)
        await callToMobile(phoneNumber)
    }
    const makeSms = async (phoneNumber) => {
        await smsToMobile(phoneNumber)
    }
    const getData = async (search, pageNo) => {
        loaderStart()
        let _pagination = {
            ...pagination,
            constituency: props.constituencyData.id,
            search: search,
            pageNo: pageNo
        }
        let result = await apiFunction(GET_VOTERS_DATA, "post", _pagination, true)
        // console.log(result)
        let _data = []
        if (result.status) {
            setTotal(result.data.count)
            result.data.rows && result.data.rows.forEach((f) => {
                _data.push({
                    id: f.ID,
                    name: f.ENG_F_NAME + " " + f.ENG_M_NAME + " " + f.ENG_SURNAME + "(" + f.F_NAME_V1 + " " + f.M_NAME_V1 + " " + f.SURNAME_V1 + ")",
                    age: f.AGE,
                    gender: f.SEX,
                    contactno: f.contactno,
                    religion: f.RELIGION,
                    caste: f.CASTE,
                    houseNo: f.ENG_HOUSE_NO,
                    updatedBy: f?.UPDATED_BY_booth?.name,
                    action: f.contactno ?
                        <div className="actions">
                            <i className="fa fa-whatsapp whatsapp" aria-hidden="true"></i>&nbsp;&nbsp;
                            <i className="fa fa-sms" onClick={() => makeSms(f.contactno)} aria-hidden="true"></i>&nbsp;&nbsp;
                            <i className="fa fa-phone" onClick={() => makeCall(f.contactno)} aria-hidden="true"></i>&nbsp;&nbsp;
                            <i className="fa fa-question-circle" onClick={() => setModal({
                                status: true,
                                voterId: f.ID
                            })} aria-hidden="true"></i>&nbsp;

                        </div>
                        : <div className="actions">
                            <i className="fa fa-question-circle" onClick={() => setModal({
                                status: true,
                                voterId: f.ID
                            })} aria-hidden="true"></i>&nbsp;
                        </div>,
                })
            })
        }
        setRows(_data)
        loaderEnd()
    }


    const updateTable = (section, data) => {
        let _pagination = { ...pagination }
        _pagination[section] = data;
        if (section === "search") {
            _pagination["pageNo"] = 1;
        }
        setPagination(_pagination)
    }

    // console.log(pagination)

    const updateFilter = (e) => {
        let _pagination = { ...pagination }
        // console.log(e)
        if (e === "yes") {
            _pagination.filters = {
                ..._pagination.filters,
                "is_met": true
            }
        }
        else if (e === "no") {
            _pagination.filters = {
                ..._pagination.filters,
                "is_met": false
            }
        }
        else {
            delete _pagination.filters.is_met
        }
        setPagination(_pagination)
    }

    const updateOtherFilter = (e, name) => {
        let _pagination = { ...pagination }
        // console.log(e, name)
        if (e) {
            _pagination.filters = {
                ..._pagination.filters,
                [name]: e
            }
        }
        else {
            delete _pagination.filters[name]
        }
        // console.log(_pagination)
        setPagination(_pagination)
    }

    // console.log(pagination)
    return (
        <>

            <br />
            <Table
                columns={voterColumns}
                rows={rows}
                title={''}
                fetchSelectedPage={(p) =>
                    updateTable('pageNo', p)
                }
                handleSearch={(s) =>
                    updateTable('search', s)
                }
                handleFilter={(s) => updateTable("filter", s)}
                totalSize={total}
                pageNumber={pagination.pageNo}
                queryString={pagination.search}
                searchText="Search here."
                hideSearch={false}
                hideFilter={true}
                itemsCountPerPage={pagination.itemsPerPage}
                voterFilter={true}

                //filter
                updateOtherFilter={(e) => updateFilter(e)}
                filter={pagination?.filters}
                allBooth={allBooth}
                updateOtherFilterDropdown={(e, section) => updateOtherFilter(e, section)}
            />
            {
                modal?.status
                    ?
                    <ModalLayout
                        isOpen={true}
                        toggleModal={() => setModal(null)}
                        title="Questions"
                    >
                        <Questions
                            voterId={modal?.voterId}
                        />
                    </ModalLayout>
                    : null
            }
        </>
    )
}

export default Voters;