import classes from "../styles.module.scss";
import InputField from '../../../components/input';
import Button from '../../../components/button';

import { useState, useEffect } from "react";

const ModalLayout = (props) => {

    const [state, setState] = useState({
        title: "",
        block: "",
        description: "",
        "fee": "",
        "date_of_event": "",
        "location": ""
    })

    useEffect(() =>{
        // console.log(props.constituencyData)
        handleChange(props.constituencyData.constituency_name, "block")
    },[])

    const handleChange = (e, name) => {
        // console.log(e, name)
        let _state = { ...state };
        _state[name] = e;
        setState(_state)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let _state = {...state};
        delete _state.block;
        props.handleSubmit({ ..._state, constituency:props.constituencyData.id })
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className={classes.col2}>
                <InputField
                    title="Block"
                    type="text"
                    required={true}
                    disabled={true}
                    value={state.block}
                    onChange={(e) => handleChange(e, "block")}
                />
                <InputField
                    title="Title"
                    type="text"
                    required={true}
                    value={state.title}
                    onChange={(e) => handleChange(e, "title")}
                    placeholder={"Enter title"}
                />
                <InputField
                    title="Description"
                    type="text"
                    required={true}
                    value={state.description}
                    onChange={(e) => handleChange(e, "description")}
                    placeholder={"Enter description"}
                />
                <InputField
                    title="Fee"
                    type="number"
                    required={true}
                    value={state.fee}
                    onChange={(e) => handleChange(e, "fee")}
                    placeholder={"Enter fee"}
                />
                <InputField
                    title="Date of event"
                    type="date"
                    required={true}
                    value={state.date_of_event}
                    onChange={(e) => handleChange(e, "date_of_event")}
                    placeholder={"Enter date of event"}
                />
                <InputField
                    title="Location"
                    type="text"
                    required={true}
                    value={state.location}
                    onChange={(e) => handleChange(e, "location")}
                    placeholder={"Enter location"}
                />
            </div>
            <br />
            <div className={classes.center}>
                <Button className="auto" title="Submit" type="submit" />
            </div>
            <br />
            <br />
        </form>

    )
}

export default ModalLayout;