import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";

import Election from "./election";
import TopParties from "./topParties";
import ElectionResult from "./electionResult";
import VoteShare from "./voteShare";
import AgeWise from "./ageWise";
import PerAge from "./ageWise/perAge";
import BoothTable from "../../election/boothsTable";
import AllBoothTable from "../../election/allBooth";
import ProbableCandidate from "../../election/probableCandidate";

import Comparison from "../../election/comparison";
import WinLossCard from "../../election/winLossCard";
import VotePerYear from "../../election/votePerYear";
import YearWise from "../../election/yearWise";
import Input from '../../../components/input';
// import VoteShare2 from "../../election/voteShare";
import LineChart from '../../../containers/charts/lineChart';

import PieChart from "../../charts/pieChart";
import { getFetchAPIData } from "../../../shared/pureFunction"
import { getVoterCount, getVoterCountPerAge, getDetailedReport, getMaleFemale } from "./actions"
import classes from "./styles.module.scss";
import { loaderStart, loaderEnd, notification } from "../../../components/notification"
import { voterPerYear, boothPerformancePerYear, marginBooth, winLooseBooth, constituencyBooth, voterPerYearBooth, boothWinLossData } from "./actions";
const allYear = [{ label: 2012, value: 2012 }, { label: 2014, value: 2014 }, { label: 2017, value: 2017 }, { label: 2019, value: 2019 }]
const AssignVoter = (props) => {

    const [selectedData, setSelectedData] = useState({})
    const [currentStatus, setCurrentStatus] = useState(null)
    const [ageWiseData, setAgeWiseData] = useState({
        table: [],
        pie: {}
    })
    const [perAgeData, setPerAgeData] = useState([])
    const [topParties, setTopParties] = useState([])
    const [topTwoParties, setTopTwoParties] = useState([])
    const [voteShare, setVoteShare] = useState({
        data: [],
        labels: []
    })
    const [totalVote, setTotalVote] = useState({})
    const [voterRatio, setVoterRatio] = useState({})

    const [yearWise, setYearWise] = useState([])
    const [topTen, setTopTen] = useState([])
    const [bottomTen, setBottomTen] = useState([])
    const [winLoose, setWinLoose] = useState([])
    const [plusMarginData, setPlusMarginData] = useState([])
    const [minusMarginData, setMinusMarginData] = useState([])
    const [voteData, setVoteData] = useState([])

    const [partyWiseGraph, setPartWiseGraph] = useState([])
    const [plusMarginGraph, setPlusMarginGraph] = useState([])
    const [winGraph, setWinGraph] = useState([])
    const [allBooth, setAllBooth] = useState([])
    const [yearWiseBooth, setYearWiseBooth] = useState([])
    const [candidate, setCandidate] = useState([])

    const [selectedYear, setSelectedYear] = useState(2012)
    const [pagination, setPagination] = useState({
        pageNo: 1
    })


    const [total, setTotal] = useState(220)

    useEffect(() => {
        let data = getFetchAPIData(props.level, props.currentDistrict, props.constituencyData?.id)
        // console.log(data);
        if (data) {
            setCurrentStatus(data)
            getAllData(data)
            detailedReport(data)
            maleFemaleData(data)
            getVotesPerYear(data)
            geBoothPerformancePerYear(data, selectedYear, "top", setTopTen)
            geBoothPerformancePerYear(data, selectedYear, "bottom", setBottomTen)
            getAllMarginData(data)
            getAllBoothData(data)

        }
        setSelectedData(data)
    }, [props.firebase.firebaseTime, props.constituencyData.id, props.level, props.currentDistrict])

    useEffect(() => {
        let data = getFetchAPIData(props.level, props.currentDistrict, props.constituencyData?.id)
        if (data) {
            geBoothPerformancePerYear(data, selectedYear, "top", setTopTen)
            geBoothPerformancePerYear(data, selectedYear, "bottom", setBottomTen)
            getMarginBooth(data, selectedYear, [0.00001, 10], setPlusMarginData)
            getMarginBooth(data, selectedYear, [-10, 0.00001], setMinusMarginData)
            getWinLooseBooth(data, selectedYear)
            candidateData(data, selectedYear)
        }
    }, [props.firebase.firebaseTime, props.constituencyData.id, props.level, props.currentDistrict, selectedYear])

    useEffect(() => {
        let data = getFetchAPIData(props.level, props.currentDistrict, props.constituencyData?.id)
        if (data) {
            getConstituencyBooth(data, selectedYear)
        }
    }, [props.firebase.firebaseTime, props.constituencyData.id, props.level, props.currentDistrict, selectedYear, pagination])

    useEffect(() => {
        // console.log(yearWise, selectedYear);
        let filter = yearWise.filter((f) => f.YEAR == selectedYear)
        // console.log(filter);
        if (filter?.length > 0) {
            let labels = ["BJP", "INC", "OTHERS"]
            let data = [filter[0].party1, filter[0].party2, filter[0].others]
            setVoteData({
                labels,
                data
            })
        }
    }, [yearWise, selectedYear])

    const getAllData = async (data) => {
        loaderStart()
        showPieSelected("18-24", "")
        let ageWise = await getVoterCount(data)
        // console.log(ageWise)
        setAgeWiseData(ageWise)
        loaderEnd()

    }

    const detailedReport = async (data) => {
        loaderStart()
        let detailed = await getDetailedReport(data)
        // console.log(detailed)
        setTopParties(detailed.topParties)
        setTopTwoParties(detailed.topTwoParty)
        setVoteShare(detailed.voteShare)
        setTotalVote(detailed.total)
        loaderEnd()
        // console.log(ageWise)
    }

    const maleFemaleData = async (data) => {
        loaderStart()
        let result = await getMaleFemale(data)
        // console.log(result)
        setVoterRatio(result)
        loaderEnd()
    }

    const showPieSelected = async (label, value) => {
        loaderStart()
        let result = await getVoterCountPerAge(currentStatus, label)
        // console.log(result)
        setPerAgeData(result)
        loaderEnd()
    }


    //New Report
    const getVotesPerYear = async (data) => {
        let res = await voterPerYear(data)
        // console.log(res);
        let _partyWiseData = []
        if (res.data) {
            setYearWise(res.data)
            let r = [...res.data]

            _partyWiseData.push([r[0].party1, r[1].party1, r[2].party1, r[2].party1])
            _partyWiseData.push([r[0].party2, r[1].party2, r[2].party2, r[2].party2])
            _partyWiseData.push([r[0].others, r[1].others, r[2].others, r[2].others])

            // console.log(_partyWiseData);
            setPartWiseGraph(_partyWiseData)
        }
    }

    const geBoothPerformancePerYear = async (data, year, section, updateFn) => {
        let res = await boothPerformancePerYear(data, year, section)
        // console.log(res);
        if (res.data) {
            updateFn(res.data)
        }
    }

    const getMarginBooth = async (data, year, range, updateFn) => {
        let res = await marginBooth(data, year, range)
        // console.log(res);
        if (res.data) {
            updateFn(res.data)
        }
    }

    const getWinLooseBooth = async (data, year) => {
        let res = await winLooseBooth(data, year)
        // console.log(res);
        if (res.data) {
            setWinLoose(res.data)

        }
    }

    const getConstituencyBooth = async (data, year) => {
        data = {
            ...data,
            "pageNo": pagination.pageNo,
            "itemsPerPage": 10
        }
        let res = await constituencyBooth(data, year)
        // console.log(res);
        if (res) {
            setAllBooth(res.rows)
            setTotal(res.count)
        }

    }


    //For Graph
    const getAllMarginData = async (data) => {
        let plus = [];
        // let minus = [];
        for (let i = 0; i < allYear.length; i++) {
            let res = await marginBooth(data, allYear[i].label, [0.00001, 10])
            // console.log(res);
            plus.push(res.data)
        }
        setPlusMarginGraph(plus)
        // console.log(plus);
    }

    const getAllBoothData = async (data) => {
        let win = [];
        let loose = [];
        // let minus = [];
        for (let i = 0; i < allYear.length; i++) {
            let res = await winLooseBooth(data, allYear[i].label)
            // console.log(res);
            win.push(res.data.winCount)
            loose.push(res.data.lossCount)
        }
        setWinGraph([win, loose])
        // console.log(plus);
    }

    const getBoothVoteAllYear = async (booth) => {
        let data = {
            "constituency": selectedData.constituency,
            "booth_no": booth
        }
        let res = await voterPerYearBooth(data)
        // console.log(res)
        if (res.data) {
            let _partyWiseData = []
            let r = [...res.data]
            _partyWiseData.push([r[0].party1, r[1].party1, r[2].party1, r[2].party1])
            _partyWiseData.push([r[0].party2, r[1].party2, r[2].party2, r[2].party2])
            _partyWiseData.push([r[0].others, r[1].others, r[2].others, r[2].others])
            setYearWiseBooth(_partyWiseData)
        }
    }

    const candidateData = async (data, year) => {
        setCandidate([])
        if (data.constituency) {
            let _data = {
                ...data,
                "year": year,
                pageNo: 1,
                "itemsPerPage": 10,
                "order": "DESC"
            }
            let res = await boothWinLossData("win", _data)
            if (res.data) {
                let _r = [...res.data.rows]
                // console.log(_r[0].all_4_combine_result);
                let _data = []
                _data[0] = _r[0].all_4_combine_result[0]
                _data[1] = _r[0].all_4_combine_result[1]
                // console.log(_data);
                setCandidate(_data)
                // setRows(_r)
            }
        }
    }


    // console.log(candidate);
    // console.log(props);



    return (
        <>
            {
                props.constituencyData?.id
                    ?
                    <>
                        <ProbableCandidate
                            constituency={props.constituencyData?.id}
                        />
                        <hr />
                    </>
                    : null
            }
            <div className={classes.allInfo2} style={{ width: "70%" }}>
                <Election total={totalVote} data={voterRatio} />
                <TopParties data={topParties} />
                <ElectionResult topTwoParties={topTwoParties} />
                <VoteShare data={voteShare} />
            </div>
            <AgeWise data={ageWiseData.table} />
            <div className={classes.allInfo2}>
                <div className={classes.chartDiv}>
                    <PieChart
                        data={ageWiseData.pie.data ? ageWiseData.pie.data : []}
                        labels={ageWiseData.pie.label ? ageWiseData.pie.label : []}
                        showSelected={showPieSelected}
                    />
                </div>
                {
                    perAgeData && perAgeData?.length > 0
                        ?
                        <PerAge
                            data={perAgeData}
                        />
                        : null
                }
            </div>
            <hr />
            <div style={{ width: "50%" }} className={classes.allInfo1}>
                {/* <VotePerYear /> */}
                <YearWise
                    yearWise={yearWise}
                />
            </div>
            <hr />
            <br />

            <div style={{ width: "30%" }}>
                <Input
                    title={"Select year"}
                    type="select"
                    options={allYear}
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Year"
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e)}
                />
            </div>
            <br />
            <div className={classes.allInfo2}>
                {
                    candidate?.map((c, i) => {
                        return <div className={classes.name} key={i}>{c.NAME_OF_CONSTITUENCY + "(" + c.PARTY_NAME + ")"}</div>
                    })
                }
            </div>
            <br />
            <WinLossCard
                margin={{
                    plus: plusMarginData,
                    minus: minusMarginData
                }}
                total={winLoose}
                totalVote={totalVote}
                selectedData={selectedData}
                selectedYear={selectedYear}
            />
            <div style={{ width: "50%" }} className={classes.allInfo1}>
                <VoteShare data={voteData} />
            </div>
            <div className={classes.allInfo2}>
                <BoothTable
                    data={topTen}
                    title="Top 10 booths" />
                <BoothTable
                    data={bottomTen}
                    title="Bottom 10 booths" />
            </div>
            <hr />
            <Comparison
                partyWise={{
                    data: partyWiseGraph,
                    label: ["BJP", "INC", "OTHERS"]
                }}
                marginWise={{
                    data: [plusMarginGraph],
                    label: ["Margin Win"]
                }}
                boothWin={{
                    data: winGraph,
                    label: ["Booth Win", "Booth Loose"]
                }}
            />
            {
                allBooth?.length > 0 &&
                <div className={classes.allInfo2}>
                    <AllBoothTable
                        data={allBooth}
                        pagination={pagination}
                        total={total}
                        setPagination={(section, id) => {
                            let _pagination = { ...pagination }
                            _pagination.pageNo = id;
                            setPagination(_pagination)
                        }}
                        getBoothVoteAllYear={getBoothVoteAllYear}
                        title="All Booth"
                    />
                    {
                        yearWiseBooth.length > 0 &&
                        <div style={{ marginTop: "12rem" }}>
                            <LineChart
                                title="Party wise"
                                data={yearWiseBooth}
                                labels={["BJP", "INC", "OTHERS"]}
                            />
                        </div>
                    }

                </div>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        firebase: state.Firebase
    }
}

export default connect(mapStateToProps, null)(AssignVoter);