import React, { useState, useEffect } from 'react';

import classes from "./styles.module.scss";

const Election = (props) => {
    return (
        <>
            <table border="1" cellSpacing="0" cellPadding="0" className={classes.grid}>
                <thead>
                    <tr>
                        <th rowSpan="2">Age</th>
                        <th colSpan="3">Gender</th>
                        <th rowSpan="2">Total</th>
                    </tr>
                    <tr>
                        <th>Male</th>
                        <th>Female</th>
                        <th>Others</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        props.data.map((d, i) => (
                            <tr key={i}>
                                <td className={classes.tac}>{d.age}</td>
                                <td className={classes.tac}>{d.male}</td>
                                <td className={classes.tac}>{d.female}</td>
                                <td className={classes.tac}>{d.other}</td>
                                <td className={classes.tac}>{d.total}</td>
                            </tr>
                        ))
                    }


                </tbody>
            </table>
        </>
    )
}

export default Election;