import React from 'react';
import { getParty, getPartyName } from "../../../partyInfo"
import classes from "./styles.module.scss";

const SingleParty = (props) => {
    return (
        <div className={classes.top_party}>
            <div className={classes.party_symbol}>
                <img src={getParty(props.data?.winning_party_name)} /><span>{getPartyName(props.data?.winning_party_name)}</span></div>
            <div className={classes.top_party_seats}>
                <div className={classes.top_party_seats_head}>Seats</div>
                <div className={classes.seats_won}>Won : <span>{props.data?.winning_seats}</span></div>
                {/* <div className={classes.seats_contested}>Contested : <span>182</span></div> */}
            </div>
        </div>
    )
}

export default SingleParty;