import { useState, useEffect } from "react";


import classes from "../../homepage/electionInfo/ageWise/styles.module.scss";
import InputField from '../../../components/input';
import Button from '../../../components/button';
import { addCandidate, editCandidate } from "../../homepage/electionInfo/actions";
import { loaderStart, loaderEnd, notification } from "../../../components/notification"


const ModalLayout = (props) => {

    const [state, setState] = useState({
        question: "",
        question_type: "",
    })



    useEffect(() => {

    }, [])

    const handleChange = (e, name) => {
        let _state = { ...state };
        _state[name] = e;
        setState(_state)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // let _state = { ...state }
        // if(state.question_type == "choice" || state.question_type == "choices"){
        //     if(options.length >= 2){
        //         _state.choice_set = [...options];
        //         props.handleSubmit(_state)
        //     }
        //     else{
        //         notification(false, "Please add at least two options")
        //     }
            
        // }
        // else{
        //     props.handleSubmit(_state)
        // }
    }
    
    return (
        <form onSubmit={handleSubmit}>
            <div className={classes.col2}>
                <InputField
                    title="Candidate's Name"
                    type="text"
                    required={true}
                    value={state.question}
                    onChange={(e) => handleChange(e, "question")}
                    placeholder={"Candidate's Name"}
                />
                <InputField
                    title="Caste"
                    type="text"
                    required={true}
                    value={state.question}
                    onChange={(e) => handleChange(e, "question")}
                    placeholder={"Caste"}
                />
                <InputField
                    title="Study"
                    type="text"
                    required={true}
                    value={state.question}
                    onChange={(e) => handleChange(e, "question")}
                    placeholder={"Study"}
                />
                <InputField
                    title="Position held in government or local self-government"
                    type="text"
                    required={true}
                    value={state.question}
                    onChange={(e) => handleChange(e, "question")}
                    placeholder={"Position held in government or local self-government"}
                />
                <InputField
                    title="Responsibility for solution in organization"
                    type="text"
                    required={true}
                    value={state.question}
                    onChange={(e) => handleChange(e, "question")}
                    placeholder={"Responsibility for solution in organization"}
                />
                <InputField
                    title="Prior responsibility in the organization"
                    type="text"
                    required={true}
                    value={state.question}
                    onChange={(e) => handleChange(e, "question")}
                    placeholder={"Prior responsibility in the organization"}
                />
                <InputField
                    title="Name of the person who can explain withdrawal of form - no"
                    type="text"
                    required={true}
                    value={state.question}
                    onChange={(e) => handleChange(e, "question")}
                    placeholder={"Name of the person who can explain withdrawal of form - no"}
                />
                {/* <InputField
                    title="Caste"
                    type="select"
                    required={true}
                    value={state.question_type}
                    options={questionType}
                    onChange={(e) => handleChange(e, "question_type")}
                    optionLabel="title"
                    optionValue="value"
                /> */}
                
            </div>
            <br />
            <div className={classes.center}>
                <Button className="auto" title="Submit" type="submit" />
            </div>
            <br />
            <br />
        </form>

    )
}

export default ModalLayout;