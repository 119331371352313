import React from "react";
const Last = ({ age }) => {
    return (
        <>
        {
            age
            ?
            <div style={{ textAlign: "right" }}><strong>Last Updated:</strong> {age}</div>
            : ""
        }
        </>
    )
}
export default Last;