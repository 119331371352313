import React, { useState, useEffect } from 'react';
import ReactSelect from '../../components/reactSelect';
import LineChart from '../../containers/charts/lineChart';

import classes from "../homepage/electionInfo/ageWise/styles.module.scss";
import classes1 from "../homepage/electionInfo/styles.module.scss";

const Election = (props) => {
    const all = [{ label: 2012, value: 2012 }, { label: 2014, value: 2014 }, { label: 2017, value: 2017 }]
    const [year, setYear] = useState([])

    // console.log(props.boothWin);
    return (
        <div>
            <hr />
            <div className={classes.title}>{props.title}</div>
            {/* <ReactSelect
                title="Select Year"
                onChange={(e) => setYear(e)}
                options={all}
                isMulti={true}
                allowSelectAll={true}
                value={year}
                required={true}
            /> */}
            <div className={classes1.allInfo2}>
                <LineChart
                    title="Win/Loss"
                    data={props.boothWin.data}
                    labels={props.boothWin.label}
                />
                <LineChart
                    title="Margin Win"
                    data={props.marginWise.data}
                    labels={props.marginWise.label}
                />
            </div>
            <div className={classes1.allInfo2}>
                <LineChart
                    title="Party wise"
                    data={props.partyWise.data}
                    labels={props.partyWise.label}
                />
            </div>

        </div>
    )
}

export default Election;