import React, { useState, useEffect } from "react";
import { select } from "d3";
import Ahmedabad from "./svgMap/ahmedabad";
import Amreli from "./svgMap/amreli";
import Arvalli from "./svgMap/arvalli";
import AnandSvg from "./svgMap/anandSvg";
import Banaskantha from "./svgMap/banaskantha";
import Bharuch from "./svgMap/bharuch";
import BhavnagarSvg from "./svgMap/bhavnagar";
import Botad from "./svgMap/botad";
import Chhotaudepur from "./svgMap/chhotaudepur";
import Dahod from "./svgMap/dahod";
import Dangs from "./svgMap/dangs";
import DevbhumiDwarka from "./svgMap/devbhumidwarka";
import GandhinagarSVG from "./svgMap/gandhinagar";
import GirSomnath from "./svgMap/girSomnath";
import Jamnagar from "./svgMap/jamnagar";
import Junagarh from "./svgMap/junagarh";
import Mahisagar from "./svgMap/mahisagar";
import Kachchh from "./svgMap/kachchh";
import Kheda from "./svgMap/kheda";
import Mehsana from "./svgMap/mehsana";
import Morbi from "./svgMap/morbi";
import Narmada from "./svgMap/narmada";
import NavsariSvg from "./svgMap/navsariSvg";
import Panchmahal from "./svgMap/panchmahal";
import Patan from "./svgMap/patan";
import Porbandar from "./svgMap/porbandar";
import Rajkot from "./svgMap/rajkot";
import Sabarkantha from "./svgMap/sabarkantha";
import Surat from "./svgMap/surat";
import Surendranagar from "./svgMap/surendranagar";
import Tapi from "./svgMap/tapi";
import Valsad from "./svgMap/valsad";
import VarodaSvg from "./svgMap/varodaSvg";




import classes from "./gujaratMap.module.scss";

const NavsariConstituency = [
    "Vansda",
    "Jalalpore",
    "Gandevi",
    "Navsari"
]

const GujaratMap = ({
    currentConstituency,
    setCurrentConstituency,
    isSmall,
    district
}) => {

    // useEffect(() => {
    //     if (currentConstituency) {
    //         setSelectedConstituency({ name: currentConstituency });
    //     }
    // }, [currentConstituency]);

    const onClick = (clicked) => {
        // console.log(currentConstituency, clicked.getAttribute("id"))
        if (currentConstituency !== clicked.getAttribute("id")) {
            // setSelectedDistrict({});
            select(`#${currentConstituency}`).attr("fill", "#FCFCFC").transition().duration(500).attr("filter", "");
        }
        select(clicked).attr("fill", "#ee8711").transition().duration(500);
        if (clicked.getAttribute("id") !== null) {
            setCurrentConstituency(clicked.getAttribute("id"));
        }
    };
    const onStateMouseEnter = (clicked) => {
        if (clicked.getAttribute("fill") !== "#ee8711") {
            select(clicked).attr("fill", "#bfc1c2").transition().duration(500);
        }
    };

    const onStateMouseLeave = (clicked) => {
        if (clicked.getAttribute("fill") === "#bfc1c2" && clicked.getAttribute("fill") !== "#ee8711") {
            select(clicked).attr("fill", "#FCFCFC").transition().duration(500).attr("filter", "");
        }
    };

    const generateDiv = () => {
        if (district == "Ahmedabad") {
            return <Ahmedabad
                clicked={onClick}
                entered={onStateMouseEnter}
                left={onStateMouseLeave}
                className={classes.biharSvg}
                style={{ width: "120%", height: "85%" }}
            />
        }
        else if (district == "Amreli") {
            return <Amreli
                clicked={onClick}
                entered={onStateMouseEnter}
                left={onStateMouseLeave}
                className={classes.biharSvg}
                style={{ width: "120%", height: "85%" }}
            />
        }
        else if (district == "Anand") {
            return <AnandSvg
                clicked={onClick}
                entered={onStateMouseEnter}
                left={onStateMouseLeave}
                className={classes.biharSvg}
                style={{ width: "120%", height: "85%" }}
            />
        }
        else if (district == "Aravalli") {
            return <Arvalli
                clicked={onClick}
                entered={onStateMouseEnter}
                left={onStateMouseLeave}
                className={classes.biharSvg}
                style={{ width: "120%", height: "85%" }}
            />
        }
        else if (district == "Banaskantha") {
            return <Banaskantha
                clicked={onClick}
                entered={onStateMouseEnter}
                left={onStateMouseLeave}
                className={classes.biharSvg}
                style={{ width: "120%", height: "85%" }}
            />
        }
        else if (district == "Bharuch") {
            return <Bharuch
                clicked={onClick}
                entered={onStateMouseEnter}
                left={onStateMouseLeave}
                className={classes.biharSvg}
                style={{ width: "120%", height: "85%" }}
            />
        }
        else if (district == "Botad") {
            return <Botad
                clicked={onClick}
                entered={onStateMouseEnter}
                left={onStateMouseLeave}
                className={classes.biharSvg}
                style={{ width: "120%", height: "85%" }}
            />
        }
        else if (district == "Chhotaudepur") {
            return <Chhotaudepur
                clicked={onClick}
                entered={onStateMouseEnter}
                left={onStateMouseLeave}
                className={classes.biharSvg}
                style={{ width: "120%", height: "85%" }}
            />
        }
        else if (district == "Dahod") {
            return <Dahod
                clicked={onClick}
                entered={onStateMouseEnter}
                left={onStateMouseLeave}
                className={classes.biharSvg}
                style={{ width: "120%", height: "85%" }}
            />
        }
        else if (district == "Dangs") {
            return <Dangs
                clicked={onClick}
                entered={onStateMouseEnter}
                left={onStateMouseLeave}
                className={classes.biharSvg}
                style={{ width: "120%", height: "85%" }}
            />
        }
        else if (district == "Devbhumi-Dwarka") {
            return <DevbhumiDwarka
                clicked={onClick}
                entered={onStateMouseEnter}
                left={onStateMouseLeave}
                className={classes.biharSvg}
                style={{ width: "120%", height: "85%" }}
            />
        }
        else if (district == "Kachchh") {
            return <Kachchh
                clicked={onClick}
                entered={onStateMouseEnter}
                left={onStateMouseLeave}
                className={classes.biharSvg}
                style={{ width: "120%", height: "85%" }}
            />
        }
        else if (district == "Kheda") {
            return <Kheda
                clicked={onClick}
                entered={onStateMouseEnter}
                left={onStateMouseLeave}
                className={classes.biharSvg}
                style={{ width: "120%", height: "85%" }}
            />
        }
        else if (district == "Jamnagar") {
            return <Jamnagar
                clicked={onClick}
                entered={onStateMouseEnter}
                left={onStateMouseLeave}
                className={classes.biharSvg}
                style={{ width: "120%", height: "85%" }}
            />
        }
        else if (district == "Bhavnagar") {
            return <BhavnagarSvg
                clicked={onClick}
                entered={onStateMouseEnter}
                left={onStateMouseLeave}
                className={classes.biharSvg}
                style={{ width: "120%", height: "85%" }}
            />
        }
        else if (district == "Gandhinagar") {
            return (
                <GandhinagarSVG
                    clicked={onClick}
                    entered={onStateMouseEnter}
                    left={onStateMouseLeave}
                    className={classes.biharSvg}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        }
        else if (district == "Gir-Somnath") {
            return (
                <GirSomnath
                    clicked={onClick}
                    entered={onStateMouseEnter}
                    left={onStateMouseLeave}
                    className={classes.biharSvg}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        }
        else if (district == "Junagadh") {
            return (
                <Junagarh
                    clicked={onClick}
                    entered={onStateMouseEnter}
                    left={onStateMouseLeave}
                    className={classes.biharSvg}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        }
        else if (district == "Mahisagar") {
            return (
                <Mahisagar
                    clicked={onClick}
                    entered={onStateMouseEnter}
                    left={onStateMouseLeave}
                    className={classes.biharSvg}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        }
        else if (district == "Morbi") {
            return (
                <Morbi
                    clicked={onClick}
                    entered={onStateMouseEnter}
                    left={onStateMouseLeave}
                    className={classes.biharSvg}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        }
        else if (district == "Narmada") {
            return (
                <Narmada
                    clicked={onClick}
                    entered={onStateMouseEnter}
                    left={onStateMouseLeave}
                    className={classes.biharSvg}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        }
        else if (district == "Mahesana") {
            return (
                <Mehsana
                    clicked={onClick}
                    entered={onStateMouseEnter}
                    left={onStateMouseLeave}
                    className={classes.biharSvg}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        }
        else if (district == "Navsari") {
            return (
                <NavsariSvg
                    clicked={onClick}
                    entered={onStateMouseEnter}
                    left={onStateMouseLeave}
                    className={classes.biharSvg}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        }
        else if (district == "Panchmahal") {
            return (
                <Panchmahal
                    clicked={onClick}
                    entered={onStateMouseEnter}
                    left={onStateMouseLeave}
                    className={classes.biharSvg}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        }
        else if (district == "Panchmahal") {
            return (
                <Panchmahal
                    clicked={onClick}
                    entered={onStateMouseEnter}
                    left={onStateMouseLeave}
                    className={classes.biharSvg}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        }
        else if (district == "Patan") {
            return (
                <Patan
                    clicked={onClick}
                    entered={onStateMouseEnter}
                    left={onStateMouseLeave}
                    className={classes.biharSvg}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        }
        else if (district == "Porbandar") {
            return (
                <Porbandar
                    clicked={onClick}
                    entered={onStateMouseEnter}
                    left={onStateMouseLeave}
                    className={classes.biharSvg}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        }
        else if (district == "Rajkot") {
            return (
                <Rajkot
                    clicked={onClick}
                    entered={onStateMouseEnter}
                    left={onStateMouseLeave}
                    className={classes.biharSvg}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        }
        else if (district == "Sabarkantha") {
            return (
                <Sabarkantha
                    clicked={onClick}
                    entered={onStateMouseEnter}
                    left={onStateMouseLeave}
                    className={classes.biharSvg}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        }
        else if (district == "Surat") {
            return (
                <Surat
                    clicked={onClick}
                    entered={onStateMouseEnter}
                    left={onStateMouseLeave}
                    className={classes.biharSvg}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        }
        else if (district == "Surendranagar") {
            return (
                <Surendranagar
                    clicked={onClick}
                    entered={onStateMouseEnter}
                    left={onStateMouseLeave}
                    className={classes.biharSvg}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        }
        else if (district == "Tapi") {
            return (
                <Tapi
                    clicked={onClick}
                    entered={onStateMouseEnter}
                    left={onStateMouseLeave}
                    className={classes.biharSvg}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        }
        else if (district == "Vadodara") {
            return (
                <VarodaSvg
                    clicked={onClick}
                    entered={onStateMouseEnter}
                    left={onStateMouseLeave}
                    className={classes.biharSvg}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        }
        else if (district == "Valsad") {
            return (
                <Valsad
                    clicked={onClick}
                    entered={onStateMouseEnter}
                    left={onStateMouseLeave}
                    className={classes.biharSvg}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        }

    }

    return (
        <>
            {generateDiv()}
        </>
    );
};

export default GujaratMap;
