import { callToMobile, smsToMobile } from "../apiCalls/apis/dropdown";
import { loaderStart, loaderEnd } from "../components/notification"


export const makeCall = async (phoneNumber) => {
    // console.log(phoneNumber)
    await callToMobile(phoneNumber)
    // await callToMobile(8075326695)
    // window.open(`tel:${phoneNumber}`, '_self');
}
export const makeSms = async (phoneNumber) => {
    await smsToMobile(phoneNumber)
    // await smsToMobile(8075326695)
}

export const manipulateDataMandal = (data, section) => {
    // console.log(data)
    let _data = []
    if (section === "zila") {
        let i = 1
        data.forEach((d) => {
            _data.push({
                sn: i++,
                name: d.name,
                designation: d.designation,
                phone: d.phone,
                zilla: d.zila,
                unit: d.unit,
                subUnit: d.sub_unit,
                action: d.phone ?
                    <div className="actions">
                        <i className="fa fa-whatsapp whatsapp" aria-hidden="true"></i>&nbsp;&nbsp;
                        <i className="fa fa-sms" onClick={() => makeSms(d.phone)} aria-hidden="true"></i>&nbsp;&nbsp;
                        <i className="fa fa-phone" onClick={() => makeCall(d.phone)} aria-hidden="true"></i>&nbsp;

                    </div>
                    : "",
            })
        })
    }
    else if (section === "mandal") {
        let i = 1
        data.forEach((d) => {
            _data.push({
                sn: i++,
                name: d.name,
                designation: d.designation,
                phone: d.phone,
                zilla: d.zila,
                vidhanSabha: d.vidhan_shabha,
                mandal: d.mandal,
                unit: d.unit,
                subUnit: d.sub_unit,
                action: d.phone ?
                    <div className="actions">
                        <i className="fa fa-whatsapp whatsapp" aria-hidden="true"></i>&nbsp;&nbsp;
                        <i className="fa fa-sms" onClick={() => makeSms(d.phone)} aria-hidden="true"></i>&nbsp;&nbsp;
                        <i className="fa fa-phone" onClick={() => makeCall(d.phone)} aria-hidden="true"></i>&nbsp;

                    </div>
                    : "",
            })
        })
    }
    else if (section === "shakti_kendra") {
        let i = 1
        data.forEach((d) => {
            _data.push({
                sn: i++,
                name: d.name,
                designation: d.designation,
                phone: d.phone,
                zilla: d.zila,
                vidhanSabha: d.vidhan_shabha,
                mandal: d.mandal,
                shaktiKendra: d.shakti_kendra,
                // unit: d.unit,
                // subUnit: d.sub_unit,
                action: d.phone ?
                    <div className="actions">
                        <i className="fa fa-whatsapp whatsapp" aria-hidden="true"></i>&nbsp;&nbsp;
                        <i className="fa fa-sms" onClick={() => makeSms(d.phone)} aria-hidden="true"></i>&nbsp;&nbsp;
                        <i className="fa fa-phone" onClick={() => makeCall(d.phone)} aria-hidden="true"></i>&nbsp;

                    </div>
                    : "",
            })
        })
    }
    else if (section === "booth") {
        let i = 1
        data.forEach((d) => {
            _data.push({
                sn: i++,
                name: d.name,
                designation: d.designation,
                phone: d.number,
                gender: d.gender,
                religion: d.religion,
                zilla: d.zila,
                vidhanSabha: d.vidhan_shabha,
                mandal: d.mandal,
                shaktiKendra: d.shakti_kendra,
                boothNo: d.booth_number,
                // unit: d.unit,
                // subUnit: d.sub_unit,
                action: d.number ?
                    <div className="actions">
                        <i className="fa fa-whatsapp whatsapp" aria-hidden="true"></i>&nbsp;&nbsp;
                        <i className="fa fa-sms" onClick={() => makeSms(d.number)} aria-hidden="true"></i>&nbsp;&nbsp;
                        <i className="fa fa-phone" onClick={() => makeCall(d.number)} aria-hidden="true"></i>&nbsp;

                    </div>
                    : "",
            })
        })
    }

    return _data;
}
export const objectToKeys = (object, name) => {
    let keys = Object.keys(object);
    let data = [];
    keys && keys.forEach((k) => {
        data.push({
            value: object[k][name],
            label: object[k][name]
        })
    })
    // console.log(data)

    return data;
}

export const commaSeparate = (data) => {
    // console.log(data)
    return data ? data.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',') : 0;
}
export const getResponse = (voters) => {
    if (voters == "+Voters")
        return "YES";
    else if (voters == "-Voters")
        return "NO"
    else if (voters == "~Swing")
        return "SWING"
}

export const generateDataForPie = (allData) => {
    let _data = []
    allData.forEach((r) => {
        if (r.VOTER_RESPONSE === "YES") {
            _data[0] = r.count ? r.count : 0
        }
        else if (r.VOTER_RESPONSE === "NO") {
            _data[1] = r.count ? r.count : 0
        }
        else if (r.VOTER_RESPONSE === "SWING") {
            _data[2] = r.count ? r.count : 0
        }
    })
    return _data;
}

export const generateVoterData = (data) => {
    let _data = []
    data && data.forEach((f) => {
        _data.push({
            id: f?.ID,
            name: f?.ENG_F_NAME + " " + f?.ENG_M_NAME + " " + f?.ENG_SURNAME + "(" + f?.F_NAME_V1 + " " + f?.M_NAME_V1 + " " + f?.SURNAME_V1 + ")",
            age: f?.AGE,
            gender: f?.SEX,
            contactno: f?.contactno,
            religion: f?.RELIGION,
            caste: f?.CASTE,
            houseNo: f?.ENG_HOUSE_NO,
            action: <>
                <i className="fa fa-whatsapp whatsapp" aria-hidden="true"></i>&nbsp;&nbsp;
                <i className="fa fa-sms" aria-hidden="true"></i>&nbsp;&nbsp;
                <i className="fa fa-phone" aria-hidden="true"></i>&nbsp;

            </>,
        })
    })
    return _data
}

export const getAllSentiments = (data) => {
    let positive = new Set();
    let negative = new Set();
    data.map((d) =>{
        let _pos = JSON.parse(d.positive_sentiment)
        let _neg = JSON.parse(d.negative_sentiment)
        if(_pos.length > 0){
            _pos = [..._pos, ...positive]
            positive = new Set(_pos)
        }
        if(_neg.length > 0){
            _neg = [..._neg, ...negative]
            negative = new Set(_neg)
        }
    })
    return {
        positive:sentimentArr([...positive]),
        negative:sentimentArr([...negative])
    }
}

export const sentimentArr = (arr) =>{
    let _word = [];
    arr.map((n) => {
        _word.push({
            text: n,
            value: 30
        })
    })
    return _word
}