import React from "react";
import BottomFooter from "./bottomFooter/bottomFooter";
import MiddleFooter from "./middleFooter/middleFooter";
import TopFooter from "./topFooter/topFooter";

const Footer = () => {
  return (
    <>
      {/* <TopFooter /> */}
      {/* <MiddleFooter /> */}
      <BottomFooter />
    </>
  );
};

export default Footer;
