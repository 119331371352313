import React, { useState, useEffect } from 'react';

import classes from "../homepage/electionInfo/ageWise/styles.module.scss";
import ModalLayout from '../../components/modalLayout';

const Election = (props) => {

    const [modal, setModal] = useState(false)
    const [data, setData] = useState([])

    return (
        <div>
            <div className={classes.title}>{props.title}</div>
            <table width="100%" border="0" cellSpacing="0" cellPadding="0" className={classes.grid}>
                <thead>
                    <tr>
                        <th>Booth ID</th>
                        {/* <th>Constituency</th> */}
                        <th >BJP</th>
                        <th >INC</th>
                        <th>Other</th>
                        <th>Total</th>
                        <th >All Candidate</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.data.map((d, i) => (
                            <tr key={i}>
                                <td className={classes.tal}>{d.booth_name}</td>
                                <td className={classes.tac}>{d.party1}</td>
                                <td className={classes.tac}>{d.party2}</td>
                                <td className={classes.tac}>{d.others}</td>
                                <td className={classes.tac}>{d.total_voters}</td>
                                <td className={classes.tac}>
                                    <i className="fa fa-user" style={{color:"#f85055"}} aria-hidden="true"
                                        onClick={() => {
                                            setData(d.all_4_combine_result)
                                            setModal(true)
                                        }}
                                    ></i>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            {
                modal
                    ?
                    <ModalLayout
                        isOpen={modal}
                        toggleModal={() => setModal(false)}
                        title="Candidates"
                        size="small"
                    >
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0" className={classes.grid}>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Party</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((d, i) => (
                                        <tr key={i}>
                                            <td className={classes.tal}>{d.NAME_OF_CONSTITUENCY}</td>
                                            <td className={classes.tac}>{d.PARTY_NAME}</td>

                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </ModalLayout>
                    : null
            }
        </div>
    )
}

export default Election;