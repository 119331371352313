import React, { useState, useEffect } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

// defaults.font.family = 'Poppins, sans-serif !important;';
export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            font: {
                family: "Montserrat", // Add your font here to change the font of your x axis
                size: 40
            },
        },
        title: {
            display: true,
            text: 'Caste wise data',
        },
    },
};


const colors = ['rgba(247,148,30, 0.5)', 'rgba(255, 99, 132, 0.5)', 'rgba(38,130,255, 0.5)',
    'rgba(96,200,122, 0.5)', 'rgba(146, 144, 163, 0.5)', 'rgba(180,130,255, 0.5)', 'rgba(134,255,0, 0.5)',
    'rgba(190,148,30, 0.5)', 'rgba(200, 99, 132, 0.5)', 'rgba(255, 0, 0, 0.5)',
    'rgba(255,82,1, 0.5)', 'rgba(255, 176, 0, 0.5)', 'rgba(252,255,0, 0.5)',
    'rgba(16, 255, 0, 0.5)', 'rgba(0,8,255, 0.5)',
    'rgba(133, 0, 255, 0.5)', 'rgba(255, 0, 106, 0.5)', 'rgba(0,0,88, 0.5)',
    'rgba(255,0,136, 0.5)', 'rgba(255, 32, 22, 0.5)', 'rgba(38,0,255, 0.5)',
    'rgba(186,8,130, 0.5)', 'rgba(153, 195, 132, 0.5)', 'rgba(0, 0, 0, 0.5)']

const labels = [''];

const BarChart = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        labels,
        datasets: [],
    })

    // console.log(defaults)

    useEffect(() => {
        if (props.data && props.data.length > 0) {
            // console.log(props)
            setLoading(true)
            setTimeout(() => {
                let _data = { ...data }

                let _dataset = []
                props.caste.forEach((c, i) => {
                    _dataset.push({
                        label: c,
                        data: [props.data[i] ? props.data[i] : 0],
                        backgroundColor: colors[i % colors.length],
                    })
                })

                _data.datasets = [..._dataset]
                setData(_data)

                console.log(_data)

                setLoading(false)
            }, 1)
        }
    }, [props.data])


    // console.log(data)

    return (
        <>
            {
                data.datasets && data.datasets[0]?.data > 0 && !loading
                    ? <Bar options={options} data={data} /> : null

            }

        </>
    );
}

export default BarChart
