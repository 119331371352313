import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";

import WordCloud from "../../charts/wordCloud"

import FaceBox from "./faceBox"
import InfoCard from "../../infoCard"
import TotalBarGraph from "./totalBarGraph"
import { apiFunction } from "../../../apiCalls/functions/api"
import { GET_ANALYTICS_DATA } from "../../../apiCalls/urls/dashboard/dashboard"
import { commaSeparate, getAllSentiments } from "../../../shared/functions";
import ProgressBar from "../../../components/progressBar";
import { getFetchAPIData } from "../../../shared/pureFunction"
import { getLastUpdatedTime } from "../../../apiCalls/apis/dropdown";
import LastUpdated from "../../../components/lastUpdated"

import classes from "../karyakarta/styles.module.scss";


const Graph = (props) => {

    const [allData, setAllData] = useState({})
    const [yes, setYes] = useState(0)
    const [no, setNo] = useState(0)
    const [swing, setSwing] = useState(0)
    const [score, setScore] = useState([0, 0])

    const [positiveWord, sePositiveWord] = useState([])
    const [negativeWord, setNegativeWord] = useState([])

    const [age, setAge] = useState([])


    useEffect(async () => {
        if (props.level != "constituency") {
            let date = await getLastUpdatedTime()
            setAge(date)
        }
        else {
            setAge("")
        }

    }, [props.level, props.currentDistrict])

    useEffect(() => {
        getData()
    }, [props.firebase.firebaseTime, props.level, props.currentDistrict, props.constituencyData?.id])


    const getData = async () => {
        let data = getFetchAPIData(props.level, props.currentDistrict, props.constituencyData?.id)
        let res = null;
        if (data)
            res = await apiFunction(GET_ANALYTICS_DATA, "post", data, true)

        if (res.status) {
            setAllData(res.data)
            let total = res.data.yes_count + res.data.no_count + res.data.swing_count;

            let yes = total > 0 ? parseFloat(res.data.yes_count / total * 100).toFixed(2) : 0;
            let no = total > 0 ? parseFloat(res.data.no_count / total * 100).toFixed(2) : 0;
            let swing = total > 0 ? parseFloat(res.data.swing_count / total * 100).toFixed(2) : 0;
            setYes(yes)
            setNo(no)
            setSwing(swing)

            let score = res.data.score;
            score = score.split("/");
            // console.log(score)
            let pos = parseInt(score[0])
            let neg = parseInt(score[1]) - pos
            // console.log(pos, neg)
            setScore([pos, neg])

            //Word
            let _posWord = [];
            let sentiments = getAllSentiments(res.data.sentiments)
            // console.log(sentiments)
            sePositiveWord(sentiments.positive)
            setNegativeWord(sentiments.negative)

        }
    }

    return (
        <div>
            <div className={classes.col21} style={{ padding: "0px 2rem", marginBottom: "0" }}>
                <div><FaceBox
                    yes={yes}
                    no={no}
                    swing={swing}
                /></div>
                <div className={classes.col2}>
                    <InfoCard
                        title={"Total Voter"}
                        value={commaSeparate(allData?.total_voter)}
                        background="#e3e1f4"
                        fontSize={'1.5rem'}
                        fontSize1={'1.7rem'}
                        fontWeight1={700}
                    />
                    <InfoCard
                        title={"Total Reach"}
                        value={commaSeparate(allData?.total_reach)}
                        background="#feefe4"
                        fontSize={'1.5rem'}
                        fontSize1={'1.7rem'}
                        fontWeight1={700}
                    />
                </div>
            </div>
            <div className={classes.col21}>
                <div>
                    <div className={classes.graphTitleText} style={{ marginBottom: 30 }}>Satisfaction Rate</div>
                    <ProgressBar progress={allData?.score_in_percentage && allData?.score_in_percentage != "NaN" ? allData?.score_in_percentage : 0} />

                    <div className={classes.graphTitleText} style={{ marginTop: 50 }}>What are people talking</div>
                    <WordCloud size={[800, 200]} words={positiveWord} />
                </div>
                <div>
                    <div className={classes.graphTitleText} style={{ textAlign: "center" }}>Winning score</div>
                    <TotalBarGraph positive={score[0]} negative={score[1]} />
                </div>
            </div>
            <div className={classes.col1}>
                <div>
                    <div className={classes.graphTitleText}>Campaign Buzz words</div>
                    <WordCloud size={[1200, 200]} words={negativeWord} />
                </div>
            </div>
            <LastUpdated age={age} />
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        firebase: state.Firebase
    }
}

export default connect(mapStateToProps, null)(Graph);