import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";

import InfoCard from "../../infoCard"

import classes from "./styles.module.scss";
import BarGraph from "../../charts/barCharts/number"
import { apiFunction } from "../../../apiCalls/functions/api"
import { GET_ANALYTICS } from "../../../apiCalls/urls/dashboard/dashboard"
import { colors } from "../../charts/utils/color"
import { monthAndDate } from "../../../shared/dateFunction"
import { commaSeparate } from '../../../shared/functions';
import { getFetchAPIData } from "../../../shared/pureFunction"

const WorkProgress = (props) => {

    const [total, setTotal] = useState(0)
    const [totalKaryakarta, setTotalKaryakarta] = useState(0)
    const [active, setActive] = useState(0)
    const [graphData, setGraphData] = useState([])

    useEffect(() => {
        getData()
    }, [props.firebase.firebaseTime, props.constituencyData.id, props.level, props.currentDistrict])

    const getData = async () => {
        let data = getFetchAPIData(props.level, props.currentDistrict, props.constituencyData?.id)
        let result;
        if (data)
            result = await apiFunction(GET_ANALYTICS, "post", data, true)
        // console.log(result)
        if (result.status) {
            setTotal(result.data.d1 ? commaSeparate(result.data.d1) : 0)
            setActive(result.data.d3 ? commaSeparate(result.data.d3) : 0)
            setTotalKaryakarta(result.data.d2 ? commaSeparate(result.data.d2) : 0)
            let graphData = getDataForGraph(result.data.d4)
            setGraphData(graphData)
        }
    }

    const getDataForGraph = (data) => {
        let _data = [];
        let count = 0;
        data && data.forEach((d, i) => {
            if (d.LAST_UPDATED_AT && _data.length <= 7) {
                _data.push({
                    label: monthAndDate(d.LAST_UPDATED_AT),
                    data: [d.COUNT],
                    backgroundColor: colors[count],
                })
                count++;
            }
        })

        // console.log(_data)
        return _data;
    }

    console.log(graphData, props.constituencyData)

    return (
        <>
            <div className={classes.title}>
                <span></span>Work Progress<span></span>
            </div>
            <div className={classes.col3}>
                {/* <div></div> */}
                <InfoCard
                    title={"Number of people met till date"}
                    value={total}
                    background="#feefe4"
                    fontSize={'1.3rem'}
                    fontSize1={'2.5rem'}
                    fontWeight1={700}
                />
                <InfoCard
                    title={"Total Karyakarta"}
                    value={totalKaryakarta}
                    background="rgb(228, 248, 254)"
                    fontSize={'1.5rem'}
                    fontSize1={'2.5rem'}
                    fontWeight1={700}
                />
                <InfoCard
                    title={"Number of active karyakartas"}
                    value={active}
                    background="#e3e1f4"
                    fontSize={'1.3rem'}
                    fontSize1={'2.5rem'}
                    fontWeight1={700}
                />
            </div>
            <div className={classes.barGraph}>
                {
                    graphData && graphData.length > 0
                        ?
                        <div>
                            <BarGraph
                                data={graphData}
                            />
                        </div>
                        : null

                }

            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        firebase: state.Firebase
    }
}

export default connect(mapStateToProps, null)(WorkProgress);