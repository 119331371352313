import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

import Input from "../../../components/input"
import PieChart from "../../charts/pieChart";
import StakedBarGraph from "../../charts/stakedBarGraph";

import { pieLabelVoter } from "../../../shared/constants"
import classes from "./styles.module.scss";
// import { allInfluencer, allCaste, allReligion } from "../../../shared/dropdowns";
import { allBooth } from "../../../shared/booth"
import { apiFunction } from "../../../apiCalls/functions/api"
import { GET_INFLUENCER_DATA_COUNT } from "../../../apiCalls/urls/dashboard/dashboard"
import { getFetchAPIData } from "../../../shared/pureFunction"

const Influencer = (props) => {

    const [overall, setOverall] = useState([])
    const [booth, setBooth] = useState()
    const [caste, setCaste] = useState()
    const [religion, setReligion] = useState()
    const [allInfluencer, setAllInfluencer] = useState([])

    const [selected, setSelected] = useState({
        booth: "%",
        caste: "%",
        religion: "%"
    })

    useEffect(() => {
        if (props.allInfluencer && props.allInfluencer.length > 0) {

            let _all = [];
            props.allInfluencer.map((p) => {
                _all.push(p.INFLUENCER_TYPE)
            })
            setAllInfluencer(_all)
            generateData(_all);
        }
    }, [props.allInfluencer, props.firebase.firebaseTime, props.level, props.currentDistrict, props.constituencyData?.id])

    const getData = async (section, value, influencer) => {
        let data = getFetchAPIData(props.level, props.currentDistrict, props.constituencyData?.id)
        let page = {
            "constituency": props.constituencyData.id,
            ...data
        }
        if (section) {
            page = {
                ...page,
                "filterType": section,
                "filter": value
            }
        }
        // console.log(page)

        let result;
        if (data)
            result = await apiFunction(GET_INFLUENCER_DATA_COUNT, "post", page, true)
        // console.log(result)
        if (result.status && result.data.length > 0) {
            let res = generateDataPerSection(result.data, influencer)
            return res;
        }
        else {
            return []
        }
    }

    const generateDataPerSection = (data, influencer) => {
        let yes = [];
        let no = [];
        let swing = [];

        // console.log(data, influencer)

        data.forEach((d) => {
            let index = influencer.findIndex((f) => f == d.INFLUENCER_TYPE)
            if (index >= 0) {
                if (d.VOTER_RESPONSE == "YES") {
                    yes[index] = d.count
                }
                else if (d.VOTER_RESPONSE == "NO") {
                    no[index] = d.count
                }
                else if (d.VOTER_RESPONSE == "SWING") {
                    swing[index] = d.count
                }
            }
        })

        // console.log([yes, no, swing])

        return [yes, no, swing]

    }

    const generateData = (influencer) => {
        for (let i = 0; i < 4; i++) {
            singleSection(i, influencer)
        }
    }



    const singleSection = async (i, influencer) => {
        let _data;
        // console.log(influencer)
        if (i == 0) {
            _data = await getData(null, null, influencer)
            setOverall(_data)
        }
        else if (i == 1) {
            _data = await getData("part_no", selected.booth, influencer)
            setBooth(_data)
        }
        else if (i == 2) {
            _data = await getData("caste", selected.caste, influencer)
            setCaste(_data)
        }
        else if (i == 3) {
            _data = await getData("religion", selected.religion, influencer)
            setReligion(_data)
        }
    }


    const handleChange = async (e, section) => {
        let _selected = { ...selected }
        _selected[section] = e;
        setSelected(_selected)

        let _data;

        if (section == "booth" && props.level === "constituency") {
            _data = await getData("PART_NO", e ? e : "%", allInfluencer)
            setBooth(_data)
        }
        else if (section == "caste") {
            _data = await getData("caste", e ? e : "%", allInfluencer)
            setCaste(_data)
        }
        else if (section == "religion") {
            _data = await getData("religion", e ? e : "%", allInfluencer)
            setReligion(_data)
        }
    }

    // console.log(props)

    return (
        <>
            <div className={classes.title}>
                <span></span>Influencer<span></span>
            </div>
            <div className={classes.col4}>
                <div>
                    <div className={classes.graphTitle}>
                        Overall
                    </div>
                    <div style={{ marginBottom: '3rem' }}></div>
                    {
                        booth ?
                            <div className={classes.bar}>
                                <StakedBarGraph
                                    data={overall}
                                    labels={allInfluencer}
                                />
                            </div>
                            : <div className={`${classes.bar} ${classes.noData}`}>No Data</div>
                    }
                </div>
                {
                    props.level === "constituency"
                        ?
                        <div>
                            <div className={classes.graphTitle}>
                                Booth wise
                            </div>
                            <Input
                                title={""}
                                type="select"
                                options={props.allBooth}
                                optionLabel="booth_name"
                                optionValue="booth_number"
                                style={{ width: "100%" }}
                                placeholder="Booth"
                                value={selected.booth}
                                onChange={(e) => handleChange(e, "booth")}
                            />
                            {
                                booth ?
                                    <div className={classes.bar}>
                                        <StakedBarGraph
                                            data={booth}
                                            labels={allInfluencer}
                                        />
                                    </div>
                                    : <div className={`${classes.bar} ${classes.noData}`}>No Data</div>
                            }
                        </div>
                        : null
                }

                <div>
                    <div className={classes.graphTitle}>
                        Caste
                    </div>

                    <Input
                        title={""}
                        type="select"
                        options={props.allCaste}
                        optionLabel="CASTE"
                        optionValue="ID"
                        style={{ width: "100%" }}
                        placeholder="Caste"
                        value={selected.caste}
                        onChange={(e) => handleChange(e, "caste")}
                    />
                    {
                        booth ?
                            <div className={classes.bar}>
                                <StakedBarGraph
                                    data={caste}
                                    labels={allInfluencer}
                                />
                            </div>
                            : <div className={`${classes.bar} ${classes.noData}`}>No Data</div>
                    }
                </div>
                <div>
                    <div className={classes.graphTitle}>
                        Religion
                    </div>

                    <Input
                        title={""}
                        type="select"
                        options={props.allReligion}
                        optionLabel="RELIGION"
                        optionValue="ID"
                        style={{ width: "100%" }}
                        placeholder="Religion"
                        value={selected.religion}
                        onChange={(e) => handleChange(e, "religion")}
                    />
                    {
                        booth ?
                            <div className={classes.bar}>
                                <StakedBarGraph
                                    data={religion}
                                    labels={allInfluencer}
                                />
                            </div>
                            : <div className={`${classes.bar} ${classes.noData}`}>No Data</div>
                    }
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        firebase: state.Firebase
    }
}

export default connect(mapStateToProps, null)(Influencer);