import React, { useState, useEffect } from 'react';

import classes from "../../homepage/electionInfo/ageWise/styles.module.scss";
import ModalLayout from '../../../components/modalLayout';
import AddCandidate from './addCandidate';
import Button from '../../../components/button';
import { viewCandidate, deleteCandidate } from "../../homepage/electionInfo/actions";
import { loaderStart, loaderEnd, notification } from "../../../components/notification"

const Candidate = (props) => {

    const [modal, setModal] = useState(false)
    const [data, setData] = useState([])

    useEffect(async () => {
        let res = await viewCandidate(props.constituency)
        if (res.status) {
            setData(res.data)
        }
    }, [])

    const handleDelete = async (id) => {
        loaderStart()
        let res = await deleteCandidate();
        if (res.status) {

        }
        loaderEnd()
    }

    return (
        <div>

            <div className={classes.spaceApart}>
                <div className={classes.title}>{"Candidate List"}</div>
                <Button className="auto" title="Add Candidate" onClick={() => setModal(true)} />
            </div>
            <table width="100%" border="0" cellSpacing="0" cellPadding="0" className={classes.grid}>
                <thead>
                    <tr>
                        <th>Candidate's Name</th>
                        <th>Caste</th>
                        <th >Study</th>
                        <th >Position</th>
                        <th>Responsibility</th>
                        <th>Prior responsibility</th>
                        <th>Withdrawal</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((d, i) => (
                            <tr key={i}>
                                {/* <td className={classes.tal}>{d.booth_name}</td>
                                <td className={classes.tac}>{d.party1}</td>
                                <td className={classes.tac}>{d.party2}</td>
                                <td className={classes.tac}>{d.others}</td>
                                <td className={classes.tac}>{d.total_voters}</td>
                                <td className={classes.tac}>
                                    <i className="fa fa-user" style={{ color: "#f85055" }} aria-hidden="true"
                                        onClick={() => {
                                            setData(d.all_4_combine_result)
                                            setModal(true)
                                        }}
                                    ></i>
                                </td> */}
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            {
                modal
                    ?
                    <ModalLayout
                        isOpen={modal}
                        toggleModal={() => setModal(false)}
                        title="Add Candidate"
                    // size="small"
                    >
                        <AddCandidate />
                    </ModalLayout>
                    : null
            }
        </div>
    )
}

export default Candidate;