export const pageType = [
    {
        label: "Election",
        value: "Constituency",
        route: "election"
    },
    {
        label: "Sangathan",
        value: "Mandal",
        route: "sangathan"
    },
    {
        label: "Karyakarta",
        value: "Karyakarta",
        route: "karyakarta"
    },
    {
        label: "Voters",
        value: "Voters",
        route: "voters"
    },
    {
        label: "Assign Karyakarta",
        value: "Assign-Karyakarta",
        route: "assign-karyakarta"
    },
    {
        label: "Graph",
        value: "Graph",
        route: "graph"
    },
    {
        label: "Question",
        value: "Question",
        route: "questions"
    }
]

export const mandalFilter = [
    {
        label: "Zilla",
        value: "zila",
    },
    {
        label: "Mandal",
        value: "mandal",
    },
    {
        label: "Shaktikendra",
        value: "shakti_kendra",
    },
    {
        label: "Booth",
        value: "booth",
    }
]





export const allGender = [
    {
        label: "Male",
        value: "M"
    },
    {
        label: "Female",
        value: "F"
    },
    {
        label: "Others",
        value: "O"
    }
]

export const allVoterFilter = [
    {
        label: "Met",
        value: "yes"
    },
    {
        label: "Not Met",
        value: "no"
    }
]

export const allAge = ["18-25", "25-40", "40-60", "Above 60"]

export const allTime = ["Today", "Last 7 days", "Last 30 days"]

export const allKaryakarta = ["Karyakarta1", "Karyakarta2", "Karyakarta3"]

export const allBeneficiary = ["Beneficiary1", "Beneficiary2"]

export const allCaste = ["Kshatriya", "Rajput", "Ahir", "Muslim", "Dalit", "Koli", "Patel - Leuva", "Patel - Kadva", "Anjna Patel", "Rabari", "Bhanushali", "Gadhavi", "Lohana", "Jain", "Brahman", "Goswami", "Sathvara", "Sindhi", "soni", "Sandhar", "Shikh", "Hariyani", "Darji /Suthar /Luhar", "Itar/Others"]

export const allReligion = ["Hindu", "Muslim", "Sikh", "Christians", "Buddhists", "Jains", "Others"]

export const allOccupation = ["Trader", "Retailer", "Wholeseller", "Manufacturing", "Agent", "Street Vender", "Farmer", "Daily Wager", "Barber", "Tailor", "Auto Driver", "Domestic Helper", "Electrician", "Carpenter", "Lawyer", "Doctor", "Unemployed", "Others"]

export const allInfluencer = ["Social Worker", "Family Head", "Local Leader", "Union member", "Social Media", "Artist", "Sports"]
