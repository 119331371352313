import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import saffron from "../../assets/img/saffron_bg.png"
import bjp from "../../assets/img/bjp_logo.png"
import prime from "../../assets/img/modi.png"
import saral from "../../assets/img/saral_logo.webp"

import InputField from "../../components/input"
import Button from "../../components/button"
import { loaderStart, loaderEnd, notification } from "../../components/notification"
import { apiFunction } from "../../apiCalls/functions/api"
import { LOGIN } from "../../apiCalls/urls/dashboard/login"

import classes from "./styles.module.scss";

const Login = () => {
	let navigate = useNavigate()
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	const onSubmit = async(data) => {
		// console.log(data)
		loaderStart()
		
		let result = await apiFunction(LOGIN, "post", data, false);
		// console.log(result.data)
		if(result.status){
			window.location.href = "/homepage/election"
			// window.location.reload()
			localStorage.setItem("gujarat-token", result.data.token)
		}
		else{
			notification(false, result.message)
		}
		loaderEnd()
	}

	return (
		<div className={classes.container}>
			<div className={classes.leftContainer}>
				<img alt="" className={classes.saffronBackground} src={saffron} />
				<img alt="" className={classes.logoImage} src={bjp} />
				<img alt="" className={classes.personImage} src={prime} />
			</div>
			<div className={classes.centerContainer}>
				{/* <div className={classes.content}>
					<img alt="" className={classes.logo} src={saral} />
				</div> */}
				<div className={classes.centerForm}>
					<form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
						<InputField
							title={"Email Address"}
							type="email"
							placeholder={"Email Address"}
							forms={
								register("username",
									{
										required: "Email Address is required",
									}
								)}
							error={errors.email ? errors.email.message : null}
						/>
						<br />
						<InputField
							title={"Password"}
							type="password"
							placeholder={"Password"}
							forms={
								register("password",
									{
										required: "Password is required",
									}
								)}
							error={errors.password ? errors.password.message : null}
						/>
						<br />
						<Button type="submit" title="Submit"></Button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Login;
