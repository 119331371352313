import React, { useState, useEffect } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    plugins: {
        title: {
            display: true,
            text: '',
        },
    },
    responsive: true,
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};



const BarG = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        labels: [],
        datasets: [
            {
                label: '+Voters',
                data: [],
                backgroundColor: 'rgba(240,138,30, 0.9)',
            },
            {
                label: '-Voters',
                data: [],
                backgroundColor: 'rgba(255, 64, 64, 0.6)',
            },
            {
                label: '~Swing',
                data: [],
                backgroundColor: 'rgba(53, 162, 235, 0.6)',
            },
        ],
    })


    useEffect(() => {
        if (props.data && props.data.length > 0) {
            // console.log(data)
            setLoading(true)
            setTimeout(() => {
                let _data = { ...data }
                _data.labels = [...props.labels]
                _data.datasets[0].data = [...props.data[0]];
                _data.datasets[1].data = [...props.data[1]];
                _data.datasets[2].data = [...props.data[2]];
                setData(_data)
                setLoading(false)
            }, 1)
        }
    }, [props.data])

    // console.log(props)

    return (
        <>
            {
                data.labels && data.labels.length > 0 && !loading
                    ?
                    <Bar options={options} data={data} width="100" height="100" />
                    : null
            }

        </>
    )
}

export default BarG;