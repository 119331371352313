import ProgressBar from "@ramonak/react-progress-bar";
import classes from "./styles.module.scss"

const Progress = (props) => {
    return (
        <>
            <ProgressBar completed={props.progress}
                labelSize={'14px'}
                height={'30px'}
                bgColor="#ec6100"
                className={classes.wrapper}
                labelClassName={classes.label}
                barContainerClassName={classes.container}
                barContainerClassName={classes.container}
                // labelClassName={classes.labelClassName}
            />
            <div style={{ position: "relative" }}>
                <div className={classes.pos}>+Ve</div>
                <div className={classes.neg}>-Ve</div>
            </div>
        </>
    )
}

export default Progress