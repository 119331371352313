import React  from "react";

import classes from "./styles.module.scss";

const colors = ["#ff073a20", "#007bff20", "#e4f4e8", "#f6f6f7"]
const colorsActive = ["#ff073a50", "#007bff50", "#c7efd1", "#e9e9e9"]


const ConCard = (props) =>{
    return(
        <div className={classes.cCard} onClick={props.onClick} style={{backgroundColor: props.active ? colorsActive[props.index%4] : colors[props.index%4]}}>
            {props.title}
        </div>
    )
}

export default ConCard;