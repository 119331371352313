import React, { useState, useEffect } from "react";
import HorizontalTabs from "../../components/tabs"
import SingleSection from "./singleSection"
import { noticeColumn, eventColumn, complainColumn } from "./columns"
import ModalLayout from "../../components/modalLayout";
import Notice from "./modal/notice";
import Events from "./modal/events";

import { loaderStart, loaderEnd, notification } from "../../components/notification"
import { dateIndian, dateIndianComplete } from "../../shared/dateFunction";
import { apiFunction } from "../../apiCalls/functions/api"
import { ADD_NOTICE, ADD_EVENTS, GET_COMPLAIN, GET_NOTICE, GET_EVENT } from "../../apiCalls/urls/dashboard/dashboard"


import classes from "./styles.module.scss";

const DepartmentDashboard = (props) => {

    const [section, setSection] = useState("notice")
    //Notice
    const [noticePagination, setNoticePagination] = useState({

    })
    const [allNotice, setAllNotice] = useState([])
    const [noticeTotal, setNoticeTotal] = useState(0)
    const [noticeModal, setNoticeModal] = useState(false)
    //Event
    const [eventPagination, setEventPagination] = useState({

    })
    const [eventTotal, setEventTotal] = useState(0)
    const [eventModal, setEventModal] = useState(false)
    const [allEvent, setAllEvent] = useState([])

    //Complain
    const [allComplain, setAllComplain] = useState([])
    const [complainPagination, setComplainPagination] = useState({
        "itemsPerPage": 10,
        "pageNo": 1,
        "filters": {
            "constituency": props.constituencyData.id,
        },
        "sort": {
            "attribute": "status",
            "order": "DESC"
        }
    })
    const [totalComplain, setTotalComplain] = useState(0)

    useEffect(() => {
        getNotice()
        getEvent()
    }, [])

    useEffect(() => {
        getComplain();
    }, [complainPagination.pageNo])

    const getComplain = async () => {
        let result = await apiFunction(GET_COMPLAIN, "post", complainPagination, true)
        // console.log(result)
        if (result.status) {
            let rows = [];
            result.data.rows.map((d, i) => {
                rows.push({
                    sn: i + 1,
                    complain: d.complain,
                    status: d.status,
                    time: dateIndianComplete(d.datetime)
                })
            })
            setTotalComplain(rows)
            setAllComplain(result.data?.count ? result.data?.count : 0)
        }
    }

    const getNotice = async () => {
        let result = await apiFunction(GET_NOTICE, "post", { constituency: props.constituencyData.id }, true)
        console.log(result)
        if (result.status) {
            let rows = [];
            result.data.map((d, i) => {
                rows.push({
                    sn: i + 1,
                    con: d.block,
                    title: d.title,
                    notice: d.notice,
                    time: dateIndianComplete(d.datetime)
                })
            })
            setAllNotice(rows)
        }
    }

    const getEvent = async () => {
        let result = await apiFunction(GET_EVENT, "post", { constituency: props.constituencyData.id }, true)
        console.log(result)
        if (result.status) {
            let rows = [];
            result.data.map((d, i) => {
                rows.push({
                    sn: i + 1,
                    con: d.block,
                    title: d.title,
                    description: d.description,
                    fee: d.fee,
                    date_of_event: dateIndian(d.date_of_event),
                    location: d.location,
                    time: dateIndianComplete(d.datetime)
                })
            })
            setAllEvent(rows)
        }
    }

    console.log(noticeModal)

    const updateTable = (section, value) => {
        let _complainPagination = { ...complainPagination }
        _complainPagination[section] = value;
        setComplainPagination(complainPagination)
    }

    const tabJson = [
        {
            title: "Notice",
            name: "notice",
            component: <SingleSection
                title={"Create Notice"}
                rows={allNotice}
                column={noticeColumn}
                total={noticeTotal}
                pagination={false}
                handleModal={() => setNoticeModal(true)}
            />
        },
        {
            title: "Events",
            name: "events",
            component: <SingleSection
                title={"Create Events"}
                rows={allEvent}
                column={eventColumn}
                total={noticeTotal}
                pagination={false}
                handleModal={() => setEventModal(true)}
            />
        },
        {
            title: "Complain",
            name: "complain",
            component: <SingleSection
                title={"Create Complain"}
                rows={allComplain}
                column={noticeColumn}
                hideButton={true}
                pagination={true}
                paginationData={complainPagination}
                totalSize={totalComplain}
                updateTable={updateTable}
            />
        }
    ]

    const handleTabClick = (section) => {
        console.log(section)
        setSection(section)
    }

    const getSelectionIndex = () => {
        let index = tabJson.findIndex((f) => f.name == section)
        return index >= 0 ? index : 0
    }

    const handleSubmit = async (data, section) => {
        loaderStart()
        let result = await apiFunction(section === "notice" ? ADD_NOTICE : ADD_EVENTS, "post", data, true)
        console.log(section)
        if (result.status) {
            notification(true, result.message)
            setNoticeModal(false)
            setEventModal(false)
            if (section == "notice") {
                getNotice()
            }
            else if (section == "events") {
                getEvent()
            }
        }
        else {
            notification(false, result.message)
        }
    }

    return (
        <div>
            <HorizontalTabs
                tabJson={tabJson}
                onClick={handleTabClick}
                selectedIndex={getSelectionIndex()}
            />
            {
                noticeModal
                    ?
                    <ModalLayout
                        size="small"
                        isOpen={true}
                        toggleModal={() => setNoticeModal(false)}
                        title="Add Notice"
                    >
                        <Notice
                            constituencyData={props.constituencyData}
                            handleSubmit={(data) => handleSubmit(data, "notice")}
                        />
                    </ModalLayout>
                    : null
            }
            {
                eventModal
                    ?
                    <ModalLayout
                        size="small"
                        isOpen={true}
                        toggleModal={() => setEventModal(false)}
                        title="Add Event"
                    >
                        <Events
                            constituencyData={props.constituencyData}
                            handleSubmit={(data) => handleSubmit(data, "events")}
                        />
                    </ModalLayout>
                    : null
            }
        </div>
    );
};

export default DepartmentDashboard;
