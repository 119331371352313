import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/dist/svg-arrow.css";
import "tippy.js/animations/shift-away.css";

import classes from "./bottomNav.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { pageType, reportsPage } from "../../../shared/allPage";
import Dropdown from "./dropdown";

const BottomNav = () => {
    const navigate = useNavigate();
    const { route } = useParams();

    const handleClick = (route) => {
        // console.log(route)
        navigate("/homepage/" + route)
    }
    return (
        <div className={classes.container}>
            <div className={classes.navWrapper}>
                <div className={classes.linksWrapper}>
                    {
                        pageType.map((p, i) => (
                            <div key={i} onClick={() => handleClick(p.route)} className={route == p.route ? `${classes.links} ${classes.active}` : classes.links}>{p.label}</div>
                        ))
                    }
                    <Tippy
                        placement="bottom-start"
                        arrow={false}
                        delay={[0, 0]}
                        animation="shift-away"
                        interactive={true}
                        content={<Dropdown page={reportsPage} handleClick={handleClick} />}
                        className={classes.tippyAlt}
                    >
                        <div
                            className={["karyakarta", "graph", "questions"].includes(route) ? `${classes.links} ${classes.active}` : classes.links}
                        // classes.linksActive
                        >
                            Reports
                        </div>
                    </Tippy>
                </div>

                <div className={classes.loginWrapper}>
                    {/* <button onClick={() => navigate("/logout")} className={classes.loginBtn}>
            Logout
          </button> */}
                </div>
            </div>
        </div>
    );
};

export default BottomNav;
