import React, { useState, useEffect } from 'react';
import classes from "./styles.module.scss";
const SingleBox = (props) =>{
    return(
        <div className={classes.singleBox}>
            <div className={classes.singleHead}>
                {props.title}
            </div>
            {props.children}
        </div>
    )
}

export default SingleBox;