import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        labels: [],
        datasets: [
            {
                label: '# of Votes',
                data: [],
                backgroundColor: [
                    'rgba(240,138,30, 1)',
                    'rgba(0, 241, 163, 0.6)',
                    'rgba(53, 162, 235, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                    'rgba(255, 159, 64, 0.6)',
                ],
                borderColor: [
                    'rgba(240,138,30, 1)',
                    'rgba(0, 241, 163, 1)',
                    'rgba(53, 162, 235, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    })

    const formatData = (label, data) => {
        let _data = [];
        label.forEach((l, i) => {
            if (data[i]) {
                _data[i] = data[i]
            }
            else {
                _data[i] = 0
            }
        })

        return _data;
    }

    useEffect(() => {
        if (props.data && props.data.length > 0) {
            // console.log(data)
            setLoading(true)
            setTimeout(() => {
                let _data = { ...data }
                _data.labels = [...props.labels]
                _data.datasets[0].data = [...formatData(props.labels, props.data)];
                setData(_data)
                setLoading(false)
            }, 1)

        }
    }, [props.data])

    const onClick = (e, item) => {
        if (item && item[0].index >= 0) {
            let index = item[0].index;
            // console.log(index);
            let allData = [...data.datasets[0].data]
            // console.log(allData[index])
            // console.log(data.labels[index])
            props.showSelected(data.labels[index], allData[index])
        }

    }

    // console.log(data)



    return (
        <>
            {
                data.labels && data.labels.length > 0 && !loading
                    ?
                    <Pie
                        data={data}
                        width="100"
                        height="100"
                        options={{
                            responsive: true,
                            onClick: function (event, item) {
                                onClick(event, item);
                            }
                        }}
                    />
                    : null

            }

        </>
    )
}

export default PieChart
