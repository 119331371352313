import { apiFunction } from "../functions/api"
import { GET_SECTIONS, GET_BOOTH, GET_KARYAKARTA, IVR_CALL, SMS_NUMBER, LAST_UPDATED } from "../urls/dashboard/dashboard"
import { loaderStart, loaderEnd, notification } from "../../components/notification"

export const getAllBooth = async (name) => {
    let result = await apiFunction(GET_BOOTH + name, "get", {}, false)
    return result.status ? result.data : [];
}

export const getAllSections = async (booth, id) => {
    let result = await apiFunction(GET_SECTIONS + booth + "/" + id, "get", {}, false)
    return result.status ? result.data : [];
}

export const getAllKaryakarta = async (booth, name) => {
    let result = await apiFunction(GET_KARYAKARTA + booth + "/" + name, "get", {}, false)
    if (result.status) {
        let res = [];
        result.data.map((d) => {
            res.push({
                karya_id: d.id,
                "Full Name": d.name
            })
        })
        return res;
    }
    else {
        return []
    }
    // return result.status ? result.data : [];
}

export const callToMobile = async (mobile) => {
    loaderStart()
    let result = await apiFunction(IVR_CALL, "post", {mobile_no:mobile.toString()}, false)
    console.log(result)
    if(result.status){
        notification(true, "Success")
    }
    else{
        notification(false, "Failure")
    }
    loaderEnd()
}

export const smsToMobile = async (mobile) => {
    loaderStart()
    let result = await apiFunction(SMS_NUMBER, "post", {mobile_no:mobile.toString()}, false)
    console.log(result)
    if(result.status){
        notification(true, "Success")
    }
    else{
        notification(false, "Failure")
    }
    loaderEnd()
}

export const getLastUpdatedTime = async () => {
    let result = await apiFunction(LAST_UPDATED, "get", {}, false)
    if(result.status){
        return result.data?.ago
    }
    return ""
}