import React, { useState, useEffect } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import "./styles.css";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: '',
        },
    },
};

const labels = ['2012', '2014', '2017', '2019'];

const colors = [
    {
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
    },
    {
        borderColor: "#742774"
    }
]

const App = (props) => {

    const [loading, setLoading] = useState(false)

    const [data, setData] = useState({
        labels,
        datasets: [
            {
                label: 'BJP',
                data: [1, 8, 3, 12],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
        ],
    })

    useEffect(() => {
        setLoading(true)
        if (props.data?.length > 0) {
            let dataSet = []
            let _data = { ...data };
            props.data.forEach((d, i) => {
                dataSet.push({
                    label: props.labels[i],
                    data: props.data[i],
                    ...colors[i]
                })
            })
            _data.datasets = dataSet;
            // console.log(_data);
            setData(_data)
        }
        setLoading(false)

    }, [props.data])

    // console.log(props);

    return <div>
        {
            !loading && (
                <>
                    <div className='title'>{props.title}</div>
                    <Line options={options} data={data} />
                </>

            )
        }
    </div>;
}

export default App;
