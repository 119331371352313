import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";

import Input from "../../../components/input"
import PieChart from "../../charts/pieChart";
import { pieLabelVoter } from "../../../shared/constants"
import classes from "./styles.module.scss";
import { allGender } from "../../../shared/dropdowns";
import { fakeData } from "../../../shared/fakedata"
import { apiFunction } from "../../../apiCalls/functions/api"
import { GET_KARYAKARTA_DATA, GET_KARYAKARTA_DATA_COUNT } from "../../../apiCalls/urls/dashboard/dashboard"
import ModalLayout from "../../../components/modalLayout"
import OverallModal from "./overallModal"
import { getResponse, generateDataForPie } from "../../../shared/functions"
import { getFetchAPIData } from "../../../shared/pureFunction"

const Overall = (props) => {

    const [overall, setOverall] = useState([])
    const [modal, setModal] = useState(false)

    const [selected, setSelected] = useState({
        booth: "%",
        section: "%",
        karyakarta: "%",
        caste: "%",
        religion: "%",
        gender: "%",
        influencer: "%",
        occupation: "%",
    })
    const [pagination, setPagination] = useState({
        "response": "YES",
        "pageNo": 0,
    })
    const filters = ["booth", "section", "karyakarta", "caste", "religion", "influencer", "occupation", "gender"]
    const [lastSection, setLastSection] = useState("caste")
    const [rows, setRows] = useState([])
    const [total, setTotal] = useState(65533)

    const handleChange = (e, section) => {
        let _selected = { ...selected }
        _selected[section] = e;
        setSelected(_selected)
        setLastSection(section)

        if (section == "booth") {
            props.getSections(e)
            props.getKaryakarta(e)
        }

        if (!filters.includes(section)) {
            let _data1 = fakeData([1], 3000, 4000)
            let _data2 = fakeData([1], 1000, 2000)
            let _data3 = fakeData([1], 1, 1000)

            let _data = [_data1, _data2, _data3]
            // console.log(_data, filters.includes(section))
            setOverall(_data)
        }
    }

    useEffect(() => {
        let data = {
            ...pagination,
            "constituency": props.constituencyData.id,
            caste: selected.caste ? selected.caste : "%",
            religion: selected.religion ? selected.religion : "%",
            occupation: selected.occupation ? selected.occupation : "%",
            gender: selected.gender ? selected.gender : "%",
            section: selected.section ? selected.section : "%",
            booth: selected.booth ? selected.booth : "%",
            karyakarta: selected.karyakarta ? selected.karyakarta : "%",
        }

        //
        let sectionData = getFetchAPIData(props.level, props.currentDistrict, props.constituencyData?.id)
        if(sectionData){
            data = {
                ...sectionData,
                ...data
            }
    
            getData(data)
    
            let swingData = {
                ...data,
            }
            delete swingData.response;
            delete swingData.pageNo;
            getSwing(swingData)
        }
    }, [pagination, selected, props.firebase.firebaseTime, props.constituencyData.id])


    const getData = async (data) => {
        let result = await apiFunction(GET_KARYAKARTA_DATA, "post", data, true)
        // console.log(result)
        let _data = []
        if (result.status) {
            result.data && result.data.forEach((f) => {
                _data.push({
                    id: f.ID,
                    name: f.ENG_F_NAME + " " + f.ENG_M_NAME + " " + f.ENG_SURNAME + "(" + f.F_NAME_V1 + " " + f.M_NAME_V1 + " " + f.SURNAME_V1 + ")",
                    age: f.AGE,
                    gender: f.SEX,
                    contactno: f.contactno,
                    religion: f.RELIGION,
                    caste: f.CASTE,
                    houseNo: f.ENG_HOUSE_NO,
                    action: <>
                        <i className="fa fa-whatsapp whatsapp" aria-hidden="true"></i>&nbsp;&nbsp;
                        <i className="fa fa-sms" aria-hidden="true"></i>&nbsp;&nbsp;
                        <i className="fa fa-phone" aria-hidden="true"></i>&nbsp;

                    </>,
                    // CHNGLISTNO: f.CHNGLISTNO,
                    // ORGNLISTNO: f.ORGNLISTNO,
                    // PART_NO: f.PART_NO,
                    // SECTION_NO: f.SECTION_NO,
                    // STATUSTYPE: f.STATUSTYPE,
                    // ST_CODE: f.ST_CODE

                })
            })
        }
        setRows(_data)
    }


    const getSwing = async (data) => {
        if (filters.includes(lastSection)) {
            let result = await apiFunction(GET_KARYAKARTA_DATA_COUNT, "post", data, true)
            // console.log(result.status, result.data)

            if (result.status && result.data.length > 0) {
                // console.log([
                //     result.data[0] ? result.data[0].count : 0,
                //     result.data[1] ? result.data[1].count : 0,
                //     result.data[2] ? result.data[2].count : 0
                // ])
                setOverall(generateDataForPie(result.data))
            }
            else {
                setOverall([])
            }
        }

    }

    const updateTable = (section, data) => {
        let _pagination = { ...pagination }
        _pagination[section] = data;
        setPagination(_pagination)
    }

    const showSelected = (label, data, section) => {
        setTotal(data ? data : 0)
        let _pagination = { ...pagination }
        setPagination({
            ..._pagination,
            pageNo: 0,
            response: getResponse(label)
        })
        setModal(true)
    }

    // console.log(overall)
    // console.log(props.firebase)



    return (
        <>
            <div className={classes.title}>
                <span></span>Karyakarta Engagement<span></span>
            </div>
            {
                props.level === "constituency"
                    ?
                    <div className={classes.col5} style={{ paddingBottom: 0, paddingLeft: 0 }}>
                        <div></div>
                        <Input
                            title={""}
                            type="select"
                            options={props.allBooth}
                            optionLabel="booth_name"
                            optionValue="booth_number"

                            placeholder="Booth"
                            value={selected.booth}
                            onChange={(e) => handleChange(e, "booth")}
                        />
                        <Input
                            title={""}
                            type="select"
                            options={props.allSection}
                            optionLabel="ENG_LOCALITYID"
                            optionValue="ID"

                            placeholder="Sections"
                            value={selected.section}
                            onChange={(e) => handleChange(e, "section")}
                        />
                        <Input
                            title={""}
                            type="select"
                            options={props.allKaryakarta}
                            optionLabel="Full Name"
                            optionValue="karya_id"

                            placeholder="Karyakarta"
                            value={selected.karyakarta}
                            onChange={(e) => handleChange(e, "karyakarta")}
                        />
                    </div>
                    : null

            }

            <div className={classes.col5}>
                <Input
                    title={""}
                    type="select"
                    options={props.allCaste}
                    optionLabel="CASTE"
                    optionValue="ID"

                    placeholder="Caste"
                    value={selected.caste}
                    onChange={(e) => handleChange(e, "caste")}
                />
                <Input
                    title={""}
                    type="select"
                    options={props.allReligion}
                    optionLabel="RELIGION"
                    optionValue="ID"

                    placeholder="Religion"
                    value={selected.religion}
                    onChange={(e) => handleChange(e, "religion")}
                />
                <Input
                    title={""}
                    type="select"
                    options={allGender}
                    optionLabel="label"
                    optionValue="value"

                    placeholder="Gender"
                    value={selected.gender}
                    onChange={(e) => handleChange(e, "gender")}
                />
                {/* <Input
                    title={""}
                    type="select"
                    options={allAge.map(a => { return { name: a } })}
                    optionLabel="name"
                    optionValue="name"

                    placeholder="Age"
                    value={selected.age}
                    onChange={(e) => handleChange(e, "age")}
                /> */}
                <Input
                    title={""}
                    type="select"
                    options={props.allInfluencer}
                    optionLabel="INFLUENCER_TYPE"
                    optionValue="ID"

                    placeholder="Influencer"
                    value={selected.influencer}
                    onChange={(e) => handleChange(e, "influencer")}
                />
                <Input
                    title={""}
                    type="select"
                    options={props.allOccupation}
                    optionLabel="OCCUPATION"
                    optionValue="ID"

                    placeholder="Occupation"
                    value={selected.occupation}
                    onChange={(e) => handleChange(e, "occupation")}
                />
                {/* <Input
                    title={""}
                    type="select"
                    options={allTime.map(a => { return { name: a } })}
                    optionLabel="name"
                    optionValue="name"

                    placeholder="Time"
                    value={selected.time}
                    onChange={(e) => handleChange(e, "time")}
                /> */}
            </div>
            <div className={classes.col3}>
                <div></div>
                <div>
                    {
                        overall.length > 0
                            ?
                            <div className={classes.karyakartaPie}>
                                <PieChart
                                    data={overall}
                                    labels={pieLabelVoter}
                                    showSelected={(label, data) => showSelected(label, data)}
                                />
                            </div>
                            : <div className={`${classes.karyakartaPie} ${classes.noData}`}>No Data</div>
                    }
                </div>
            </div>
            {
                modal
                    ?
                    <ModalLayout
                        isOpen={modal}
                        toggleModal={() => setModal(false)}
                        title="Karyakarta Engagement"

                    >

                        <OverallModal
                            updateTable={updateTable}
                            total={total}
                            pagination={pagination}
                            rows={rows}
                        />
                    </ModalLayout>
                    : null
            }
        </>
    )
}



const mapStateToProps = (state) => {
    return {
        firebase: state.Firebase
    }
}

export default connect(mapStateToProps, null)(Overall);