import React, { useState, useEffect } from 'react';

import classes from "../homepage/electionInfo/ageWise/styles.module.scss";
import { commaSeparate } from "../../shared/functions"
import ModalLayout from '../../components/modalLayout';
import BoothPerFiler from "./boothPerFilter";
import { boothWinLossData, marginWinLossList } from "../homepage/electionInfo/actions";
const Election = (props) => {

    const [modal, setModal] = useState(false)
    const [pagination, setPagination] = useState({
        pageNo: 1
    })

    const [total, setTotal] = useState(220)
    const [section, setSection] = useState(220)
    const [rows, setRows] = useState([])

    const onClickCard = async (section, operation) => {
        setTotal(0)
        // setPagination({
        //     pageNo: 1
        // })
        setRows([])
        setModal(true)
        if (section === "booth") {
            let data = {
                ...props.selectedData,
                "year": props.selectedYear,
                ...pagination,
                "itemsPerPage": 10,
                "order": "DESC"
            }

            let res = await boothWinLossData(operation, data)
            // console.log((res));
            
            if (res.data) {
                setTotal(res.data.count)
                setRows(res.data.rows)
            }
            
            setSection({
                section, operation
            })
        }
        else{
            let data = {
                ...props.selectedData,
                "year": props.selectedYear,
                ...pagination,
                "itemsPerPage": 10,
                "marginRange": [0, 10]
            }
            if(operation =="minus"){
                data.marginRange = [-10, 0]
            }
            let res = await marginWinLossList(data)
            // console.log(res)
            if (res.data) {
                setTotal(res.data.count)
                setRows(res.data.rows)
            }
            

        }
    }

    const updateTable = (action, id) => {
        let _pagination = { ...pagination }
        _pagination.pageNo = id
        console.log(_pagination);
        setPagination(_pagination)
    }

    useEffect(() => {
        if(section.section && section.operation){
            onClickCard(section.section, section.operation)
        }
    }, [pagination])

    return (
        <>
            <br />
            <div className={classes.allInfo2}>
                <div>
                    <div className={classes.title}>Win/Loss Booth</div>
                    <div className={classes.eachSection}>
                        <EachCard
                            count={commaSeparate(props.total?.winCount)}
                            name="Seat Won"
                            handleClick={() => onClickCard("booth", "win")}
                            hideButton={props.selectedData?.constituency ? false : true}
                        />
                        <EachCard
                            count={commaSeparate(props.total?.lossCount)}
                            name="Seat Loss"
                            handleClick={() => onClickCard("booth", "loss")}
                            hideButton={props.selectedData?.constituency ? false : true}
                        />
                    </div>
                </div>
                <div>
                    <div className={classes.title}>Marginal Win/Loss</div>
                    <div className={classes.eachSection}>
                        <EachCard
                            count={commaSeparate(props.margin?.plus)}
                            name="Margin Won"
                            handleClick={() => onClickCard("margin", "plus")}
                            hideButton={props.selectedData?.constituency ? false : true}
                        />
                        <EachCard
                            count={commaSeparate(props.margin?.minus)}
                            name="Margin Loss"
                            handleClick={() => onClickCard("margin", "minus")}
                            hideButton={props.selectedData?.constituency ? false : true}
                        />
                    </div>
                </div>
            </div>
            <div style={{ width: "50%", margin: "auto" }}>
                <div>
                    <div className={classes.title}>Total Votes</div>
                    <div className={classes.eachSection}>
                        <EachCard
                            count={commaSeparate(props.totalVote?.totalValidVotes)}
                            name="Total Votes"
                            hideButton={true}
                        />
                    </div>
                </div>
            </div>
            {
                modal
                    ?
                    <ModalLayout
                        isOpen={modal}
                        toggleModal={() => setModal(false)}
                        title="Booth"
                    >
                        <BoothPerFiler
                            data={rows}
                            pagination={pagination}
                            total={total}
                            updateTable={updateTable}
                        />
                    </ModalLayout>
                    : null
            }
        </>
    )
}

export default Election;


export const EachCard = (props) => {
    return (
        <div className={classes.eachBox} style={{position:"relative"}}>
            <span className={classes.numberSpan}>{props.count}</span>
            <br />
            <span className={classes.nameSpan}>{props.name}</span>
            {
                !props.hideButton
                    ?
                    <span style={{position:"absolute", top:8, right:8}}>
                        <i className="fa fa-eye" style={{ color: "#f85055" }} aria-hidden="true"
                            onClick={props.handleClick}
                        ></i>
                    </span>
                    : null
            }

        </div>
    )
}