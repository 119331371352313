import React, { useState, useEffect } from 'react';
import WordCloud from "../../charts/wordCloud"

import positive from "../././../../assets/img/pos.png"
import negative from "../././../../assets/img/negative.png"
import swing from "../././../../assets/img/swing.png"



import classes from "../karyakarta/styles.module.scss";


const Facebook = (props) => {
    return (
        <div className={classes.col3}>
            <div className={classes.faceBox}>
                <img className={classes.faceImg} src={positive} />
                <div style={{ color: "#4dd64dd1" }} className={classes.faceTitle}>{props.yes}%</div>
            </div>
            <div className={classes.faceBox}>
                <img src={swing} className={classes.faceImg} />
                <div style={{ color: "#ff9c3f" }} className={classes.faceTitle}>{props.no}%</div>
            </div>
            <div className={classes.faceBox}>
                <img src={negative} className={classes.faceImg} />
                <div style={{ color: "#ff3f3fe6" }} className={classes.faceTitle}>{props.swing}%</div>
            </div>
        </div>

    )
}

export default Facebook;