import React, { useState, useEffect } from 'react';

import SingleBox from "../singleBox";
import classes from "./styles.module.scss";

const Election = (props) => {
    return (
        <>
            <SingleBox title="ELECTORAL FEATURES">
                <div className={classes.electFeature}>
                    <div className={classes.elect_feature_left}>
                        <div className={classes.normal_text}>Electors : <span>{props.total?.totalVoter}</span></div>
                        <div className={classes.male}>
                            <img src={"https://www.indiastatelections.com/images/ElectionImage/male.svg"} />
                            <div className={classes.male_graph}>{props.data?.malePer}%</div>
                        </div>
                        <div className={classes.male}>
                            <img src={"https://www.indiastatelections.com/images/ElectionImage/female.svg"} />
                            <div className={classes.male_graph} style={{background: '#b5b5b5'}}>{props.data?.femalePer}%</div>
                        </div>
                        <div className={classes.normal_text}>Sex Ratio : <span>{props.data?.sexRatio}</span></div>
                    </div>
                    <div className={classes.elect_feature_right}>
                        <div className={classes.votes_box}>
                            <div className={classes.votes_icon}>
                                <img src={"https://www.indiastatelections.com/images/ElectionImage/votes_icon.svg"} /></div>
                            <div className={classes.normal_text}>Votes : <span>{props.total?.totalValidVotes}</span></div>
                            <div className={classes.normal_text} >Polling Stations</div>
                            <div className={classes.polling_stations}>{props.total?.totalPollingStations}</div>
                        </div>
                        <div className={classes.voter_turnout_text}>Voter Turnout</div>
                        <div className={classes.voter_turnout} style={{ width: props.total?.percent ? props.total?.percent + "%" : "0%" }}>{props.total?.percent}%</div>
                        <div
                            style={{ width: props.total?.percent ? (100 - props.total?.percent) + "%" : "0%" }}
                            className={classes.voter_turnout_blank}>&nbsp;</div>
                    </div>
                    {/* <div class="elect_feature_left">
                        <div class="poll_date">Poll Date : <span>09-14/12/2017</span></div>
                    </div> */}
                </div>
            </SingleBox>
        </>
    )
}

export default Election;