import * as React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { followCursor } from "tippy.js";
import classes from "../gujaratMap.module.scss";

const SvgMap = (props) => (
	<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
		<defs>
			<style>
				{".st0{fill:#000000}.st1{fill:#ddd; border:1px solid red;}"}
			</style>
		</defs>

		<Tippy
			followCursor={true}
			plugins={[followCursor]}
			className={classes.tippy}
			hideOnClick={false}
			content={<span className={classes.tippyContent}>Mansa</span>}
		>
			<polygon

				onClick={(e) => props.clicked(e.target)}
				onMouseEnter={(e) => props.entered(e.target)}
				onMouseLeave={(e) => props.left(e.target)}
				id="Mansa"
				fill="#FCFCFC"
				points="405.1,106.2 406.8,106.2 406.8,107.9 406.8,109.5 406.8,111.2 406.8,112.9 408.5,114.5 408.5,116.2 
	408.5,117.8 408.5,119.5 406.8,121.2 408.5,121.2 408.5,122.8 410.1,122.8 411.8,124.5 411.8,122.8 413.5,122.8 415.1,121.2 
	415.1,119.5 416.8,119.5 416.8,117.8 418.4,117.8 418.4,116.2 420.1,116.2 421.8,116.2 423.4,116.2 425.1,116.2 426.7,116.2 
	431.7,117.8 431.7,119.5 431.7,121.2 431.7,122.8 431.7,124.5 430.1,124.5 430.1,126.1 430.1,127.8 428.4,127.8 428.4,129.5 
	428.4,131.1 428.4,132.8 430.1,134.4 430.1,136.1 430.1,137.8 430.1,139.4 428.4,139.4 428.4,141.1 426.7,141.1 430.1,144.4 
	433.4,147.7 438.4,151 440,152.7 441.7,152.7 443.3,152.7 443.3,151 445,151 445,149.4 446.6,149.4 446.6,147.7 448.3,147.7 
	450,147.7 451.6,147.7 453.3,147.7 455,147.7 456.6,147.7 458.3,147.7 458.3,149.4 459.9,149.4 461.6,149.4 461.6,151 463.3,151 
	464.9,151 466.6,151 466.6,149.4 468.2,149.4 468.2,151 469.9,151 469.9,152.7 469.9,154.4 471.6,156 471.6,157.7 473.2,157.7 
	473.2,159.3 474.9,159.3 474.9,161 474.9,162.7 476.5,162.7 476.5,164.3 476.5,166 476.5,167.6 474.9,169.3 476.5,171 476.5,172.6 
	478.2,172.6 479.9,172.6 481.5,172.6 481.5,174.3 483.2,172.6 484.8,172.6 486.5,172.6 486.5,171 488.2,171 503.1,177.6 
	503.1,175.9 503.1,174.3 503.1,172.6 504.8,172.6 504.8,171 506.4,169.3 506.4,171 508.1,171 509.8,172.6 511.4,172.6 513.1,174.3 
	514.7,174.3 516.4,174.3 518,175.9 519.7,175.9 521.4,175.9 523,175.9 524.7,175.9 528,175.9 528,174.3 529.7,174.3 529.7,175.9 
	528,175.9 528,177.6 528,179.3 529.7,180.9 529.7,182.6 529.7,184.2 531.3,184.2 533,185.9 533,187.6 536.3,189.2 536.3,190.9 
	538,192.6 539.6,194.2 539.6,195.9 541.3,195.9 541.3,197.5 543,199.2 543,200.9 544.6,202.5 544.6,204.2 546.3,205.8 546.3,207.5 
	546.3,209.1 546.3,210.8 546.3,212.5 546.3,214.1 544.6,215.8 544.6,217.4 543,219.1 543,220.8 543,222.4 541.3,224.1 539.6,225.8 
	539.6,227.4 539.6,229.1 538,230.7 538,232.4 536.3,232.4 536.3,234.1 534.7,235.7 534.7,237.4 534.7,239 533,239 531.3,240.7 
	531.3,242.4 529.7,242.4 529.7,244 528,244 528,245.7 526.3,245.7 524.7,245.7 524.7,247.3 523,247.3 523,249 521.4,249 
	521.4,250.7 519.7,250.7 519.7,252.3 519.7,254 519.7,255.6 519.7,257.3 521.4,259 521.4,260.6 523,262.3 524.7,263.9 526.3,265.6 
	526.3,267.3 528,267.3 528,268.9 528,270.6 528,272.2 526.3,272.2 526.3,273.9 524.7,273.9 523,273.9 521.4,273.9 519.7,273.9 
	518,273.9 516.4,272.2 514.7,272.2 513.1,270.6 511.4,268.9 509.8,267.3 509.8,265.6 508.1,263.9 506.4,263.9 504.8,262.3 
	503.1,262.3 501.4,262.3 499.8,262.3 498.1,262.3 496.5,262.3 496.5,263.9 494.8,263.9 494.8,265.6 493.1,265.6 493.1,267.3 
	491.5,267.3 489.8,268.9 488.2,268.9 486.5,268.9 484.8,268.9 483.2,268.9 481.5,268.9 479.9,268.9 478.2,268.9 476.5,268.9 
	474.9,268.9 473.2,268.9 469.9,268.9 469.9,270.6 468.2,270.6 466.6,270.6 464.9,272.2 463.3,272.2 461.6,273.9 459.9,273.9 
	458.3,275.6 456.6,277.2 455,277.2 455,278.9 453.3,280.5 453.3,283.9 453.3,285.5 453.3,287.2 453.3,288.8 453.3,290.5 
	453.3,292.2 455,292.2 455,293.8 456.6,293.8 456.6,295.5 458.3,295.5 459.9,297.1 461.6,297.1 461.6,298.8 461.6,300.5 
	461.6,302.1 461.6,303.8 461.6,305.4 459.9,305.4 459.9,307.1 458.3,307.1 456.6,308.8 455,308.8 453.3,308.8 451.6,308.8 
	451.6,310.4 450,310.4 450,312.1 448.3,312.1 448.3,313.8 446.6,315.4 446.6,317.1 446.6,318.7 446.6,320.4 446.6,322 448.3,322 
	448.3,323.7 448.3,325.4 448.3,327 448.3,328.7 450,328.7 448.3,330.4 448.3,333.7 448.3,335.3 448.3,337 446.6,338.6 445,340.3 
	443.3,343.6 441.7,343.6 441.7,345.3 440,347 440,348.6 438.4,348.6 436.7,348.6 436.7,347 435,347 433.4,347 435,347 435,345.3 
	433.4,345.3 433.4,343.6 435,343.6 435,342 435,340.3 433.4,340.3 433.4,338.6 433.4,340.3 433.4,338.6 431.7,338.6 431.7,337 
	430.1,337 428.4,337 428.4,335.3 426.7,335.3 426.7,333.7 428.4,333.7 428.4,332 428.4,330.4 428.4,328.7 426.7,328.7 426.7,330.4 
	426.7,328.7 425.1,328.7 425.1,330.4 425.1,332 423.4,332 423.4,330.4 421.8,328.7 418.4,328.7 416.8,328.7 416.8,327 415.1,327 
	415.1,328.7 413.5,328.7 411.8,328.7 410.1,327 410.1,325.4 410.1,327 408.5,327 406.8,327 406.8,325.4 405.1,327 401.8,327 
	400.2,327 400.2,328.7 398.5,328.7 396.9,327 395.2,327 391.9,327 388.5,327 386.9,325.4 385.2,327 381.9,327 380.2,327 378.6,327 
	375.3,328.7 373.6,330.4 370.3,332 367,328.7 365.3,327 363.6,325.4 362,325.4 360.3,325.4 358.6,323.7 357,325.4 355.3,327 
	353.7,330.4 352,332 348.7,332 345.4,330.4 343.7,328.7 340.4,327 338.7,330.4 337.1,332 335.4,332 333.8,330.4 332.1,330.4 
	330.4,330.4 330.4,332 330.4,330.4 330.4,328.7 330.4,327 330.4,325.4 330.4,323.7 332.1,322 332.1,320.4 333.8,320.4 333.8,318.7 
	335.4,318.7 337.1,317.1 338.7,315.4 338.7,313.8 337.1,313.8 335.4,313.8 335.4,312.1 333.8,312.1 332.1,312.1 327.1,310.4 
	325.5,310.4 323.8,310.4 323.8,312.1 322.1,312.1 320.5,312.1 318.8,312.1 318.8,313.8 317.1,313.8 315.5,313.8 313.8,313.8 
	312.2,313.8 310.5,312.1 308.9,312.1 307.2,312.1 305.5,312.1 303.9,312.1 302.2,312.1 300.5,312.1 298.9,312.1 298.9,310.4 
	298.9,308.8 297.2,308.8 297.2,307.1 295.6,305.4 295.6,303.8 295.6,302.1 293.9,303.8 293.9,302.1 292.3,302.1 290.6,302.1 
	288.9,302.1 287.3,302.1 285.6,302.1 285.6,303.8 284,303.8 282.3,303.8 282.3,305.4 280.6,305.4 280.6,307.1 279,307.1 279,308.8 
	277.3,308.8 275.6,308.8 274,307.1 272.3,307.1 270.7,305.4 269,303.8 269,302.1 269,300.5 265.7,300.5 264,297.1 262.4,295.5 
	262.4,293.8 260.7,293.8 259,293.8 257.4,293.8 257.4,292.2 255.7,292.2 255.7,290.5 254.1,290.5 252.4,290.5 252.4,288.8 
	252.4,287.2 250.7,287.2 250.7,285.5 249.1,285.5 249.1,283.9 249.1,280.5 247.4,280.5 247.4,277.2 249.1,277.2 249.1,275.6 
	250.7,275.6 250.7,273.9 252.4,270.6 254.1,268.9 254.1,267.3 255.7,267.3 255.7,265.6 257.4,265.6 259,263.9 260.7,263.9 
	262.4,262.3 264,260.6 265.7,260.6 267.3,260.6 269,260.6 269,259 270.7,259 270.7,257.3 270.7,255.6 269,252.3 269,250.7 269,249 
	269,247.3 267.3,247.3 269,247.3 270.7,239 272.3,239 274,239 274,240.7 275.6,240.7 275.6,239 280.6,237.4 280.6,235.7 
	282.3,235.7 282.3,237.4 285.6,237.4 288.9,235.7 288.9,234.1 288.9,232.4 288.9,230.7 287.3,229.1 287.3,227.4 287.3,225.8 
	285.6,225.8 285.6,224.1 284,224.1 282.3,222.4 284,222.4 284,220.8 284,219.1 284,217.4 284,215.8 285.6,215.8 285.6,214.1 
	285.6,212.5 285.6,210.8 284,209.1 288.9,209.1 290.6,210.8 292.3,210.8 293.9,210.8 293.9,212.5 295.6,212.5 297.2,212.5 
	298.9,212.5 300.5,214.1 302.2,214.1 303.9,214.1 305.5,214.1 307.2,214.1 308.9,214.1 310.5,214.1 312.2,214.1 313.8,214.1 
	315.5,212.5 317.1,212.5 318.8,212.5 320.5,210.8 322.1,210.8 323.8,212.5 325.5,212.5 327.1,212.5 327.1,210.8 328.8,209.1 
	330.4,207.5 332.1,207.5 332.1,205.8 333.8,205.8 333.8,204.2 333.8,200.9 333.8,199.2 333.8,195.9 333.8,194.2 333.8,190.9 
	333.8,189.2 333.8,185.9 333.8,184.2 333.8,180.9 335.4,179.3 335.4,175.9 335.4,172.6 335.4,171 343.7,144.4 343.7,146.1 
	345.4,147.7 347,147.7 347,149.4 348.7,149.4 350.4,151 352,151 353.7,151 355.3,152.7 357,152.7 357,154.4 357,156 358.6,157.7 
	358.6,159.3 358.6,161 358.6,162.7 362,162.7 363.6,162.7 365.3,162.7 367,162.7 368.6,162.7 370.3,162.7 371.9,162.7 373.6,162.7 
	373.6,161 375.3,161 376.9,161 376.9,159.3 376.9,157.7 376.9,156 376.9,154.4 376.9,152.7 376.9,151 376.9,149.4 376.9,147.7 
	376.9,146.1 376.9,144.4 376.9,142.7 376.9,141.1 376.9,139.4 376.9,137.8 378.6,137.8 376.9,137.8 375.3,136.1 373.6,136.1 
	375.3,134.4 375.3,132.8 376.9,131.1 378.6,129.5 378.6,127.8 380.2,127.8 381.9,127.8 381.9,126.1 381.9,124.5 380.2,124.5 
	380.2,122.8 380.2,121.2 380.2,119.5 378.6,119.5 378.6,117.8 378.6,116.2 380.2,114.5 381.9,112.9 383.6,111.2 385.2,109.5 
	386.9,109.5 386.9,107.9 388.5,107.9 390.2,106.2 391.9,106.2 391.9,104.6 393.5,104.6 395.2,104.6 395.2,102.9 398.5,101.2 
	398.5,102.9 400.2,104.6 400.2,106.2 401.8,106.2 403.5,106.2 "

			/>
		</Tippy>

		<path class="st1" d="M440,351.1h-3.3c-1.1,0-2-0.7-2.4-1.7h-1c-1.4,0-2.5-1.1-2.5-2.5c0.2-0.5,0.2-1.1,0-1.7v-1.6
	c0-0.6,0.2-1.2,0.6-1.7c-0.2-0.3-0.4-0.6-0.5-0.9c-0.8-0.2-1.4-0.8-1.6-1.6h-1c-1.8-0.9-3.2-2.4-4.1-4.2v-0.8h-0.8
	c-1.4,0-2.5-1.1-2.5-2.5v-0.6l-0.2-0.2h-3.9c-0.5-0.2-1.1-0.2-1.7,0h-3.3c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9h-2.3
	c-0.3,0-0.6,0-0.8-0.1c-0.3,0.1-0.5,0.1-0.8,0.1h-2.6c-0.4,1-1.3,1.7-2.4,1.7h-1.6c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9h-7.3
	c-0.6,0-1.2-0.2-1.6-0.6c-0.5,0.4-1,0.6-1.7,0.6h-6.1l-2.4,1.2l-1.4,1.4c-0.2,0.2-0.4,0.4-0.6,0.5l-3.3,1.6c-1,0.5-2.1,0.3-2.9-0.5
	l-5.9-5.9h-2.3c-0.6,0-1.2-0.2-1.7-0.6l-1.3,1.3l-1.5,3c-0.1,0.2-0.3,0.5-0.5,0.6l-1.7,1.7c-0.5,0.5-1.1,0.7-1.8,0.7h-3.3
	c-0.4,0-0.8-0.1-1.1-0.3l-3.3-1.7c-0.2-0.1-0.5-0.3-0.6-0.5l-1.4-1.4l-0.7-0.4l-0.5,1.1c-0.1,0.2-0.3,0.5-0.5,0.7l-1.7,1.7
	c-0.5,0.5-1.1,0.7-1.8,0.7h-1.7c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9c-0.5,1.3-1.9,1.9-3.2,1.5c-1-0.4-1.6-1.3-1.6-2.4v-8.3
	c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-0.6c0.9-1.8,2.4-3.2,4.2-4.2h0.6l0.1-0.1c-0.7-0.3-1.2-0.8-1.5-1.5h-1c-0.3,0-0.5,0-0.8-0.1
	l-4.6-1.5h-0.6c-0.4,1-1.3,1.7-2.4,1.7h-2.6c-0.4,1-1.3,1.7-2.4,1.7h-6.6c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9h-10.5
	c-1.4,0-2.5-1.1-2.5-2.5c0,0,0,0,0,0v-1c-1-0.4-1.7-1.3-1.7-2.4v-0.6l-0.9-0.9c-0.3-0.3-0.5-0.6-0.6-1l-0.2-0.1
	c-0.6-0.3-1.2-0.8-1.4-1.5H288c-0.4,1-1.3,1.7-2.4,1.6h-1c-1.1,1.1-2.2,2.1-3.3,3.2c-0.3,1.1-1.3,1.8-2.4,1.8h-3.3
	c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9h-0.6c-0.7,0-1.3-0.3-1.8-0.7l-3.3-3.3c-0.5-0.5-0.7-1.1-0.7-1.8V303h-0.8c-0.9,0-1.8-0.5-2.2-1.4
	l-1.4-3l-1.4-1.4c-0.3-0.3-0.5-0.6-0.6-0.9h-2.6c-1.5-0.9-2.8-2.1-4-3.3h-1c-1.4,0-2.5-1.1-2.5-2.5v-1c-1.3-1.2-2.4-2.5-3.3-4v-2.6
	c-1-0.4-1.7-1.3-1.7-2.4v-3.3c0.9-1.5,2.1-2.9,3.4-4c0-0.1,0.1-0.3,0.2-0.4l1.6-3.3c0.1-0.2,0.3-0.5,0.5-0.7l0.9-0.9v-0.6
	c0.9-1.8,2.4-3.2,4.2-4.2h0.6l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7h0.6l2.6-2.6c0.5-0.5,1.1-0.7,1.8-0.7h2.6c0.3-0.7,0.8-1.3,1.5-1.5
	v-0.4l-1.4-2.8c-0.2-0.3-0.3-0.7-0.3-1.1v-2.6c-1.3-0.5-2-1.9-1.5-3.2c0.3-0.9,1.1-1.5,1.9-1.6l1.3-6.3c0.2-1.2,1.3-2,2.5-2h3.3
	c0.3,0,0.6,0,0.8,0.1l0,0l3.3-1.1c0.1-1.3,1.2-2.4,2.5-2.4h1.7c1.1,0,2,0.7,2.4,1.7h0.4l1.4-0.7v-2.4l-0.9-0.9
	c-0.5-0.5-0.7-1.1-0.7-1.8v-1c-0.6-1-1.5-1.7-2.6-2.3l-1.7-1.7c-1-1-1-2.6,0-3.5c0.3-0.3,0.6-0.5,0.9-0.6v-4.3c0-1.1,0.7-2,1.6-2.4
	v-1.6l-0.9-0.9c-1-1-1-2.6,0-3.5c0.5-0.5,1.1-0.7,1.8-0.7h5c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h2.3c1.1,0,2,0.7,2.4,1.7h2.6
	c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h11.2l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7h2.3l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7h1.7
	c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9c0.1-0.3,0.3-0.6,0.6-0.9l3.3-3.3c0.3-0.3,0.6-0.5,1-0.6c0.2-0.8,0.8-1.4,1.6-1.6v-22.5
	c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9V171c0-0.3,0-0.5,0.1-0.8l8.3-26.6c0.4-1.3,1.8-2.1,3.1-1.7c1.1,0.3,1.8,1.3,1.8,2.4v0.6l0.2,0.2h0.6
	c1.1,0,2.1,0.7,2.4,1.8c0.4,0.1,0.7,0.3,1,0.6l0.9,0.9h2.3c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h0.7c1.4,0,2.5,1.1,2.5,2.5v2.3l0.9,0.9
	c0.5,0.5,0.7,1.1,0.7,1.8v2.5h10.1c0.4-1,1.3-1.7,2.4-1.7h0.8v-19.7l-0.2-0.2h-0.6c-1.4,0-2.5-1.1-2.5-2.5c0-0.7,0.3-1.3,0.7-1.8
	l0.9-0.9v-0.6c0-0.7,0.3-1.3,0.7-1.8l2.6-2.6v-0.6c0-1.1,0.7-2.1,1.8-2.4c-0.1-0.3-0.2-0.6-0.2-0.9v-2.6c-1-0.4-1.7-1.3-1.7-2.4
	v-3.3c0-0.7,0.3-1.3,0.7-1.8l6.6-6.7c0.3-0.3,0.6-0.5,1-0.6c0.3-1.1,1.3-1.8,2.4-1.8h0.6l0.9-0.9c0.3-0.3,0.6-0.5,1-0.6
	c0.3-1,1.3-1.8,2.4-1.8h1c0.2-0.6,0.7-1.1,1.2-1.4l3.4-1.7c1.2-0.6,2.7-0.1,3.3,1.1c0.2,0.3,0.3,0.7,0.3,1.1v0.6l0.9,0.9
	c0.3,0.3,0.5,0.6,0.6,0.9h4.3c1.4,0,2.5,1.1,2.5,2.5v5.6l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v5c0,0.3,0,0.6-0.1,0.8v0.1h0.1
	c0.3-0.1,0.5-0.1,0.8-0.1h0.6l0.2-0.2v-0.6c0-1.1,0.7-2.1,1.8-2.4c0.2-0.8,0.9-1.4,1.6-1.7c0.3-1.1,1.3-1.8,2.4-1.8h8.3
	c0.3,0,0.5,0,0.8,0.1l5,1.7c1,0.3,1.7,1.3,1.7,2.4v6.6c0,1.1-0.7,2-1.6,2.3v1c0,1.1-0.7,2-1.7,2.4v1.6l0.9,0.9
	c0.5,0.5,0.7,1.1,0.7,1.8v5c0,1-0.6,2-1.6,2.3l4,4l4.8,3.2c0.1,0.1,0.3,0.2,0.4,0.3l0.9,0.9c0.3-0.7,0.9-1.3,1.6-1.5
	c0.2-0.8,0.9-1.4,1.6-1.7c0.3-1.1,1.3-1.8,2.4-1.8h11.6c1.1,0,2,0.7,2.4,1.7h1c1.1,0,2,0.7,2.4,1.7h0.3c0.4-1,1.3-1.7,2.4-1.7h1.7
	c1.1,0,2.1,0.7,2.4,1.8c1,0.3,1.8,1.3,1.8,2.4v2.3l0.9,0.9c0.3,0.3,0.5,0.6,0.6,1c0.8,0.2,1.4,0.9,1.7,1.7c1,0.3,1.8,1.3,1.8,2.4v1
	c1,0.4,1.7,1.3,1.7,2.4v5c0,0.6-0.2,1.2-0.6,1.7c0.2,0.2,0.4,0.5,0.5,0.8h2.6c0.3,0,0.6,0,0.8,0.1c0.3-0.1,0.5-0.1,0.8-0.1h1
	c0.4-1,1.3-1.7,2.4-1.7h1.7c0.3,0,0.7,0.1,1,0.2l11.4,5.1v-1.1c0-1.1,0.7-2.1,1.8-2.4c0.1-0.4,0.3-0.7,0.6-1l1.6-1.7
	c0.7-0.7,1.8-0.9,2.7-0.6c0.6,1,1.5,1.7,2.5,2.2l0.9,0.9h0.6c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h2.3c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h6.6
	c0.4-1,1.3-1.6,2.3-1.6h1.7c1.4,0,2.5,1.1,2.5,2.5v1.6c0,1-0.6,2-1.6,2.3l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v1
	c0.4,0.1,0.7,0.3,0.9,0.6l1.7,1.7c0.5,0.5,0.7,1.1,0.7,1.8v0l1.9,1c0.9,0.4,1.4,1.3,1.4,2.2v0.6l2.6,2.6c0.3,0.3,0.5,0.6,0.6,1
	c1.1,0.3,1.8,1.3,1.8,2.4v0.6l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v0.6l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v0.6l0.9,0.9
	c0.5,0.5,0.7,1.1,0.7,1.8v8.3c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v0.6c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v2.3c0,0.7-0.3,1.3-0.7,1.8
	l-2.6,2.6v2.3c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v0.6c0,1.1-0.7,2.1-1.8,2.4c-0.1,0.4-0.3,0.8-0.6,1.1l-0.9,0.9v2.3
	c0,1.4-1.1,2.5-2.5,2.5H534l-0.2,0.2v0.6c0,1.1-0.7,2.1-1.8,2.4c-1.2,1.3-2.5,2.5-4,3.4h-1c-0.3,0.7-0.9,1.3-1.6,1.5
	c-0.2,0.8-0.9,1.4-1.7,1.7c-0.2,0.8-0.8,1.4-1.5,1.6v3.2l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v0.6l4.2,4.3c0.3,0.3,0.5,0.6,0.6,1
	c1.1,0.3,1.8,1.3,1.8,2.4v5c-0.9,1.8-2.4,3.2-4.2,4.2H518c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9h-0.6c-0.6,0-1.3-0.2-1.7-0.7l-5-5
	c-0.5-0.5-0.7-1.1-0.7-1.8v-0.6l-0.2-0.2h-0.6c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9h-4.9c-0.3,0.7-0.9,1.3-1.6,1.5
	c-1.2,1.3-2.5,2.5-4,3.4h-0.6l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-17.6c-0.4,1-1.3,1.7-2.4,1.7h-2.3l-0.9,0.9
	c-0.5,0.5-1.1,0.7-1.8,0.7h-0.6l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7H461l-2.6,2.6c-0.3,0.3-0.6,0.5-1,0.6c-0.1,0.4-0.3,0.8-0.6,1
	l-0.9,0.9v8.2c1,1.1,2.1,2.2,3.2,3.3c0.4,0.1,0.8,0.3,1,0.6l0.9,0.9h0.6c1.4,0,2.5,1.1,2.5,2.5v8.3c-0.9,1.8-2.4,3.2-4.2,4.2h-0.6
	l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7H454c-1.1,1-2.2,2.1-3.3,3.2c-0.1,0.4-0.3,0.7-0.6,1l-0.9,0.9v3.2c1,0.4,1.7,1.3,1.7,2.4v4.3
	c1.3,0.5,2,1.9,1.5,3.2c-0.1,0.3-0.3,0.7-0.6,0.9l-0.9,0.9v5.6c0,0.7-0.3,1.3-0.7,1.8l-3,3l-1.5,3c-0.3,0.6-0.8,1.1-1.5,1.3
	c-0.1,0.4-0.3,0.7-0.6,1l-0.9,0.9v0.6C442.6,350,441.5,351.1,440,351.1C440.1,351.1,440,351.1,440,351.1z M437.4,344.6
	c0.3,0.1,0.7,0.3,0.9,0.5l0.8-0.8v-0.6c0-1.4,1.1-2.5,2.5-2.5c0,0,0,0,0,0h0.1l1-2c0.1-0.2,0.3-0.5,0.5-0.6l2.6-2.6v-5.6
	c0.2-0.5,0.2-1.1,0-1.7v-4.3c-1-0.3-1.7-1.3-1.7-2.4v-6.6c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-0.6c0.9-1.5,2.1-2.8,3.4-4
	c0.3-1.1,1.3-1.8,2.4-1.8h4l0.9-0.9c0.3-0.3,0.6-0.5,1-0.6c0.2-0.8,0.8-1.4,1.5-1.6v-3.6c-0.4-0.1-0.7-0.3-0.9-0.6l-0.9-0.9h-0.6
	c-1.1,0-2.1-0.7-2.4-1.8c-0.8-0.2-1.4-0.9-1.7-1.7c-1-0.3-1.8-1.3-1.8-2.4v-11.6c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-0.6
	c0-1.4,1.1-2.5,2.5-2.5h0.6l2.6-2.6c0.5-0.5,1.1-0.7,1.8-0.7h0.6l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7h0.6l0.9-0.9
	c0.5-0.5,1.1-0.7,1.8-0.7h1c0.4-1,1.3-1.7,2.4-1.7h18.9l0.9-0.9c0.3-0.3,0.6-0.5,1-0.6c1.1-1.1,2.2-2.2,3.3-3.3
	c0.3-1.1,1.3-1.8,2.4-1.8h8.3c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h0.6c0.7,0,1.3,0.3,1.8,0.7l1.7,1.7c0.5,0.5,0.7,1.1,0.7,1.8v0.6
	l3.5,3.5h0.6c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h4.9c0.3-0.7,0.8-1.3,1.5-1.5v-0.3c-1-0.4-1.7-1.3-1.7-2.4v-0.6l-4.3-4.3
	c-0.5-0.5-0.7-1.1-0.7-1.8V260l-0.9-0.9c-0.5-0.5-0.7-1.1-0.7-1.8v-6.6c0-1.1,0.7-2.1,1.8-2.4c0.2-0.8,0.9-1.4,1.7-1.7
	c1.2-1.3,2.5-2.5,4-3.4h1c0.3-0.7,0.9-1.3,1.6-1.6c0.2-0.8,0.9-1.4,1.7-1.7c0.1-0.4,0.3-0.7,0.6-1l1.7-1.7c0.3-0.3,0.6-0.5,0.9-0.6
	v-1c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-0.6c0-1.1,0.7-2.1,1.8-2.4c0.1-0.4,0.3-0.7,0.6-1l0.9-0.9v-2.3c0-0.7,0.3-1.3,0.7-1.8l2.6-2.6
	v-2.3c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-0.6c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-6.2l-0.9-0.9c-0.5-0.5-0.7-1.1-0.7-1.8v-0.6l-0.9-0.9
	c-0.5-0.5-0.7-1.1-0.7-1.8v-0.6l-0.9-0.9c-0.3-0.3-0.5-0.6-0.6-1c-1.1-0.3-1.8-1.3-1.8-2.4v-0.6l-2.6-2.6c-0.5-0.5-0.7-1.1-0.7-1.8
	v-0.1l-2-1c-0.8-0.4-1.4-1.3-1.4-2.2V187l-0.2-0.2h-0.6c-1.4,0-2.5-1.1-2.5-2.5V182l-0.9-0.9c-0.5-0.5-0.7-1.1-0.7-1.8v-0.8H518
	c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9H513c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9h-0.6c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9
	c-0.3,0.7-0.8,1.2-1.5,1.5v2.6c0,1.4-1.1,2.5-2.5,2.5c-0.4,0-0.7-0.1-1-0.2l-13.5-6c-0.5,0.8-1.3,1.2-2.1,1.2h-2.3l-0.9,0.9
	c-0.7,0.7-1.8,0.9-2.7,0.6c-0.7-0.3-1.2-0.8-1.4-1.5h-2.6c-1.4,0-2.5-1.1-2.5-2.5V172l-0.9-0.9c-1-1-1-2.6,0-3.5l0.9-0.9V165
	c-1-0.4-1.7-1.3-1.7-2.4v-1c-0.7-0.3-1.3-0.9-1.5-1.6c-1.1-0.3-1.8-1.3-1.8-2.4V157l-0.9-0.9c-0.5-0.5-0.7-1.1-0.7-1.8v-1
	c-0.3,0.1-0.5,0.1-0.8,0.1h-5c-1.1,0-2-0.7-2.4-1.7h-1c-1.1,0-2-0.7-2.4-1.7H449c-0.3,0.7-0.9,1.3-1.6,1.6c-0.2,0.8-0.9,1.4-1.7,1.7
	c-0.3,1-1.3,1.8-2.4,1.8H440c-0.7,0-1.3-0.3-1.8-0.7l-1.5-1.4l-4.8-3.2c-0.1-0.1-0.3-0.2-0.4-0.3l-6.6-6.6c-1-1-1-2.6,0-3.5
	c0.3-0.3,0.6-0.5,1-0.6c0.2-0.8,0.8-1.4,1.6-1.6v-1.6l-0.9-0.9c-0.5-0.5-0.7-1.1-0.7-1.8v-5c0-1.1,0.7-2,1.7-2.4v-1
	c0-1.1,0.7-2,1.6-2.4v-2.5l-2.9-1h-5.5c-0.3,0.7-0.9,1.3-1.6,1.5c-0.2,0.8-0.9,1.4-1.7,1.7c-0.1,0.4-0.3,0.7-0.6,1l-1.7,1.7
	c-0.3,0.3-0.6,0.5-1,0.6c-0.4,1.3-1.8,2-3.2,1.6c-0.4-0.1-0.7-0.3-1-0.6l-0.9-0.9h-0.6c-1.1,0-2.1-0.7-2.4-1.8
	c-0.7-0.2-1.3-0.8-1.6-1.4c-0.4-0.9-0.2-2,0.5-2.7l0.9-0.9v-2.9l-0.9-0.9c-0.5-0.5-0.7-1.1-0.7-1.8v-4.1h-4.1
	c-1.4,0-2.5-1.1-2.5-2.5v-0.6l-0.1-0.2c-0.4,1-1.3,1.6-2.3,1.6h-1c-0.4,1-1.3,1.7-2.4,1.7h-0.6l-0.9,0.9c-0.3,0.3-0.7,0.5-1,0.6
	c-0.3,1-1.3,1.8-2.4,1.8h-0.6l-5.1,5.1c1,0.4,1.6,1.3,1.6,2.3v2.6c1,0.4,1.7,1.3,1.7,2.4v3.3c0,1.4-1.1,2.5-2.5,2.5h-1
	c-0.1,0.3-0.3,0.7-0.6,0.9l-2.6,2.6v0.6c0,0.2,0,0.4-0.1,0.6l0.3,0.3h0.6c1.4,0,2.5,1.1,2.5,2.5c0,1.1-0.7,2-1.7,2.4V161
	c0,1.4-1.1,2.5-2.5,2.5h-1c-0.4,1-1.3,1.7-2.4,1.7h-15c-1.4,0-2.5-1.1-2.5-2.5v-3.9l-0.9-0.9c-0.5-0.5-0.7-1.1-0.7-1.8v-1
	c-0.4-0.1-0.7-0.3-0.9-0.6l-0.9-0.9h-2.3c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9H347c-1.1,0-2.1-0.7-2.4-1.8h-0.1l-6.6,21.3v7.9
	c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v23.9c-1,1.8-2.4,3.2-4.2,4.2h-0.6l-1.9,1.9v0.6c0,1.4-1.1,2.5-2.5,2.5h-3.3c-0.7,0-1.3-0.3-1.8-0.7
	l-0.7-0.7l-0.7,0.7c-0.5,0.5-1.1,0.7-1.8,0.7h-2.3l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-13.2c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9h-4
	c-1.1,0-2-0.7-2.4-1.7h-1c-0.7,0-1.3-0.3-1.8-0.7l-0.7-0.7v3.9c0,1.1-0.7,2-1.6,2.4v3.6c1.3,1.2,2.4,2.5,3.3,4v2.3l0.9,0.9
	c0.5,0.5,0.7,1.1,0.7,1.8v5c0,1-0.5,1.9-1.3,2.3l-3.3,1.7c-0.3,0.2-0.7,0.3-1.1,0.3h-3.3c-0.3,0-0.6,0-0.8-0.1l0,0l-3.3,1.1
	c-0.1,1.3-1.2,2.4-2.5,2.4H274c-0.5,0-1.1-0.2-1.5-0.5l-1,4.9v4.1l1.4,2.8c0.2,0.3,0.3,0.7,0.3,1.1v3.3c-0.9,1.8-2.4,3.2-4.2,4.2
	H265l-2.6,2.6c-0.5,0.5-1.1,0.7-1.8,0.7h-0.6l-0.9,0.9c-1.1,0.5-2,1.3-2.7,2.3c-0.1,0.4-0.3,0.7-0.6,1l-1.4,1.4l-1.2,2.4v1.1
	c0,1.1-0.7,2.1-1.8,2.4c-0.1,0.3-0.3,0.7-0.5,0.9c0.4,0.5,0.6,1,0.6,1.7v2.6c0.7,0.3,1.3,0.9,1.6,1.6c1,0.3,1.8,1.3,1.8,2.4v0.8h0.8
	c1.1,0,2.1,0.7,2.4,1.8c0.8,0.2,1.3,0.8,1.6,1.6h2.6c1.4,0,2.5,1.1,2.5,2.5v0.6l0.9,0.9c0.2,0.2,0.3,0.4,0.5,0.6l1,1.9h1.8
	c1.4,0,2.5,1.1,2.5,2.5v2.3l1.9,1.9h0.6c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9c1.1-1,2.2-2.1,3.3-3.2c0.3-1.1,1.3-1.8,2.4-1.8h1
	c0.4-1,1.3-1.6,2.4-1.6h8.3c0.9,0.2,1.7,0.2,2.6,0.2c0.9,0.4,1.5,1.3,1.5,2.3v2.3l0.9,0.9c0.3,0.3,0.5,0.7,0.6,1
	c1.1,0.3,1.8,1.3,1.8,2.4v0.8h9.1c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h3.3c0.4-1,1.3-1.7,2.4-1.7h2.6c0.4-1,1.3-1.7,2.4-1.7h3.3
	c0.3,0,0.5,0,0.8,0.1l4.6,1.5h2.9c1.1,0,2,0.7,2.4,1.7h1c1.4,0,2.5,1.1,2.5,2.5v1.7c0,0.7-0.3,1.3-0.7,1.8l-3.3,3.3
	c-0.3,0.3-0.6,0.5-1,0.6c-1,0.7-1.8,1.6-2.3,2.7l-0.9,0.9v3.1h0.8c0.7,0,1.3,0.3,1.8,0.7l0.7,0.7l0.4-0.4l1.5-3
	c0.3-0.6,0.8-1.1,1.4-1.3c0.6-0.2,1.3-0.2,1.9,0.1l3.3,1.7c0.2,0.1,0.5,0.3,0.7,0.5l1.4,1.4l2.4,1.2h1.6l0.6-0.6l1.5-3
	c0.1-0.2,0.3-0.5,0.5-0.6l3.3-3.3c0.5-0.5,1.1-0.7,1.8-0.7l0,0c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h2.3c0.7,0,1.3,0.3,1.8,0.7l5.4,5.4
	l1.3-0.7l1.4-1.4c0.2-0.2,0.4-0.3,0.6-0.5l3.3-1.7c0.3-0.2,0.7-0.3,1.1-0.3h5.6l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7l0,0
	c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h7.3c0.6,0,1.2,0.2,1.6,0.6c0.5-0.4,1-0.6,1.7-0.6h4l0.9-0.9c0.7-0.7,1.8-0.9,2.7-0.5
	c0.5,0.6,1.5,0.7,2.1,0.1c0.1-0.1,0.2-0.2,0.3-0.3c1.4,0,2.5,1.1,2.5,2.5v0.7l0.1,0.1c0.4-1,1.3-1.6,2.3-1.6h1.7
	c1.1,0,2,0.7,2.4,1.7h2.6c0.6,0,1.2,0.2,1.7,0.6c0.5-0.4,1-0.6,1.7-0.6h3.3c1.4,0,2.5,1.1,2.5,2.5v5c0,0.3,0,0.6-0.1,0.8h1
	c2.4,1.5,4.4,3.4,5.8,5.8v3.3C437.6,343.9,437.5,344.2,437.4,344.6L437.4,344.6z" />
		<Tippy
			followCursor={true}
			plugins={[followCursor]}
			className={classes.tippy}
			hideOnClick={false}
			content={<span className={classes.tippyContent}>Gandhinagar South</span>}
		>
			<polygon
				// class="st0" 
				onClick={(e) => props.clicked(e.target)}
				onMouseEnter={(e) => props.entered(e.target)}
				onMouseLeave={(e) => props.left(e.target)}
				id="Gandhinagar-South"
				fill="#FCFCFC"
				points="453.3,285.5 455,285.5 456.6,285.5 458.3,285.5 459.9,285.5 461.6,285.5 463.3,285.5 464.9,283.9 
	466.6,283.9 466.6,282.2 468.2,282.2 469.9,282.2 471.6,283.9 473.2,283.9 474.9,285.5 476.5,287.2 478.2,287.2 478.2,288.8 
	479.9,288.8 479.9,290.5 479.9,292.2 478.2,292.2 478.2,293.8 478.2,295.5 476.5,295.5 476.5,297.1 474.9,298.8 473.2,300.5 
	473.2,302.1 476.5,302.1 478.2,303.8 481.5,305.4 484.8,307.1 486.5,308.8 488.2,308.8 489.8,308.8 489.8,310.4 488.2,312.1 
	488.2,313.8 486.5,313.8 486.5,315.4 486.5,317.1 484.8,318.7 486.5,318.7 488.2,320.4 488.2,322 489.8,322 491.5,320.4 
	493.1,320.4 493.1,322 494.8,322 496.5,322 498.1,322 498.1,320.4 501.4,320.4 501.4,322 503.1,322 506.4,323.7 508.1,323.7 
	509.8,323.7 516.4,320.4 518,320.4 518,323.7 519.7,323.7 521.4,323.7 521.4,322 528,323.7 528,325.4 526.3,328.7 526.3,330.4 
	526.3,333.7 524.7,338.6 524.7,342 524.7,343.6 524.7,345.3 523,345.3 521.4,347 519.7,347 518,347 518,351.9 519.7,351.9 
	519.7,353.6 519.7,355.3 519.7,356.9 519.7,358.6 519.7,360.2 521.4,360.2 523,360.2 524.7,360.2 528,360.2 529.7,360.2 528,361.9 
	529.7,361.9 529.7,363.6 531.3,365.2 529.7,365.2 528,365.2 526.3,366.9 524.7,366.9 523,366.9 523,368.5 521.4,366.9 521.4,368.5 
	521.4,370.2 521.4,371.9 521.4,375.2 523,375.2 523,376.8 521.4,378.5 521.4,380.2 521.4,381.8 521.4,383.5 521.4,385.1 
	521.4,386.8 521.4,388.5 521.4,390.1 521.4,391.8 521.4,393.4 521.4,395.1 519.7,396.8 518,396.8 516.4,396.8 514.7,398.4 
	513.1,398.4 511.4,398.4 509.8,398.4 508.1,398.4 508.1,400.1 509.8,400.1 509.8,401.7 511.4,403.4 509.8,405.1 509.8,406.7 
	508.1,406.7 508.1,408.4 506.4,406.7 506.4,408.4 504.8,408.4 504.8,410 504.8,411.7 503.1,411.7 501.4,411.7 499.8,411.7 
	498.1,413.4 498.1,415 498.1,416.7 498.1,418.3 496.5,420 496.5,421.7 496.5,423.3 496.5,425 496.5,426.6 494.8,426.6 494.8,428.3 
	498.1,428.3 498.1,430 499.8,431.6 501.4,431.6 503.1,434.9 504.8,436.6 504.8,438.3 504.8,439.9 503.1,441.6 503.1,443.3 
	503.1,444.9 504.8,444.9 504.8,446.6 504.8,448.2 504.8,449.9 503.1,449.9 503.1,451.5 503.1,453.2 503.1,454.9 503.1,456.5 
	501.4,456.5 501.4,458.2 501.4,459.9 499.8,459.9 499.8,461.5 499.8,463.2 499.8,466.5 499.8,468.2 501.4,469.8 501.4,468.2 
	503.1,468.2 504.8,466.5 506.4,466.5 508.1,466.5 511.4,468.2 513.1,469.8 513.1,468.2 513.1,469.8 513.1,468.2 514.7,468.2 
	514.7,469.8 516.4,471.5 516.4,473.1 514.7,473.1 513.1,474.8 511.4,474.8 509.8,476.5 509.8,478.1 508.1,479.8 508.1,481.4 
	508.1,483.1 508.1,486.4 508.1,488.1 506.4,488.1 506.4,489.7 504.8,491.4 503.1,493 503.1,494.7 501.4,494.7 501.4,496.4 
	499.8,498 498.1,498 498.1,499.7 499.8,499.7 499.8,501.4 499.8,503 499.8,504.7 499.8,506.3 501.4,506.3 501.4,508 501.4,509.7 
	501.4,511.3 501.4,513 501.4,514.6 501.4,516.3 501.4,518 499.8,518 498.1,518 496.5,518 496.5,519.6 496.5,521.3 496.5,522.9 
	494.8,524.6 494.8,526.3 494.8,527.9 494.8,529.6 496.5,531.2 496.5,532.9 498.1,532.9 499.8,534.6 501.4,534.6 501.4,536.2 
	498.1,537.9 498.1,539.5 499.8,541.2 498.1,541.2 498.1,542.9 496.5,542.9 496.5,544.5 494.8,544.5 493.1,546.2 491.5,547.8 
	491.5,549.5 491.5,551.2 491.5,549.5 489.8,549.5 488.2,549.5 486.5,549.5 484.8,549.5 484.8,551.2 483.2,551.2 481.5,552.8 
	481.5,554.5 479.9,554.5 479.9,556.1 478.2,556.1 478.2,557.8 476.5,557.8 476.5,559.5 474.9,559.5 474.9,561.1 473.2,561.1 
	471.6,562.8 469.9,562.8 469.9,564.5 468.2,564.5 466.6,566.1 464.9,566.1 463.3,564.5 461.6,564.5 459.9,564.5 458.3,564.5 
	456.6,562.8 456.6,561.1 455,561.1 455,562.8 453.3,562.8 451.6,562.8 450,561.1 448.3,561.1 448.3,562.8 448.3,566.1 448.3,567.8 
	450,567.8 450,569.4 450,571.1 450,572.8 453.3,576.1 453.3,577.7 451.6,577.7 450,577.7 448.3,577.7 448.3,576.1 448.3,574.4 
	446.6,574.4 445,577.7 443.3,577.7 443.3,576.1 441.7,576.1 440,576.1 438.4,577.7 436.7,577.7 435,577.7 431.7,577.7 430.1,577.7 
	428.4,579.4 426.7,579.4 426.7,577.7 426.7,576.1 426.7,574.4 426.7,571.1 425.1,569.4 425.1,567.8 425.1,566.1 423.4,566.1 
	423.4,564.5 423.4,562.8 421.8,562.8 420.1,562.8 418.4,564.5 418.4,562.8 416.8,562.8 415.1,562.8 413.5,562.8 413.5,561.1 
	411.8,561.1 410.1,562.8 410.1,564.5 408.5,566.1 406.8,567.8 405.1,567.8 405.1,569.4 403.5,569.4 401.8,569.4 400.2,571.1 
	398.5,571.1 396.9,571.1 395.2,569.4 395.2,567.8 395.2,566.1 393.5,566.1 391.9,566.1 390.2,564.5 388.5,564.5 388.5,566.1 
	386.9,567.8 385.2,569.4 385.2,571.1 383.6,574.4 381.9,576.1 378.6,576.1 376.9,576.1 376.9,574.4 375.3,574.4 373.6,574.4 
	373.6,576.1 371.9,576.1 370.3,576.1 368.6,574.4 368.6,572.8 367,571.1 367,569.4 365.3,569.4 363.6,569.4 362,571.1 362,572.8 
	360.3,572.8 360.3,574.4 358.6,574.4 357,576.1 348.7,577.7 345.4,577.7 343.7,577.7 342.1,577.7 340.4,577.7 337.1,576.1 
	335.4,576.1 333.8,576.1 328.8,576.1 328.8,577.7 327.1,577.7 328.8,579.4 327.1,579.4 327.1,581 325.5,581 323.8,581 322.1,579.4 
	320.5,579.4 318.8,579.4 318.8,577.7 315.5,576.1 315.5,574.4 315.5,572.8 317.1,572.8 317.1,571.1 312.2,569.4 302.2,564.5 
	300.5,562.8 298.9,562.8 298.9,561.1 298.9,559.5 297.2,557.8 295.6,557.8 295.6,556.1 293.9,554.5 292.3,554.5 290.6,552.8 
	290.6,554.5 288.9,554.5 287.3,554.5 287.3,557.8 285.6,559.5 284,557.8 284,556.1 284,554.5 284,551.2 284,549.5 285.6,549.5 
	287.3,547.8 288.9,546.2 290.6,544.5 290.6,542.9 290.6,541.2 292.3,539.5 292.3,537.9 290.6,537.9 290.6,536.2 292.3,536.2 
	292.3,534.6 290.6,532.9 290.6,531.2 290.6,529.6 288.9,529.6 288.9,527.9 287.3,527.9 285.6,526.3 284,526.3 282.3,527.9 
	280.6,529.6 279,529.6 277.3,529.6 274,531.2 272.3,531.2 269,531.2 265.7,531.2 267.3,529.6 267.3,527.9 265.7,527.9 265.7,526.3 
	267.3,526.3 267.3,524.6 267.3,521.3 269,519.6 270.7,518 269,516.3 269,514.6 269,513 270.7,513 270.7,511.3 272.3,509.7 
	272.3,508 272.3,506.3 272.3,504.7 274,506.3 274,504.7 275.6,504.7 275.6,503 275.6,501.4 275.6,499.7 274,498 272.3,498 
	270.7,498 269,498 267.3,498 265.7,498 265.7,496.4 262.4,496.4 259,496.4 257.4,493 257.4,489.7 257.4,486.4 259,486.4 259,484.8 
	259,483.1 260.7,481.4 262.4,481.4 262.4,478.1 262.4,476.5 262.4,473.1 264,473.1 265.7,469.8 265.7,468.2 267.3,468.2 
	267.3,466.5 265.7,464.8 265.7,463.2 264,461.5 262.4,461.5 264,459.9 262.4,458.2 262.4,456.5 264,456.5 265.7,454.9 265.7,453.2 
	264,451.5 265.7,449.9 269,449.9 269,446.6 270.7,446.6 272.3,444.9 272.3,443.3 274,443.3 275.6,441.6 275.6,439.9 274,438.3 
	272.3,438.3 270.7,436.6 269,436.6 267.3,433.3 265.7,433.3 264,431.6 264,430 262.4,430 262.4,428.3 260.7,426.6 260.7,425 
	259,425 259,421.7 257.4,420 257.4,415 262.4,413.4 262.4,411.7 262.4,410 262.4,408.4 262.4,406.7 264,406.7 264,405.1 264,403.4 
	265.7,403.4 267.3,403.4 269,403.4 274,406.7 275.6,406.7 277.3,406.7 279,406.7 279,405.1 280.6,408.4 282.3,406.7 284,406.7 
	284,408.4 285.6,408.4 287.3,408.4 287.3,410 288.9,411.7 290.6,413.4 290.6,415 290.6,416.7 292.3,416.7 293.9,416.7 295.6,416.7 
	297.2,416.7 297.2,418.3 298.9,418.3 298.9,416.7 300.5,416.7 300.5,418.3 302.2,420 300.5,420 300.5,421.7 300.5,423.3 
	302.2,423.3 303.9,425 305.5,426.6 307.2,426.6 307.2,428.3 308.9,428.3 308.9,430 308.9,431.6 310.5,431.6 312.2,431.6 
	313.8,431.6 315.5,431.6 317.1,431.6 317.1,430 318.8,430 320.5,430 320.5,431.6 322.1,431.6 323.8,431.6 325.5,431.6 325.5,433.3 
	327.1,434.9 327.1,436.6 328.8,438.3 328.8,439.9 328.8,441.6 328.8,443.3 330.4,443.3 330.4,444.9 330.4,446.6 330.4,448.2 
	332.1,449.9 332.1,451.5 330.4,451.5 332.1,453.2 332.1,454.9 332.1,456.5 333.8,456.5 333.8,458.2 335.4,459.9 335.4,461.5 
	335.4,463.2 337.1,463.2 338.7,464.8 338.7,466.5 340.4,466.5 340.4,468.2 342.1,468.2 343.7,468.2 345.4,468.2 345.4,474.8 
	343.7,484.8 355.3,486.4 357,486.4 360.3,486.4 376.9,486.4 390.2,488.1 391.9,483.1 403.5,468.2 408.5,458.2 410.1,449.9 
	410.1,441.6 411.8,413.4 401.8,411.7 403.5,411.7 403.5,410 405.1,408.4 405.1,406.7 406.8,406.7 408.5,405.1 410.1,405.1 
	411.8,403.4 413.5,403.4 415.1,403.4 415.1,401.7 416.8,401.7 416.8,400.1 418.4,400.1 418.4,398.4 418.4,396.8 418.4,395.1 
	418.4,393.4 418.4,386.8 418.4,383.5 418.4,381.8 418.4,380.2 418.4,378.5 418.4,376.8 418.4,375.2 418.4,373.5 420.1,371.9 
	420.1,370.2 420.1,368.5 420.1,366.9 421.8,366.9 421.8,365.2 421.8,363.6 423.4,363.6 423.4,361.9 423.4,360.2 425.1,360.2 
	425.1,358.6 426.7,356.9 426.7,355.3 428.4,355.3 428.4,353.6 428.4,351.9 430.1,351.9 430.1,350.3 431.7,348.6 433.4,345.3 
	435,345.3 435,347 433.4,347 435,347 436.7,347 436.7,348.6 438.4,348.6 440,348.6 440,347 441.7,345.3 441.7,343.6 443.3,343.6 
	445,340.3 446.6,338.6 448.3,337 448.3,335.3 448.3,333.7 448.3,330.4 450,328.7 448.3,328.7 448.3,327 448.3,325.4 448.3,323.7 
	448.3,322 446.6,322 446.6,320.4 446.6,318.7 446.6,317.1 446.6,315.4 448.3,313.8 448.3,312.1 450,312.1 450,310.4 451.6,310.4 
	451.6,308.8 453.3,308.8 455,308.8 456.6,308.8 458.3,307.1 459.9,307.1 459.9,305.4 461.6,305.4 461.6,303.8 461.6,302.1 
	461.6,300.5 461.6,298.8 461.6,297.1 459.9,297.1 458.3,295.5 456.6,295.5 456.6,293.8 455,293.8 455,292.2 453.3,292.2 
	453.3,290.5 453.3,288.8 453.3,287.2 "
			/>
		</Tippy>
		<path class="st1" d="M479,560.2c-0.3,0.8-0.9,1.4-1.7,1.7c-0.3,1.1-1.3,1.8-2.4,1.8h-0.6l-0.9,0.9c-0.3,0.3-0.7,0.5-1,0.6
	c-0.3,1.1-1.3,1.8-2.4,1.8h-0.6l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-1.7c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9h-3.9
	c-0.7,0-1.3-0.3-1.8-0.7l-1-1c-0.2,0-0.4,0.1-0.6,0.1h-3.3c-0.3,0-0.5,0-0.8-0.1v0.3c1,0.4,1.7,1.3,1.7,2.4v4l2.6,2.6
	c0.5,0.5,0.7,1.1,0.7,1.8v1.7c0,1.4-1.1,2.5-2.5,2.5h-5c-0.6,0-1.2-0.2-1.7-0.6c-0.5,0.4-1,0.6-1.7,0.6h-1.7c-1,0-2-0.6-2.3-1.6
	l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-7.3l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-1.6c-1.4,0-2.5-1.1-2.5-2.5v-7.3l-0.9-0.9
	c-0.5-0.5-0.7-1.1-0.7-1.8v-1c-1-0.4-1.7-1.3-1.7-2.3v-0.6l-0.7,0.7c-0.7,0.7-1.8,0.9-2.7,0.5c-0.6-0.3-1.2-0.8-1.4-1.5h-2.6
	c-0.3,0-0.6-0.1-0.9-0.2c-0.1,0.4-0.3,0.8-0.6,1.1l-3.3,3.3c-0.3,0.3-0.7,0.5-1,0.6c-0.3,1.1-1.3,1.8-2.4,1.8h-2.3l-0.9,0.9
	c-0.5,0.5-1.1,0.7-1.8,0.7h-3.3c-0.7,0-1.3-0.3-1.8-0.7l-1.7-1.7c-0.5-0.5-0.7-1.1-0.7-1.8v-0.8h-0.8c-0.6,0-1.2-0.2-1.6-0.6
	l-2.5,2.5v0.6c0,0.4-0.1,0.8-0.3,1.1l-1.7,3.3c-0.1,0.2-0.3,0.5-0.5,0.7l-1.7,1.7c-0.5,0.5-1.1,0.7-1.8,0.7h-5
	c-0.9-0.8-2.4-0.8-3.3,0h-3.3c-0.7,0-1.3-0.3-1.8-0.7l-1.7-1.7c-0.5-0.5-0.7-1.1-0.7-1.8v-0.6l-0.9-0.9c-0.2-0.2-0.4-0.5-0.6-0.9
	l-0.1,0.1v0.6c-0.9,1.8-2.4,3.2-4.2,4.2h-0.6l-0.9,0.9c-0.3,0.4-0.8,0.6-1.3,0.7l-8.3,1.7c-0.2,0-0.3,0-0.5,0h-8.3
	c-0.4,0-0.8-0.1-1.1-0.3l-2.8-1.4h-5.4c-0.5,2.2-2,4.1-4,5l0,0h-3.3c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9h-2.3c-1.4,0-2.5-1.1-2.5-2.5
	v-0.1l-1.9-1c-0.8-0.4-1.4-1.3-1.4-2.2v-3.3c0-0.1,0-0.3,0-0.4l-1.6-0.5c-0.1,0-0.2-0.1-0.3-0.1l-10-5c-0.2-0.1-0.5-0.3-0.6-0.5
	l-0.9-0.9h-0.6c-1.4,0-2.5-1.1-2.5-2.5c0,0,0,0,0,0v-2.2l-0.2-0.2h-0.6c-1.4,0-2.5-1.1-2.5-2.5c0,0,0,0,0,0v-0.6l-0.2-0.2h-0.6
	c-0.5-0.2-1.1-0.2-1.7,0h-0.8v0.8c0,0.7-0.3,1.3-0.7,1.8l-1.7,1.7c-1,1-2.6,1-3.5,0l-1.7-1.7c-0.5-0.5-0.7-1.1-0.7-1.8v-8.3
	c0-1.4,1.1-2.5,2.5-2.5c0,0,0.1,0,0.1,0h0.6l3.5-3.5v-2.3c0-0.6,0.2-1.2,0.6-1.7c-0.4-0.5-0.6-1-0.6-1.7v-1.7c0.8-1,0.8-2.4,0-3.3
	v-1c-0.7-0.3-1.3-0.9-1.6-1.6c-0.4-0.1-0.7-0.3-1-0.6l-0.7-0.7l-2.4,2.4c-0.5,0.5-1.1,0.7-1.8,0.7H278l-2.8,1.4
	c-0.3,0.2-0.7,0.3-1.1,0.3h-8.3c-0.9-1.9-1.8-3.8-2.5-5.8v-1.7c0-1.1,0.7-2,1.7-2.4v-2.6c0-0.7,0.3-1.3,0.7-1.8l1.6-1.5
	c-0.4-0.5-0.6-1-0.6-1.7V513c0-1.1,0.7-2.1,1.8-2.4c0.1-0.4,0.3-0.7,0.6-1l0.9-0.9v-3.9c0-1.4,1.1-2.5,2.5-2.5c0.3,0,0.6,0,0.8,0.1
	v-1.6l-0.2-0.2h-7.3c-1.1,0-2-0.7-2.4-1.7h-4.3c-0.9,0-1.8-0.5-2.2-1.4l-1.7-3.3c-0.2-0.3-0.3-0.7-0.3-1.1v-6.6c0-1.1,0.7-2,1.7-2.4
	v-1c0-0.7,0.3-1.3,0.7-1.8l1.6-1.7c0.3-0.3,0.6-0.5,0.9-0.6v-6c0-1.4,1.1-2.5,2.5-2.5h0.1l0.7-1.4v-1.1c0.8-0.9,0.8-2.4,0-3.3v-0.6
	L263,464h-0.6c-1.4,0-2.5-1.1-2.5-2.5c0-0.6,0.2-1.2,0.6-1.7c-0.4-0.5-0.6-1-0.6-1.7v-1.7c0-1.4,1.1-2.5,2.5-2.5h0.6l-0.7-0.7
	c-1-1-1-2.6,0-3.5l1.7-1.7c0.5-0.5,1.1-0.7,1.8-0.7h0.8v-0.8c0-1.4,1.1-2.5,2.5-2.5h0.6l0.2-0.2v-0.6c0-1.3,1-2.4,2.3-2.5
	c-0.6-0.1-1.1-0.3-1.5-0.7l-0.9-0.9H269c-1,0-1.8-0.5-2.2-1.4l-1-1.9h-0.1c-0.7,0-1.3-0.3-1.8-0.7l-1.6-1.6
	c-0.7-1.2-1.5-2.4-2.4-3.4v-0.6l-0.9-0.9c-0.3-0.3-0.5-0.6-0.6-1c-1-0.3-1.7-1.3-1.8-2.4v-2.3l-0.9-0.9c-0.5-0.5-0.7-1.1-0.7-1.8v-5
	c0-1.1,0.7-2,1.7-2.4l3.3-1.1v-4.8c0-1.1,0.7-2,1.7-2.4v-1c0-1.4,1.1-2.5,2.5-2.5h5c0.5,0,1,0.1,1.4,0.4l4.4,2.9h1.9
	c0.5-1.3,1.9-2,3.2-1.5c0.6,0.2,1.1,0.7,1.4,1.2l0.2,0.4c0.3-0.1,0.6-0.2,0.9-0.2h1.7c1.1,0,2,0.7,2.4,1.7h1c1.4,0,2.5,1.1,2.5,2.5
	v0.7l2.6,2.6c0.5,0.5,0.7,1.1,0.7,1.8v0.8h4.1c0.5,0.2,1.1,0.2,1.7,0h1.7c1.4,0,2.5,1.1,2.5,2.5v0.6l0.9,0.9c0.9,0.9,1,2.4,0.1,3.4
	l2.5,2.5h0.6c1.8,0.9,3.2,2.4,4.1,4.2v0.8h3.4c0.3-1,1.3-1.7,2.4-1.7h3.3c1.1,0,2,0.7,2.4,1.7h2.6c1.4,0,2.5,1.1,2.5,2.5v0.6
	l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v0.6l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v2.6c1,0.4,1.6,1.3,1.6,2.4v4l0.9,0.9
	c0.5,0.5,0.7,1.1,0.7,1.8v1.7c-0.2,0.5-0.2,1.1,0,1.7v1c1,0.4,1.7,1.3,1.7,2.4v0.6l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v1
	c0.4,0.1,0.7,0.3,0.9,0.6l1.7,1.7c0.3,0.3,0.5,0.6,0.6,1c0.8,0.2,1.3,0.8,1.6,1.6h2.6c1.4,0,2.5,1.1,2.5,2.5c0,0,0,0,0,0v6.6
	c0,0.1,0,0.3,0,0.4l-1.2,7.4l8.9,1.3h21.4c0.1,0,0.2,0,0.3,0l11.3,1.4l1-3c0.1-0.3,0.2-0.5,0.4-0.8l11.5-14.7l4.7-9.5l1.5-7.7v-8.2
	l1.5-26l-7.8-1.3c-1.4-0.2-2.3-1.5-2.1-2.8c0.2-1,0.8-1.7,1.8-2c0.1-0.4,0.3-0.7,0.6-1l0.9-0.9v-0.6c0-1.4,1.1-2.5,2.5-2.5h0.6
	l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7h0.6l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7h1c0.3-0.7,0.9-1.3,1.6-1.5c0.2-0.8,0.8-1.4,1.5-1.6v-24.3
	c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-3.9c0-1.1,0.7-2,1.7-2.4v-1c0-1.1,0.7-2,1.7-2.4v-1c0-1.1,0.7-2.1,1.8-2.4c0.1-0.4,0.3-0.7,0.6-1
	l0.9-0.9v-0.6c0-1.1,0.7-2,1.6-2.4v-1c0.9-1.1,1.7-2.2,2.4-3.4l1.4-1.4l1.5-3c0.4-0.8,1.3-1.4,2.2-1.4h1.5c1.1,0,2.1,0.7,2.4,1.8
	c0.3,0.1,0.7,0.3,0.9,0.5l0.8-0.8v-0.6c0-1.4,1.1-2.5,2.5-2.5c0,0,0,0,0,0h0.1l1-2c0.1-0.2,0.3-0.5,0.5-0.6l2.6-2.6v-5.6
	c0.2-0.5,0.2-1.1,0-1.7v-4.2c-1-0.3-1.7-1.3-1.7-2.4v-6.6c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-0.6c0.9-1.5,2.1-2.8,3.4-4
	c0.3-1.1,1.3-1.8,2.4-1.8h4l0.9-0.9c0.3-0.3,0.6-0.5,1-0.6c0.2-0.8,0.8-1.4,1.5-1.6v-3.6c-0.4-0.1-0.7-0.3-0.9-0.6l-0.9-0.9h-0.6
	c-1.1,0-2.1-0.7-2.4-1.8c-0.8-0.2-1.4-0.9-1.7-1.7c-1-0.3-1.8-1.3-1.8-2.4v-6.6c0-1.4,1.1-2.5,2.5-2.5h8.9l0.9-0.9
	c0.3-0.3,0.6-0.5,1-0.6c0.3-1,1.3-1.8,2.4-1.8h3.3c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h0.6c0.7,0,1.3,0.3,1.8,0.7l2.6,2.6h0.6
	c1.8,0.9,3.2,2.4,4.2,4.2v3.3c0,1.1-0.7,2-1.7,2.4v1c-0.9,1.1-1.7,2.2-2.4,3.4l-0.9,0.9c0.3,0.1,0.6,0.3,0.9,0.6l1.4,1.4l6.3,3.1
	c0.2,0.1,0.5,0.3,0.6,0.5l0.9,0.9h2.3c1.4,0,2.5,1.1,2.5,2.5v1.7c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v0.6c0,1-0.6,1.9-1.6,2.3v1
	c0,0.2,0,0.4-0.1,0.6l0.9,0.9c0.5-0.4,1-0.6,1.7-0.6h1.7c1.1,0,2,0.7,2.4,1.6h0.3c0.4-1,1.3-1.6,2.4-1.6h3.3c1.1,0,2.1,0.7,2.4,1.8
	c0.1,0,0.3,0.1,0.4,0.2l2.8,1.4h2.1l6.1-3.1c0.3-0.2,0.7-0.3,1.1-0.3h1.7c1.1,0,2.1,0.7,2.4,1.8c0.5-0.2,1-0.2,1.5-0.1l6.6,1.7
	c1.1,0.3,1.9,1.3,1.9,2.4v1.7c0,0.4-0.1,0.8-0.3,1.1l-1.4,2.8v4.4c0,0.3,0,0.5-0.1,0.8l-1.5,4.6v6.2c0,1.4-1.1,2.5-2.5,2.5h-0.6
	l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-0.8v0.1c1,0.4,1.7,1.3,1.7,2.4v5.8h7.5c1.4,0,2.5,1.1,2.5,2.5c0,0.3,0,0.6-0.1,0.8
	c0.1,0.3,0.1,0.6,0.1,0.8v0.6l0.9,0.9c1,1,1,2.6,0,3.5c-0.5,0.5-1.1,0.7-1.8,0.7H529l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-1
	c-0.2,0.7-0.7,1.2-1.4,1.5h-0.1v1.9c1,0.4,1.7,1.3,1.7,2.4v1.7c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v15.6c0,0.7-0.3,1.3-0.7,1.8l-1.7,1.7
	c-0.5,0.5-1.1,0.7-1.8,0.7h-2.3l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-2.3l0.7,0.7c1,1,1,2.5,0,3.5c0,0,0,0,0,0l-0.9,0.9v0.6
	c-0.1,2.3-2.1,4.2-4.4,4.1c-0.2,0-0.4,0-0.6-0.1v1c0,1.4-1.1,2.5-2.5,2.5h-4l-0.2,0.2v3.9c0,0.7-0.3,1.3-0.7,1.8L499,421v5
	c1,0.4,1.6,1.3,1.6,2.4v0.6l0.2,0.2h0.6c0.9,0,1.8,0.5,2.2,1.4l1.5,3l1.4,1.4c0.5,0.5,0.7,1.1,0.7,1.8v3.3c0,0.7-0.3,1.3-0.7,1.8
	l-0.9,0.9c1,0.4,1.6,1.3,1.6,2.3v5c0,1.1-0.7,2-1.7,2.4v4.3c0,1.1-0.7,2-1.7,2.4v1c0,1.1-0.7,2-1.7,2.4v3.2l0.7-0.7
	c0.5-0.5,1.1-0.7,1.8-0.7h3.3c0.4,0,0.8,0.1,1.1,0.3l3,1.5c0.3-0.1,0.5-0.1,0.8-0.1h1.7c1.4,0,2.5,1.1,2.5,2.5c0,0,0,0,0,0v0.6
	l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v1.7c0,1.4-1.1,2.5-2.5,2.5h-0.6l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-0.6l-0.2,0.2v0.6
	c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v7.3c0,1.1-0.7,2.1-1.8,2.4c-0.1,0.4-0.3,0.7-0.6,1l-2.6,2.6v0.6c-0.9,1.1-1.7,2.2-2.4,3.4l-1,1
	c0,0.2,0.1,0.4,0.1,0.6v4.2c1,0.4,1.7,1.3,1.7,2.4V518c0,1.4-1.1,2.5-2.5,2.5H499v2.5c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v2.9l0.9,0.9
	c0.3,0.3,0.5,0.6,0.6,1c0.4,0.1,0.7,0.3,1,0.6l0.9,0.9h0.6c1.4,0,2.5,1.1,2.5,2.5v1.7c0,0.9-0.5,1.8-1.4,2.2l-1.3,0.7l0.3,0.3
	c0.7,0.7,0.9,1.8,0.5,2.7c-1.1,1-2.2,2-3.2,3.1c-0.3,1.1-1.3,1.8-2.4,1.8h-0.6l-1.8,1.9v2.3c0,1.4-1.1,2.5-2.5,2.5
	c-1.1,0-2-0.7-2.4-1.7h-1.9c-0.3,1-1.3,1.7-2.4,1.7h-0.6l-0.2,0.2v0.6c0,1.1-0.7,2.1-1.8,2.4 M321.2,576.9h1c0.7,0,1.3,0.3,1.8,0.7
	l0.8,0.8c-0.4-1.3,0.3-2.7,1.7-3.1c0,0,0,0,0,0c0.3-1,1.3-1.8,2.4-1.8h8.3c0.4,0,0.8,0.1,1.1,0.3l2.8,1.4h7.5l7.3-1.5l1.1-1.1
	c0.3-0.3,0.7-0.5,1-0.6c1-0.7,1.8-1.6,2.3-2.7l1.6-1.7c0.5-0.5,1.1-0.7,1.8-0.7h3.3c1.4,0,2.5,1.1,2.5,2.5v0.6l0.9,0.9
	c0.5,0.5,0.7,1.1,0.7,1.8v0.6l0.1,0.2c0.4-1,1.3-1.6,2.3-1.6h3.3c1.1,0,2,0.7,2.4,1.7h1.6l0.6-0.6l1.2-2.4v-1.1
	c0-0.7,0.3-1.3,0.7-1.8l2.6-2.6v-0.6c0-1.4,1.1-2.5,2.5-2.5c0,0,0,0,0,0h1.7c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h2.3
	c1.4,0,2.5,1.1,2.5,2.5v2.3l0.2,0.2h1.3l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7h1c0.4-1,1.3-1.7,2.4-1.7h0.6l1.9-1.9v-0.6
	c0-0.7,0.3-1.3,0.7-1.8l1.7-1.7c0.5-0.5,1.1-0.7,1.8-0.7h1.7c1.1,0,2,0.7,2.4,1.7h2.6c0.3,0,0.6,0,0.8,0.1c0.3-0.1,0.6-0.1,0.8-0.1
	h3.3c1.4,0,2.5,1.1,2.5,2.5c0,0,0,0,0,0v1c1,0.4,1.7,1.3,1.7,2.4v2.3l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v4.3c0.3-0.1,0.5-0.1,0.8-0.1
	h7.3l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7h3.3c0.3,0,0.6,0.1,0.9,0.2l0.2-0.4c0.4-0.9,1.3-1.4,2.2-1.4h0.8v-1.8c-1-0.4-1.7-1.3-1.7-2.3
	v-6.6c0-1.4,1.1-2.5,2.5-2.5h1.6c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9c0.4-1,1.3-1.6,2.3-1.6h1.7c1.4,0,2.5,1.1,2.5,2.5v0.6l0.2,0.2h3.9
	c0.7,0,1.3,0.3,1.8,0.7l0.7,0.7l0.7-0.7c0.3-0.3,0.6-0.5,1-0.6c0.3-1.1,1.3-1.8,2.4-1.8h0.6l0.9-0.9c1.1-0.5,2-1.3,2.7-2.3
	c1.1-1.1,2.2-2.2,3.3-3.3c0.2-0.8,0.9-1.4,1.7-1.7c0.1-0.4,0.3-0.7,0.6-1l1.6-1.7c1.2-0.7,2.4-1.5,3.4-2.4h4.3
	c0.1-0.3,0.3-0.7,0.6-0.9l3.3-3.3c0.3-0.3,0.6-0.5,1-0.6c0.2-0.8,0.9-1.4,1.6-1.7v-0.1c-0.1-0.3-0.1-0.5-0.1-0.8V538
	c0-0.9,0.5-1.8,1.4-2.2l0.2-0.1l-0.1-0.1h-0.6c-1.4,0-2.5-1.1-2.5-2.5v-0.6l-0.9-0.9c-0.5-0.5-0.7-1.1-0.7-1.8v-5
	c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-4c0-1.4,1.1-2.5,2.5-2.5h2.5v-6.8c-1-0.4-1.7-1.3-1.7-2.4v-4.3c-1-0.4-1.7-1.3-1.7-2.4V498
	c0-1.4,1.1-2.5,2.5-2.5h0.6l0.2-0.2v-0.6c0.9-1.1,1.7-2.2,2.4-3.4l2.6-2.6V488c0-1.1,0.7-2,1.7-2.4v-5.9c0-0.7,0.3-1.3,0.7-1.8
	l0.9-0.9v-0.6c0-0.7,0.3-1.3,0.7-1.8l1.7-1.7c0.5-0.5,1.1-0.7,1.8-0.7h0.7l0.2-0.1c-0.3-0.1-0.6-0.3-0.9-0.6l-1.4-1.4l-2.4-1.2h-1.7
	l-0.9,0.9c-0.3,0.3-0.6,0.5-1,0.6c-0.4,1.3-1.8,2-3.1,1.6c-0.4-0.1-0.7-0.3-1-0.6l-1.7-1.6c-0.5-0.5-0.7-1.1-0.7-1.8v-8.3
	c0-1.1,0.7-2,1.7-2.4v-1c0-1.1,0.7-2,1.7-2.4v-4.3c0-1.1,0.7-2,1.7-2.4v-0.3c-1-0.4-1.7-1.3-1.7-2.4v-3.3c0-0.7,0.3-1.3,0.7-1.8
	l0.9-0.9v-1.3l-0.9-0.9c-0.2-0.2-0.3-0.4-0.5-0.6l-1-1.9h-0.1c-0.7,0-1.3-0.3-1.8-0.7l-1.7-1.7c-0.3-0.3-0.5-0.6-0.6-0.9h-1
	c-1.4,0-2.5-1.1-2.5-2.5c0,0,0,0,0,0v-1.7c0-1.1,0.7-2,1.7-2.4V420c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-4c0-0.7,0.3-1.3,0.7-1.8l1.7-1.7
	c0.5-0.5,1.1-0.7,1.8-0.7h2.5v-0.8c0.1-2.3,2-4.1,4.3-4.1c0.2,0,0.5,0,0.7,0.1h0.1c0.5-0.3,0.7-1,0.4-1.5c-0.1-0.1-0.2-0.3-0.4-0.4
	c-1-0.3-1.8-1.3-1.8-2.4v-1.7c0-1.4,1.1-2.5,2.5-2.5h5.6l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7h2.3l0.2-0.2v-15.6c0-0.6,0.2-1.2,0.6-1.7
	c-0.4-0.5-0.6-1-0.6-1.7v-8.3c0-1,0.6-1.9,1.5-2.3c0.9,0.1,1.8,0,2.6-0.2h2.3l0.8-0.8c-0.2-0.2-0.3-0.4-0.4-0.7c0,0,0-0.1,0-0.1
	h-5.9c-1.4,0-2.5-1.1-2.5-2.5v-5.9c-1-0.4-1.7-1.3-1.7-2.4v-5c0-1.4,1.1-2.5,2.5-2.5h2.3l0.9-0.9c0.3-0.3,0.6-0.5,0.9-0.6v-4.3
	c0-0.3,0-0.5,0.1-0.8l1.5-4.6v-4.6c0-0.4,0.1-0.8,0.3-1.1l1-2l-1.7-0.4c-0.5,0.7-1.2,1.1-2,1.1H518c-1.4,0-2.5-1.1-2.5-2.5v-0.1
	l-4.7,2.4c-0.3,0.2-0.7,0.3-1.1,0.3h-3.3c-0.4,0-0.8-0.1-1.1-0.3l-2.8-1.4h-1.1c-0.9-0.8-2.4-0.8-3.3,0h-5c-1-0.8-2.4-0.8-3.3,0
	h-1.7c-1.4,0-2.5-1.1-2.5-2.5c0,0,0,0,0,0v-0.6l-0.2-0.2h-0.6c-1.4,0-2.5-1.1-2.5-2.5c0-0.7,0.3-1.3,0.7-1.8l0.9-1v-2.3
	c0-1.1,0.7-2.1,1.8-2.4l0.1-0.2c-0.4-0.1-0.8-0.3-1.1-0.6l-1.4-1.4l-6.3-3.1c-0.2-0.1-0.5-0.3-0.6-0.5l-0.9-0.9h-2.3
	c-1.4,0-2.5-1.1-2.5-2.5v-1.7c0-0.7,0.3-1.3,0.7-1.8l2.6-2.6v-0.6c0-1.1,0.7-2,1.7-2.4v-1c0-0.6,0.2-1.2,0.6-1.7
	c-0.2-0.3-0.4-0.6-0.5-0.9c-0.4-0.1-0.7-0.3-1-0.6l-2.6-2.6h-0.6c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9c-0.4,1-1.3,1.6-2.3,1.6H466
	l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-7.5v1.8c1,1.1,2.1,2.2,3.2,3.3c0.4,0.1,0.8,0.3,1,0.6l0.9,0.9h0.6c1.4,0,2.5,1.1,2.5,2.5v8.3
	c-0.9,1.8-2.4,3.2-4.2,4.2h-0.6l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7H454c-1.1,1-2.2,2.1-3.3,3.2c-0.1,0.4-0.3,0.7-0.6,1l-0.9,0.9v3.2
	c1,0.4,1.7,1.3,1.7,2.4v4.3c1.3,0.5,2,1.9,1.5,3.2c-0.1,0.3-0.3,0.7-0.6,0.9l-0.9,0.9v5.7c0,0.7-0.3,1.3-0.7,1.8l-3,3l-1.5,3
	c-0.3,0.6-0.8,1.1-1.5,1.3c-0.1,0.4-0.3,0.7-0.6,1l-0.9,0.9v0.6c0,1.4-1.1,2.5-2.5,2.5h-3.3c-1.1,0-2-0.7-2.4-1.7h-0.2l-0.1,0.3
	c-0.1,0.2-0.3,0.5-0.5,0.6l-0.9,0.9v0.6c0,1.1-0.7,2-1.7,2.4v1c-0.9,1.1-1.7,2.2-2.4,3.4l-0.9,0.9v0.6c0,1.1-0.7,2-1.7,2.4v1
	c0,1.1-0.7,2-1.7,2.4v1c0,1.1-0.7,2-1.7,2.4v2.6c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v25.5c-1.5,2.4-3.4,4.4-5.8,5.8h-2.3l-0.9,0.9
	c-0.5,0.5-1.1,0.7-1.8,0.7h-0.6l-0.9,0.9c-0.3,0.3-0.7,0.5-1,0.6c-0.1,0.3-0.3,0.7-0.5,0.9l5.2,0.9c1.3,0.2,2.2,1.3,2.1,2.6
	l-1.7,28.1v8.2c0,0.2,0,0.3,0,0.5l-1.7,8.3c0,0.2-0.1,0.4-0.2,0.6l-5,10c-0.1,0.1-0.2,0.3-0.3,0.4l-11.4,14.6l-1.5,4.6
	c-0.4,1.1-1.5,1.8-2.7,1.7l-13.1-1.6h-21.5c-0.1,0-0.2,0-0.4,0l-11.6-1.7c-0.7-0.1-1.3-0.5-1.7-1c-0.4-0.5-0.6-1.2-0.5-1.9l1.6-9.8
	v-3.9h-2.5c-1.8-0.9-3.2-2.4-4.1-4.2V466l-0.2-0.2h-0.6c-1.4,0-2.5-1.1-2.5-2.5V461l-0.9-1c-0.3-0.3-0.5-0.6-0.6-1
	c-1.1-0.3-1.8-1.3-1.8-2.4v-2.3l-0.9-0.9c0.3-1.7,0.1-3.5-0.7-5.1v-2.7c-1-0.4-1.7-1.3-1.6-2.4v-3.9l-0.9-0.9
	c-0.5-0.5-0.7-1.1-0.7-1.8V436l-0.9-0.9c-0.3-0.3-0.5-0.6-0.6-0.9h-2.6c-0.6,0-1.2-0.2-1.7-0.6c-0.5,0.4-1.1,0.6-1.7,0.6h-8.3
	c-1.4,0-2.5-1.1-2.5-2.5v-1c-0.8-0.3-1.3-0.9-1.6-1.6c-0.4-0.1-0.7-0.3-1-0.6l-2.6-2.6h-0.6c-1.4,0-2.5-1.1-2.5-2.5v-2.5h-0.8
	c-1.1,0-2-0.7-2.4-1.7h-4.3c-1.4,0-2.5-1.1-2.5-2.5c0,0,0,0,0,0v-2.2l-2.6-2.6c-0.3-0.3-0.5-0.6-0.6-0.9h-1c-0.6,0-1.2-0.2-1.7-0.6
	c-1,0.9-2.6,0.8-3.5-0.2c-0.1-0.2-0.3-0.3-0.4-0.5l-0.1-0.3H274c-0.5,0-1-0.1-1.4-0.4l-4.4-2.9h-1.7v0.8c0,1.1-0.7,2-1.7,2.4v4.3
	c0,1.1-0.7,2-1.7,2.4l-3.3,1.1v2.2l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v1c1,0.4,1.6,1.3,1.6,2.4v0.6l0.9,0.9c0.7,1.2,1.5,2.4,2.4,3.4
	v0.6l0.2,0.2h0.6c1,0,1.8,0.5,2.2,1.4l1,1.9h0.1c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h0.6c0.7,0,1.3,0.3,1.8,0.7l1.6,1.7
	c0.5,0.5,0.7,1.1,0.7,1.8v1.7c0,0.7-0.3,1.3-0.7,1.8l-1.7,1.6c-0.3,0.3-0.7,0.5-1,0.6c-0.1,0.4-0.3,0.7-0.6,1l-1.7,1.7
	c-0.3,0.3-0.6,0.5-0.9,0.6v1c0,1.4-1.1,2.5-2.5,2.5h-1c0.1,0.3,0.1,0.5,0.1,0.8v1.7c0,0.7-0.3,1.3-0.7,1.8l-1.5,1.5
	c0.4,0.5,0.6,1.1,0.6,1.7c0,0.2,0,0.4-0.1,0.6l1,1c0.5,0.5,0.7,1.1,0.7,1.8v0.6l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v1.7
	c-0.9,0.7-1.5,1.7-1.9,2.8l-1.7,3.3c-0.3,0.6-0.8,1-1.4,1.2v5.9c0,1.4-1.1,2.5-2.5,2.5h-0.6l-0.2,0.2v2.3c0,1.1-0.7,2-1.7,2.4v3.7
	l0.7,1.4h5.1c1.1,0,2,0.7,2.4,1.7h6c0.7,0,1.3,0.3,1.8,0.7l1.6,1.7c0.5,0.5,0.7,1.1,0.7,1.8v5c-0.9,1.4-2,2.8-3.2,4l-0.1,0v1
	c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v0.6c0,1-0.6,2-1.6,2.3l0.9,0.9c1,1,1,2.6,0,3.5l-2.6,2.6v4c-0.2,0.5-0.2,1.1,0,1.7v0.8h3.6l2.8-1.4
	c0.3-0.2,0.7-0.3,1.1-0.3h2.3l2.6-2.6c0.5-0.5,1.1-0.7,1.8-0.7h1.6c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h0.6c1.8,0.9,3.2,2.4,4.2,4.2v2.3
	l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v1.7c-0.2,0.5-0.2,1.1,0,1.7v1.7c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v2.3c0,0.7-0.3,1.3-0.7,1.8l-5,5
	c-0.3,0.3-0.6,0.5-0.9,0.6v0.3c0.3-0.1,0.5-0.1,0.8-0.1h1c0.5-1.3,1.9-2,3.2-1.5c0.3,0.1,0.7,0.3,0.9,0.6l0.9,0.9h0.6
	c0.7,0,1.3,0.3,1.8,0.7l1.7,1.7c0.3,0.3,0.5,0.6,0.6,1c0.4,0.1,0.7,0.3,1,0.6l1.6,1.7c0.5,0.5,0.7,1.1,0.7,1.8v1
	c0.4,0.1,0.7,0.3,0.9,0.6l1.4,1.4l9.4,4.7l4.8,1.6c1,0.3,1.7,1.3,1.7,2.4v1.7c0,0.8-0.4,1.6-1.1,2.1l1.4,0.7
	C320.5,575.8,320.9,576.3,321.2,576.9L321.2,576.9z" />
		<Tippy
			followCursor={true}
			plugins={[followCursor]}
			className={classes.tippy}
			hideOnClick={false}
			content={<span className={classes.tippyContent}>Kalol</span>}
		>
			<polygon
				onClick={(e) => props.clicked(e.target)}
				onMouseEnter={(e) => props.entered(e.target)}
				onMouseLeave={(e) => props.left(e.target)}
				id="Kalol"
				fill="#FCFCFC"
				// class="st0"
				points="330.4,332 328.8,332 322.1,332 320.5,330.4 318.8,330.4 317.1,332 317.1,333.7 315.5,335.3 
	315.5,338.6 313.8,343.6 313.8,345.3 312.2,347 310.5,348.6 310.5,350.3 303.9,350.3 302.2,353.6 298.9,355.3 295.6,356.9 
	292.3,356.9 287.3,356.9 287.3,355.3 285.6,355.3 282.3,360.2 280.6,366.9 280.6,368.5 282.3,368.5 282.3,370.2 282.3,371.9 
	282.3,373.5 282.3,375.2 282.3,376.8 282.3,378.5 282.3,381.8 282.3,383.5 282.3,385.1 282.3,388.5 280.6,388.5 279,388.5 
	277.3,395.1 277.3,396.8 275.6,400.1 275.6,401.7 275.6,403.4 274,405.1 272.3,405.1 270.7,403.4 269,403.4 267.3,403.4 
	265.7,403.4 264,403.4 264,405.1 264,406.7 262.4,406.7 262.4,408.4 262.4,410 262.4,411.7 262.4,413.4 257.4,415 257.4,420 
	259,421.7 259,425 260.7,425 260.7,426.6 262.4,428.3 262.4,430 264,430 264,431.6 265.7,433.3 267.3,433.3 269,436.6 270.7,436.6 
	272.3,438.3 274,438.3 275.6,439.9 275.6,441.6 274,443.3 272.3,443.3 272.3,444.9 270.7,446.6 269,446.6 269,449.9 265.7,449.9 
	264,451.5 265.7,453.2 265.7,454.9 264,456.5 262.4,456.5 262.4,458.2 264,459.9 262.4,461.5 264,461.5 265.7,463.2 265.7,464.8 
	267.3,466.5 267.3,468.2 265.7,468.2 265.7,469.8 264,473.1 262.4,473.1 262.4,476.5 262.4,478.1 262.4,481.4 260.7,481.4 
	259,483.1 259,484.8 259,486.4 257.4,486.4 257.4,489.7 257.4,493 259,496.4 262.4,496.4 265.7,496.4 265.7,498 265.7,499.7 
	264,501.4 264,503 264,504.7 264,506.3 262.4,508 260.7,509.7 260.7,511.3 259,513 259,514.6 257.4,514.6 254.1,514.6 254.1,513 
	249.1,513 247.4,513 247.4,511.3 244.1,509.7 244.1,508 242.4,504.7 242.4,503 240.8,501.4 239.1,503 237.5,503 235.8,504.7 
	234.1,506.3 234.1,508 234.1,509.7 234.1,511.3 232.5,513 234.1,513 234.1,514.6 234.1,516.3 234.1,518 229.1,518 227.5,518 
	225.8,518 224.2,518 222.5,518 222.5,516.3 220.9,516.3 219.2,514.6 217.5,514.6 214.2,514.6 214.2,518 215.9,518 215.9,519.6 
	215.9,521.3 215.9,522.9 215.9,524.6 215.9,526.3 214.2,526.3 214.2,527.9 214.2,529.6 214.2,531.2 215.9,532.9 215.9,534.6 
	215.9,536.2 217.5,537.9 217.5,539.5 219.2,539.5 219.2,541.2 220.9,541.2 220.9,542.9 220.9,544.5 222.5,544.5 222.5,546.2 
	224.2,546.2 224.2,547.8 225.8,547.8 225.8,549.5 225.8,551.2 225.8,552.8 225.8,554.5 225.8,556.1 224.2,556.1 224.2,557.8 
	224.2,559.5 224.2,561.1 222.5,561.1 219.2,561.1 217.5,561.1 217.5,562.8 215.9,562.8 214.2,562.8 210.9,562.8 209.2,562.8 
	207.6,562.8 207.6,564.5 205.9,564.5 205.9,566.1 205.9,567.8 205.9,569.4 204.3,571.1 202.6,572.8 200.9,572.8 200.9,574.4 
	199.3,574.4 196,574.4 194.3,574.4 194.3,576.1 192.6,576.1 191,576.1 189.3,576.1 187.6,574.4 187.6,577.7 187.6,579.4 
	189.3,579.4 191,579.4 191,577.7 192.6,577.7 194.3,577.7 194.3,579.4 194.3,581 196,582.7 197.6,582.7 197.6,584.4 199.3,586 
	199.3,587.7 200.9,587.7 200.9,589.3 199.3,589.3 199.3,591 197.6,592.7 197.6,594.3 197.6,596 196,596 194.3,596 194.3,597.7 
	192.6,597.7 191,597.7 189.3,597.7 187.6,597.7 186,597.7 184.3,597.7 184.3,596 182.7,596 181,596 179.3,596 177.7,597.7 
	176,597.7 176,599.3 176,601 176,602.6 176,604.3 174.4,606 176,606 176,607.6 176,609.3 174.4,609.3 174.4,610.9 172.7,610.9 
	171.1,610.9 169.4,610.9 167.7,610.9 166.1,610.9 164.4,610.9 164.4,612.6 162.8,612.6 161.1,612.6 159.4,612.6 159.4,610.9 
	157.8,610.9 156.1,610.9 156.1,609.3 154.4,609.3 154.4,607.6 156.1,607.6 154.4,606 156.1,606 156.1,604.3 156.1,602.6 
	157.8,602.6 159.4,602.6 159.4,601 159.4,599.3 157.8,599.3 157.8,597.7 157.8,596 157.8,594.3 159.4,594.3 159.4,592.7 
	157.8,592.7 156.1,591 154.4,591 152.8,591 151.1,591 149.5,591 147.8,591 146.1,591 144.5,591 144.5,589.3 142.8,589.3 
	142.8,587.7 141.2,587.7 139.5,587.7 137.8,587.7 137.8,589.3 136.2,589.3 134.5,589.3 134.5,591 132.9,591 132.9,592.7 
	132.9,594.3 134.5,594.3 132.9,594.3 132.9,596 132.9,597.7 131.2,597.7 129.5,597.7 127.9,597.7 127.9,599.3 126.2,599.3 
	124.6,599.3 122.9,599.3 122.9,601 121.2,601 119.6,602.6 117.9,602.6 117.9,601 119.6,601 119.6,599.3 117.9,597.7 117.9,596 
	116.3,596 116.3,594.3 114.6,594.3 114.6,592.7 112.9,592.7 112.9,591 114.6,591 114.6,589.3 114.6,587.7 114.6,586 114.6,584.4 
	114.6,582.7 114.6,581 114.6,579.4 116.3,579.4 116.3,577.7 116.3,576.1 117.9,576.1 117.9,574.4 116.3,574.4 116.3,572.8 
	114.6,572.8 112.9,572.8 111.3,572.8 109.6,572.8 109.6,571.1 108,569.4 106.3,567.8 106.3,566.1 106.3,564.5 106.3,562.8 
	104.6,561.1 104.6,559.5 103,561.1 103,562.8 101.3,562.8 99.7,562.8 98,562.8 96.3,562.8 94.7,562.8 94.7,564.5 93,564.5 
	91.3,564.5 91.3,562.8 89.7,562.8 88,562.8 88,561.1 86.4,561.1 84.7,561.1 84.7,559.5 84.7,557.8 84.7,556.1 84.7,554.5 
	83.1,554.5 83.1,552.8 81.4,551.2 81.4,549.5 79.7,549.5 79.7,547.8 78.1,546.2 76.4,544.5 74.8,544.5 74.8,542.9 73.1,542.9 
	73.1,541.2 73.1,539.5 73.1,537.9 74.8,537.9 74.8,536.2 74.8,534.6 76.4,534.6 76.4,532.9 76.4,531.2 76.4,529.6 78.1,527.9 
	78.1,526.3 78.1,524.6 79.7,524.6 81.4,524.6 83.1,524.6 83.1,526.3 84.7,527.9 84.7,526.3 86.4,526.3 88,526.3 88,527.9 
	89.7,527.9 89.7,526.3 91.3,526.3 93,527.9 94.7,529.6 94.7,531.2 94.7,532.9 94.7,534.6 96.3,534.6 96.3,536.2 96.3,537.9 
	98,537.9 98,539.5 99.7,539.5 99.7,541.2 101.3,541.2 101.3,542.9 103,544.5 103,546.2 103,547.8 103,549.5 104.6,549.5 103,551.2 
	103,552.8 101.3,552.8 101.3,554.5 103,556.1 103,557.8 104.6,556.1 104.6,554.5 106.3,554.5 106.3,552.8 106.3,551.2 108,551.2 
	108,549.5 109.6,549.5 109.6,547.8 111.3,547.8 111.3,546.2 111.3,544.5 112.9,544.5 114.6,544.5 114.6,542.9 116.3,542.9 
	117.9,542.9 117.9,541.2 117.9,539.5 117.9,537.9 119.6,537.9 119.6,536.2 121.2,536.2 121.2,534.6 122.9,534.6 122.9,532.9 
	124.6,532.9 124.6,531.2 126.2,531.2 126.2,532.9 127.9,532.9 127.9,531.2 129.5,531.2 131.2,531.2 132.9,531.2 132.9,529.6 
	134.5,529.6 136.2,529.6 136.2,527.9 134.5,527.9 134.5,526.3 134.5,524.6 134.5,522.9 134.5,521.3 136.2,521.3 137.8,521.3 
	139.5,521.3 141.2,522.9 142.8,522.9 144.5,522.9 146.1,524.6 149.5,524.6 151.1,524.6 151.1,522.9 152.8,521.3 152.8,519.6 
	154.4,519.6 156.1,518 156.1,516.3 157.8,516.3 157.8,514.6 159.4,514.6 159.4,513 161.1,513 161.1,511.3 159.4,511.3 159.4,509.7 
	159.4,508 161.1,506.3 162.8,504.7 161.1,504.7 162.8,503 161.1,503 161.1,501.4 159.4,501.4 157.8,503 156.1,503 152.8,503 
	151.1,503 149.5,504.7 147.8,504.7 147.8,506.3 146.1,506.3 146.1,504.7 144.5,506.3 142.8,506.3 141.2,506.3 139.5,506.3 
	139.5,504.7 137.8,504.7 136.2,506.3 136.2,504.7 134.5,506.3 132.9,506.3 131.2,506.3 131.2,504.7 131.2,503 129.5,503 
	129.5,501.4 127.9,501.4 126.2,501.4 124.6,499.7 124.6,498 124.6,496.4 122.9,496.4 122.9,494.7 121.2,493 121.2,491.4 
	121.2,489.7 121.2,488.1 121.2,486.4 121.2,484.8 121.2,483.1 122.9,483.1 122.9,481.4 124.6,481.4 124.6,479.8 124.6,478.1 
	126.2,478.1 127.9,478.1 129.5,478.1 129.5,476.5 131.2,476.5 131.2,474.8 132.9,474.8 134.5,474.8 134.5,473.1 134.5,471.5 
	136.2,471.5 136.2,469.8 137.8,469.8 137.8,468.2 139.5,466.5 139.5,468.2 141.2,466.5 142.8,466.5 144.5,466.5 146.1,466.5 
	146.1,468.2 147.8,468.2 149.5,468.2 151.1,468.2 152.8,466.5 152.8,464.8 152.8,463.2 152.8,461.5 151.1,461.5 151.1,459.9 
	151.1,458.2 151.1,456.5 152.8,454.9 152.8,453.2 152.8,451.5 152.8,449.9 154.4,448.2 156.1,448.2 156.1,446.6 157.8,446.6 
	157.8,448.2 159.4,448.2 161.1,448.2 162.8,448.2 162.8,449.9 164.4,449.9 164.4,451.5 166.1,451.5 166.1,453.2 167.7,453.2 
	167.7,454.9 169.4,454.9 171.1,456.5 172.7,458.2 172.7,459.9 172.7,461.5 174.4,461.5 176,461.5 177.7,461.5 179.3,461.5 
	181,461.5 182.7,461.5 182.7,459.9 182.7,458.2 184.3,458.2 182.7,456.5 181,456.5 179.3,456.5 177.7,454.9 176,453.2 176,451.5 
	177.7,449.9 176,448.2 176,446.6 176,444.9 174.4,444.9 174.4,443.3 176,441.6 174.4,441.6 174.4,439.9 174.4,438.3 174.4,436.6 
	172.7,436.6 172.7,434.9 171.1,434.9 171.1,433.3 171.1,431.6 171.1,430 171.1,428.3 169.4,428.3 169.4,426.6 169.4,425 
	169.4,423.3 169.4,421.7 169.4,420 171.1,420 171.1,418.3 169.4,418.3 171.1,416.7 169.4,416.7 167.7,416.7 167.7,415 166.1,415 
	166.1,413.4 166.1,411.7 166.1,410 166.1,408.4 167.7,408.4 169.4,408.4 169.4,410 171.1,410 172.7,410 176,410 177.7,410 
	179.3,410 179.3,411.7 181,411.7 182.7,411.7 184.3,411.7 186,411.7 186,410 186,408.4 186,406.7 186,405.1 186,403.4 184.3,403.4 
	184.3,401.7 182.7,400.1 181,398.4 179.3,398.4 179.3,396.8 179.3,395.1 177.7,395.1 176,396.8 174.4,395.1 174.4,393.4 
	172.7,393.4 171.1,393.4 169.4,393.4 167.7,393.4 167.7,395.1 167.7,396.8 167.7,398.4 167.7,400.1 166.1,400.1 164.4,400.1 
	164.4,398.4 164.4,396.8 164.4,395.1 164.4,393.4 162.8,393.4 162.8,391.8 162.8,390.1 162.8,388.5 161.1,386.8 161.1,385.1 
	159.4,385.1 159.4,383.5 157.8,383.5 157.8,381.8 157.8,380.2 156.1,380.2 156.1,378.5 156.1,376.8 156.1,375.2 157.8,373.5 
	159.4,371.9 159.4,370.2 159.4,368.5 161.1,368.5 161.1,366.9 162.8,366.9 162.8,365.2 162.8,363.6 164.4,363.6 164.4,361.9 
	166.1,360.2 166.1,358.6 166.1,356.9 166.1,355.3 167.7,355.3 167.7,353.6 169.4,353.6 169.4,351.9 171.1,350.3 172.7,350.3 
	172.7,348.6 174.4,348.6 176,348.6 177.7,348.6 179.3,347 181,347 182.7,348.6 182.7,350.3 184.3,350.3 186,351.9 187.6,351.9 
	189.3,353.6 191,353.6 191,355.3 192.6,358.6 192.6,360.2 194.3,360.2 196,360.2 197.6,360.2 197.6,361.9 199.3,361.9 200.9,361.9 
	202.6,361.9 204.3,361.9 205.9,360.2 205.9,358.6 207.6,358.6 209.2,358.6 210.9,358.6 210.9,356.9 212.6,356.9 214.2,356.9 
	215.9,356.9 215.9,355.3 215.9,353.6 214.2,353.6 212.6,353.6 212.6,351.9 210.9,351.9 210.9,350.3 209.2,350.3 209.2,348.6 
	207.6,348.6 207.6,347 205.9,347 204.3,348.6 204.3,347 202.6,348.6 202.6,347 200.9,347 200.9,345.3 202.6,345.3 204.3,345.3 
	205.9,345.3 207.6,345.3 209.2,345.3 210.9,345.3 212.6,345.3 214.2,345.3 214.2,347 215.9,347 217.5,347 219.2,347 220.9,347 
	222.5,345.3 222.5,343.6 222.5,342 222.5,340.3 224.2,340.3 224.2,338.6 224.2,337 224.2,335.3 224.2,333.7 222.5,332 222.5,330.4 
	222.5,328.7 222.5,327 220.9,325.4 220.9,323.7 219.2,323.7 219.2,322 215.9,318.7 215.9,317.1 215.9,315.4 219.2,313.8 
	220.9,313.8 220.9,315.4 222.5,315.4 222.5,317.1 224.2,317.1 225.8,318.7 227.5,320.4 229.1,320.4 230.8,320.4 232.5,320.4 
	234.1,320.4 234.1,318.7 235.8,318.7 240.8,317.1 242.4,315.4 244.1,315.4 245.8,317.1 245.8,315.4 247.4,315.4 249.1,315.4 
	249.1,313.8 254.1,312.1 255.7,312.1 257.4,312.1 259,312.1 260.7,312.1 260.7,310.4 260.7,308.8 262.4,308.8 262.4,307.1 
	262.4,305.4 264,305.4 264,303.8 265.7,302.1 265.7,300.5 269,300.5 269,302.1 269,303.8 270.7,305.4 272.3,307.1 274,307.1 
	275.6,308.8 277.3,308.8 279,308.8 279,307.1 280.6,307.1 280.6,305.4 282.3,305.4 282.3,303.8 284,303.8 285.6,303.8 285.6,302.1 
	287.3,302.1 288.9,302.1 290.6,302.1 292.3,302.1 293.9,302.1 293.9,303.8 295.6,302.1 295.6,303.8 295.6,305.4 297.2,307.1 
	297.2,308.8 298.9,308.8 298.9,310.4 298.9,312.1 300.5,312.1 302.2,312.1 303.9,312.1 305.5,312.1 307.2,312.1 308.9,312.1 
	310.5,312.1 312.2,313.8 313.8,313.8 315.5,313.8 317.1,313.8 318.8,313.8 318.8,312.1 320.5,312.1 322.1,312.1 323.8,312.1 
	323.8,310.4 325.5,310.4 327.1,310.4 332.1,312.1 333.8,312.1 335.4,312.1 335.4,313.8 337.1,313.8 338.7,313.8 338.7,315.4 
	337.1,317.1 335.4,318.7 333.8,318.7 333.8,320.4 332.1,320.4 332.1,322 330.4,323.7 330.4,325.4 330.4,327 330.4,328.7 
	330.4,330.4 "
			/>
		</Tippy>
		<path class="st1" d="M164.4,615.1h-5c-1.1,0-2-0.7-2.4-1.7h-1c-1.1,0-2.1-0.7-2.4-1.8c-1.1-0.3-1.8-1.3-1.8-2.4v-1.7
	c0-0.3,0-0.6,0.1-0.8c-0.5-1.3,0.2-2.7,1.5-3.2c0,0,0,0,0,0v-1c0-1.1,0.7-2.1,1.8-2.4c-0.1-0.3-0.2-0.6-0.2-0.9v-5
	c0-0.2,0-0.4,0.1-0.6l-0.3-0.3h-10.5c-1.1,0-2.1-0.7-2.4-1.8c-0.8-0.2-1.4-0.8-1.6-1.5h-0.3c-0.4,1-1.3,1.7-2.4,1.7h-1
	c-0.7,0.6-0.7,1.6-0.1,2.3c0.1,0.1,0.2,0.2,0.2,0.2c0,1.1-0.7,2-1.7,2.4v1c0,1.4-1.1,2.5-2.5,2.5h-2.6c-0.4,1-1.3,1.7-2.4,1.7h-2.6
	c-0.4,1-1.3,1.7-2.4,1.7h-0.6l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-1.7c-1.4,0-2.5-1.1-2.5-2.5V601c0-0.6,0.2-1.2,0.6-1.7
	c-0.2-0.3-0.4-0.6-0.5-0.9c-0.8-0.2-1.4-0.9-1.7-1.7c-0.8-0.2-1.4-0.9-1.7-1.7c-1.1-0.3-1.8-1.3-1.8-2.4V591c0-1.1,0.7-2,1.7-2.4
	v-9.3c0-1.1,0.7-2,1.7-2.3v-1c0-0.3,0-0.6,0.1-0.8h-4.3c-1.4,0-2.5-1.1-2.5-2.5v-0.6l-2.6-2.6c-0.5-0.5-0.7-1.1-0.7-1.8v-2.6
	c-0.3,0.1-0.5,0.1-0.8,0.1H97c-0.3,1-1.3,1.7-2.3,1.8h-3.3c-1.1,0-2-0.7-2.3-1.7h-1c-1.1,0-2-0.7-2.3-1.7h-1c-1.4,0-2.5-1.1-2.5-2.5
	v-4.3c-1-0.4-1.7-1.3-1.7-2.4v-0.6l-0.9-0.9c-0.3-0.3-0.5-0.6-0.6-1c-1.1-0.3-1.8-1.3-1.8-2.4v-0.6l-1.8-1.9h-0.6
	c-1.1,0-2.1-0.7-2.4-1.8c-1.1-0.3-1.8-1.3-1.8-2.4v-5c0-1.1,0.7-2,1.7-2.3v-1c0-1.1,0.7-2,1.7-2.3v-2.6c0-0.7,0.3-1.3,0.7-1.8
	l0.9-0.9v-2.2c0-1.4,1.1-2.5,2.5-2.5h5c1.1,0,2,0.7,2.3,1.7H88c0.3,0,0.6,0,0.8,0.1c0.3-0.1,0.5-0.1,0.8-0.1h1.7
	c0.7,0,1.3,0.3,1.8,0.7l3.3,3.3c0.5,0.5,0.7,1.1,0.7,1.8v2.6c1,0.3,1.7,1.3,1.7,2.3v1c0.7,0.3,1.3,0.9,1.6,1.6
	c0.8,0.2,1.4,0.9,1.7,1.7c1.1,0.3,1.8,1.3,1.8,2.4v0.6l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v2.6c0.3,0.1,0.6,0.3,0.8,0.5
	c0.3-0.2,0.6-0.4,0.9-0.5c0.2-0.8,0.8-1.4,1.6-1.6v-1c0-1.4,1.1-2.5,2.5-2.5h1c0.4-1,1.3-1.7,2.4-1.7h0.8v-2.5
	c0-1.1,0.7-2.1,1.8-2.4c0.2-0.8,0.9-1.4,1.7-1.7c0.2-0.8,0.9-1.4,1.7-1.7c0.2-0.8,0.9-1.4,1.7-1.7c0.3-1.1,1.3-1.8,2.4-1.8h1.7
	c0.3,0,0.6,0,0.8,0.1c0.3-0.1,0.5-0.1,0.8-0.1h2.6c0.3-0.7,0.8-1.3,1.5-1.5v-6c0-1.4,1.1-2.5,2.5-2.5h5c0.7,0,1.3,0.3,1.8,0.7
	l0.9,0.9h2.3c0.7,0,1.3,0.3,1.8,0.8l0.9,0.9h1.6c0.1-0.4,0.3-0.7,0.6-0.9l0.9-0.9v-0.6c0-1.4,1.1-2.5,2.5-2.5h0.6l0.2-0.2v-0.6
	c0-1.1,0.7-2.1,1.8-2.4c0.2-0.8,0.9-1.4,1.7-1.7v-0.1c-0.1-0.3-0.1-0.5-0.1-0.8V508c0-0.7,0.3-1.3,0.7-1.8l0.8-0.8
	c-0.2,0.1-0.5,0.1-0.7,0.1h-5.6l-0.9,0.9c-0.3,0.3-0.6,0.5-1,0.6c-0.3,1.1-1.3,1.8-2.4,1.8h-1.7c-0.3,0-0.6,0-0.8-0.1
	c-0.3,0.1-0.5,0.1-0.8,0.1h-5c-0.6,0-1.2-0.2-1.7-0.6c-0.7,0.6-1.6,0.8-2.5,0.5c-0.3,0.1-0.5,0.1-0.8,0.1h-3.3
	c-1.4,0-2.5-1.1-2.5-2.5l0,0v-1c-0.7-0.3-1.3-0.8-1.5-1.5h-1c-0.7,0-1.3-0.3-1.8-0.7l-1.7-1.7c-0.5-0.5-0.7-1.1-0.7-1.8v-1
	c-1-0.4-1.7-1.3-1.7-2.4v-0.6l-0.9-0.9c-0.5-0.5-0.7-1.1-0.7-1.8v-9.9c0-1.1,0.7-2.1,1.8-2.4c0.2-0.8,0.8-1.4,1.6-1.6v-1
	c0-1.4,1.1-2.5,2.5-2.5h2.6c0.3-0.7,0.9-1.3,1.6-1.5c0.3-1.1,1.3-1.8,2.4-1.8h0.8v-0.8c0-1.1,0.7-2.1,1.8-2.4
	c0.2-0.8,0.9-1.4,1.7-1.7c0.1-0.4,0.3-0.7,0.6-1l1.7-1.7c0.7-0.7,1.7-0.9,2.6-0.6c0.3-0.1,0.5-0.1,0.8-0.1h5c1.1,0,2,0.7,2.4,1.7
	h1.6l0.2-0.2v-1.6c-1-0.4-1.7-1.3-1.7-2.4v-5c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-3.9c0-0.7,0.3-1.3,0.7-1.8l1.7-1.7
	c0.3-0.3,0.6-0.5,1-0.6c0.3-1,1.3-1.8,2.4-1.8h1.7c1.1,0,2,0.7,2.4,1.7h2.6c1.1,0,2.1,0.7,2.4,1.8c0.8,0.2,1.4,0.9,1.6,1.7
	c0.8,0.2,1.4,0.9,1.7,1.6c0.8,0.2,1.4,0.9,1.7,1.7c0.4,0.1,0.7,0.3,1,0.6l3.3,3.3c0.5,0.5,0.7,1.1,0.7,1.8v0.8h3.8
	c-0.5-0.1-1.1-0.3-1.4-0.7l-3.3-3.3c-0.5-0.5-0.7-1.1-0.7-1.8v-1.7c0.8-0.9,0.8-2.4,0-3.3v-1c-1-0.4-1.7-1.3-1.7-2.4v-1.7
	c0.2-0.5,0.2-1.1,0-1.6V439c-1.3-1.2-2.4-2.5-3.3-4v-4.3c-1-0.4-1.7-1.3-1.7-2.4V420c0-0.3,0-0.6,0.1-0.8v-0.1
	c-1.3-1.2-2.5-2.5-3.4-4v-6.6c0-1.4,1.1-2.5,2.5-2.5h3.3c1.1,0,2,0.7,2.4,1.7h7.6c1.1,0,2,0.7,2.4,1.7h1.8v-3.4
	c-1-0.4-1.7-1.3-1.7-2.4v-0.6l-1.9-1.9h-0.6c-1.1,0-2.1-0.7-2.4-1.8c-0.9,0.4-2,0.1-2.7-0.6l-1.7-1.7c-0.3-0.3-0.5-0.6-0.6-0.9h-1.8
	v4.1c0,1.4-1.1,2.5-2.5,2.5c0,0,0,0,0,0h-3.4c-1.4,0-2.5-1.1-2.5-2.5v-4.3c-1-0.4-1.6-1.3-1.6-2.4v-3.9l-0.9-0.9
	c-0.3-0.3-0.5-0.7-0.6-1c-0.8-0.2-1.4-0.9-1.7-1.7c-1.1-0.3-1.8-1.3-1.8-2.4v-1c-1-0.4-1.7-1.3-1.7-2.4v-5c0-0.7,0.3-1.3,0.7-1.8
	l2.6-2.6v-2.3c0-1.1,0.7-2.1,1.8-2.4c0.2-0.8,0.8-1.4,1.6-1.6v-1c0-1.1,0.7-2.1,1.8-2.4c0.1-0.4,0.3-0.7,0.6-1l0.9-0.9v-4
	c0.9-1.5,2.1-2.8,3.4-4c0.1-0.4,0.3-0.7,0.6-1l1.7-1.7c1.2-0.7,2.4-1.5,3.4-2.4h3.9l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7h1.7
	c0.7,0,1.3,0.3,1.8,0.7l1.6,1.7c0.3,0.3,0.5,0.6,0.6,1c0.4,0.1,0.7,0.3,1,0.6l0.9,0.9h0.6c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h0.7
	c1.4,0,2.5,1.1,2.5,2.5c0,0,0,0,0,0v1.1l1.4,2.8c0,0.1,0.1,0.2,0.1,0.3h2.6c1.1,0,2,0.7,2.4,1.7h3.3l0.2-0.2v-0.6
	c0-1.4,1.1-2.5,2.5-2.5c0,0,0,0,0,0h2.6c0.3-0.8,0.9-1.4,1.7-1.6l-0.1-0.2c-1.1-1.1-2.2-2.2-3.3-3.3c-0.3-0.1-0.7-0.3-0.9-0.5
	c-0.7,0.6-1.6,0.8-2.5,0.5c-0.6,0.2-1.2,0.2-1.8,0c-1.2-1.2-2.3-2.6-3.2-4v-1.7c0-1.4,1.1-2.5,2.5-2.5h13.3c1.1,0,2,0.7,2.4,1.7h3.3
	l0.2-0.2v-3.9c0-1.1,0.7-2,1.6-2.4v-3.3l-0.9-0.9c-0.5-0.5-0.8-1.1-0.8-1.8v-3.9l-0.9-0.9c-0.7-1.2-1.5-2.4-2.4-3.4v-0.6l-2.6-2.6
	c-0.5-0.5-0.7-1.1-0.7-1.8v-3.3c0-0.9,0.5-1.8,1.4-2.2l3.3-1.7c0.3-0.2,0.7-0.3,1.1-0.3h1.7c1.5,0.9,2.9,2.1,4.1,3.4
	c0.4,0.1,0.7,0.3,1,0.6l2.6,2.6h3.3c0.4-1,1.3-1.7,2.4-1.7h1.3l4-1.3l1.2-1.2c0.5-0.5,1.1-0.7,1.8-0.7h1.6c0.3,0,0.6,0,0.8,0.1
	c0.3-0.1,0.6-0.1,0.8-0.1h1c0.3-0.7,0.8-1.3,1.6-1.5l5-1.7c0.3-0.1,0.5-0.1,0.8-0.1h4.1v-0.8c0-1.1,0.7-2,1.7-2.4v-1
	c0-1.1,0.7-2.1,1.8-2.4c0.1-0.4,0.3-0.7,0.6-1l0.9-0.9v-0.6c0-1.4,1.1-2.5,2.5-2.5h3.3c1.4,0,2.5,1.1,2.5,2.5v2.3l1.9,1.9h0.6
	c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9c1.1-1,2.2-2.1,3.3-3.2c0.3-1.1,1.3-1.8,2.4-1.8h1c0.4-1,1.3-1.6,2.4-1.6h8.3
	c0.9,0.2,1.7,0.2,2.6,0.2c0.9,0.4,1.5,1.3,1.5,2.3v2.3l0.9,0.9c0.3,0.3,0.5,0.7,0.6,1c1.1,0.3,1.8,1.3,1.8,2.4v0.8h9.1
	c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h3.3c0.4-1,1.3-1.7,2.4-1.7h2.6c0.4-1,1.3-1.7,2.4-1.7h3.3c0.3,0,0.5,0,0.8,0.1l4.6,1.5h2.9
	c1.1,0,2,0.7,2.4,1.7h1c1.4,0,2.5,1.1,2.5,2.5v1.7c0,0.7-0.3,1.3-0.7,1.8l-3.3,3.3c-0.3,0.3-0.6,0.5-1,0.6c-1,0.7-1.8,1.6-2.3,2.7
	l-0.9,0.9v7.3c0,1.4-1.1,2.5-2.5,2.5h-8.3c-0.7,0-1.3-0.3-1.8-0.7l-0.7-0.7v0.6c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v2.3
	c0,0.3,0,0.5-0.1,0.8l-1.6,4.6v1.3c0,0.7-0.3,1.3-0.7,1.8l-2.6,2.6v0.6c0,1.4-1.1,2.5-2.5,2.5h-5.1l-1,1.9c-0.2,0.5-0.6,0.9-1.1,1.1
	l-6.6,3.3c-0.3,0.2-0.7,0.3-1.1,0.3h-8.3c-0.4,0-0.8-0.1-1.2-0.3l-1.4,2.2l-1.3,5c0.9,0.4,1.4,1.3,1.4,2.3v19.9
	c0,1.4-1.1,2.5-2.5,2.5h-1.4l-1.1,4.5v1.4c0,0.4-0.1,0.8-0.3,1.1l-1.4,2.8v2.7c0,0.7-0.3,1.3-0.7,1.8l-1.6,1.7
	c-0.5,0.5-1.1,0.7-1.8,0.7h-1.7c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9h-3.1v0.8c0,1.1-0.7,2-1.7,2.4v4.3c0,1.1-0.7,2-1.7,2.4l-3.3,1.1
	v2.2l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v1c1,0.4,1.6,1.3,1.6,2.4v0.6l0.9,0.9c0.7,1.2,1.5,2.4,2.4,3.4v0.6l0.2,0.2h0.6
	c1,0,1.8,0.5,2.2,1.4l1,1.9h0.1c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h0.7c0.7,0,1.3,0.3,1.8,0.7l1.6,1.7c0.5,0.5,0.7,1.1,0.7,1.8v1.7
	c0,0.7-0.3,1.3-0.7,1.8l-1.7,1.6c-0.3,0.3-0.7,0.5-1,0.6c-0.1,0.4-0.3,0.7-0.6,1l-1.7,1.7c-0.3,0.3-0.6,0.5-0.9,0.6v1
	c0,1.4-1.1,2.5-2.5,2.5h-1c0.1,0.3,0.1,0.5,0.1,0.8v1.7c0,0.7-0.3,1.3-0.7,1.8l-1.5,1.5c0.4,0.5,0.6,1.1,0.6,1.7
	c0,0.2,0,0.4-0.1,0.6l1,1c0.5,0.5,0.7,1.1,0.7,1.8v0.6l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v1.7c-0.9,0.7-1.5,1.7-1.9,2.8l-1.7,3.3
	c-0.3,0.6-0.8,1-1.4,1.2v5.9c0,1.4-1.1,2.5-2.5,2.5h-0.6l-0.2,0.2v2.3c0,1.1-0.7,2-1.7,2.4v3.7l0.7,1.4h5.1c1.4,0,2.5,1.1,2.5,2.5
	v3.3c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v4c0,0.7-0.3,1.3-0.7,1.8l-2.6,2.6v0.6c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v0.6
	c0,1.4-1.1,2.5-2.5,2.5h-5c-1.1,0-2-0.7-2.4-1.7h-4.3c-1.4,0-2.5-1.1-2.5-2.5v-0.1l-1.9-1c-0.9-0.4-1.4-1.3-1.4-2.2v-1.1l-1.4-2.8
	c-0.1-0.1-0.1-0.3-0.2-0.5c-0.3,0.1-0.6,0.2-0.9,0.2h-0.6l-1.9,1.9v3.9c0,0.3,0,0.6-0.1,0.8c0.1,0.3,0.1,0.5,0.1,0.8v5
	c0,1.4-1.1,2.5-2.5,2.5h-11.6c-1.1-0.9-2.2-1.7-3.4-2.4l-0.8-0.8c0.1,0.2,0.1,0.5,0.1,0.7v8.3c0,1.1-0.7,2-1.7,2.3v1.6l0.9,0.9
	c0.5,0.5,0.7,1.1,0.7,1.8v2.3l0.9,0.9c0.3,0.3,0.5,0.6,0.6,1c1.3,1.2,2.5,2.5,3.4,4v1c0.7,0.3,1.3,0.9,1.6,1.6
	c1.3,1.2,2.5,2.5,3.4,4v8.3c0,1.1-0.7,2-1.7,2.4v2.6c0,1.4-1.1,2.5-2.5,2.5h-4.3c-0.4,1-1.3,1.7-2.4,1.7h-7.6
	c-0.3,0.7-0.8,1.3-1.5,1.5v2.6c0,0.7-0.3,1.3-0.7,1.8l-3.3,3.3c-1.2,0.7-2.4,1.5-3.4,2.4h-4.3c0.1,0.3,0.1,0.5,0.1,0.8v2.3l0.2,0.2
	h0.6c1.4,0,2.5,1.1,2.5,2.5v0.6l0.9,0.9c0.7,1.2,1.5,2.4,2.4,3.4v1.7c-0.9,1.1-1.7,2.2-2.4,3.4l-0.9,0.9v2.3c0,1.4-1.1,2.5-2.5,2.5
	h-1c-0.4,1-1.3,1.7-2.4,1.7h-10c-1.1,0-2-0.7-2.4-1.7h-1.6l-0.9,0.9c-0.3,0.3-0.6,0.5-0.9,0.6v4.3c-0.2,0.5-0.2,1.1,0,1.7v3.3
	c-0.9,1.8-2.4,3.2-4.2,4.2h-7.6C166.3,614.4,165.4,615.1,164.4,615.1z M161.8,610.1h0.3c0.4-1,1.3-1.6,2.4-1.7h7.6
	c0.1-0.3,0.3-0.6,0.5-0.8c-0.2-0.2-0.3-0.4-0.4-0.7c-0.4-0.9-0.2-2,0.5-2.7l0.9-0.9v-5.6c0-1.4,1.1-2.5,2.5-2.5c0,0,0,0,0,0h0.6
	l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7h5c1.1,0,2,0.7,2.4,1.7h5.3c0.3-1,1.3-1.7,2.4-1.7h0.8v-0.8c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-0.6
	c0.2-0.5,0.2-1.1,0-1.7v-0.6l-0.9-0.9c-0.3-0.3-0.5-0.6-0.6-1c-0.4-0.1-0.7-0.3-1-0.6l-1.7-1.7c-0.3-0.3-0.5-0.7-0.6-1.1
	c-0.3,0.1-0.6,0.2-0.9,0.2h-3.3c-1.4,0-2.5-1.1-2.5-2.5v-5c0-1.4,1.1-2.5,2.5-2.5c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h1.6
	c0.3-1,1.3-1.7,2.4-1.7h4.3c0.4-1,1.3-1.7,2.4-1.7h0.6l1.8-1.8v-4c0.9-1.8,2.4-3.2,4.2-4.2h7.6c0.4-1,1.3-1.7,2.4-1.7h4.1v-2.5
	c0-1.1,0.7-2,1.7-2.3v-3.6c-1-1.1-2.1-2.2-3.2-3.3c-1.1-0.3-1.8-1.3-1.8-2.4v-1c-0.7-0.3-1.3-0.9-1.6-1.6c-1-0.3-1.8-1.3-1.8-2.4
	v-0.6l-0.9-0.9c-0.5-0.5-0.7-1.1-0.7-1.8v-2.3l-0.9-0.9c-0.5-0.5-0.7-1.1-0.7-1.8v-5c0-1.1,0.7-2,1.7-2.4v-3.6
	c-1-0.4-1.7-1.3-1.7-2.3v-3.3c0-1.4,1.1-2.5,2.5-2.5h5c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h0.6c1.1,0,2,0.7,2.4,1.7h6.8v-0.1
	c-1.3-0.5-2-1.9-1.5-3.2c0.1-0.3,0.3-0.7,0.6-0.9l0.9-0.9v-3.9c0-0.7,0.3-1.3,0.7-1.8l3.3-3.3c0.5-0.5,1.1-0.7,1.8-0.7h0.6l0.9-0.9
	c1-1,2.6-1,3.5,0l1.7,1.7c0.5,0.5,0.7,1.1,0.7,1.8v1.1l1.4,2.8c0.2,0.3,0.3,0.7,0.3,1.1v0.1l1.9,1c0.6,0.3,1,0.8,1.2,1.4h4.3
	c1.1,0,2,0.7,2.4,1.7h0.3c0.1-0.3,0.3-0.7,0.6-0.9l0.9-0.9v-0.6c0-0.7,0.3-1.3,0.7-1.8l2.6-2.6v-3.9c0-0.7,0.3-1.3,0.7-1.8l0.7-0.7
	h-3.9c-0.9,0-1.8-0.5-2.2-1.4l-1.7-3.3c-0.2-0.3-0.3-0.7-0.3-1.1v-6.6c0-1.1,0.7-2,1.7-2.4v-1c0-0.7,0.3-1.3,0.7-1.8l1.6-1.7
	c0.3-0.3,0.6-0.5,0.9-0.6v-6c0-1.4,1.1-2.5,2.5-2.5h0.1l0.7-1.4v-1.1c0.8-0.9,0.8-2.4,0-3.3v-0.6L263,464h-0.6
	c-1.4,0-2.5-1.1-2.5-2.5c0-0.6,0.2-1.2,0.6-1.7c-0.4-0.5-0.6-1-0.6-1.7v-1.7c0-1.4,1.1-2.5,2.5-2.5h0.6l-0.7-0.7c-1-1-1-2.6,0-3.5
	l1.7-1.7c0.5-0.5,1.1-0.7,1.8-0.7h0.8v-0.8c0-1.4,1.1-2.5,2.5-2.5h0.6l0.2-0.2v-0.6c0-1.3,1-2.4,2.3-2.5c-0.6-0.1-1.1-0.3-1.5-0.7
	l-0.9-0.9H269c-1,0-1.8-0.5-2.2-1.4l-1-1.9h-0.1c-0.7,0-1.3-0.3-1.8-0.7l-1.6-1.6c-0.7-1.2-1.5-2.4-2.4-3.4v-0.6l-0.9-0.9
	c-0.3-0.3-0.5-0.6-0.6-1c-1-0.3-1.7-1.3-1.8-2.4v-2.3l-0.9-0.9c-0.5-0.5-0.7-1.1-0.7-1.8v-5c0-1.1,0.7-2,1.7-2.4l3.3-1.1v-4.8
	c0-1.1,0.7-2,1.7-2.4v-1c0-1.4,1.1-2.5,2.5-2.5h6.6c0.7,0,1.3,0.3,1.8,0.7l0.7,0.7V400c0-0.4,0.1-0.8,0.3-1.1l1.4-2.8v-1
	c0-0.2,0-0.4,0.1-0.6l1.7-6.6c0.3-1.1,1.3-1.9,2.5-1.9h0.8v-15.1c-1-0.4-1.7-1.3-1.7-2.4v-1.7c0-0.2,0-0.4,0.1-0.6l1.7-6.6
	c0.1-0.3,0.2-0.5,0.4-0.8l3.3-5c0.5-0.7,1.2-1.1,2.1-1.1h1.7c1.1,0,2,0.7,2.4,1.7h5.3l5.4-2.7l1.3-2.6c0.4-0.8,1.3-1.4,2.2-1.4h4.3
	c0.1-0.3,0.3-0.7,0.6-0.9l2.6-2.6v-0.6c0-0.3,0-0.5,0.1-0.8l1.5-4.6v-2.9c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9V332c0-0.7,0.3-1.3,0.7-1.8
	l1.7-1.7c0.5-0.5,1.1-0.7,1.8-0.7h1.6c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h4.8v-5.8c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-0.6
	c0.9-1.8,2.4-3.2,4.2-4.2h0.6l0.1-0.1c-0.7-0.3-1.2-0.8-1.5-1.5h-1c-0.3,0-0.5,0-0.8-0.1l-4.6-1.5h-0.6c-0.4,1-1.3,1.7-2.4,1.7h-2.6
	c-0.4,1-1.3,1.7-2.4,1.7h-6.6c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9h-10.6c-1.4,0-2.5-1.1-2.5-2.5c0,0,0,0,0,0v-1c-1-0.4-1.7-1.3-1.7-2.4
	v-0.6l-0.9-0.9c-0.3-0.3-0.5-0.6-0.6-1l-0.2-0.1c-0.6-0.3-1.2-0.8-1.4-1.5H288c-0.4,1-1.3,1.7-2.4,1.6h-1c-1.1,1.1-2.2,2.1-3.3,3.2
	c-0.3,1.1-1.3,1.8-2.4,1.8h-3.3c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9h-0.6c-0.7,0-1.3-0.3-1.8-0.7l-3.3-3.3c-0.2-0.2-0.4-0.5-0.6-0.9
	l-0.1,0.1v0.6c0,1.1-0.7,2-1.7,2.4v1c0,1.1-0.7,2-1.7,2.4v1c0,1.4-1.1,2.5-2.5,2.5c0,0,0,0,0,0h-6.2l-2.9,1
	c-0.1,1.3-1.2,2.4-2.5,2.4h-1c-0.5,1.3-1.9,2-3.2,1.5c-0.3-0.1-0.7-0.3-0.9-0.6l-0.7-0.7l-0.7,0.7c-0.3,0.3-0.6,0.5-1,0.6l-5,1.7
	h-0.1c-0.3,1.1-1.3,1.8-2.4,1.8h-6.6c-0.7,0-1.3-0.3-1.8-0.7l-2.6-2.6h-0.6c-1.3-0.9-2.5-1.9-3.8-2.8l-0.4,0.2v0.7l2.6,2.6
	c0.3,0.3,0.5,0.6,0.6,1c1.1,0.3,1.8,1.3,1.8,2.4v0.6l0.9,0.9c0.4,0.5,0.7,1.1,0.7,1.7v4l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v6.6
	c0,1.1-0.7,2-1.6,2.4v2.6c0,0.7-0.3,1.3-0.7,1.8l-1.7,1.7c-0.5,0.5-1.1,0.7-1.8,0.7h-6.6c-0.4,0-0.7-0.1-1.1-0.2
	c0.1,0.1,0.1,0.2,0.1,0.3c0.8,0.2,1.4,0.8,1.6,1.6h1c1.4,0,2.5,1.1,2.5,2.5c0,0,0,0,0,0v3.3c0,1.4-1.1,2.5-2.5,2.5h-2.6
	c-0.4,1-1.3,1.7-2.4,1.7h-2.6c-0.1,0.3-0.3,0.7-0.6,0.9l-1.7,1.6c-0.5,0.5-1.1,0.7-1.8,0.8h-6.6c-1.1,0-2-0.7-2.4-1.7h-2.6
	c-1.4,0-2.5-1.1-2.5-2.5v-1.1l-1.4-2.8c-0.1-0.1-0.1-0.3-0.1-0.4c-0.4-0.1-0.7-0.3-1-0.6l-0.9-0.9H186c-0.7,0-1.3-0.3-1.8-0.7
	l-0.9-0.9h-0.6c-1.4,0-2.5-1.1-2.5-2.5v-0.6l-0.7,0.7c-0.5,0.5-1.1,0.7-1.8,0.7h-2.6c-0.4,1-1.3,1.6-2.4,1.6h-0.6l-0.2,0.2v0.6
	c0,1.1-0.7,2.1-1.8,2.4c-0.2,0.8-0.8,1.3-1.6,1.6v2.6c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v0.6c0,1.1-0.7,2-1.6,2.4v1
	c0,1.1-0.7,2.1-1.8,2.4c-0.2,0.8-0.8,1.4-1.6,1.6v1c0,0.7-0.3,1.3-0.7,1.8l-2.6,2.6v1.6c1,0.4,1.7,1.3,1.7,2.4v1
	c0.7,0.3,1.3,0.9,1.6,1.6c1.1,0.3,1.8,1.3,1.8,2.4v0.6l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v2.6c0.3,0.1,0.6,0.3,0.8,0.5
	c0.5-0.4,1-0.6,1.7-0.6h6.6c1.1,0,2.1,0.7,2.4,1.8c0.3-0.1,0.6-0.2,0.9-0.2h1.7c1.4,0,2.5,1.1,2.5,2.5v1c0.3,0.1,0.7,0.3,0.9,0.6
	l3.3,3.3c0.3,0.3,0.5,0.6,0.6,1c1.1,0.3,1.8,1.3,1.8,2.4v8.3c0,1.4-1.1,2.5-2.5,2.5h-6.6c-1.1,0-2-0.7-2.4-1.7h-7.6
	c-0.3,0-0.6,0-0.8-0.1v0.3c0.7,0.3,1.3,0.8,1.5,1.5h1c1.4,0,2.5,1.1,2.5,2.5c0,0.3,0,0.5-0.1,0.8c0.1,0.3,0.1,0.5,0.1,0.8v1.6
	c0,1.1-0.7,2-1.7,2.4v3.6c1,0.3,1.7,1.3,1.7,2.4v4.3c1.3,1.2,2.4,2.5,3.3,4v2.6c0.7,0.2,1.2,0.8,1.5,1.4c0.4,0.9,0.2,1.9-0.4,2.6
	c0.4,0.5,0.6,1,0.6,1.7v2.3l0.9,0.9c1,1,1,2.6,0,3.5l-0.7,0.7l1.6,1.6h2.3c0.7,0,1.3,0.3,1.8,0.7l1.7,1.6c1,1,1,2.6,0,3.5
	c-0.3,0.3-0.6,0.5-0.9,0.6v1c0,1.4-1.1,2.5-2.5,2.5h-9.9c-1.4,0-2.5-1.1-2.5-2.5v-2.3l-1.9-1.9h-0.6c-1.5-0.9-2.8-2.1-4-3.4
	c-0.8-0.2-1.4-0.9-1.7-1.7c-0.8-0.2-1.4-0.8-1.6-1.5h-2.6c-0.3,0-0.6,0-0.8-0.1c-0.3,0.1-0.5,0.2-0.8,0.1h-0.6l-0.2,0.2v4
	c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v1.6c1,0.4,1.7,1.3,1.7,2.4v5c0,0.7-0.3,1.3-0.7,1.8L153,470c-0.5,0.5-1.1,0.7-1.8,0.7h-5
	c-1.1,0-2-0.7-2.4-1.7h-1.7l-0.9,0.9c-0.3,0.3-0.7,0.5-1.1,0.6c-0.2,0.8-0.9,1.4-1.7,1.6c-0.2,0.8-0.8,1.4-1.5,1.6v1
	c0,1.4-1.1,2.5-2.5,2.5h-1c-0.3,0.7-0.9,1.3-1.6,1.5c-0.3,1.1-1.3,1.8-2.4,1.8H127v0.8c0,1.1-0.7,2.1-1.8,2.4
	c-0.2,0.8-0.8,1.4-1.5,1.6v6.4l0.9,0.9c0.3,0.3,0.5,0.6,0.6,1c1.1,0.3,1.8,1.3,1.8,2.4v2.3l0.2,0.2h2.3c1.1,0,2.1,0.7,2.4,1.8
	c1.1,0.3,1.8,1.3,1.8,2.4v0.6l0.7-0.7c0.7-0.7,1.7-0.9,2.6-0.6c0.3-0.1,0.5-0.1,0.8-0.1h1.7c1.1,0,2,0.7,2.4,1.7h1.6l0.9-0.9
	c0.7-0.7,1.7-0.9,2.6-0.6c0.3-0.1,0.6-0.1,0.8-0.1h0.6l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7h5.6l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7h1.7
	c1.1,0,2.1,0.7,2.4,1.8c1.3,0.4,2.1,1.8,1.7,3.1c0,0,0,0.1,0,0.1c0.3,0.9,0.1,1.9-0.6,2.6L162,509c1,0.4,1.6,1.3,1.6,2.3v1.7
	c0,1.1-0.7,2.1-1.8,2.4c-0.2,0.8-0.9,1.4-1.7,1.7c-0.2,0.8-0.9,1.4-1.7,1.7c-0.1,0.4-0.3,0.7-0.6,1l-1.7,1.7c-0.3,0.3-0.6,0.5-1,0.6
	c-0.1,0.4-0.3,0.7-0.6,1l-0.9,0.9v0.6c0,1.4-1.1,2.5-2.5,2.5h-5c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9h-2.3c-0.7,0-1.3-0.3-1.8-0.7
	l-0.9-0.9H137v1.8c1,0.4,1.6,1.3,1.6,2.3v1.7c0,1.4-1.1,2.5-2.5,2.5h-0.9c-0.4,1-1.3,1.7-2.4,1.7h-2.6c-0.4,1-1.3,1.7-2.4,1.7h-1.7
	c-0.3,0-0.6,0-0.8-0.1h-0.1c-0.2,0.8-0.9,1.4-1.7,1.7c-0.2,0.8-0.9,1.4-1.7,1.7c-0.2,0.8-0.8,1.4-1.6,1.6v2.6c0,1.4-1.1,2.5-2.5,2.5
	c0,0,0,0,0,0h-1c-0.3,1-1.2,1.7-2.3,1.7h-0.8v0.8c0,1.1-0.7,2.1-1.8,2.4c-0.2,0.8-0.9,1.4-1.7,1.7c-0.2,0.8-0.8,1.4-1.6,1.6v1
	c0,1.1-0.7,2.1-1.8,2.4c-0.1,0.3-0.3,0.7-0.5,0.9c0.4,0.5,0.6,1,0.6,1.7v0.6l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v3.9l2.6,2.6
	c0.3,0.3,0.5,0.6,0.6,0.9h4.3c1.1,0,2.1,0.7,2.4,1.8c1,0.3,1.8,1.3,1.8,2.4v1.7c0,1.1-0.7,2-1.7,2.3v1c0,1.1-0.7,2-1.7,2.4v9.2
	c0,0.3,0,0.6-0.1,0.8v0.1c0.8,0.2,1.4,0.9,1.7,1.7c1,0.3,1.8,1.3,1.8,2.4v0.6l0.8,0.8c0.5-0.4,1-0.6,1.7-0.6h2.6
	c0.4-1,1.3-1.7,2.3-1.7h2.5V591c0-1.1,0.7-2.1,1.8-2.4c0.3-1.1,1.3-1.8,2.4-1.8h1c0.4-1,1.3-1.7,2.4-1.7h5c1.1,0,2.1,0.7,2.4,1.8
	c0.8,0.2,1.4,0.8,1.6,1.5h9.3c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h0.6c1.4,0,2.5,1.1,2.5,2.5v1.7c0,1.1-0.7,2-1.7,2.4v0.3
	c1,0.4,1.7,1.3,1.7,2.3v3.3c0,1.4-1.1,2.5-2.5,2.5h-0.8v0.8c0,0.3,0,0.6-0.1,0.8c0.2,0.5,0.2,1.1,0,1.7h0.9
	C160.5,608.5,161.4,609.1,161.8,610.1L161.8,610.1z M90.4,560.3h1c0.6,0,1.2,0.2,1.7,0.6c0.5-0.4,1-0.6,1.7-0.6h6
	c0.1-0.3,0.3-0.6,0.5-0.8c-0.4-0.5-0.6-1-0.6-1.7v-0.6l-0.9-0.9c-0.5-0.5-0.7-1.1-0.7-1.8v-1.7c0-1.1,0.7-2.1,1.8-2.4v-0.1
	c-0.1-0.3-0.1-0.6-0.1-0.8v-3.9l-0.9-0.9c-0.3-0.3-0.5-0.6-0.6-1c-0.8-0.2-1.4-0.9-1.7-1.7c-0.8-0.2-1.4-0.9-1.7-1.7
	c-1.1-0.3-1.8-1.3-1.8-2.4v-1c-1-0.4-1.7-1.3-1.7-2.4v-4l-0.8-0.8c-0.5,0.4-1,0.6-1.7,0.6H88c-0.6,0-1.2-0.2-1.7-0.6
	c-0.2,0.2-0.4,0.3-0.7,0.4c-0.9,0.4-2,0.2-2.7-0.5l-1.6-1.7c-0.3-0.3-0.5-0.6-0.6-0.9h-0.1v0.8c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v3.9
	c0,1.1-0.7,2-1.7,2.4v1c0,1.1-0.7,2-1.7,2.4v0.3c0.7,0.3,1.3,0.9,1.6,1.6c0.4,0.1,0.7,0.3,1,0.6l3.3,3.3c0.3,0.3,0.5,0.6,0.6,1
	c1,0.3,1.8,1.3,1.8,2.4v0.6l0.9,0.9c0.3,0.3,0.5,0.6,0.6,1c1.1,0.3,1.8,1.3,1.8,2.4v4.2H88C89.1,558.6,90.1,559.3,90.4,560.3
	L90.4,560.3z M211.6,347.9c0.1,0,0.2,0.1,0.3,0.1c0-0.1-0.1-0.1-0.1-0.2L211.6,347.9L211.6,347.9z" />
		<Tippy
			followCursor={true}
			plugins={[followCursor]}
			className={classes.tippy}
			hideOnClick={false}
			content={<span className={classes.tippyContent}>Gandhinagar North</span>}
		>
			<polygon
				// class="st0" 
				onClick={(e) => props.clicked(e.target)}
				onMouseEnter={(e) => props.entered(e.target)}
				onMouseLeave={(e) => props.left(e.target)}
				id="Gandhinagar-North"
				fill="#FCFCFC"
				points="330.4,332 330.4,330.4 332.1,330.4 333.8,330.4 335.4,332 337.1,332 338.7,330.4 340.4,327 
	343.7,328.7 345.4,330.4 348.7,332 352,332 353.7,330.4 355.3,327 357,325.4 358.6,323.7 360.3,325.4 362,325.4 363.6,325.4 
	365.3,327 367,328.7 370.3,332 373.6,330.4 375.3,328.7 378.6,327 380.2,327 381.9,327 385.2,327 386.9,325.4 388.5,327 391.9,327 
	395.2,327 396.9,327 398.5,328.7 400.2,328.7 400.2,327 401.8,327 405.1,327 406.8,325.4 406.8,327 408.5,327 410.1,327 
	410.1,325.4 410.1,327 411.8,328.7 413.5,328.7 415.1,328.7 415.1,327 416.8,327 416.8,328.7 418.4,328.7 421.8,328.7 423.4,330.4 
	423.4,332 425.1,332 425.1,330.4 425.1,328.7 426.7,328.7 426.7,330.4 426.7,328.7 428.4,328.7 428.4,330.4 428.4,332 428.4,333.7 
	426.7,333.7 426.7,335.3 428.4,335.3 428.4,337 430.1,337 431.7,337 431.7,338.6 433.4,338.6 433.4,340.3 433.4,338.6 433.4,340.3 
	435,340.3 435,342 435,343.6 433.4,343.6 433.4,345.3 431.7,348.6 430.1,350.3 430.1,351.9 428.4,351.9 428.4,353.6 428.4,355.3 
	426.7,355.3 426.7,356.9 425.1,358.6 425.1,360.2 423.4,360.2 423.4,361.9 423.4,363.6 421.8,363.6 421.8,365.2 421.8,366.9 
	420.1,366.9 420.1,368.5 420.1,370.2 420.1,371.9 418.4,373.5 418.4,375.2 418.4,376.8 418.4,378.5 418.4,380.2 418.4,381.8 
	418.4,383.5 418.4,386.8 418.4,393.4 418.4,395.1 418.4,396.8 418.4,398.4 418.4,400.1 416.8,400.1 416.8,401.7 415.1,401.7 
	415.1,403.4 413.5,403.4 411.8,403.4 410.1,405.1 408.5,405.1 406.8,406.7 405.1,406.7 405.1,408.4 403.5,410 403.5,411.7 
	401.8,411.7 411.8,413.4 410.1,441.6 410.1,449.9 408.5,458.2 403.5,468.2 391.9,483.1 390.2,488.1 376.9,486.4 360.3,486.4 
	357,486.4 355.3,486.4 343.7,484.8 345.4,474.8 345.4,468.2 343.7,468.2 342.1,468.2 340.4,468.2 340.4,466.5 338.7,466.5 
	338.7,464.8 337.1,463.2 335.4,463.2 335.4,461.5 335.4,459.9 333.8,458.2 333.8,456.5 332.1,456.5 332.1,454.9 332.1,453.2 
	330.4,451.5 332.1,451.5 332.1,449.9 330.4,448.2 330.4,446.6 330.4,444.9 330.4,443.3 328.8,443.3 328.8,441.6 328.8,439.9 
	328.8,438.3 327.1,436.6 327.1,434.9 325.5,433.3 325.5,431.6 323.8,431.6 322.1,431.6 320.5,431.6 320.5,430 318.8,430 317.1,430 
	317.1,431.6 315.5,431.6 313.8,431.6 312.2,431.6 310.5,431.6 308.9,431.6 308.9,430 308.9,428.3 307.2,428.3 307.2,426.6 
	305.5,426.6 303.9,425 302.2,423.3 300.5,423.3 300.5,421.7 300.5,420 302.2,420 300.5,418.3 300.5,416.7 298.9,416.7 298.9,418.3 
	297.2,418.3 297.2,416.7 295.6,416.7 293.9,416.7 292.3,416.7 290.6,416.7 290.6,415 290.6,413.4 288.9,411.7 287.3,410 
	287.3,408.4 285.6,408.4 284,408.4 284,406.7 282.3,406.7 280.6,408.4 279,405.1 279,406.7 277.3,406.7 275.6,406.7 274,406.7 
	269,403.4 270.7,403.4 272.3,405.1 274,405.1 275.6,403.4 275.6,401.7 275.6,400.1 277.3,396.8 277.3,395.1 279,388.5 280.6,388.5 
	282.3,388.5 282.3,385.1 282.3,383.5 282.3,381.8 282.3,378.5 282.3,376.8 282.3,375.2 282.3,373.5 282.3,371.9 282.3,370.2 
	282.3,368.5 280.6,368.5 280.6,366.9 282.3,360.2 285.6,355.3 287.3,355.3 287.3,356.9 292.3,356.9 295.6,356.9 298.9,355.3 
	302.2,353.6 303.9,350.3 310.5,350.3 310.5,348.6 312.2,347 313.8,345.3 313.8,343.6 315.5,338.6 315.5,335.3 317.1,333.7 
	317.1,332 318.8,330.4 320.5,330.4 322.1,332 328.8,332 " />
		</Tippy>
		<path class="st1" d="M390.2,490.6h-0.3l-13.1-1.6h-21.4c-0.1,0-0.2,0-0.4,0l-11.6-1.7c-0.7-0.1-1.3-0.5-1.7-1
	c-0.4-0.5-0.6-1.2-0.5-1.9l1.6-9.8v-3.9h-2.5c-1.8-0.9-3.2-2.4-4.1-4.2v-0.6l-0.2-0.2h-0.6c-1.4,0-2.5-1.1-2.5-2.5v-2.3L332,460
	c-0.3-0.3-0.5-0.6-0.6-1c-1.1-0.3-1.8-1.3-1.8-2.4v-2.3l-0.9-0.9c0.3-1.7,0.1-3.5-0.7-5.1v-2.7c-1-0.4-1.7-1.3-1.6-2.4v-3.9
	l-0.9-0.9c-0.5-0.5-0.7-1.1-0.7-1.8V436l-0.9-0.9c-0.3-0.3-0.5-0.6-0.6-0.9h-2.6c-0.6,0-1.2-0.2-1.7-0.6c-0.5,0.4-1.1,0.6-1.7,0.6
	h-8.3c-1.4,0-2.5-1.1-2.5-2.5v-1c-0.8-0.3-1.3-0.9-1.6-1.6c-0.4-0.1-0.7-0.3-1-0.6l-2.6-2.6h-0.6c-1.4,0-2.5-1.1-2.5-2.5v-2.5h-0.8
	c-1.1,0-2-0.7-2.4-1.7h-4.3c-1.4,0-2.5-1.1-2.5-2.5c0,0,0,0,0,0v-2.2l-2.6-2.6c-0.3-0.3-0.5-0.6-0.6-0.9h-1c-0.6,0-1.2-0.2-1.7-0.6
	c-1,0.9-2.6,0.8-3.5-0.2c-0.1-0.2-0.3-0.3-0.4-0.5l-0.1-0.3H274c-0.5,0-1-0.1-1.4-0.4l-5-3.3c-1.1-0.8-1.5-2.3-0.7-3.5
	c0.5-0.7,1.2-1.1,2.1-1.1h1.7c0.7,0,1.3,0.3,1.8,0.7l0.7,0.7v-2.3c0-0.4,0.1-0.8,0.3-1.1l1.4-2.8v-1.1c0-0.2,0-0.4,0.1-0.6l1.7-6.6
	c0.3-1.1,1.3-1.9,2.5-1.9h0.8v-15.1c-1-0.4-1.7-1.3-1.7-2.4v-1.7c0-0.2,0-0.4,0.1-0.6l1.7-6.6c0.1-0.3,0.2-0.5,0.4-0.8l3.3-5
	c0.5-0.7,1.2-1.1,2.1-1.1h1.7c1.1,0,2,0.7,2.4,1.7h5.3l5.4-2.7l1.3-2.6c0.4-0.8,1.3-1.4,2.2-1.4h4.3c0.1-0.3,0.3-0.7,0.6-0.9
	l2.6-2.6v-0.6c0-0.3,0-0.5,0.1-0.8l1.5-4.6v-2.9c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9V332c0-0.7,0.3-1.3,0.7-1.8l1.7-1.7
	c0.5-0.5,1.1-0.7,1.8-0.7h1.6c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h4.9c0.4-1,1.3-1.7,2.4-1.7h3.3c0.7,0,1.3,0.3,1.8,0.7l0.7,0.7l0.4-0.4
	l1.5-3c0.3-0.6,0.8-1.1,1.4-1.3c0.6-0.2,1.3-0.2,1.9,0.1l3.3,1.7c0.2,0.1,0.5,0.3,0.7,0.5l1.4,1.4l2.4,1.2h1.7l0.6-0.6l1.5-3
	c0.1-0.2,0.3-0.5,0.5-0.6l3.3-3.3c0.5-0.5,1.1-0.7,1.8-0.7l0,0c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h2.3c0.7,0,1.3,0.3,1.8,0.7l5.4,5.4
	l1.3-0.7l1.4-1.4c0.2-0.2,0.4-0.3,0.6-0.5l3.3-1.7c0.3-0.2,0.7-0.3,1.1-0.3h5.6l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7l0,0
	c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h7.3c0.6,0,1.2,0.2,1.6,0.6c0.5-0.4,1-0.6,1.7-0.6h4l0.9-0.9c0.7-0.7,1.8-0.9,2.7-0.5
	c0.5,0.6,1.5,0.7,2.1,0.1c0.1-0.1,0.2-0.2,0.3-0.3c1.4,0,2.5,1.1,2.5,2.5v0.7l0.1,0.1c0.4-1,1.3-1.6,2.3-1.6h1.7
	c1.1,0,2,0.7,2.4,1.7h2.6c0.6,0,1.2,0.2,1.7,0.6c0.5-0.4,1-0.6,1.7-0.6h3.3c1.4,0,2.5,1.1,2.5,2.5v5c0,0.3,0,0.6-0.1,0.8h1
	c2.4,1.5,4.4,3.4,5.8,5.8v3.3c0,1.1-0.7,2.1-1.8,2.4c0,0.1-0.1,0.3-0.2,0.4l-1.7,3.3c-0.1,0.2-0.3,0.5-0.5,0.6l-0.9,0.9v0.6
	c0,1.1-0.7,2-1.7,2.4v1c-0.9,1.1-1.7,2.2-2.4,3.4l-0.9,0.9v0.6c0,1.1-0.7,2-1.7,2.4v1c0,1.1-0.7,2-1.7,2.4v1c0,1.1-0.7,2-1.7,2.4
	v2.6c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v25.5c-1.5,2.4-3.4,4.4-5.8,5.8h-2.3l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-0.6l-0.9,0.9
	c-0.3,0.3-0.7,0.5-1,0.6c-0.1,0.3-0.3,0.7-0.5,0.9l5.2,0.9c1.3,0.2,2.2,1.3,2.1,2.6l-1.7,28.1v8.2c0,0.2,0,0.3,0,0.5l-1.7,8.3
	c0,0.2-0.1,0.4-0.2,0.6l-5,10c-0.1,0.1-0.2,0.3-0.3,0.4l-11.4,14.6l-1.5,4.6C392.2,490,391.3,490.6,390.2,490.6z M355.5,483.9h21.4
	c0.1,0,0.2,0,0.3,0l11.3,1.4l1-3c0.1-0.3,0.2-0.5,0.4-0.8l11.5-14.7l4.7-9.5l1.5-7.7v-8.2l1.5-26l-7.8-1.3c-1.4-0.2-2.3-1.5-2.1-2.8
	c0.2-1,0.8-1.7,1.8-2c0.1-0.4,0.3-0.7,0.6-1l0.9-0.9v-0.6c0-1.4,1.1-2.5,2.5-2.5h0.6l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7h0.6l0.9-0.9
	c0.5-0.5,1.1-0.7,1.8-0.7h1c0.3-0.7,0.9-1.3,1.6-1.5c0.2-0.8,0.8-1.4,1.5-1.6v-24.2c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-3.9
	c0-1.1,0.7-2,1.7-2.4v-1c0-1.1,0.7-2,1.7-2.4v-1c0-1.1,0.7-2.1,1.8-2.4c0.1-0.4,0.3-0.7,0.6-1l0.9-0.9v-0.6c0-1.1,0.7-2,1.6-2.4v-1
	c0.9-1.1,1.7-2.2,2.4-3.4l1.4-1.4l1.2-2.4v-1.1c0-0.6,0.2-1.2,0.6-1.7c-0.2-0.3-0.4-0.6-0.5-0.9c-0.8-0.2-1.4-0.8-1.6-1.6h-1
	c-1.8-0.9-3.2-2.4-4.1-4.2v-0.8h-0.8c-1.4,0-2.5-1.1-2.5-2.5v-0.6l-0.2-0.2h-3.9c-0.5-0.2-1.1-0.2-1.7,0h-3.3
	c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9h-2.3c-0.3,0-0.6,0-0.8-0.1c-0.3,0.1-0.5,0.1-0.8,0.1h-2.6c-0.4,1-1.3,1.7-2.4,1.7h-1.6
	c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9h-7.3c-0.6,0-1.2-0.2-1.6-0.6c-0.5,0.4-1,0.6-1.7,0.6h-6.1l-2.4,1.2l-1.4,1.4
	c-0.2,0.2-0.4,0.4-0.6,0.5l-3.3,1.6c-1,0.5-2.1,0.3-2.9-0.5l-5.9-5.9h-2.3c-0.6,0-1.2-0.2-1.7-0.6l-1.3,1.3l-1.5,3
	c-0.1,0.2-0.3,0.5-0.5,0.6l-1.7,1.7c-0.5,0.5-1.1,0.7-1.8,0.7h-3.3c-0.4,0-0.8-0.1-1.1-0.3l-3.3-1.7c-0.2-0.1-0.5-0.3-0.6-0.5
	l-1.4-1.4l-0.7-0.4l-0.5,1.1c-0.1,0.2-0.3,0.5-0.5,0.7l-1.7,1.7c-0.5,0.5-1.1,0.7-1.8,0.7h-1.7c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9
	c-0.4,1-1.3,1.6-2.3,1.6h-8.3c-0.7,0-1.3-0.3-1.8-0.7l-0.7-0.7v0.6c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v2.3c0,0.3,0,0.5-0.1,0.8
	l-1.5,4.6v1.3c0,0.7-0.3,1.3-0.7,1.8l-2.6,2.6v0.6c0,1.4-1.1,2.5-2.5,2.5h-5.1l-1,1.9c-0.2,0.5-0.6,0.9-1.1,1.1l-6.6,3.3
	c-0.3,0.2-0.7,0.3-1.1,0.3h-8.3c-0.4,0-0.8-0.1-1.2-0.3l-1.4,2.2l-1.3,5c0.9,0.4,1.4,1.3,1.4,2.3v19.9c0,1.4-1.1,2.5-2.5,2.5h-1.4
	l-1.1,4.5v1.4c0,0.4-0.1,0.8-0.3,1.1l-1.4,2.8v2l0.3-0.1c1.1-0.3,2.3,0.3,2.8,1.3l0.2,0.4c0.3-0.1,0.6-0.2,0.9-0.2h1.8
	c1.1,0,2,0.7,2.4,1.7h1c1.4,0,2.5,1.1,2.5,2.5v0.7l2.6,2.6c0.5,0.5,0.7,1.1,0.7,1.8v0.8h4.1c0.5,0.2,1.1,0.2,1.7,0h1.7
	c1.4,0,2.5,1.1,2.5,2.5v0.6l0.9,0.9c0.9,0.9,1,2.4,0.1,3.4l2.5,2.5h0.6c1.8,0.9,3.2,2.4,4.1,4.2v0.8h3.4c0.3-1,1.3-1.7,2.4-1.7h3.3
	c1.1,0,2,0.7,2.4,1.7h2.6c1.4,0,2.5,1.1,2.5,2.5v0.6l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v0.6l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v2.6
	c1,0.4,1.6,1.3,1.6,2.4v4l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v1.7c-0.2,0.5-0.2,1.1,0,1.7v1c1,0.4,1.7,1.3,1.7,2.4v0.6l0.9,0.9
	c0.5,0.5,0.7,1.1,0.7,1.8v1c0.4,0.1,0.7,0.3,0.9,0.6l1.7,1.7c0.3,0.3,0.5,0.6,0.6,1c0.8,0.2,1.3,0.8,1.6,1.6h2.6
	c1.4,0,2.5,1.1,2.5,2.5c0,0,0,0,0,0v6.6c0,0.1,0,0.3,0,0.4l-1.2,7.4L355.5,483.9z" />
		<Tippy
			followCursor={true}
			plugins={[followCursor]}
			className={classes.tippy}
			hideOnClick={false}
			content={<span className={classes.tippyContent}>Dehgam</span>}
		>
			<polygon
				// class="st0" 
				onClick={(e) => props.clicked(e.target)}
				onMouseEnter={(e) => props.entered(e.target)}
				onMouseLeave={(e) => props.left(e.target)}
				id="Dehgam"
				fill="#FCFCFC"
				points="528,360.2 529.7,360.2 531.3,358.6 534.7,356.9 536.3,355.3 538,353.6 539.6,353.6 541.3,351.9 
	543,350.3 544.6,350.3 544.6,351.9 546.3,351.9 547.9,350.3 547.9,351.9 549.6,351.9 549.6,350.3 551.3,350.3 551.3,348.6 
	552.9,350.3 552.9,351.9 552.9,353.6 554.6,353.6 556.2,353.6 556.2,355.3 557.9,355.3 559.6,355.3 559.6,356.9 561.2,356.9 
	561.2,358.6 562.9,358.6 562.9,360.2 562.9,361.9 564.5,361.9 564.5,363.6 566.2,363.6 567.9,363.6 569.5,363.6 569.5,365.2 
	569.5,366.9 571.2,366.9 572.8,368.5 574.5,368.5 574.5,366.9 576.2,368.5 577.8,368.5 579.5,370.2 579.5,368.5 581.1,368.5 
	582.8,368.5 584.5,368.5 586.1,368.5 587.8,368.5 589.4,368.5 589.4,370.2 591.1,370.2 592.8,370.2 594.4,370.2 596.1,371.9 
	596.1,370.2 596.1,368.5 597.7,368.5 597.7,366.9 597.7,365.2 597.7,363.6 597.7,361.9 599.4,361.9 601.1,361.9 602.7,361.9 
	604.4,360.2 606,360.2 607.7,360.2 609.4,360.2 611,360.2 612.7,360.2 614.3,360.2 616,360.2 617.7,360.2 619.3,360.2 621,360.2 
	621,361.9 622.6,361.9 624.3,363.6 626,363.6 627.6,363.6 627.6,365.2 626,365.2 626,366.9 624.3,366.9 622.6,368.5 624.3,368.5 
	624.3,370.2 622.6,371.9 624.3,371.9 624.3,373.5 622.6,375.2 622.6,376.8 622.6,378.5 622.6,380.2 622.6,381.8 622.6,385.1 
	622.6,386.8 622.6,388.5 622.6,390.1 621,390.1 621,388.5 619.3,388.5 617.7,388.5 616,390.1 614.3,390.1 612.7,390.1 611,390.1 
	609.4,390.1 607.7,391.8 606,391.8 604.4,391.8 604.4,390.1 602.7,390.1 601.1,390.1 599.4,390.1 597.7,390.1 596.1,390.1 
	596.1,391.8 594.4,393.4 594.4,395.1 594.4,396.8 596.1,396.8 597.7,396.8 597.7,398.4 599.4,398.4 601.1,400.1 602.7,401.7 
	602.7,403.4 604.4,403.4 606,403.4 607.7,403.4 609.4,403.4 611,403.4 611,401.7 614.3,400.1 616,398.4 617.7,398.4 619.3,396.8 
	621,396.8 622.6,395.1 624.3,395.1 626,395.1 629.3,395.1 629.3,396.8 627.6,398.4 627.6,400.1 627.6,401.7 627.6,403.4 
	627.6,405.1 627.6,406.7 627.6,408.4 627.6,410 627.6,411.7 627.6,413.4 626,416.7 626,418.3 626,420 626,421.7 626,423.3 
	627.6,423.3 629.3,426.6 631,428.3 632.6,428.3 632.6,430 632.6,431.6 632.6,434.9 632.6,436.6 632.6,438.3 634.3,438.3 
	634.3,439.9 635.9,439.9 635.9,441.6 635.9,443.3 634.3,443.3 634.3,444.9 635.9,444.9 637.6,443.3 637.6,444.9 639.3,444.9 
	639.3,446.6 639.3,448.2 640.9,448.2 642.6,448.2 644.2,448.2 645.9,448.2 647.5,446.6 649.2,444.9 649.2,443.3 649.2,441.6 
	649.2,439.9 649.2,438.3 649.2,436.6 650.9,436.6 652.5,436.6 652.5,434.9 652.5,436.6 654.2,436.6 654.2,438.3 654.2,439.9 
	654.2,441.6 655.9,441.6 655.9,443.3 655.9,444.9 657.5,444.9 657.5,446.6 657.5,448.2 659.2,448.2 660.8,448.2 662.5,448.2 
	662.5,446.6 664.2,446.6 664.2,444.9 664.2,443.3 664.2,441.6 664.2,439.9 665.8,439.9 665.8,438.3 665.8,436.6 664.2,436.6 
	662.5,436.6 662.5,434.9 662.5,433.3 662.5,431.6 662.5,430 664.2,430 664.2,428.3 665.8,428.3 665.8,430 667.5,430 667.5,431.6 
	669.1,433.3 670.8,433.3 672.5,433.3 672.5,431.6 670.8,430 670.8,428.3 670.8,426.6 672.5,426.6 672.5,425 674.1,425 674.1,423.3 
	674.1,425 675.8,425 675.8,426.6 677.4,426.6 679.1,426.6 680.8,426.6 680.8,425 680.8,423.3 679.1,421.7 677.4,421.7 677.4,420 
	675.8,420 675.8,418.3 674.1,418.3 674.1,416.7 672.5,416.7 670.8,415 670.8,413.4 669.1,413.4 667.5,413.4 665.8,413.4 
	664.2,413.4 662.5,413.4 662.5,411.7 662.5,410 664.2,408.4 665.8,408.4 667.5,408.4 669.1,408.4 670.8,408.4 672.5,408.4 
	674.1,408.4 675.8,408.4 677.4,408.4 679.1,406.7 680.8,406.7 680.8,405.1 682.4,405.1 684.1,405.1 684.1,406.7 685.7,406.7 
	687.4,408.4 689.1,408.4 690.7,408.4 692.4,406.7 694,406.7 694,405.1 695.7,403.4 697.4,401.7 697.4,400.1 699,400.1 699,401.7 
	700.7,401.7 700.7,403.4 702.3,405.1 702.3,406.7 705.7,408.4 709,408.4 710.6,410 715.6,413.4 717.3,413.4 715.6,415 712.3,415 
	710.6,416.7 710.6,418.3 707.3,418.3 704,420 702.3,420 700.7,421.7 700.7,423.3 699,425 699,426.6 697.4,426.6 697.4,428.3 
	697.4,430 695.7,430 695.7,431.6 694,433.3 694,434.9 692.4,436.6 692.4,438.3 690.7,439.9 690.7,441.6 689.1,443.3 689.1,444.9 
	689.1,446.6 689.1,448.2 689.1,449.9 689.1,451.5 687.4,453.2 687.4,456.5 685.7,456.5 685.7,458.2 685.7,459.9 685.7,461.5 
	685.7,463.2 684.1,464.8 682.4,466.5 680.8,468.2 680.8,469.8 680.8,471.5 680.8,473.1 680.8,474.8 680.8,478.1 680.8,479.8 
	680.8,481.4 682.4,481.4 682.4,483.1 680.8,483.1 682.4,484.8 680.8,484.8 680.8,486.4 679.1,488.1 680.8,488.1 682.4,488.1 
	682.4,489.7 684.1,489.7 684.1,491.4 682.4,491.4 682.4,493 680.8,494.7 680.8,496.4 679.1,496.4 679.1,498 679.1,499.7 
	677.4,499.7 677.4,501.4 677.4,503 675.8,504.7 675.8,506.3 675.8,508 675.8,509.7 674.1,511.3 675.8,511.3 675.8,513 679.1,513 
	679.1,514.6 679.1,516.3 680.8,516.3 679.1,518 679.1,519.6 677.4,518 677.4,519.6 675.8,519.6 675.8,521.3 674.1,521.3 
	672.5,521.3 670.8,521.3 669.1,521.3 669.1,522.9 669.1,524.6 667.5,524.6 667.5,526.3 669.1,526.3 670.8,527.9 670.8,529.6 
	674.1,531.2 675.8,531.2 677.4,531.2 679.1,531.2 680.8,531.2 682.4,531.2 684.1,531.2 685.7,531.2 687.4,531.2 689.1,531.2 
	690.7,531.2 694,531.2 697.4,531.2 700.7,531.2 705.7,531.2 707.3,531.2 709,531.2 709,532.9 707.3,531.2 707.3,532.9 705.7,532.9 
	705.7,534.6 705.7,536.2 705.7,537.9 705.7,539.5 707.3,542.9 707.3,544.5 709,544.5 707.3,546.2 707.3,549.5 710.6,551.2 
	714,551.2 715.6,551.2 715.6,549.5 717.3,549.5 717.3,551.2 719,552.8 719,554.5 720.6,556.1 720.6,557.8 720.6,559.5 719,561.1 
	715.6,564.5 714,564.5 712.3,564.5 710.6,566.1 709,566.1 707.3,566.1 705.7,566.1 704,564.5 702.3,562.8 699,561.1 697.4,561.1 
	694,559.5 692.4,559.5 690.7,557.8 689.1,557.8 687.4,557.8 684.1,557.8 682.4,557.8 680.8,557.8 679.1,557.8 679.1,559.5 
	677.4,559.5 675.8,559.5 675.8,561.1 674.1,561.1 672.5,562.8 670.8,564.5 670.8,566.1 669.1,566.1 669.1,567.8 669.1,569.4 
	667.5,571.1 667.5,572.8 667.5,574.4 669.1,576.1 669.1,577.7 669.1,579.4 670.8,579.4 670.8,581 669.1,581 667.5,581 665.8,581 
	664.2,581 662.5,579.4 660.8,579.4 659.2,579.4 657.5,581 659.2,581 659.2,582.7 659.2,584.4 659.2,586 659.2,587.7 659.2,589.3 
	659.2,591 659.2,592.7 659.2,594.3 659.2,596 659.2,597.7 657.5,597.7 657.5,599.3 655.9,599.3 654.2,599.3 652.5,601 650.9,601 
	649.2,601 647.5,602.6 647.5,604.3 647.5,606 647.5,607.6 649.2,609.3 649.2,610.9 650.9,612.6 650.9,614.3 650.9,615.9 
	650.9,617.6 650.9,615.9 649.2,615.9 647.5,615.9 645.9,615.9 642.6,614.3 640.9,614.3 640.9,615.9 639.3,615.9 637.6,615.9 
	637.6,614.3 635.9,614.3 634.3,615.9 634.3,614.3 632.6,614.3 632.6,612.6 632.6,610.9 632.6,612.6 631,612.6 629.3,610.9 
	627.6,610.9 626,610.9 624.3,610.9 622.6,610.9 621,610.9 621,609.3 621,607.6 621,606 619.3,607.6 619.3,609.3 621,609.3 
	621,610.9 621,612.6 619.3,612.6 617.7,612.6 617.7,614.3 616,614.3 616,615.9 617.7,617.6 616,617.6 614.3,617.6 612.7,617.6 
	611,617.6 611,619.2 609.4,619.2 607.7,619.2 606,620.9 604.4,620.9 602.7,620.9 601.1,620.9 601.1,622.6 602.7,622.6 602.7,624.2 
	604.4,624.2 604.4,625.9 604.4,627.5 606,629.2 607.7,632.5 607.7,634.2 606,634.2 604.4,634.2 604.4,635.8 602.7,637.5 
	602.7,639.2 601.1,639.2 602.7,640.8 601.1,642.5 601.1,644.1 601.1,645.8 599.4,645.8 597.7,649.1 597.7,650.8 597.7,652.4 
	597.7,654.1 597.7,655.8 596.1,655.8 594.4,655.8 592.8,655.8 591.1,655.8 591.1,657.4 589.4,657.4 589.4,659.1 587.8,659.1 
	589.4,660.7 587.8,660.7 587.8,662.4 586.1,664.1 584.5,664.1 584.5,665.7 586.1,665.7 584.5,667.4 584.5,669 586.1,669 
	584.5,670.7 582.8,670.7 582.8,669 579.5,669 579.5,670.7 577.8,669 576.2,669 574.5,670.7 574.5,669 572.8,669 572.8,670.7 
	571.2,670.7 571.2,672.4 569.5,672.4 571.2,672.4 569.5,674 571.2,674 569.5,675.7 567.9,675.7 566.2,675.7 564.5,675.7 
	562.9,675.7 559.6,675.7 557.9,675.7 556.2,675.7 554.6,675.7 554.6,674 554.6,672.4 552.9,672.4 551.3,672.4 549.6,672.4 
	549.6,670.7 547.9,670.7 544.6,670.7 543,670.7 541.3,670.7 541.3,672.4 539.6,672.4 538,672.4 539.6,674 541.3,675.7 541.3,677.3 
	543,679 543,680.7 543,682.3 543,684 541.3,685.7 539.6,685.7 538,685.7 536.3,685.7 531.3,685.7 531.3,684 531.3,682.3 
	529.7,682.3 529.7,680.7 528,680.7 528,679 526.3,679 524.7,679 523,679 521.4,677.3 519.7,675.7 519.7,674 519.7,672.4 
	519.7,670.7 521.4,669 521.4,667.4 521.4,665.7 521.4,664.1 519.7,664.1 518,664.1 516.4,664.1 516.4,665.7 514.7,664.1 
	514.7,662.4 514.7,660.7 513.1,659.1 511.4,659.1 509.8,659.1 508.1,659.1 508.1,657.4 506.4,657.4 506.4,655.8 506.4,654.1 
	506.4,652.4 508.1,652.4 509.8,652.4 509.8,650.8 511.4,650.8 509.8,649.1 511.4,649.1 511.4,647.5 513.1,649.1 513.1,647.5 
	514.7,645.8 514.7,644.1 513.1,644.1 511.4,644.1 509.8,644.1 508.1,644.1 506.4,644.1 506.4,645.8 504.8,644.1 503.1,644.1 
	503.1,642.5 501.4,642.5 501.4,640.8 499.8,640.8 499.8,639.2 498.1,639.2 496.5,637.5 494.8,635.8 494.8,634.2 496.5,634.2 
	496.5,632.5 498.1,632.5 498.1,630.9 498.1,629.2 496.5,627.5 496.5,625.9 494.8,625.9 494.8,624.2 493.1,624.2 493.1,622.6 
	491.5,622.6 489.8,620.9 488.2,620.9 486.5,620.9 484.8,620.9 483.2,622.6 481.5,622.6 479.9,620.9 478.2,620.9 476.5,620.9 
	474.9,620.9 473.2,620.9 473.2,619.2 471.6,619.2 469.9,619.2 471.6,615.9 473.2,612.6 473.2,610.9 474.9,609.3 476.5,609.3 
	476.5,607.6 478.2,607.6 478.2,606 479.9,606 479.9,604.3 481.5,604.3 481.5,602.6 481.5,601 481.5,599.3 481.5,597.7 481.5,596 
	479.9,596 479.9,594.3 479.9,592.7 478.2,592.7 478.2,591 476.5,591 476.5,589.3 474.9,589.3 474.9,587.7 473.2,586 473.2,584.4 
	471.6,584.4 471.6,582.7 469.9,582.7 468.2,582.7 466.6,582.7 464.9,582.7 463.3,584.4 461.6,584.4 461.6,586 459.9,586 
	459.9,587.7 455,587.7 453.3,587.7 451.6,587.7 451.6,586 451.6,584.4 451.6,582.7 451.6,581 451.6,579.4 453.3,579.4 453.3,577.7 
	453.3,576.1 450,572.8 450,571.1 450,569.4 450,567.8 448.3,567.8 448.3,566.1 448.3,562.8 448.3,561.1 450,561.1 451.6,562.8 
	453.3,562.8 455,562.8 455,561.1 456.6,561.1 456.6,562.8 458.3,564.5 459.9,564.5 461.6,564.5 463.3,564.5 464.9,566.1 
	466.6,566.1 468.2,564.5 469.9,564.5 469.9,562.8 471.6,562.8 473.2,561.1 474.9,561.1 474.9,559.5 476.5,559.5 476.5,557.8 
	478.2,557.8 478.2,556.1 479.9,556.1 479.9,554.5 481.5,554.5 481.5,552.8 483.2,551.2 484.8,551.2 484.8,549.5 486.5,549.5 
	488.2,549.5 489.8,549.5 491.5,549.5 491.5,551.2 491.5,549.5 491.5,547.8 493.1,546.2 494.8,544.5 496.5,544.5 496.5,542.9 
	498.1,542.9 498.1,541.2 499.8,541.2 498.1,539.5 498.1,537.9 501.4,536.2 501.4,534.6 499.8,534.6 498.1,532.9 496.5,532.9 
	496.5,531.2 494.8,529.6 494.8,527.9 494.8,526.3 494.8,524.6 496.5,522.9 496.5,521.3 496.5,519.6 496.5,518 498.1,518 499.8,518 
	501.4,518 501.4,516.3 501.4,514.6 501.4,513 501.4,511.3 501.4,509.7 501.4,508 501.4,506.3 499.8,506.3 499.8,504.7 499.8,503 
	499.8,501.4 499.8,499.7 498.1,499.7 498.1,498 499.8,498 501.4,496.4 501.4,494.7 503.1,494.7 503.1,493 504.8,491.4 506.4,489.7 
	506.4,488.1 508.1,488.1 508.1,486.4 508.1,483.1 508.1,481.4 508.1,479.8 509.8,478.1 509.8,476.5 511.4,474.8 513.1,474.8 
	514.7,473.1 516.4,473.1 516.4,471.5 514.7,469.8 514.7,468.2 513.1,468.2 513.1,469.8 513.1,468.2 513.1,469.8 511.4,468.2 
	508.1,466.5 506.4,466.5 504.8,466.5 503.1,468.2 501.4,468.2 501.4,469.8 499.8,468.2 499.8,466.5 499.8,463.2 499.8,461.5 
	499.8,459.9 501.4,459.9 501.4,458.2 501.4,456.5 503.1,456.5 503.1,454.9 503.1,453.2 503.1,451.5 503.1,449.9 504.8,449.9 
	504.8,448.2 504.8,446.6 504.8,444.9 503.1,444.9 503.1,443.3 503.1,441.6 504.8,439.9 504.8,438.3 504.8,436.6 503.1,434.9 
	501.4,431.6 499.8,431.6 498.1,430 498.1,428.3 494.8,428.3 494.8,426.6 496.5,426.6 496.5,425 496.5,423.3 496.5,421.7 496.5,420 
	498.1,418.3 498.1,416.7 498.1,415 498.1,413.4 499.8,411.7 501.4,411.7 503.1,411.7 504.8,411.7 504.8,410 504.8,408.4 
	506.4,408.4 506.4,406.7 508.1,408.4 508.1,406.7 509.8,406.7 509.8,405.1 511.4,403.4 509.8,401.7 509.8,400.1 508.1,400.1 
	508.1,398.4 509.8,398.4 511.4,398.4 513.1,398.4 514.7,398.4 516.4,396.8 518,396.8 519.7,396.8 521.4,395.1 521.4,393.4 
	521.4,391.8 521.4,390.1 521.4,388.5 521.4,386.8 521.4,385.1 521.4,383.5 521.4,381.8 521.4,380.2 521.4,378.5 523,376.8 
	523,375.2 521.4,375.2 521.4,371.9 521.4,370.2 521.4,368.5 521.4,366.9 523,368.5 523,366.9 524.7,366.9 526.3,366.9 528,365.2 
	529.7,365.2 531.3,365.2 529.7,363.6 529.7,361.9 528,361.9 529.7,360.2 " />
		</Tippy>
		<path class="st1" d="M541.3,688.2h-10c-1.4,0-2.5-1.1-2.5-2.5c0,0,0,0,0,0v-1c-0.7-0.3-1.3-0.9-1.6-1.6c-0.8-0.2-1.4-0.8-1.6-1.6
	H523c-0.7,0-1.3-0.3-1.8-0.7l-3.3-3.3c-0.5-0.5-0.7-1.1-0.7-1.8v-5c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-1.5h-0.1
	c-0.2,0.7-0.7,1.2-1.4,1.5c-0.9,0.4-2,0.2-2.7-0.5l-1.6-1.6c-0.5-0.5-0.7-1.1-0.7-1.8v-2.3l-0.2-0.2h-4c-1.8-0.9-3.2-2.4-4.2-4.2v-5
	c0-1.4,1.1-2.5,2.5-2.5c0,0,0,0,0,0h1c-0.2-0.6-0.2-1.2,0.1-1.8V648l-0.1,0c-0.9,0.4-2,0.2-2.7-0.5l-0.9-0.9h-0.6
	c-2-1.5-4-3.2-5.7-5.1c-0.4-0.1-0.7-0.3-1-0.6l-3.4-3.3c-0.5-0.5-0.7-1.1-0.7-1.8v-1.7c0.9-1.4,2-2.8,3.3-4l-0.9-0.9
	c-0.5-1.1-1.3-2-2.3-2.7c-0.8-0.2-1.4-0.9-1.7-1.7c-0.4-0.1-0.7-0.3-1-0.6l-0.9-0.9h-2.9l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-1.6
	c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9h-5.6c-1.1,0-2-0.7-2.4-1.7h-1c-1.4,0-2.5-1.1-2.5-2.5c0-0.4,0.1-0.8,0.3-1.1l3.1-6.1v-1.1
	c0-0.7,0.3-1.3,0.7-1.8l1.7-1.7c1.1-0.5,2-1.3,2.7-2.3c1.1-1.1,2.2-2.2,3.2-3.3v-3.6c-1-0.4-1.7-1.3-1.7-2.4v-1
	c-1-1.1-2.1-2.2-3.2-3.3c-1.1-0.3-1.8-1.3-1.8-2.4v-0.6l-0.9-0.9c-0.5-1-1.3-1.9-2.2-2.6H466l-0.9,0.9c-1.1,0.5-2,1.3-2.7,2.3
	c-0.3,1.1-1.3,1.8-2.4,1.8h-8.3c-1.4,0-2.5-1.1-2.5-2.5v-8.3c0-1,0.6-2,1.6-2.3l-2.5-2.5c-0.5-0.5-0.7-1.1-0.7-1.8v-2.6
	c-1-0.4-1.7-1.3-1.7-2.3v-6.6c0-1.4,1.1-2.5,2.5-2.5h1.6c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9c0.4-1,1.3-1.6,2.3-1.6h1.7
	c1.4,0,2.5,1.1,2.5,2.5v0.6l0.2,0.2h3.9c0.7,0,1.3,0.3,1.8,0.7l0.7,0.7l0.7-0.7c0.3-0.3,0.6-0.5,1-0.6c0.3-1.1,1.3-1.8,2.4-1.8h0.6
	l0.9-0.9c1.1-0.5,2-1.3,2.7-2.3c1.1-1.1,2.2-2.2,3.3-3.3c0.2-0.8,0.9-1.4,1.7-1.7c0.1-0.4,0.3-0.7,0.6-1l1.6-1.7
	c1.2-0.7,2.4-1.5,3.4-2.4h4.3c0.1-0.3,0.3-0.7,0.6-0.9l3.3-3.3c0.3-0.3,0.6-0.5,1-0.6c0.2-0.8,0.9-1.4,1.6-1.7v-0.1
	c-0.1-0.3-0.1-0.5-0.1-0.8v-1.7c0-0.9,0.5-1.8,1.4-2.2l0.2-0.1l-0.1-0.1h-0.6c-1.4,0-2.5-1.1-2.5-2.5v-0.6l-0.9-0.9
	c-0.5-0.5-0.7-1.1-0.7-1.8v-5c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9V518c0-1.4,1.1-2.5,2.5-2.5h2.5v-6.8c-1-0.4-1.7-1.3-1.7-2.4v-4.3
	c-1-0.4-1.7-1.3-1.7-2.4V498c0-1.4,1.1-2.5,2.5-2.5h0.6l0.2-0.2v-0.6c0.9-1.1,1.7-2.2,2.4-3.4l2.6-2.6V488c0-1.1,0.7-2,1.7-2.4v-5.9
	c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-0.6c0-0.7,0.3-1.3,0.7-1.8l1.7-1.7c0.5-0.5,1.1-0.7,1.8-0.7h0.7l0.2-0.1c-0.3-0.1-0.6-0.3-0.9-0.6
	l-1.4-1.4l-2.4-1.2h-1.7l-0.9,0.9c-0.3,0.3-0.6,0.5-1,0.6c-0.4,1.3-1.8,2-3.1,1.6c-0.4-0.1-0.7-0.3-1-0.6l-1.7-1.6
	c-0.5-0.5-0.7-1.1-0.7-1.8v-8.3c0-1.1,0.7-2,1.7-2.4v-1c0-1.1,0.7-2,1.7-2.4v-4.3c0-1.1,0.7-2,1.7-2.4v-0.3c-1-0.4-1.7-1.3-1.7-2.4
	v-3.3c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-1.3l-0.9-0.9c-0.2-0.2-0.3-0.4-0.5-0.6l-1-1.9h-0.1c-0.7,0-1.3-0.3-1.8-0.7l-1.7-1.7
	c-0.3-0.3-0.5-0.6-0.6-0.9h-1c-1.4,0-2.5-1.1-2.5-2.5c0,0,0,0,0,0v-1.7c0-1.1,0.7-2,1.7-2.4V420c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-4
	c0-0.7,0.3-1.3,0.7-1.8l1.7-1.7c0.5-0.5,1.1-0.7,1.8-0.7h2.5v-0.8c0.1-2.3,2-4.1,4.3-4.1c0.2,0,0.5,0,0.7,0.1h0.1
	c0.5-0.3,0.7-1,0.4-1.5c-0.1-0.1-0.2-0.3-0.4-0.4c-1-0.3-1.8-1.3-1.8-2.4v-1.7c0-1.4,1.1-2.5,2.5-2.5h5.6l0.9-0.9
	c0.5-0.5,1.1-0.7,1.8-0.7h2.3l0.2-0.2v-15.6c0-0.6,0.2-1.2,0.6-1.7c-0.4-0.5-0.6-1-0.6-1.7v-8.3c0-1.4,1.1-2.5,2.5-2.5
	c0.3,0,0.6,0,0.8,0.1c0.3-0.1,0.5-0.1,0.8-0.1h2.3l0.8-0.8c-0.2-0.2-0.3-0.4-0.4-0.7c0.1-0.9,0-1.8-0.2-2.6c0-1.4,1.1-2.5,2.5-2.5
	h0.6l0.9-0.9c0.2-0.2,0.4-0.3,0.7-0.5l3-1.5l3-3c0.5-0.5,1.1-0.7,1.8-0.7h0.6l2.6-2.6c0.5-0.5,1.1-0.7,1.8-0.7h1.7
	c1.2,0.8,2.8,0.9,4.1,0.1h0.1c0.4-1.3,1.8-2,3.1-1.6c0.4,0.1,0.7,0.3,1,0.6l1.7,1.6c0.5,0.5,0.7,1.1,0.7,1.8v0.8h0.8
	c1.1,0,2,0.7,2.3,1.6h1c2.4,1.5,4.4,3.5,5.8,5.8v1c0.7,0.3,1.3,0.8,1.5,1.5h2.6c1.4,0,2.5,1.1,2.5,2.5v1c0.3,0.1,0.6,0.3,0.8,0.5
	c0.2-0.2,0.5-0.3,0.7-0.5c0.9-0.4,2-0.2,2.7,0.5l0.9,0.9h0.6c0.3,0,0.6,0,0.8,0.1c0.3-0.1,0.5-0.1,0.8-0.1h10c1.1,0,2,0.7,2.4,1.6
	h1.9c0.3-0.7,0.8-1.3,1.5-1.5v-4.4c0-1.4,1.1-2.5,2.5-2.5h3.9l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7H621c1.1,0,2.1,0.7,2.4,1.8
	c0.4,0.1,0.7,0.3,1,0.6l0.9,0.9h2.3c1.4,0,2.5,1.1,2.5,2.5v1.6c-0.9,1.5-2.1,2.8-3.3,4v1c0,0.3,0,0.6-0.1,0.8
	c0.1,0.3,0.1,0.6,0.1,0.8v1.7c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v13.9c0,1.2-0.9,2.3-2.1,2.5h6.3c1.4,0,2.5,1.1,2.5,2.5v1.7
	c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v13.9c0,0.4-0.1,0.8-0.3,1.1l-1.4,2.8v3.7c0.6,0.2,1.1,0.7,1.4,1.2l1.5,3l0.6,0.6h0.6
	c1.4,0,2.5,1.1,2.5,2.5v7.6c1.3,1.2,2.4,2.5,3.3,4v1l0.1,0c1.2,1.2,2.3,2.6,3.2,4v0.8h3.1l1.8-1.9v-7.3c0-1.4,1.1-2.5,2.5-2.5h1
	c2.1-1.3,4.8-0.6,6.1,1.5c0.2,0.3,0.3,0.7,0.5,1v2.6c1,0.4,1.7,1.3,1.7,2.4v1c1,0.4,1.6,1.3,1.7,2.4v0.8h0.1
	c0.2-0.7,0.8-1.3,1.5-1.5v-4.3c0.5-1.3-0.1-2.7-1.4-3.2c-0.1,0-0.1-0.1-0.2-0.1V430c0.9-1.8,2.4-3.2,4.2-4.2h1.7
	c1.1,0,2.1,0.7,2.4,1.8h0.1v-1c0.9-1.5,2.1-2.9,3.4-4c0.3-0.8,0.9-1.5,1.7-1.7c0-0.1,0-0.1-0.1-0.2c-0.8-0.2-1.4-0.9-1.7-1.7
	c-0.4-0.1-0.8-0.3-1-0.6l-1.7-1.6c-0.3-0.3-0.4-0.6-0.6-0.9h-6c-1.4,0-2.5-1.1-2.5-2.5V410c0-0.7,0.3-1.3,0.7-1.8l1.7-1.7
	c0.5-0.5,1.1-0.7,1.8-0.7h12.3l0.9-0.9c0.3-0.3,0.6-0.5,1-0.6c0.3-1,1.3-1.8,2.4-1.8h3.3c1.1,0,2.1,0.7,2.4,1.8
	c0.4,0.1,0.8,0.3,1.1,0.6l0.9,0.9h1.3l0.9-0.9c0.8-0.2,1.4-0.9,1.7-1.6l2.6-2.6V400c0-1.4,1.1-2.5,2.5-2.5h1.7
	c1.1,0,2.1,0.7,2.4,1.8c1,0.3,1.8,1.3,1.8,2.4v0.6l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v0.1l1.4,0.7h2.8c0.7,0,1.3,0.3,1.8,0.7l1.5,1.5
	l4.2,2.8h0.9c1.4,0,2.5,1.1,2.5,2.5c0,0.7-0.3,1.3-0.7,1.8l-1.7,1.7c-0.5,0.5-1.1,0.7-1.8,0.7h-2.3l-0.2,0.2v0.6
	c0,1.4-1.1,2.5-2.5,2.5h-2.7l-2.8,1.4c-0.3,0.2-0.7,0.3-1.1,0.3h-0.6l-0.2,0.2v0.6c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v0.6
	c0,1.1-0.7,2-1.7,2.4v1c-0.9,1.1-1.7,2.2-2.4,3.4l-0.9,0.9v0.6c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v0.6c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9
	v0.6c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v7.3c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v2.3c0,1.1-0.7,2-1.7,2.4v4.3c0,0.7-0.3,1.3-0.7,1.8
	l-4.3,4.3v9.9c1,0.4,1.7,1.3,1.7,2.4v1.7c0,0.3,0,0.6-0.1,0.8c0.3,0.9,0.1,1.8-0.5,2.5c0.2,0.3,0.4,0.6,0.5,0.9
	c1.1,0.3,1.8,1.3,1.8,2.4v1.7c0,1.1-0.7,2.1-1.8,2.4c-0.1,0.4-0.3,0.7-0.6,1l-0.9,0.9v0.6c0,1.1-0.7,2-1.7,2.4v1
	c0,1.1-0.7,2-1.7,2.4v1c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v4c0,0.3,0,0.6-0.1,0.8h1c1.4,0,2.5,1.1,2.5,2.5v1c0.7,0.2,1.2,0.7,1.5,1.4
	c0.4,0.9,0.2,2-0.5,2.7l-0.9,0.9v0.6c0,1.4-1.1,2.5-2.5,2.5c-0.3,0-0.5,0-0.8-0.1h-0.1c-0.3,1-1.3,1.8-2.4,1.8h-4.2v0.8
	c0,0.2,0,0.4-0.1,0.6l1,1c0.5,0.5,0.7,1.1,0.7,1.8v0l1.4,0.7H709c1.4,0,2.5,1.1,2.5,2.5v1.7c0,1-0.6,1.9-1.5,2.3
	c-0.6,0.2-1.2,0.2-1.8,0l0,0v3.8l1.4,2.8c0.1,0.1,0.1,0.3,0.2,0.4c0.7,0.2,1.3,0.7,1.6,1.4c0.4,0.9,0.2,2-0.5,2.7l-0.9,0.9v0.7
	l1.4,0.7h2c0.4-1,1.3-1.7,2.3-1.7h1.7c1.4,0,2.5,1.1,2.5,2.5v0.6l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v0.6l0.9,0.9
	c0.5,0.5,0.7,1.1,0.7,1.8v3.3c0,0.7-0.3,1.3-0.7,1.8l-5,5c-0.5,0.5-1.1,0.7-1.8,0.7h-2.3l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-5
	c-0.7,0-1.3-0.3-1.8-0.7l-3-3l-2.4-1.2h-1.1c-0.4,0-0.8-0.1-1.1-0.3l-2.8-1.4h-1.1c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9h-8.2
	c-0.4,1-1.3,1.7-2.4,1.7h-1c-0.4,1-1.3,1.7-2.3,1.7h-0.6l-1.8,1.8v0.6c0,1.1-0.7,2-1.7,2.3v1c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v1.3
	l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v1c1,0.4,1.7,1.3,1.7,2.4v1.7c0,1.4-1.1,2.5-2.5,2.5h-6.7c-0.7,0-1.3-0.3-1.8-0.7l-0.7-0.7v15.5
	c-0.9,1.8-2.4,3.2-4.2,4.2h-2.3l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-2.3l-0.2,0.2v2.9l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v0.6l0.9,0.9
	c0.5,0.5,0.7,1.1,0.7,1.8v5c0,1.4-1.1,2.5-2.5,2.5c-1.1,0-2-0.7-2.4-1.7h-2.6c-0.4,0-0.8-0.1-1.1-0.3l-1.8-0.9
	c-0.5,0.7-1.3,1.1-2.1,1.1h-3.3c-1.3-0.8-2.9-0.9-4.3-0.2c-0.7-0.3-1.2-0.9-1.4-1.6c-0.8-0.2-1.4-0.9-1.7-1.7
	c-0.4-0.1-0.7-0.3-1-0.6l-0.9-0.9h-4.9c-0.4,1-1.3,1.7-2.4,1.7h-1c-0.1,0.3-0.3,0.6-0.5,0.8c0.9,1,0.8,2.6-0.2,3.5
	c-0.5,0.4-1,0.6-1.7,0.6h-4.3c-0.4,1-1.3,1.7-2.4,1.7h-2.3l-0.9,0.9c-0.3,0.3-0.7,0.5-1.1,0.6c0.1,0.3,0.2,0.6,0.2,0.9v2.3l0.9,0.9
	c0.2,0.2,0.3,0.4,0.5,0.7l1.7,3.3c0.2,0.3,0.3,0.7,0.3,1.1v1.7c0,1.4-1.1,2.5-2.5,2.5h-1c-0.1,0.3-0.3,0.7-0.6,0.9l-0.9,0.9v0.6
	c0,0.3,0,0.6-0.1,0.8c0.3,0.9,0.1,1.9-0.6,2.6l-0.9,0.9v2.3c0,1.4-1.1,2.5-2.5,2.5h0l-0.7,1.4v6c0,1.4-1.1,2.5-2.5,2.5h-4.3
	c-0.3,0.7-0.9,1.3-1.6,1.5v0.1c0.5,1.3-0.2,2.7-1.5,3.2c0,0-0.1,0-0.1,0c-0.1,0.4-0.3,0.7-0.6,1l-1,1c-0.8,0.7-0.9,2-0.2,2.8
	c0,0,0.1,0.1,0.1,0.1c0.4,0.9,0.2,2-0.5,2.7l-1.7,1.7c-0.5,0.5-1.1,0.7-1.8,0.7h-1.7c-0.4-0.7-1.4-0.9-2-0.5
	c-0.1,0.1-0.2,0.2-0.3,0.3c-0.9,0.4-2,0.2-2.7-0.5l-0.7-0.7l-0.7,0.7c-0.7,0.7-1.7,0.9-2.6,0.6h-0.1c0,0,0,0.1,0,0.1
	c0.3,0.9,0.1,1.9-0.6,2.6l-1.7,1.7c-0.5,0.5-1.1,0.7-1.8,0.7h-15c-1.4,0-2.5-1.1-2.5-2.5v-0.8h-2.5c-1.1,0-2-0.7-2.4-1.7h-3.6
	c-0.1,0.3-0.3,0.6-0.5,0.8c0.4,0.5,0.6,1.1,0.6,1.7v0.6l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v5c0,0.7-0.3,1.3-0.7,1.8l-1.7,1.7
	C542.6,687.9,542,688.1,541.3,688.2z M479,560.2c-0.3,0.8-0.9,1.4-1.7,1.7c-0.3,1.1-1.3,1.8-2.4,1.8h-0.6l-0.9,0.9
	c-0.3,0.3-0.7,0.5-1,0.6c-0.3,1.1-1.3,1.8-2.4,1.8h-0.6l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-1.7c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9
	h-3.9c-0.7,0-1.3-0.3-1.8-0.7l-1-1c-0.2,0-0.4,0.1-0.6,0.1h-3.3c-0.3,0-0.5,0-0.8-0.1v0.3c1,0.4,1.7,1.3,1.7,2.4v4l2.6,2.6
	c0.5,0.5,0.7,1.1,0.7,1.8v3.3c0,1.1-0.7,2-1.7,2.4v3.4h3.5c1.2-1.3,2.5-2.4,4-3.3h0.6l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7h6.6
	c1.8,0.9,3.2,2.4,4.2,4.2v0.6l0.9,0.9c0.3,0.3,0.5,0.6,0.6,1c0.8,0.2,1.4,0.9,1.7,1.7c0.8,0.2,1.4,0.9,1.7,1.7
	c1,0.3,1.8,1.3,1.8,2.4v1c1,0.4,1.6,1.3,1.6,2.4v8.3c-1.5,2-3.2,4-5.1,5.7c-0.3,1-1.3,1.8-2.4,1.8h-0.6l-0.2,0.2v0.6
	c0,0.4-0.1,0.8-0.3,1.1l-1.6,3.1c0.8,0.2,1.4,0.8,1.7,1.6h4.3c0.7,0,1.3,0.3,1.8,0.7l0.7,0.7l0.7-0.7c0.5-0.5,1.1-0.7,1.8-0.7h5
	c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h0.6c2.4,1.5,4.4,3.5,5.8,5.8v0.6l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v3.3c0,1.1-0.7,2.1-1.8,2.4
	c-0.1,0.3-0.3,0.7-0.5,0.9l0.8,0.8h0.6c2,1.5,4,3.2,5.7,5.1h0.1c0.3-0.1,0.5-0.1,0.8-0.1h8.3c1.4,0,2.5,1.1,2.5,2.5v1.7
	c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v0.6c0,1-0.6,1.9-1.5,2.3l-0.2,0.1c0,0.1-0.1,0.2-0.1,0.2c-0.3,0.7-0.9,1.2-1.6,1.4
	c-0.3,1.1-1.3,1.8-2.4,1.8h-0.8v0.1c0.7,0.2,1.3,0.8,1.5,1.5h2.6c0.7,0,1.3,0.3,1.8,0.7l1.7,1.7c0.5,0.5,0.7,1.1,0.7,1.8v0.8h4.1
	c1.4,0,2.5,1.1,2.5,2.5v5c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v2.9l1.8,1.8h4c2.4,1.5,4.4,3.5,5.8,5.8l0,0.8h6.4l0.2-0.2v-3l-0.9-0.9
	c-0.5-0.5-0.7-1.1-0.7-1.8v-0.6l-2.6-2.6c-1-1-1-2.6,0-3.5c0.5-0.5,1.1-0.7,1.8-0.7h1c0.4-1,1.3-1.7,2.4-1.7h8.3
	c1.1,0,2,0.7,2.4,1.7h2.6c1.4,0,2.5,1.1,2.5,2.5v0.8h10.1c-0.1-0.3-0.1-0.5-0.1-0.8c1.5-2.4,3.4-4.4,5.8-5.8h1.7
	c0.5,0.2,1.1,0.2,1.7,0h1.7c0.3,0,0.6,0,0.8,0.1c0.3-0.1,0.5-0.1,0.8-0.1h2.6c-0.1-0.3-0.1-0.5-0.1-0.8V664c0-1.4,1.1-2.5,2.5-2.5
	h0.6l0.2-0.2v-0.6c0.2-0.9,0.2-1.7,0.2-2.6c0.3-0.7,0.9-1.2,1.6-1.4c0.3-0.8,0.9-1.4,1.7-1.7c0.3-1.1,1.3-1.8,2.4-1.8h4.1v-4.1
	c0-0.4,0.1-0.8,0.3-1.1l1.7-3.3c0.3-0.6,0.8-1,1.4-1.2v-1c0-0.6,0.2-1.2,0.6-1.7c0.1-1.8,0.7-3.6,1.8-5.1l0.9-0.9v-0.6
	c0-1.4,1.1-2.5,2.5-2.5h0.1l-0.5-1l-1.4-1.4c-0.5-0.5-0.7-1.1-0.7-1.8v-1c-1.3-1.2-2.4-2.5-3.3-4v-1.6c0-1.4,1.1-2.5,2.5-2.5h3.9
	l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7h1c0.4-1,1.3-1.6,2.3-1.7h2.5v-0.8c0-1.1,0.7-2.1,1.8-2.4c0.3-0.8,0.9-1.5,1.7-1.7
	c-0.1-0.3-0.2-0.6-0.2-0.9v-1.7c0-0.7,0.3-1.3,0.7-1.8l1.7-1.7c1-1,2.6-1,3.5,0c0.5,0.5,0.7,1.1,0.7,1.8v2.5h5.8
	c0.9,0.8,2.4,0.8,3.3,0c1.4,0,2.5,1.1,2.5,2.5v1c0.3-0.1,0.5-0.1,0.8-0.1h1.7c0.6,0,1.2,0.2,1.7,0.6c0.5-0.4,1-0.6,1.7-0.6h1.7
	c0.4,0,0.8,0.1,1.1,0.3l2.8,1.4h1.7l-0.7-0.7c-0.5-0.5-0.7-1.1-0.7-1.8v-0.6l-0.9-0.9c-0.5-0.5-0.7-1.1-0.7-1.8v-5
	c0-0.7,0.3-1.3,0.7-1.8l1.7-1.7c0.5-0.5,1.1-0.7,1.8-0.7h2.3l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7h1c0.2-0.7,0.8-1.3,1.5-1.5v-11.9
	c-1.3-0.5-2-1.9-1.5-3.2c0.1-0.4,0.3-0.7,0.6-0.9l1.7-1.7c0.5-0.5,1.1-0.7,1.8-0.7h3.3c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h1.4v-1.5
	l-0.9-0.9c-0.5-0.5-0.7-1.1-0.7-1.8v-3.3c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-2.3c0.9-1.1,1.7-2.2,2.4-3.4l3.3-3.3
	c0.3-0.3,0.6-0.5,1-0.6c0.3-1.1,1.3-1.8,2.4-1.8h1c0.4-1,1.3-1.7,2.4-1.7h11.6c0.7,0,1.3,0.3,1.8,0.7l0.9,0.9h0.5
	c0.4,0,0.8,0.1,1.1,0.3l2.8,1.4h1.1c0.4,0,0.8,0.1,1.1,0.3l3.3,1.7c0.2,0.1,0.5,0.3,0.7,0.5l2.6,2.6h2.9l0.9-0.9
	c0.5-0.5,1.1-0.7,1.8-0.7h2.3l3.5-3.5v-1.3l-0.9-0.9c-0.5-0.5-0.7-1.1-0.7-1.8v-0.6l-0.3-0.3c-0.2,0-0.4,0.1-0.6,0.1h-5
	c-0.4,0-0.8-0.1-1.1-0.3l-3.3-1.7c-0.8-0.4-1.4-1.3-1.4-2.2v-3.3c0-0.3,0-0.6,0.1-0.8c-0.1-0.3-0.1-0.6-0.1-0.8v-1.1l-1.4-2.8
	c-0.2-0.4-0.3-0.7-0.3-1.1v-5.8h-29c-0.4,0-0.8-0.1-1.1-0.3l-3.3-1.7c-0.8-0.4-1.4-1.3-1.4-2.2V529l-0.2-0.2h-0.6
	c-1.4,0-2.5-1.1-2.5-2.5v-1.7c0-1.1,0.7-2,1.7-2.3v-1c0-1.4,1.1-2.5,2.5-2.5h4.3c1.1-1,2.1-2,3-3.1h0.1v-0.1h-0.8
	c-1.1,0-2.1-0.7-2.4-1.8c-0.7-0.2-1.3-0.7-1.6-1.4c-0.4-0.9-0.2-2,0.5-2.7l0.9-0.9v-3.9c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-2.3
	c0-1.1,0.7-2,1.7-2.4v-1c0.9-1,1.7-2.2,2.4-3.4l0.9-0.9v-0.6c0-0.3,0-0.6,0.1-0.8h-1c-1.4,0-2.5-1.1-2.5-2.5c0-0.7,0.3-1.3,0.7-1.8
	l0.9-0.9v-0.6c0.2-0.8,0.2-1.7,0.1-2.5c-0.1-0.3-0.1-0.6-0.1-0.8v-13.3c0-0.7,0.3-1.3,0.7-1.8l4.3-4.3v-5.6c0-1.1,0.7-2,1.7-2.4v-1
	c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-7.3c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-0.6c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-0.6c0-0.7,0.3-1.3,0.7-1.8
	l0.9-0.9v-0.6c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9V430c0-1.1,0.7-2,1.7-2.4v-1c0.9-1.1,1.7-2.2,2.4-3.4l0.9-0.9v-0.6
	c0-0.7,0.3-1.3,0.7-1.8l1.7-1.7c0.5-0.5,1.1-0.7,1.8-0.7h1.1l2.8-1.4c0.3-0.2,0.7-0.3,1.1-0.3h1c0.1-0.3,0.3-0.7,0.6-0.9l1.7-1.7
	l0.2-0.2l-1.5-1c-0.1-0.1-0.3-0.2-0.4-0.3l-0.9-0.9h-2.3c-0.4,0-0.8-0.1-1.1-0.3l-3.4-1.6c-0.8-0.4-1.4-1.3-1.4-2.2v-0.6l-0.9-0.9
	c-0.2-0.2-0.4-0.5-0.6-0.9l-1.8,1.8v0.6c0,1.4-1.1,2.5-2.5,2.5h-0.6l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-3.3c-0.7,0-1.3-0.3-1.8-0.7
	l-0.9-0.9h-0.6c-0.9-0.8-2.4-0.8-3.3,0h-0.6l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-6.3c1.2,0.2,2.2,1.2,2.2,2.5v0.6l0.2,0.2h0.6
	c2.1,1.5,4,3.2,5.7,5.1c0.4,0.1,0.7,0.3,1,0.6l1.7,1.7c0.5,0.5,0.7,1.1,0.7,1.8v3.3c0,1.4-1.1,2.5-2.5,2.5h-5
	c-0.6,0-1.2-0.2-1.7-0.6c-0.2,0.2-0.5,0.4-0.8,0.5l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v1.6c0,1.4-1.1,2.5-2.5,2.5c0,0,0,0,0,0h-3.3
	c-0.1-0.3-0.5-0.5-0.8-0.4c-0.3,0.1-0.5,0.5-0.4,0.8c0.1,0.2,0.2,0.3,0.4,0.4v3.3c0,1.1-0.7,2-1.7,2.4v4.3c-0.9,1.8-2.4,3.2-4.2,4.2
	h-5c-1.4,0-2.5-1.1-2.5-2.5v-1c-1-0.4-1.6-1.3-1.7-2.4v-1c-0.9-0.3-1.5-1.1-1.7-2v3c0,0.7-0.3,1.3-0.7,1.8l-3.3,3.3
	c-0.5,0.5-1.1,0.7-1.8,0.7h-6.6c-1.4,0-2.5-1.1-2.5-2.5v-1c-0.3,0.1-0.5,0.1-0.8,0.1h-1.7c-1.4,0-2.5-1.1-2.5-2.5v-1.7
	c0-0.6,0.2-1.2,0.6-1.6c-0.2-0.3-0.4-0.6-0.5-0.9c-1-0.3-1.8-1.3-1.8-2.4v-7.6c-0.4-0.1-0.7-0.3-0.9-0.6l-1.7-1.7
	c-0.2-0.2-0.3-0.4-0.5-0.6l-1-1.9h0c-1.4,0-2.5-1.1-2.5-2.5v-6.6c0-0.4,0.1-0.8,0.3-1.1l1.4-2.8v-14.3c0-0.3,0-0.6,0.1-0.8h-1.6
	l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-0.6l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7H617l-0.9,0.9c-0.2,0.2-0.4,0.3-0.7,0.5l-1.9,1v0.1
	c0,1.4-1.1,2.5-2.5,2.5h-8.3c-1.4,0-2.5-1.1-2.5-2.5v-0.6l-1.8-1.9h-0.6c-1.1,0-2-0.7-2.3-1.7h-1c-1.4,0-2.5-1.1-2.5-2.5v-3.3
	c0-0.7,0.3-1.3,0.7-1.8l0.9-0.9v-0.6c0-1.4,1.1-2.5,2.5-2.5h8.3c1,0,2,0.6,2.3,1.6l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7h5.7l0.9-0.9
	c0.5-0.5,1.1-0.7,1.8-0.7h2.5v-10.8c0-0.6,0.2-1.2,0.6-1.7c-0.2-0.2-0.3-0.4-0.4-0.7c-0.4-0.9-0.2-1.9,0.4-2.6
	c-0.2-0.2-0.3-0.4-0.5-0.7c-0.4-0.9-0.2-2,0.5-2.7l1.5-1.5l-0.8-0.8H621c-1.1,0-2-0.7-2.3-1.7h-13.2l-0.9,0.9
	c-0.5,0.5-1.1,0.7-1.8,0.7h-2.5v4.1c0,1.1-0.7,2-1.7,2.4v1c0,1.4-1.1,2.5-2.5,2.5c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9h-3.9
	c-1.1,0-2-0.7-2.3-1.6h-5.3c-0.5,1.3-1.9,2-3.2,1.5c-0.3-0.1-0.7-0.3-0.9-0.6l-0.9-0.9h-0.6c-0.5-0.2-1.1-0.2-1.7,0h-1.7
	c-0.7,0-1.3-0.3-1.8-0.7l-0.9-0.9h-0.6c-1.4,0-2.5-1.1-2.5-2.5v-0.8h-2.5c-1.8-0.9-3.2-2.4-4.2-4.2v-1c-0.7-0.3-1.3-0.9-1.5-1.6
	c-0.8-0.2-1.4-0.8-1.6-1.5h-1c-1.1,0-2-0.7-2.4-1.6h-1c-0.4-1.3-1.7-2.1-3.1-1.8c-0.1,0-0.2,0.1-0.2,0.1h-1.7
	c-0.5-0.2-1.1-0.2-1.7,0h-1.7c-0.6,0-1.2-0.2-1.7-0.6l-1.6,1.6c-0.5,0.5-1.1,0.7-1.8,0.7H539l-2.6,2.6c-0.2,0.2-0.4,0.3-0.7,0.5
	l-3,1.5l-0.7,0.7c0,0.2,0.1,0.4,0.1,0.6v0.6l0.9,0.9c1,1,1,2.6,0,3.5c-0.5,0.5-1.1,0.7-1.8,0.7H529l-0.9,0.9
	c-0.5,0.5-1.1,0.7-1.8,0.7h-1c-0.2,0.7-0.7,1.2-1.4,1.5h-0.1v1.9c1,0.4,1.7,1.3,1.7,2.4v1.7c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v15.6
	c0,0.7-0.3,1.3-0.7,1.8l-1.7,1.7c-0.5,0.5-1.1,0.7-1.8,0.7h-2.3l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-2.3l0.7,0.7c1,1,1,2.5,0,3.5
	c0,0,0,0,0,0l-0.9,0.9v0.6c-0.1,2.3-2.1,4.2-4.4,4.1c-0.2,0-0.4,0-0.6-0.1v1c0,1.4-1.1,2.5-2.5,2.5h-4l-0.2,0.2v3.9
	c0,0.7-0.3,1.3-0.7,1.8L499,421v5c1,0.4,1.6,1.3,1.6,2.4v0.6l0.2,0.2h0.6c0.9,0,1.8,0.5,2.2,1.4l1.5,3l1.4,1.4
	c0.5,0.5,0.7,1.1,0.7,1.8v3.3c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9c1,0.4,1.6,1.3,1.6,2.3v5c0,1.1-0.7,2-1.7,2.4v4.3c0,1.1-0.7,2-1.7,2.4
	v1c0,1.1-0.7,2-1.7,2.4v3.2l0.7-0.7c0.5-0.5,1.1-0.7,1.8-0.7h3.3c0.4,0,0.8,0.1,1.1,0.3l3,1.5c0.3-0.1,0.5-0.1,0.8-0.1h1.7
	c1.4,0,2.5,1.1,2.5,2.5c0,0,0,0,0,0v0.6l0.9,0.9c0.5,0.5,0.7,1.1,0.7,1.8v1.7c0,1.4-1.1,2.5-2.5,2.5c0,0,0,0,0,0h-0.6l-0.9,0.9
	c-0.5,0.5-1.1,0.7-1.8,0.7h-0.6l-0.2,0.2v0.6c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v7.3c0,1.1-0.7,2.1-1.8,2.4c-0.1,0.4-0.3,0.7-0.6,1
	l-2.6,2.6v0.6c-0.9,1.1-1.7,2.2-2.4,3.4l-1,1c0,0.2,0.1,0.4,0.1,0.6v4.2c1,0.4,1.7,1.3,1.7,2.4V518c0,1.4-1.1,2.5-2.5,2.5H499v2.5
	c0,0.7-0.3,1.3-0.7,1.8l-0.9,0.9v2.9l0.9,0.9c0.3,0.3,0.5,0.6,0.6,1c0.4,0.1,0.7,0.3,1,0.6l0.9,0.9h0.6c1.4,0,2.5,1.1,2.5,2.5v1.7
	c0,0.9-0.5,1.8-1.4,2.2l-1.3,0.7l0.3,0.3c0.7,0.7,0.9,1.8,0.5,2.7c-1.1,1-2.2,2-3.2,3.1c-0.3,1.1-1.3,1.8-2.4,1.8h-0.6l-1.8,1.9v2.3
	c0,1.4-1.1,2.5-2.5,2.5c-1.1,0-2-0.7-2.4-1.7h-1.9c-0.3,1-1.3,1.7-2.4,1.7h-0.6l-0.2,0.2v0.6c0,1.1-0.7,2.1-1.8,2.4 M508.8,646.6
	l-0.1,0.2c0.1-0.1,0.2-0.1,0.3-0.1v-0.1L508.8,646.6z M665,434.1h0.8c0.2,0,0.5,0,0.7,0.1l-0.8-0.8c-0.3-0.3-0.5-0.6-0.6-1H665
	V434.1z M605.1,400.9h3.6c0.2-0.6,0.7-1.1,1.2-1.4l3-1.5l1.4-1.4c0.5-0.5,1.1-0.7,1.8-0.7h0.6l0.9-0.9c0.5-0.5,1.1-0.7,1.8-0.7h0.7
	l0.9-0.9c0.4-0.4,0.9-0.6,1.5-0.7H621c-1,0-2-0.6-2.3-1.6l-0.9,0.9c-0.5,0.5-1.1,0.7-1.8,0.7h-5.6l-0.9,0.9
	c-0.5,0.5-1.1,0.7-1.8,0.7h-3.3c-1.1,0-2-0.7-2.3-1.7h-3.6c-0.1,0.4-0.3,0.7-0.6,0.9l-0.7,0.7h0.6c1.1,0.9,2.2,1.7,3.4,2.4l3.3,3.3
	C604.7,400.2,604.9,400.6,605.1,400.9z" />
	</svg>
);

export default SvgMap;
