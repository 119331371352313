import React, { useState, useEffect } from "react";
import {
    GoogleMap,
    DrawingManager,
    Polygon
} from "@react-google-maps/api";
import { LoadScript } from "@react-google-maps/api";

import { MAP_API_KEY } from "../../../shared/constants"
import { apiFunction } from "../../../apiCalls/functions/api"
import { GET_GEOLOCATION, UPDATE_GEOLOCATION } from "../../../apiCalls/urls/dashboard/dashboard"
import { loaderStart, loaderEnd, notification } from "../../../components/notification"



const lib = ["drawing", "places"];
const google = window.google;

function Map(props) {
    console.log(props)
    const [currentLocation, setCurrentLocation] = useState({});
    const [selectedCoords, setSelectedCoords] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(async() =>{
        let data = {
            "karyakarta_id": props.karyakartaId,
        }
        let result = await apiFunction(GET_GEOLOCATION, "post", data, true)
        if(result.status){
            setSelectedCoords(result.data)
        }
    },[])

    const onMapLoad = map => {
        navigator?.geolocation.getCurrentPosition(
            ({ coords: { latitude: lat, longitude: lng } }) => {
                const pos = { lat, lng };
                setCurrentLocation(pos)
            }
        );
    };

    const onPolygonComplete = (polygon) => {
        let polygonArray = []
        for (var i = 0; i < polygon.getPath().getLength(); i++) {
            let str = polygon.getPath().getAt(i).toUrlValue(6);
            str = str.split(",")
            let single = {
                lat: parseFloat(str[0]),
                lng: parseFloat(str[1])
            }
            polygonArray.push(single);
        }
        setLoading(true)
        updateGeoLocation(polygonArray)
        setTimeout(() => {
            setSelectedCoords(polygonArray)
            setLoading(false)
        }, 10)

    }

    const updateGeoLocation = async(coordinates) =>{
        loaderStart()
        let data = {
            "karyakarta_id": props.karyakartaId,
            "coordinates": coordinates
        }
        let result = await apiFunction(UPDATE_GEOLOCATION, "post", data, true)
        if(result.status){
            
        }
        loaderEnd()
    }

    return (
        <div style={{ width: "85vw" }}>
            {
                !loading
                    ?
                    <LoadScript googleMapsApiKey={MAP_API_KEY} libraries={lib}>
                        <GoogleMap
                            center={currentLocation}
                            zoom={12}
                            onLoad={map => onMapLoad(map)}
                            mapContainerStyle={{ height: '100vh', width: "100%" }}
                        >
                            <Polygon
                                path={selectedCoords}
                                key={1}
                                editable={false}
                                options={{
                                    strokeColor: "#FF0000",
                                    strokeOpacity: 0.8,
                                    strokeWeight: 2,
                                    fillColor: "#FF0000",
                                    fillOpacity: 0.35
                                }}
                            />
                            <DrawingManager
                                defaultDrawingMode={window.google?.maps?.drawing?.OverlayType?.POLYGON}
                                onPolylineComplete={onPolygonComplete}
                                defaultOptions={{
                                    drawingControl: true,
                                    drawingControlOptions: {
                                        position: window.google?.maps?.ControlPosition?.TOP_CENTER,
                                        drawingModes: [window.google?.maps?.drawing?.OverlayType?.POLYGON]
                                    },
                                    polygonOptions: {
                                        strokeColor: "#FF0000",
                                        strokeOpacity: 0.8,
                                        strokeWeight: 2,
                                        fillColor: "#FF0000",
                                        fillOpacity: 0.35,
                                        clickable: false,
                                        editable: false,
                                        zIndex: 1
                                    }
                                }}
                            />
                        </GoogleMap>
                    </LoadScript>
                    : null
            }

        </div>
    );
}

export default Map;
