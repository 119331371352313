import React from "react";
import classes from "./dropdown.module.scss";
import { useNavigate } from "react-router-dom";

const AboutUsDropdown = ({ setAboutUsNavbarHover, ...props }) => {
	const navigate = useNavigate();
	return (
		<div
			className={classes.listContainer}
		>
			{
				props.page?.map((d, i) => (
					<div key={i} className={classes.items} onClick={() => props.handleClick(d.route)}>
						{d.label}
					</div>
				))
			}
		</div>
	);
};

export default AboutUsDropdown;
