import classes from "./styles.module.scss";
const Button = (props) => {
    return (
        <button
            className={classes.secondaryBtn + " " + (classes[props.className] ? classes[props.className] : "")}
            style={props.style}
            type={props.type ? props.type : "button"}
            onClick={props.onClick}
        >
            {props.title}
        </button>
    )
}

export default Button