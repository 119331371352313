import classes from "../../noticeComplainEvent/styles.module.scss";
import InputField from '../../../components/input';
import Button from '../../../components/button';
import { notification } from "../../../components/notification"

import { useState, useEffect } from "react";

const questionType = [
    {
        value: "text",
        title: "Text"
    },
    {
        value: "choice",
        title: "Single Select"
    },
    {
        value: "choices",
        title: "Multiple Select"
    }
]

const ModalLayout = (props) => {

    const [state, setState] = useState({
        question: "",
        question_type: "",
    })

    const [options, setOptions] = useState([""])


    useEffect(() => {

    }, [])

    const handleChange = (e, name) => {
        let _state = { ...state };
        _state[name] = e;
        setState(_state)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let _state = { ...state }
        if(state.question_type == "choice" || state.question_type == "choices"){
            if(options.length >= 2){
                _state.choice_set = [...options];
                props.handleSubmit(_state)
            }
            else{
                notification(false, "Please add at least two options")
            }
            
        }
        else{
            props.handleSubmit(_state)
        }
    }

    const handleChangeOption = (e, i) => {
        let _option = [...options]
        _option[i] = e;
        setOptions(_option)
    }

    const handleAdd = () => {
        setOptions([...options, ""])
    }

    const handleEdit = (i) => {
        let _option = [...options]
        _option.splice(i, 1);
        setOptions(_option)
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className={classes.col1}>
                <InputField
                    title="Question"
                    type="text"
                    required={true}
                    value={state.question}
                    onChange={(e) => handleChange(e, "question")}
                    placeholder={"Enter Question"}
                />
                <InputField
                    title="Question Type"
                    type="select"
                    required={true}
                    value={state.question_type}
                    options={questionType}
                    onChange={(e) => handleChange(e, "question_type")}
                    optionLabel="title"
                    optionValue="value"
                />
                <div className={classes.col1}>
                    {
                        (state.question_type == "choice" || state.question_type == "choices") && options.map((o, i) => (
                            <div style={{ display: "flex" }}>
                                <InputField
                                    title="Options"
                                    type="text"
                                    required={true}
                                    value={o}
                                    onChange={(e) => handleChangeOption(e, i)}
                                    placeholder={"Enter Options"}
                                    sectionStyle={{width: '95.5%'}}
                                />
                                <i className="fa fa-times cross" aria-hidden="true"
                                    onClick={() => handleEdit(i)}
                                ></i>
                            </div>
                        ))

                    }
                    {
                        state.question_type == "choice" || state.question_type == "choices"
                            ?
                            <div className="">
                                <i className="fa fa-add" aria-hidden="true"
                                    onClick={() => handleAdd()}
                                ></i>
                            </div>
                            : null
                    }

                </div>

            </div>
            <br />
            <div className={classes.center}>
                <Button className="auto" title="Submit" type="submit" />
            </div>
            <br />
            <br />
        </form>

    )
}

export default ModalLayout;