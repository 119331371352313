export const noticeColumn = [
    {
        label: 'SN',
        field: 'sn',
        sort: 'disabled',
        width: 150,
    },
    {
        label: 'Constituency',
        field: 'con',
        sort: 'disabled',
    },
    {
        label: 'Title',
        field: 'title',
        sort: 'disabled',
    },
    {
        label: 'Notice',
        field: 'notice',
        sort: 'disabled',
    },
    // {
    //     label: 'User',
    //     field: 'user',
    //     sort: 'disabled',
    // },
    {
        label: 'Added On',
        field: 'time',
        sort: 'disabled',
    }
];

export const eventColumn = [
    {
        label: 'SN',
        field: 'sn',
        sort: 'disabled',
        width: 150,
    },
    {
        label: 'Constituency',
        field: 'con',
        sort: 'disabled',
    },
    {
        label: 'Title',
        field: 'title',
        sort: 'disabled',
    },
    {
        label: 'Description',
        field: 'description',
        sort: 'disabled',
    },
    {
        label: 'Fee',
        field: 'fee',
        sort: 'disabled',
    },
    {
        label: 'Date of event',
        field: 'date_of_event',
        sort: 'disabled',
    },
    {
        label: 'Location',
        field: 'location',
        sort: 'disabled',
    },
    // {
    //     label: 'User',
    //     field: 'user',
    //     sort: 'disabled',
    // },
    {
        label: 'Added On',
        field: 'time',
        sort: 'disabled',
    }
];


export const complainColumn = [
    {
        label: 'SN',
        field: 'sn',
        sort: 'disabled',
        width: 150,
    },
    {
        label: 'Complain',
        field: 'complain',
        sort: 'disabled',
    },
    {
        label: 'Status',
        field: 'status',
        sort: 'disabled',
    },
    {
        label: 'Added On',
        field: 'time',
        sort: 'disabled',
    }
];