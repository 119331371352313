import React, { useState, useEffect } from 'react';

import SingleBox from "../singleBox";

import classes from "./styles.module.scss";
import bjp from "../../../../assets/img/bjp.png";
import { getParty, getPartyName } from "../../../partyInfo"


const Election = (props) => {
    return (
        <>
            <SingleBox title="ELECTION RESULT ANALYSIS">
                <div className={classes.box_matter_container}>
                    <div className={classes.result}>
                        <div className={classes.Winner_box}>
                            <div className={classes.winner_head}>Winner ({props?.topTwoParties.first?.party_name})</div>
                            <div className={classes.partyData}>
                                <div className={classes.result_party_symbol}><img src={getParty(props?.topTwoParties.first?.party_name)} /></div>

                                <div className={classes.votes_text}>Vote</div>
                                <div className={classes.votes}>{props?.topTwoParties.first?.total_valid_votes} <span>({props?.topTwoParties.first?.per})%</span></div>
                            </div>
                        </div>
                        <div className={classes.Winner_box}>
                            <div className={classes.winner_head}>Runner-up ({props?.topTwoParties.second?.party_name})</div>
                            <div className={classes.partyData}>
                                <div className={classes.result_party_symbol}><img src={getParty(props?.topTwoParties.second?.party_name)} /></div>
                                <div className={classes.votes_text}>Vote</div>
                                <div className={classes.votes}>{props?.topTwoParties.second?.total_valid_votes}% <span>({props?.topTwoParties.second?.per}%)</span></div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.winning_margin}>Winning Margin Votes : <span>{props?.topTwoParties.diff} <span>({props?.topTwoParties.diffPer}%)</span></span></div>
                    {/* <div className={classes.polarity}>
                        <div className={classes.polarity_left}><img src={"https://www.indiastatelections.com/images/polarity/polarity_BJP.svg"} /></div>
                        <div className={classes.polarity_middle}>
                            <div className={classes.polarity_head}>Polarity (Bipolar)</div>
                            <div className={classes.polarity_box}>BJP-INC</div>
                        </div>
                        <div className={classes.polarity_right}><img src={"https://www.indiastatelections.com/images/polarity/polarity_INC.svg"} /></div>
                    </div> */}
                </div>
            </SingleBox>
        </>
    )
}

export default Election;