import React, { useState, useEffect } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'People met in last one week',
        },
    },
};

const labels = [''];

const BarChart = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        labels,
        datasets: [
            {
                label: '10th',
                data: 10,
                backgroundColor: 'rgb(247,148,30, 0.5)',
            },
            {
                label: '9th',
                data: 10,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: '7th',
                data: 10,
                backgroundColor: 'rgba(38,130,255, 0.5)',
            },
            {
                label: '6th',
                data: 10,
                backgroundColor: 'rgba(16, 255, 0, 0.5)',
            },
            {
                label: '5th',
                data: 10,
                backgroundColor: 'rgba(0,8,255, 0.5)',
            },
            {
                label: '4th',
                data: 10,
                backgroundColor: 'rgba(146, 144, 163, 0.5)',
            },
            {
                label: '3rd',
                data: 10,
                backgroundColor: 'rgba(252,255,0, 0.5)',
            },
        ],
    })

    useEffect(() => {
        if (props.data && props.data.length > 0) {
            // console.log(data)
            setLoading(true)
            setTimeout(() => {
                let _data = { ...data }
                _data.datasets = [...props.data];
                console.log(_data)
                setData(_data)
                setLoading(false)
            }, 1)
        }
    }, [props.data])

    console.log(data)


    return (
        <>
            {
                data.datasets && data.datasets[0].data > 0 && !loading
                    ? <Bar options={options} data={data} /> : null

            }

        </>
    );
}

export default BarChart
