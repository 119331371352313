import React, { useState, useEffect } from "react";
import ModalLayout from "../../../components/modalLayout"
import OverallModal from "../karyakarta/overallModal"
import PieChart from "../../charts/pieChart";
import { generateVoterData } from "../../../shared/functions"
import { apiFunction } from "../../../apiCalls/functions/api"
import { GET_QUESTIONS_ANALYTICS_USER } from "../../../apiCalls/urls/dashboard/dashboard"
import { loaderStart, loaderEnd, notification } from "../../../components/notification"

import classes from "./styles.module.scss";
const Analytics = (props) => {
    const [modal, setModal] = useState(false)
    const [pagination, setPagination] = useState({
        "itemsPerPage":20,
        "pageNo": 1,
    })
    const [rows, setRows] = useState([])
    const [total, setTotal] = useState(65533)

    const updateTable = (section, data) => {
        let _pagination = { ...pagination }
        _pagination[section] = data;
        setPagination(_pagination)
    }

    const getData = async (answer, index, id) => {
        loaderStart()
        let postData = {
            "level": "constituency",
            "constituency": props.constituencyId,
            "question_id": id,
            "choice": answer,
            ...pagination
        }
        let result = await apiFunction(GET_QUESTIONS_ANALYTICS_USER, "post", postData, true)
        if(result.status){
            let rows = generateVoterData(result.data.rows)
            setRows(rows)
            setTotal(result.data.count)
            setModal(true)
        }
        loaderEnd()

        console.log(result)
    }
    
    console.log(props.data)
    return (
        <>
            <div className={classes.chartSection}>
                {
                    props.data?.map((d, i) => (
                        <div key={i}>
                            <div className={classes.title}>
                                {d.question}
                            </div>
                            <PieChart
                                data={d.value}
                                labels={d.label}
                                showSelected={(answer, index) =>getData(answer, index, d.question_id)}
                            />
                        </div>
                    ))
                }

            </div>
            {
                modal
                    ?
                    <ModalLayout
                        isOpen={modal}
                        toggleModal={() => setModal(false)}
                        title="Voters"
                    >
                        <OverallModal
                            updateTable={updateTable}
                            total={total}
                            pagination={pagination}
                            rows={rows}
                        />
                    </ModalLayout>
                    : null
            }
        </>
    )

}

export default Analytics;