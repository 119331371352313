import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import classes from "./styles.module.scss";


const HorizontalTabs = (props) => {
    return (
        <div className={classes.tabContainer}>
            <Tabs selectedIndex={props.selectedIndex} className={classes.tabs}>
                <TabList className={classes.tabList}>
                    {
                        props.tabJson && props.tabJson.map((t, i) => (
                            <Tab 
                                onClick={() => props.onClick(t.name)}
                                selectedClassName={classes.selectedTab} className={classes.tab}>
                                {t.title}
                            </Tab>
                        ))
                    }
                </TabList>

                <div className={classes.tabPanelContainer}>
                    {
                        props.tabJson && props.tabJson.map((t, i) => (
                            <TabPanel className={classes.tabPanel}>
                                {t.component}
                            </TabPanel>
                        ))
                    }
                </div>
            </Tabs>
        </div>
    )
}

export default HorizontalTabs;