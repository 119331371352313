import React from "react";
import { callToMobile, smsToMobile } from "../../apiCalls/apis/dropdown";

import classes from "./styles.module.scss";

const InfoCard = ({ title, value, mobile, style }) => {

    const makeCall = async (phoneNumber) => {
        // await callToMobile(8075326695)
        await callToMobile(phoneNumber)
    }

    const makeSms = async(phoneNumber) => {
        await smsToMobile(phoneNumber)
    }

    return (
        <div className={classes.infoCard} style={style}>
            <div className={classes.singleRow} >
                <div className={classes.value} >
                    {value}
                </div>
                <div className={classes.title} >
                    {title}
                </div>
                <div className={classes.mobile} >
                    {mobile} <i className="fa fa-phone" onClick={() => makeCall(mobile)} aria-hidden="true"></i>&nbsp;
                    <i className="fa fa-sms" onClick={() => makeSms(mobile)} aria-hidden="true"></i>&nbsp;
                </div>
            </div>
        </div>
    )
}

export default InfoCard;