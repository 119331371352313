import React, { useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Winning score',
        },
    },
};

const labels = [''];

const BarChart = (props) => {

    const data = {
        labels,
        datasets: [
            {
                label: '',
                data: [props.positive],
                backgroundColor: 'rgb(240,138,30, 0.5)',
            },
            {
                label: '',
                data: [props.negative],
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            // {
            //     label: '~swing',
            //     data: [props.swing],
            //     backgroundColor: 'rgba(38,130,255, 0.5)',
            // },
        ],
    };

    return <Bar options={options} data={data} />;
}

export default BarChart
