import * as actionTypes from '../actionTypes/firebase';

const initialState = {
    firebaseTime: 0,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_TIME:
            state = {
                ...state,
                firebaseTime: action.payload
            }
            break;
    }

    return state;
}

export default reducer;