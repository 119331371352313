import { GET_VOTER_COUNT_BY_AGE_GENDER, GET_DETAILED_REPORT, GET_AGG_VOTER_COUNT, VOTES_PER_YEAR, BOOTH_PERFORMANCE, MARGIN_BOOTH, WIN_LOOSE_BOOTH, CONSTITUENCY_BOOTH, WIN_LOSS_BOOTH, MARGIN_BOOTH_LIST, VOTES_PER_YEAR_BOOTH, ADD_CANDIDATE, EDIT_CANDIDATE, VIEW_CANDIDATE, DELETE_CANDIDATE } from "../../../apiCalls/urls/dashboard/dashboard"
import { apiFunction } from "../../../apiCalls/functions/api"
export const getVoterCount = async (data) => {
    data = {
        ...data,
        "age_range": [
            [18, "-", 24],
            [25, "-", 34],
            [35, "-", 44],
            [45, "-", 54],
            [55, "-", 64],
            [65, ">="]
        ],
        "group_by": ["SEX"]
    }
    let result = await apiFunction(GET_VOTER_COUNT_BY_AGE_GENDER, "post", data, true)
    if (result.status && result.data && result.data.length > 0) {
        let total = {
            SEX: "Total"
        }
        let data = [...result.data];
        let keys = Object.keys(data[0]);
        for (let i = 0; i < keys.length; i++) {
            let single = 0;
            if (keys[i] != "SEX") {
                data.forEach((d) => {
                    single += d[keys[i]] ? parseInt(d[keys[i]]) : 0
                })
                total = {
                    ...total,
                    [keys[i]]: single
                }
            }
        }
        data.push({ ...total })


        return {
            table: ageWisePercent([...data]),
            pie: ageWiseTotal({ ...total })
        };
    }
    else {
        return {
            table: [],
            pie: {}
        }
    }
}

export const getVoterCountPerAge = async (data, label) => {
    data = {
        ...data,
        "age_range": generateAgeRange(label),
        "group_by": ["SEX"]
    }
    let result = await apiFunction(GET_VOTER_COUNT_BY_AGE_GENDER, "post", data, true)
    if (result.status && result.data && result.data.length > 0) {
        let data = [...result.data]
        let keys = Object.keys(data[0]);
        let allData = []
        for (let i = 0; i < keys.length; i++) {
            let sum = 0;
            if (keys[i] != "SEX") {
                let total = data[0][keys[i]] + data[1][keys[i]] + data[2][keys[i]];
                let single = {
                    age: keys[i].slice(0, -1),
                    female: data[0][keys[i]] + (total ? "(" + (data[0][keys[i]] / total * 100).toFixed(2) + "%)" : ""),
                    male: data[1][keys[i]] + (total ? "(" + (data[1][keys[i]] / total * 100).toFixed(2) + "%)" : ""),
                    other: data[2][keys[i]] + (total ? "(" + (data[2][keys[i]] / total * 100).toFixed(2) + "%)" : ""),
                    total: total
                }
                allData.push(single)
            }
        }
        return allData
    }
    else {
        return []
    }

}

export const getDetailedReport = async (data, label) => {
    data = {
        ...data,
    }
    let result = await apiFunction(GET_DETAILED_REPORT, "post", data, true)
    if (result.status && result.data) {
        // console.log(result.data)
        let topParties = [...result.data.topWinningParties]

        return {
            voteShare: getVoteWiseShare([...result.data.voterShare.votePartyWise], result.data.total.totalValidVotes),
            topParties: topParties,
            topTwoParty: topTwoParty([...result.data.voterShare.votePartyWise], result.data.total.totalValidVotes),
            total: { ...result.data.total, percent: parseInt((result.data.total.totalValidVotes) / parseInt(result.data.total.totalVoter) * 100).toFixed(2) }
        }

    }
    else {
        return {
            voteShare: {
                data: [],
                labels: []
            },
            topTwoParty: {},
            topParties: []
        }
    }

}

export const getMaleFemale = async (data, label) => {
    data = {
        ...data,
    }
    let result = await apiFunction(GET_AGG_VOTER_COUNT, "post", data, true)
    if (result.status && result.data && result.data.length > 0) {
        return {
            ...result.data[0],
            malePer: parseInt(result.data[0].male / result.data[0].total * 100),
            femalePer: parseInt(result.data[0].female / result.data[0].total * 100),
            sexRatio: parseInt(result.data[0].female / result.data[0].male * 1000)
        }
    }
    else {
        return {}
    }

}



//Functions
const ageWisePercent = (data) => {
    let keys = Object.keys(data[0]);
    let _data = []
    data.map((d, j) => {
        let single = { ...d }
        let total = 0;
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] != "SEX")
                total += single[keys[i]]
        }
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] != "SEX")
                single = {
                    ...single,
                    [keys[i]]: single[keys[i]] + (total ? "(" + (single[keys[i]] / total * 100).toFixed(2) + "%)" : "")
                }
        }
        single.SEX = sexName(single.SEX)
        single.total = total;
        _data.push(single)
    })
    // console.log(data)
    return _data;
}

const sexName = (n) => {
    if (n == "F")
        return "Female";
    else if (n == "M")
        return "Male";
    else if (n == "O")
        return "Other";
    else
        return "Total";
}

const ageWiseTotal = (total) => {
    delete total.SEX;
    let keys = Object.keys(total);
    let totalPer = {}
    let data = []
    const sum = Object.values(total).reduce((a, b) => a + b);
    // console.log(sum)
    for (let i = 0; i < keys.length; i++) {
        totalPer = {
            ...totalPer,
            [keys[i]]: (total[keys[i]] / sum * 100).toFixed(2)
        }
        data.push((total[keys[i]] / sum * 100).toFixed(2))
    }
    return {
        label: keys,
        data: data
    }
}

const generateAgeRange = (range) => {
    let all = range.split("-")
    let ageRange = []
    // console.log(all)
    if (all.length === 2) {
        for (let i = parseInt(all[0]); i <= parseInt(all[1]); i++) {
            ageRange.push([i, "="])
        }
    }
    else {
        for (let i = 65; i <= 75; i++) {
            ageRange.push([i, "="])
        }
        ageRange.push([76, ">="])
    }
    // console.log(ageRange)
    return ageRange

}

const getVoteWiseShare = (allData, validVote) => {
    let data = [];
    let labels = [];
    allData.map((a, i) => {
        labels.push(a.party_name)
        data.push(((parseInt(a.total_valid_votes) / parseInt(validVote)) * 100).toFixed(2))
    })

    return {
        data,
        labels
    }

}

const topTwoParty = (allData, validVote) => {
    if (allData) {
        // console.log(allData)
        let diff = parseInt(allData[0].total_valid_votes) - parseInt(allData[1].total_valid_votes);
        return {
            first: allData[0] ? {
                ...allData[0],
                per: ((parseInt(allData[0].total_valid_votes) / parseInt(validVote)) * 100).toFixed(2)
            } : {},
            second: allData[1] ? {
                ...allData[1],
                per: ((parseInt(allData[1].total_valid_votes) / parseInt(validVote)) * 100).toFixed(2)
            } : {},
            diff: diff,
            diffPer: (diff / validVote * 100).toFixed(2)
        }
    }
}



//New Report
export const voterPerYear = async (data) => {
    let result = await apiFunction(VOTES_PER_YEAR, "post", data, true)
    return result
}

export const voterPerYearBooth = async (data) => {
    let result = await apiFunction(VOTES_PER_YEAR_BOOTH, "post", data, true)
    return result
}

export const boothPerformancePerYear = async (data, year, section) => {
    data = {
        ...data,
        year: year
    }
    let result = await apiFunction(BOOTH_PERFORMANCE + section, "post", data, true)
    return result
}

export const marginBooth = async (data, year, range) => {
    data = {
        ...data,
        year: year,
        "marginRange": range
    }
    return await apiFunction(MARGIN_BOOTH, "post", data, true)
}

export const winLooseBooth = async (data, year) => {
    data = {
        ...data,
        year: year,
    }
    return await apiFunction(WIN_LOOSE_BOOTH, "post", data, true)
}

export const constituencyBooth = async (data, year) => {
    // console.log(data);
    if (data.constituency) {
        let _data = {
            year: year,
            ...data,
        }
        let res = await apiFunction(CONSTITUENCY_BOOTH + data.constituency, "post", _data, true)
        return res.data
    }
    else {
        return [];
    }

}


export const boothWinLossData = async (section, data) => {
    return await apiFunction(WIN_LOSS_BOOTH + section, "post", data, true)
}


export const marginWinLossList = async (data) => {
    return await apiFunction(MARGIN_BOOTH_LIST, "post", data, true)
}

//Probable Candidate
export const addCandidate = async (data) => {
    return await apiFunction(ADD_CANDIDATE, "post", data, true)
}

export const editCandidate = async (data) => {
    return await apiFunction(EDIT_CANDIDATE, "post", data, true)
}

export const deleteCandidate = async (data) => {
    return await apiFunction(DELETE_CANDIDATE, "post", data, true)
}

export const viewCandidate = async (data) => {
    return await apiFunction(VIEW_CANDIDATE, "get", data, true)
}