import { axios } from "../../shared/axios";
export const getToken = () => {
    let token = localStorage.getItem("gujarat-token");
    if(token){
        return token;
    }
    else{
        return false
    }
};

export const apiFunction = async (url, method, postData, token, extraConfig) => {
    let config = {
        method: method,
        url: "https://lakshyagujarat.com/api/v1"+url,
        data: postData ? postData : {},
    };
    let data;
    if (token) {
        let token = getToken();
        // console.log(token)
        config = {
            ...config,
            headers: { Authorization: `Bearer ${token}` },
        };
    }
    else{
        config = {
            ...config,
            headers: { Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsInVzZXJUeXBlIjoia2FyeWFrYXJ0YSIsImNvbnN0aXR1ZW5jeU5vIjozNywiaWF0IjoxNjUwNDQzOTY5fQ.uqlgfYsjmIQ6eHg8qWDR5kYy7hiOTGXSf3s6o2_zhjY` },
        };
    }

    await axios({ ...config })
        .then((res) => {
            if (extraConfig == "blob") {
                data = res.data
            }
            else {
                if (res.data) {
                    data = {
                        data: res.data.data,
                        status: true,
                        message: "Success",
                    };
                } else {
                    data = {
                        status: false,
                        message: "This action is not allowed",
                    };
                }
            }
        })
        .catch((err) => {
            // console.log(err.response.data.message);
            // console.log(err);
            data = {
                ...err.response.data,
                status: false,
            };
        });
    // console.log(data);
    return data;
};

