import React, { useState, useEffect } from 'react';

import SingleBox from "../singleBox";

import classes from "./styles.module.scss";
import PieChart from "../../../charts/pieChart";



const Election = (props) => {
    return (
        <>
            <SingleBox title="VOTE SHARE OF MAJOR PARTIES (%)">
                <div className={classes.chartDiv}>
                    <PieChart data={props.data?.data} labels={props.data?.labels} />
                </div>

            </SingleBox>
        </>
    )
}

export default Election;