export const voterColumns = [
    {
        label: 'Id',
        field: 'id',
        sort: 'enable',
    },
    {
        label: 'Name',
        field: 'name',
        sort: 'enable',
    },
    {
        label: 'Age',
        field: 'age',
        sort: 'disabled',
    },
    {
        label: 'Gender',
        field: 'gender',
        sort: 'disabled',
    },
    {
        label: 'Contact No',
        field: 'contactno',
        sort: 'disabled',
    },
    {
        label: 'Religion',
        field: 'religion',
        sort: 'disabled',
    },
    {
        label: 'Caste',
        field: 'caste',
        sort: 'disabled',
    },
    {
        label: 'House No',
        field: 'houseNo',
        sort: 'disabled',
    },
    {
        label: 'Updated By',
        field: 'updatedBy',
        sort: 'disabled',
    },
    {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
    },
    // {
    //     label: 'CHNGLISTNO',
    //     field: 'CHNGLISTNO',
    //     sort: 'disabled',
    //     width: 150,
    // },
    
    // {
    //     label: 'Org. List',
    //     field: 'ORGNLISTNO',
    //     sort: 'disabled',
    //     width: 150,
    // },
    // {
    //     label: 'Part',
    //     field: 'PART_NO',
    //     sort: 'disabled',
    //     width: 150,
    // },
    // {
    //     label: 'Section',
    //     field: 'SECTION_NO',
    //     sort: 'disabled',
    //     width: 150,
    // },
    // {
    //     label: 'Sttaus',
    //     field: 'STATUSTYPE',
    //     sort: 'disabled',
    //     width: 150,
    // },
    // {
    //     label: 'St Code',
    //     field: 'ST_CODE',
    //     sort: 'disabled',
    //     width: 150,
    // },
];