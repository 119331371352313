import React, { useEffect } from "react"
import { connect } from "react-redux";

import "./App.css";
import MainRoutes from "./routes";
import firebase from "./shared/firebase";
import { updateFirebaseTime } from "./store/actions/firebase"

function App(props) {

    useEffect(() => {
        updateDataUsingFireBase()
    }, [])

    // console.log(props.firebase)


    const updateDataUsingFireBase = () => {
        // console.log(1)
        var starCountRef = firebase.database().ref("admin/karyakarta");
        // console.log(2)
        // console.log(starCountRef)
        starCountRef.on('value', (snapshot) => {
            
            // console.log(3)
            const data = snapshot.val();
            // console.log(data)
            if (data) {
                props.updateFirebaseTime(data)
            }
        });
    }

    return (
        <div className="App">
            <MainRoutes />
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        firebase: state.Firebase
    }
}

const mapDispatchToProps = { updateFirebaseTime }

export default connect(mapStateToProps, mapDispatchToProps)(App);
